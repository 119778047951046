<ng-container *ngIf="isFalse">
  <div id="profile-item"
class="summary-box profile-summary introjs-should-disabled"><div rel="group1"
  class="summary-link modal-link"
  data-bind="css: { 'ignored': isIgnored() }">
  <div class="card">
    <div class="flex col-md-12 job-buttons">
      <ul class="nav nav-pills nav-stack small fw-normal">
        <li class="nav-item" *ngIf="!hasApplied" stopPropagation (click)="applyForms(data?._id)">
          <a class="nav-link bg-light py-1 px-2 mb-0"> <i
              class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
        </li>
        <span> {{showDateApplied | date: "dd.MM.yyyy HH:mm" }}</span>
        <li class="nav-item" *ngIf="hasApplied" (click)="goToUserApplied(data?._id)">
          <a class="nav-link bg-light py-1 px-2 mb-0"> <i
              class="bi me-1 bi-box-arrow-up-right text-info pe-2"></i> {{"job.appliedJobs" | translate}}</a>
        </li>
        <ng-container *ngIf="!!this.isPage">

          <li class="nav-item" *ngIf="savedIDPage" (click)="unsaveProjectData(data?._id)" stopPropagation>
            <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="!savedIDPage" (click)="saveProjectPage(data?._id)" stopPropagation>
            <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
          </li>
        </ng-container>
        <ng-container *ngIf="!this.isPage">

          <li class="nav-item" *ngIf="isSaved" (click)="unsaveData(data?._id)" stopPropagation>
            <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="!isSaved" (click)="saveData(data?._id)" stopPropagation>
            <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
          </li>
        </ng-container>

        <li class="nav-item">
          <a class="nav-link bg-light py-1 px-2 mb-0"> <i
              class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
        </li>
        <li class="nav-item dropdown ms-lg-auto">
          <a class="nav-link bg-light py-1 px-2 mb-0" stopPropagation id="feedActionShare"
            data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots"></i>
          </a>
          <!-- Dropdown menu -->
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
            <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
            <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
              </a></li>
            <li *ngIf="isIgnoredProject"><a stopPropagation class="dropdown-item" (click)="deleteFromIgnored()"><i
                  class="bi bi-trash me-2 fw-icon"></i>Delete</a></li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">
            <div class="flex">
              <img borderWidth="2" borderColor="#ffffff" [src]="this.image" ngx-gravatar
                [email]="'example@mail.com'" fallback="mp" size="30">

              <h5 *ngIf="!job?.pageID" class="ms-1">{{job?.firstName}} {{job?.lastName}}</h5>
              <h5 *ngIf="job?.pageID" class="ms-1">{{page?.name}}</h5>
            </div>

            <h5>{{job?.positionTitle}}</h5>
            <div style="margin-bottom: 0.5rem !important; display: flex; align-items: center;"><p style="margin-right: 0.25rem;"><i class="bi me-1 bi-briefcase"></i> {{'career.employmentType' | translate}}</p> <p>{{employmentTypeString | translate}}</p></div>
            <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
              <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
              <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
            </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
            <div style="display: flex; align-items: center">
              <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
            {{job?.street}}, {{job?.postalCode}}, {{job?.city}}, {{countryJsonObject?.EN}}</p>
            </div>
            <p><i class="bi me-1 bi-cash"></i>
              {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{jobSalary}}+ {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
            <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}: {{jobDurationString | translate}} - Start date {{job?.startDate | date: "dd.MM.yyyy"}}</p>
            <div style="display: flex; align-items: center; flex-wrap: wrap;" class="col-md-12 px-0">
              <div class="flex">
                <p><i class="bi me-1 bi-check2-all me-1"></i>
                </p>
                <p style="margin: 0;">{{"job.todo" | translate}}: </p>


              </div>
            <div *ngFor="let sp of job?.skillsOffer; let searchIndex = index"
            class="d-flex justify-content-between pr-1 pt-1 ms-1">
            <div class="card__text">
              {{sp.name}}
            </div>
          </div>
          </div>

            <div class="col-lg-12 mt-3  ms-lg-0 px-0" *ngIf="openMoreInfo">
              <div class="card-header px-0">
                <strong>{{ 'category.Skills' | translate }}</strong>
              </div>
              <div class="Text-Text-text pt-2">
                <strong>
                  {{ "job.secondGroup.tasks" | translate}}
                </strong>
              </div>

              <div class="Text-Text-text-wrapper">
                <app-chips [chips]="moreJobInfo?.skillsOffer" stopPropagation></app-chips>
              </div>
              <div class="Text-Text-text pt-2">
                <strong>
                  {{ "skills.pcKnowledge" | translate}}
                </strong>
              </div>

              <div class="Text-Text-text-wrapper">
                <app-chips [chips]="moreJobInfo?.pcKnowledge" stopPropagation></app-chips>
              </div>
              <div class="Text-Text-text pt-2">
                <strong>
                  {{ "skills.driveLicense" | translate}}
                </strong>
              </div>

              <div class="Text-Text-text-wrapper" style="margin-bottom: .5rem;">
                <app-chips [chips]="moreJobInfo?.driveLicense" stopPropagation></app-chips>
              </div>
              <strong>
                {{ "skills.language" | translate}}
              </strong>

            <div *ngFor="let language of moreJobInfo?.language; let iLang = index">
              <div class="Field-field-fieldBase">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    <div class="progress">
                      <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                        style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                        [style.font-size.px]="10">
                        <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                          {{language.name | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2">
              <div class="Text-Text-text">
                <strong>
                  {{ "skills.qualifications" | translate}}
                </strong>
              </div>

              <div class="Text-Text-text-wrapper">
                <app-chips [chips]="moreJobInfo?.qualifications" stopPropagation></app-chips>
              </div>
            </div>
              <br>
              <div class="card-header px-0">
                <strong>{{"job.description" | translate}}</strong>
                </div>
              <div class="card-body px-0">
                <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                  <div class="col-lg-12 mt-lg-0 ms-lg-0 px-0">
                    <div class="Text-Text-text-wrapper" [innerHTML]="job?.description | noSanitize">
                    </div>
                  </div>
                </div>

            </div>

          </div>
      </div>
      </div>

      <div class="button-bottom">
        <span style="align-items: self-end;
        display: flex;">{{job?.public?.startDate | date:"dd.MM.yyyy HH:mm"}}

        </span>
        <button type="button" class="btn btn-light" stopPropagation (click)="moreInfo(job?._id)" *ngIf="!openMoreInfo" stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
        <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i class="bi me-1 bi-dash"></i> Less</button>

      </div>

    </div>
  </div>
</div>
</div>
</ng-container>
<ng-container *ngIf="!!userSearchFromJob">
<ng-container *ngIf="!!userForSearch">
  <app-data-item-details [isUserSearch]="true" [employmentTypeInput]="true" [isJob]="true" [isObjectSearch]="true" (sendContact)="contactUser($event)" [showForApply]="false"  [isJob]="true" [published]="false" [data]="userForSearch" [user]="user" [showUser]="false" [showSkills]="true" (searchObjectEvent)="searchObject(job._id)"></app-data-item-details>
</ng-container>
</ng-container>
<div *ngIf="isTrue">
<ng-container *ngIf="!!job">
  <app-data-item-details  (publicObject)="publicJob($event)" [employmentTypeInput]="true" [isJob]="true" [advertisment]="false" [applyFormAdvertisment]="false" (removeObjFromPublic)="removeJobFromPublic()" [isUserSearch]="false" [myObject]="true" (showOnlySelected)="showManage()" [published]="true"  [isJob]="true" [data]="job" [user]="user" [showUser]="false" [showSkills]="true" (searchObjectEvent)="searchObject(job._id)"></app-data-item-details>
</ng-container>
</div>
<div *ngIf="searchForJob">
  <ng-container *ngIf="!!job">
    <app-data-item-details [isUserSearch]="false" [advertismentHeader]="true" [applyFormAdvertismentHeader]="true" [employmentTypeInput]="true" [isJob]="true" (ignoreObject)="ignoreJob($event)" [isIgnored]="isIgnored" [showForApply]="true"  (saveObject)="saveJob($event)" (unsaveObject)="unsaveJob($event)" [isSaved]="isSaved" [hasApplied]="hasApplied" (applyObject)="applyJob($event)"  (goToAppliedData)="goToApplied()"   [published]="true"  [isJob]="true" [data]="job" [user]="user" [showUser]="false" [showSkills]="true" (searchObjectEvent)="searchObject(job._id)" [showUser]="true"></app-data-item-details>
  </ng-container>
  </div>
<div *ngIf="isProjectSearch">
<ng-container *ngIf="!!project && !!user">
  <app-job-item-details [data]="project" [isUserSelected]="user" [apply]="apply" [search]="'project.search'" [public]="'project.public'" [project]="true" [isProjectSearch]="true"  [isProject]="false" [isProjectSearchCompany]="false"></app-job-item-details>
</ng-container>
</div>
<div *ngIf="hasUserProjects">
<ng-container *ngIf="!!project">
  <app-job-item-details [hasUserProjects]="true" [data]="project" [apply]="apply" [search]="'project.search'" [public]="'project.public'" [project]="true" [isProjectSearch]="false" [isProject]="true"></app-job-item-details>
</ng-container>
</div>
<div *ngIf="!!isSearchUser">
  <app-job-item-details [userData]="userData" [isSearchUser]="true" [projectSearch]="projectSearch">{{userData | json}}</app-job-item-details>
</div>
<div *ngIf="!!isSearchPage">
  <app-job-item-details [pageData]="pageData" [isSearchPage]="true" [projectSearch]="projectSearch">{{pageData | json}}</app-job-item-details>
</div>

<div *ngIf="appliedProjects">
  <ng-container *ngIf="!!pageAppliedProject">
    <app-job-item-details  [projectData]="pageAppliedProject" [isProjectSearch]="true" [isPageApplyProject]="true" [pageObject]="pageObject"></app-job-item-details>
    </ng-container>
</div>
<div *ngIf="isSavedProject">
  <ng-container *ngIf="!!savedProject">
    <app-job-item-details [pageObject]="pageObject" [data]="savedProject" [apply]="apply" [search]="'project.search'" [public]="'project.public'" [project]="true" [isProjectSearch]="true" [isSavedProject]="true" (unsaveProjectPage)="removeProjectPage($event)" (unsaveProject)="test2($event)" [isPage]="true" [selectedPage]="selectedPage"  [isProjectSearch]="true" [isProjectSearchCompany]="false"></app-job-item-details>
  </ng-container>
  </div>
  <div *ngIf="appliedUserProjects">
    <ng-container *ngIf="!!appliedProjectUser">
      <app-job-item-details  [projectData]="appliedProjectUser" [isProjectSearch]="true" [isPageApplyProject]="true" [isUser]="true"></app-job-item-details>
      </ng-container>
  </div>

    <div *ngIf="isProjectCompanySearch">
      <ng-container *ngIf="!!isProjectCompanySearchData">
        <app-job-item-details [data]="isProjectCompanySearchData" (showSelected)="selectedPageID(selectedPageID)" [selectedPage]="selectedPageID" [apply]="apply" [search]="'project.search'" [public]="'project.public'" [project]="true" [isProjectSearch]="true" (ignoreProjectData)="ignoreProjectPage($event)" (unsaveProjectPage)="removeProjectPage($event)" [isSavedProject]="false"  [isProjectSearchCompany]="true" [isProject]="false" [isPage]="true"></app-job-item-details>
      </ng-container>
    </div>
<div *ngIf="savedJobs" >
  <ng-container *ngIf="!!job">
    <app-data-item-details [showUser]="true"  [isIgnored]="isIgnored" [employmentTypeInput]="true" [isJob]="true" [isUserSearch]="false"  [showForApply]="true"  (saveObject)="saveJob($event)" (unsaveObject)="unsaveJob($event)" [isSaved]="isSaved" [hasApplied]="hasApplied" (applyObject)="applyJob($event)"  (goToAppliedData)="goToApplied()"   [published]="true"  [isJob]="true" [data]="job" [user]="user" [showSkills]="true" (searchObjectEvent)="searchObject(job._id)"></app-data-item-details>
  </ng-container>

</div>
<div *ngIf="ignoredJobs">
  <ng-container *ngIf="!!job">
  <app-data-item-details [showUser]="true" [isUserSearch]="false" [employmentTypeInput]="true" [isJob]="true" [isIgnored]="isIgnored" [showForApply]="true"  (saveObject)="saveJob($event)" (unsaveObject)="unsaveJob($event)" [isSaved]="isSaved" [hasApplied]="hasApplied" (applyObject)="applyJob($event)" (unIgnoreObject)="unIgnore($event)" (goToAppliedData)="goToApplied()"   [published]="true"  [isJob]="true" [data]="job" [user]="user" [showSkills]="true" (searchObjectEvent)="searchObject(job._id)"></app-data-item-details>
    </ng-container>
</div>
<div *ngIf="appliedJobs">
  <ng-container *ngIf="!!job">
    <app-data-item-details (cancelApply)="cancelApply($event)"  [isApliedMessage]="true" [showUserMessage]="true" [employmentTypeInput]="true" [isJob]="true" [isIgnored]="isIgnored" [hasApplied]="hasApplied"  [isUserSearch]="false" [isApplied]="true" [published]="false"  [isJob]="true" (goToMessages)="goToMessages($event)" [data]="job" [user]="user"  [showUser]="false" [showSkills]="true" (searchObjectEvent)="searchObject(job._id)" ></app-data-item-details>
  </ng-container>
  </div>

<div *ngIf="userSearch">
  <div id="profile-item"
  class="summary-box profile-summary introjs-should-disabled"><div rel="group1"
    class="summary-link modal-link"
    data-bind="css: { 'ignored': isIgnored() }">
    <div class="card">
      <div class="card-body">
        <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">
              <h1>{{userSearchObject?.cv[0].newJobTitle}}</h1>
              <p style="margin-bottom: 0.5rem !important;">{{'career.employmentType' | translate}} {{employmentTypeString | translate}}</p>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
              </svg>{{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
           <!--   <div style="display: flex; align-items: center">
               <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:</p>
                <p style="margin-left: 0.5rem;">{{"search" | translate}} {{"area" | translate }} {{userSearchObject?.searchLocation.data[0].postalCode}} <span *ngIf="!userSearchObject?.searchLocation.data[0].postalCode">{{userSearchObject?.searchLocation.data[0].region}} </span> {{countryJsonObject?.EN}}</p>
              </div> -->
              <div style="display: flex; align-items: center">
               <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:</p>
                <p style="margin-left: 0.5rem;"> {{"Adresse"}}: {{userSearchObject?.cv[0].postalCode}} {{userSearchObject?.cv[0].city}}  {{countryJsonObject?.EN}}</p>
              </div>
              <div style="display: flex; align-items: center;">
                <h4 style="margin: 0; padding-right: 7px;">{{"job.searchingFor" | translate}}: </h4>
              <div *ngFor="let sp of userSearchObject?.searchSkillsFilter; let searchIndex = index"
              class="d-flex justify-content-between pr-1 pt-1">
              <div class="card__text">
                {{sp.name}}
              </div>
            </div>
            </div>
              <p>{{"job.fourthPage.paymentForm" | translate }}:* {{paymentID | translate}} {{jobSalary}}+ {{currencyUserSearchObject?.currency.Symbol}} ({{currencyUserSearchObject?.currency.Name}}) </p>
              <div class="col-lg-12 mt-3  ms-lg-0 px-0" *ngIf="openMoreInfo">
                <div class="card-header px-0">
                  <strong>{{ 'category.Skills' | translate }}</strong>
                </div>
                <div class="Text-Text-text pt-2">
                  <strong>
                    {{ "job.secondGroup.tasks" | translate}}
                  </strong>
                </div>

                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="userSearchObject?.cv[0].skills[0].subCategories[0].skillsOffer" stopPropagation></app-chips>
                  {{userSearchObject?.cv[0].skills.skillsOffer | json}}
                </div>
                <div class="Text-Text-text pt-2">
                  <strong>
                    {{ "skills.pcKnowledge" | translate}}
                  </strong>
                </div>

                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="userSearchObject?.cv[0].skills[0].subCategories[0].pcKnowledge" stopPropagation></app-chips>
                </div>

                <strong>
                  {{ "skills.language" | translate}}
                </strong>

              <div *ngFor="let language of userSearchObject?.cv[0].skills[0].subCategories[0].languages; let iLang = index">
                <div class="Field-field-fieldBase">
                  <div class="Text-Text-wrapper">
                    <div class="Text-Text-text-wrapper">
                      <div class="progress">
                        <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                          style="display: flex; white-space: pre-wrap;" [style.line-height.px]="23"
                          [style.font-size.px]="12">
                          <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                            {{language.name | translate}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2" *ngIf="userSearchObject?.cv[0].skills[0].subCategories[0].qualifications.length > 0">
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.qualifications" | translate}}
                  </strong>
                </div>

                <div class="Text-Text-text-wrapper">

                  <div *ngFor="let qualifications of userSearchObject?.cv[0].skills[0].subCategories[0].qualifications; let iLang = index">
                    <div class="Field-field-fieldBase">
                      <div class="Text-Text-wrapper">
                        <div class="Text-Text-text-wrapper">

                                {{qualifications.name}}</div>
                      </div>
                    </div>
                  </div>                </div>
              </div>
              <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2">
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.driveLicense" | translate}}
                  </strong>
                </div>

                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="userSearchObject?.cv[0].skills[0].subCategories[0].driveLicenses" stopPropagation></app-chips>
                </div>
              </div>

              <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2">
                <div class="card-header px-0">
                  <strong>{{ 'category.Education' | translate }}</strong>
                </div>

                <div class="Text-Text-text-wrapper">

                  <div *ngFor="let educationObj of userSearchObject?.cv[0].education[0].subCategories; let iLang = index">
                    <div pageContent class="Line-line-container">
                      <div class="Line-line-line">
                        <div class="Field-field-fieldBase first-template-fieldField">
                          <div class="Text-Text-wrapper">
                            <div  class="Text-Text-text">
                              {{educationObj?.startDate | date:'MM.yyyy'}}
                              <div class="float-right" *ngIf="!educationObj?.today">{{educationObj?.endDate |
                                date:'MM.yyyy'}}
                              </div>
                              <div class="float-right" *ngIf="educationObj?.today">{{'present' | translate}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Field-field-fieldBase first-template-fieldValue">
                        <div class="Text-Text-wrapper">
                          <div class="Text-Text-text-wrapper">
                            {{educationObj?.title}}
                          </div>
                        </div>
                      </div>
                      <div class="Field-field-fieldBase first-template-fieldValue">
                        <div class="Text-Text-wrapper">
                          <div class="Text-Text-text-wrapper">
                            <app-education-name [educationObj]="educationObj" [educationObject]="true">
                            </app-education-name>
                          </div>
                        </div>
                      </div>
                      <div class="Field-field-fieldBase first-template-fieldValue">
                        <div class="Text-Text-wrapper">
                          <div class="Text-Text-text-wrapper" aria-multiline="true"
                            [innerHTML]="educationObj?.description | noSanitize">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>                </div>
              </div>
              <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2">
                <div class="card-header px-0">
                  <strong>{{ 'category.Career' | translate }}</strong>
                </div>

                <div class="Text-Text-text-wrapper">

                  <div *ngFor="let careerObj of userSearchObject?.cv[0].careers[0].subCategories; let iLang = index">
                    <div class="Line-line-container">
                    <div class="Line-line-line">
                      <div class="Field-field-fieldBase first-template-fieldField">
                        <div class="Text-Text-wrapper">
                          <div pageContent class="Text-Text-text">
                            {{careerObj?.startDate | date:'MM.yyyy'}}
                            <div class="float-right" *ngIf="!careerObj?.today">{{careerObj?.endDate | date:'MM.yyyy'}}
                            </div>
                            <div class="float-right" *ngIf="careerObj?.today">{{'present' | translate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="Field-field-fieldBase first-template-fieldValue">
                      <div class="Text-Text-wrapper">
                        <div pageContent class="Text-Text-text-wrapper">
                          {{careerObj?.role}}
                        </div>
                      </div>
                    </div>
                    <div class="Field-field-fieldBase first-template-fieldValue">
                      <div class="Text-Text-wrapper">
                        <div pageContent class="Text-Text-text-wrapper">
                          <app-education-name [careerObj]="careerObj" [careerObject]="true"></app-education-name>
                        </div>
                      </div>
                    </div>
                    <div class="Field-field-fieldBase first-template-fieldValue">
                      <div class="Text-Text-wrapper">
                        <div pageContent class="Text-Text-text-wrapper" aria-multiline="true"
                          [innerHTML]="careerObj?.description | noSanitize">
                        </div>
                      </div>
                    </div>
                    <div class="Field-field-fieldBase first-template-fieldValue" *ngIf="careerObj?.showCompanyUrl">
                      <div class="Text-Text-wrapper">
                        <div pageContent class="Text-Text-text-wrapper">
                          <app-education-name [careerObj]="careerObj" [companyURL]="true"></app-education-name>
                        </div>
                      </div>
                    </div>
                      </div>
                      </div>
                      </div>
              </div>

            </div>
            </div>

        </div>
        <div class="buttons">
          <button type="button" (click)="contact(userSearchObject, job?._id)" class="btn btn-primary"><i class="bi me-1 bi-box-arrow-up-right"></i>
            Contact</button>
          <button type="button" class="btn btn-light" stopPropagation (click)="unsaveJob(job?._id)"><i class="bi me-1 bi-bookmark-fill"></i> <span>Save</span></button>
          <button type="button" class="btn btn-light"><i class="bi me-1 bi-eye-slash"></i>
             <span>Ignore</span></button>
          <button type="button" class="btn btn-light"><i class="bi me-1 bi-share"></i> <span>Share</span></button>
          <button type="button" class="btn btn-light"><i class="bi me-1 bi-three-dots"></i></button>      </div>
          <div class="button-bottom">
            <button type="button" class="btn btn-light" (click)="moreInfo()" *ngIf="!openMoreInfo" stopPropagation><i class="bi me-1 bi-plus"></i> More</button>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i class="bi me-1 bi-dash"></i> Less</button>

          </div>
      </div>
    </div>
  </div>
  </div>
</div>

