<div class="tab-content py-0 mb-0" *ngIf="!isPageApplyProject && !isSearchUser && !isSearchPage">
  <div class="fade tab-pane show active h-100">
    <div id="profile-item" stopPropagation class="summary-box profile-summary introjs-should-disabled">
      <div rel="group1" class="summary-link modal-link  mb-3" data-bind="css: { 'ignored': isIgnored() }">
        <div class="card">
          <div class="card-header"  *ngIf="!hasUserProjects">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center" >
                  <!-- Avatar -->
                  <div class="avatar me-2">
                      <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/logo/12.svg" alt=""> </a>
                  </div>
                  <!-- Info -->
                  <div>
                    <h5 *ngIf="!data?.pageID"  class="nav-item card-title mb-0"><a  href="" (click)="goToUser();preventDefault($event)" stopPropagation>{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}</a></h5>

                    <h5 *ngIf="data?.pageID" class="nav-item card-title mb-0"> <a  href=""  (click)="goToPageW();preventDefault($event)" stopPropagation> {{page?.name}} </a></h5>

                  </div>
              </div>
              <!-- Card share action START -->
              <div class="dropdown">
                  <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction2" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="bi bi-three-dots"></i>
                  </a>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                  </ul>
              </div>
              <!-- Card share action START -->
          </div>

          </div>
          <div class="flex row col-md-12 job-buttons" *ngIf="isJob">

            <button stopPropagation [disabled]="data?.public?.startDate" class="btn btn-sm btn-outline-success btn-sm btn-sm col-lg-3"
            (click)="searchJob()">
            {{search | translate}}
          </button>
          <button  class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation
          (click)="manageJob()">
            {{'job.manage' | translate}}
          </button>



            <button *ngIf="!project" class="btn btn-primary-soft btn-sm col-lg-2" (click)="publicJob()" stopPropagation
              [disabled]="data?.public?.startDate">
              {{public | translate}}
            </button>

          </div>
          <div class="flex col-md-12 job-buttons" *ngIf="(!isJob && isProject && !isProjectSearch)">
            <button stopPropagation [disabled]="!data?.public?.startDate" class="btn btn-sm btn-outline-success btn-sm btn-sm col-lg-3"
              (click)="searchProject(data?._id)">
              {{'project.search' | translate}}
            </button>
            <button *ngIf="!selectedProject" class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation
              (click)="manageProject(project?._id)">
              {{'project.manage' | translate}}
            </button>
            <button *ngIf="selectedProject" class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation
              (click)="closeManageProject(project?._id)">
              {{'project.closeManage' | translate}}
            </button>


            <button *ngIf="!data?.public?.startDate" class="btn btn-success-soft d-block btn-sm col-lg-3"
              (click)="publicProject(project?._id)" stopPropagation [disabled]="data?.public?.startDate">
              {{'project.public' | translate}}
            </button>
            <button *ngIf="data?.public?.startDate" class="btn btn-sm py-1 btn-danger-soft col-lg-3"
              (click)="removeProjectFromPublic(data)" stopPropagation>
              {{'job.isPublic' | translate}}
            </button>
            <li class="nav-item dropdown me-2">
              <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="bi bi-three-dots"></i>
              </a>
              <!-- Dropdown menu -->
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                  </a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
              </ul>
            </li>
          </div>
          <div class="flex col-12 job-buttons"
            *ngIf="(isProjectSearch && !isProject && !isProjectSearchCompany && !isSavedProject)">

            <ul class="nav nav-pills nav-stack small fw-normal">
              <li class="nav-item" *ngIf="!hasApplied" stopPropagation (click)="applyForms(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
              </li>
              <span> {{showDateApplied | date: "dd.MM.yyyy HH:mm" }}</span>
              <li class="nav-item" *ngIf="hasApplied" (click)="goToUserApplied(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-info pe-2"></i> {{"job.appliedJobs" | translate}}</a>
              </li>
              <ng-container *ngIf="!!this.isPage">

                <li class="nav-item" *ngIf="savedIDPage" (click)="unsaveProjectData(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="!savedIDPage" (click)="saveProjectPage(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
                </li>
              </ng-container>
              <ng-container *ngIf="!this.isPage">

                <li class="nav-item" *ngIf="isSaved" (click)="unsaveData(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="!isSaved" (click)="saveData(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
                </li>
              </ng-container>

              <li class="nav-item">
                <a *ngIf="!!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: data, isPage: true, url: page.pageUrl })"> <i
                    class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
                    <a *ngIf="!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: data, isUser: true, url: userCreatedJob.userUrl})"> <i
                      class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>

              </li>
              <li class="nav-item dropdown ms-lg-auto">
                <a class="nav-link bg-light py-1 px-2 mb-0" stopPropagation id="feedActionShare"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                    </a></li>
                  <li *ngIf="isIgnoredProject"><a stopPropagation class="dropdown-item" (click)="deleteFromIgnored()"><i
                        class="bi bi-trash me-2 fw-icon"></i>Delete</a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="flex col-12 job-buttons"
            *ngIf="(isProjectSearch && isSavedProject && !isProjectSearchCompany && !isJob)">

            <ul class="nav nav-pills nav-stack small fw-normal">
              <li class="nav-item" *ngIf="!hasApplied" stopPropagation (click)="applyForProject(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
              </li>
              <span> {{showDateApplied | date: "dd.MM.yyyy HH:mm" }}</span>
              <li class="nav-item" *ngIf="hasApplied" (click)="applyForProject(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-info pe-2"></i> {{"job.appliedJobs" | translate}}</a>
              </li>
              <ng-container *ngIf="!!this.isPage">

                <li class="nav-item" *ngIf="savedIDPage" (click)="unsaveProjectData(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="!savedIDPage" (click)="saveProjectPage(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
                </li>
              </ng-container>
              <ng-container *ngIf="!this.isPage">

                <li class="nav-item" *ngIf="isSaved" (click)="unsaveData(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="!isSaved" (click)="saveData(data?._id)" stopPropagation>
                  <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                      class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
                </li>
              </ng-container>

              <li class="nav-item">
                <a *ngIf="!!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: projectData, isPage: true, url: page.pageUrl })"> <i
                  class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
                  <a *ngIf="!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: projectData, isUser: true, url: userCreatedJob.userUrl})"> <i
                    class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
              </li>
              <li class="nav-item dropdown ms-lg-auto">
                <a class="nav-link bg-light py-1 px-2 mb-0" stopPropagation id="feedActionShare"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                    </a></li>
                  <li><a stopPropagation class="dropdown-item" (click)="deleteFromSaved()"><i
                        class="bi bi-trash me-2 fw-icon"></i>Delete</a></li>

                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="flex col-12 job-buttons"
            *ngIf="(!isProjectSearch && !isJob && !isProject && !isSavedProject && !isProjectSearchCompany)">

            <ul class="nav nav-pills nav-stack small fw-normal">
              <li class="nav-item" *ngIf="!hasApplied" (click)="applyForProject(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
              </li>
             <span> {{showDateApplied | date: "dd.MM.yyyy HH:mm" }}</span>
              <li class="nav-item" *ngIf="hasApplied" (click)="applyForProject(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-info pe-2"></i> {{"job.appliedJobs" | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="isSaved" (click)="unsaveData(data?._id)" stopPropagation>
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
              </li>
              <li class="nav-item" *ngIf="!isSaved" (click)="saveJobFromIgnored(data?._id)" stopPropagation>
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
              </li>
              <li *ngIf="isPage" class="nav-item" stopPropagation (click)="removeFromIngoredPageProject(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-eye-slash-fill text-danger pe-2"></i><span> {{'job.unignore' | translate}}</span>
                </a>
              </li>
              <li *ngIf="!isPage" class="nav-item" stopPropagation (click)="removeFromIgnored(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-eye-slash-fill text-danger pe-2"></i><span> {{'job.unignore' | translate}}</span>
                </a>
              </li>
              <li class="nav-item">
                <a *ngIf="!!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: projectData, isPage: true, url: page.pageUrl })"> <i
                  class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
                  <a *ngIf="!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: projectData, isUser: true, url: userCreatedJob.userUrl})"> <i
                    class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
              </li>
              <li class="nav-item dropdown ms-lg-auto">
                <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                    </a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="flex col-12 job-buttons" *ngIf="!!isProjectSearchCompany">
            <ul class="nav nav-pills nav-stack small fw-normal">
              <li class="nav-item" *ngIf="!isAppliedProjectPage" (click)="applyForms(data?._id)">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
              </li>
              {{showDateAppliedPage | date: "dd.MM.yyyy HH:mm"}}

              <li class="nav-item" *ngIf="isAppliedProjectPage">
                <a (click)="goToAppliedPageProjects()" stopPropagation class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-info pe-2"></i> {{"job.appliedJobs" | translate}}</a>
              </li>

              <li class="nav-item" *ngIf="isSavedProjectPage" (click)="unsaveProjectData(data?._id)" stopPropagation>
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-bookmark-fill text-info pe-2"></i><span>{{'job.unsave' | translate}}</span></a>
              </li>
              <li class="nav-item" *ngIf="!isSavedProjectPage" (click)="saveProjectPage(data?._id)" stopPropagation>
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
              </li>

              <li class="nav-item">
                <a *ngIf="!!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: projectData, isPage: true, url: page.pageUrl })"> <i
                  class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
                  <a *ngIf="!page" class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: projectData, isUser: true, url: userCreatedJob.userUrl})"> <i
                    class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
              </li>
              <li class="nav-item dropdown ms-lg-auto">
                <a class="nav-link bg-light py-1 px-2 mb-0" id="feedActionShare" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                    </a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <ng-container *ngIf="applyForm">
            <div class="d-sm-flex mt-1 ms-2">
              <span *ngIf="data.apply.status !== 7">Kostenpflichtig</span>
            </div>
            <div class="d-sm-flex align-items-end mt-1">

              <br/>
              <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config" [(ngModel)]="description" >
              </quill-editor>
              <button *ngIf="!isProjectSearchCompany" (click)="applyForProject()"stopPropagation [disabled]="!description" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
              <button *ngIf="!!isProjectSearchCompany" (click)="applyForPageProject()"stopPropagation [disabled]="!description" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
              <button (click)="cancelApplyForm()" stopPropagation class="btn btn-sm btn-primary ms-2"><i class="bi bi-x  fs-6"></i></button>

            </div>
          </ng-container>


          <div class="card-body">
            <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
              <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">




                <div [ngClass]="{ 'isActive': selectedFirstGroup && isUserProject}" (mouseenter)="selectFirstGroup()"
                  (click)="selectFirstGroup()" (mouseleave)="removeClick()">
                  <div>

                    <h6 class="nav-item card-title mb-0"> <a href=""  (click)="preventDefault($event)" > {{data?.positionTitle}} </a></h6>
                    <div *ngIf="employmentTypeInput"
                      style="margin-bottom: 0.5rem !important; display: flex; align-items: center;">
                      <p style="margin-right: 0.25rem;"><i class="bi me-1 bi-briefcase"></i> {{'career.employmentType' |
                        translate}}</p>
                      <p>{{employmentTypeString | translate}}</p>
                    </div>
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                        <path
                          d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        <path
                          d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                      </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
                    <div style="display: flex; align-items: center">
                      <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:

                        {{data?.street}}, {{data?.postalCode}}, {{data?.city}},
                        {{countryJsonObject?.EN}}</p>
                    </div>

                    <p><i class="bi me-1 bi-cash"></i>
                      {{"job.fourthPage.paymentForm" | translate }}: {{paymentIDForIncomes | translate}}
                      {{salaryStatus}}+
                      {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
                  </div>

                  <div *ngIf="selectedFirstGroup && isUserProject">
                    <div clickOutside (clickOutside)="removeClick()">
                      <span stopPropagation class="bi bi-pencil-fill edit-icon"
                        (click)="editFirstGroup({job:data, firstGroup: true})"></span>
                    </div>
                  </div>
                </div>
                <div [ngClass]="{ 'isActive': selectedSecondGroup && isUserProject}" (mouseenter)="selectSecondGroup()"
                  (mouseleave)="removeClick()" (click)="selectSecondGroup()">
                  <div>
                    <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                      {{jobDurationString | translate}} - Start date {{data?.startDate | date: "dd.MM.yyyy"}}</p>
                  </div>
                  <div *ngIf="selectedSecondGroup && isUserProject">
                    <div clickOutside (clickOutside)="removeClick()">
                      <span stopPropagation class="bi bi-pencil-fill edit-icon"
                        (click)="editSecondGroup({job:data, secondGroup: true})"></span>

                    </div>
                  </div>
                </div>

                <ng-container *ngIf="isProjectSearch">
                  <div style="display: flex; align-items: center; flex-wrap: wrap;" class="col-md-12 px-0">
                    <div class="flex">
                      <p><i class="bi me-1 bi-check2-all me-1"></i>
                      </p>
                      <p style="margin: 0;">{{"job.todo" | translate}}: </p>

                    </div>


                    <ng-container *ngIf="isProjectSearchCompany">
                      <div *ngFor="let sp of data?.tasks; let searchIndex = index"
                        class="d-flex justify-content-between pr-1 pt-1 ms-1">
                        <div class="card__text">
                          {{sp.name}}
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isProjectSearch && !isProjectSearchCompany">
                      <div *ngFor="let sp of data?.skillsOffer; let searchIndex = index"
                        class="d-flex justify-content-between pr-1 pt-1 ms-1">
                        <div class="card__text">
                          {{sp.name}}
                        </div>
                      </div>
                    </ng-container>

                  </div>
                </ng-container>
                <div class="col-lg-12 mt-3  ms-lg-0 px-0" *ngIf="openMoreInfo">
                  <ng-container *ngIf="!isUserProject">
                    <div class="card-header px-0" *ngIf="!isProjectSearchCompany">
                      <strong>{{ 'category.Skills' | translate }}</strong>
                    </div>
                    <ng-container *ngIf="isProject || isProjectSearch && !isProjectSearchCompany">
                      <div class="Text-Text-text pt-2">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="data?.skillsOffer" stopPropagation></app-chips>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isProjectSearchCompany">
                      <div class="card-header px-0">
                        <strong>{{ 'project.company' | translate }}</strong>
                      </div>
                      <div class="Text-Text-text pt-2">
                        <strong>
                          {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="data?.tasks" stopPropagation></app-chips>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isProject || isProjectSearch && !isProjectCompanySearch">
                      <div class="Text-Text-text pt-2">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="moreJobInfo?.pcKnowledge" stopPropagation></app-chips>
                      </div>
                      <div class="Text-Text-text pt-2">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper" style="margin-bottom: .5rem;">
                        <app-chips [chips]="data?.driveLicense" stopPropagation></app-chips>
                      </div>
                      <strong>
                        {{ "skills.language" | translate}}
                      </strong>

                      <div *ngFor="let language of data?.language; let iLang = index">
                        <div class="Field-field-fieldBase">
                          <div class="Text-Text-wrapper">
                            <div class="Text-Text-text-wrapper">
                              <div class="progress">
                                <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                  style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                  [style.font-size.px]="10">
                                  <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                                    {{getLanguageName(language.name)?.value}} </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2">
                        <div class="Text-Text-text">
                          <strong>
                            {{ "skills.qualifications" | translate}}
                          </strong>
                        </div>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="data?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>
                    </ng-container>


                    <br>
                    <ng-container *ngIf="!isProjectSearch">
                      <div class="card-header px-0">
                        <strong>{{"description" | translate}}</strong>
                      </div>
                      <div class="card-body px-0">
                        <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                          <div class="col-lg-12 mt-lg-0 ms-lg-0 px-0">
                            <div class="Text-Text-text-wrapper" [innerHTML]="data?.description | noSanitize">
                            </div>
                          </div>
                        </div>

                      </div>
                    </ng-container>
                    <ng-container *ngIf="isProjectSearch">
                      <div class="card-header px-0">
                        <strong>{{"description" | translate}}</strong>
                      </div>
                      <div class="card-body px-0">
                        <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                          <div class="col-lg-12 mt-lg-0 ms-lg-0 px-0">
                            <div class="Text-Text-text-wrapper" [innerHTML]="data?.description | noSanitize">
                            </div>
                          </div>
                        </div>

                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="isUserProject">
                    <div *ngIf="data.company" class="remove-icon-border">
                      <div class="card-header px-0">

                        <span stopPropagation class="bi bi-trash3-fill remove-icon"
                          *ngIf="data?.freelancer && isUserProject" (click)="deleteProjectTasks(index)"></span>
                        <span stopPropagation class="bi bi-plus-circle-fill remove-icon"
                          *ngIf="!data?.freelancer && isUserProject" (click)="addFreelancer(index)"></span>

                        <strong>{{ 'project.company' | translate }}</strong>
                      </div>

                      <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
                        [ngClass]="{ 'isActive': selectedTasks && isUserProject}" (mouseenter)="selectTasks()"
                        (mouseleave)="removeClick()" (click)="selectTasks()">
                        <div class="Text-Text-text pt-2">
                          <strong>
                            {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                          </strong>
                        </div>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="data?.tasks" stopPropagation></app-chips>
                        </div>
                        <div *ngIf="selectedTasks && isUserProject">
                          <div clickOutside (clickOutside)="removeClick()">
                            <span stopPropagation class="bi bi-pencil-fill edit-icon"
                              (click)="editJobTasks({job:data, jobTasks: true})"></span>


                          </div>
                        </div>
                      </div>


                    </div>

                    <div *ngIf="data?.freelancer" class="remove-icon-border mt-4">


                      <div class="card-header px-0">
                        <span stopPropagation class="bi bi-trash3-fill remove-icon"
                          *ngIf="data?.company > 0 && isUserProject" (click)="deleteProjectFreelancer(index)"></span>
                        <span stopPropagation class="bi bi-plus-circle-fill remove-icon" (click)="addCompany()"
                          *ngIf="!data?.company && isUserProject"></span>
                        <strong>{{'project.freelancer' | translate}}</strong>
                      </div>
                      <div class="card-body px-0">

                        <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
                          [ngClass]="{ 'isActive': selectedSkillsOffer && isUserProject}"
                          (mouseenter)="selectSkillsOffer()" (mouseleave)="removeClick()" (click)="selectSkillsOffer()">
                          <div class="Text-Text-text">
                            <strong>
                              {{ "job.secondGroup.tasks" | translate}}
                            </strong>
                          </div>

                          <div class="Text-Text-text-wrapper">
                            <app-chips [chips]="data?.skillsOffer" stopPropagation></app-chips>
                          </div>
                          <div *ngIf="selectedSkillsOffer && isUserProject">
                            <div clickOutside (clickOutside)="removeClick()">
                              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                                (click)="editSkillsOffer({job:data, jobSkillsOffer: true})"></span>


                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data?.pcKnowledge?.length > 0"
                          [ngClass]="{ 'isActive': selectedPCKnowledge && isUserProject}"
                          (mouseenter)="selectPCKnowledge()" (mouseleave)="removeClick()" (click)="selectPCKnowledge()">
                          <div class="Text-Text-text">
                            <strong>
                              {{ "skills.pcKnowledge" | translate}}
                            </strong>
                          </div>

                          <div class="Text-Text-text-wrapper">
                            <app-chips [chips]="data?.pcKnowledge" stopPropagation></app-chips>
                          </div>
                          <div *ngIf="selectedPCKnowledge && isUserProject">
                            <div clickOutside (clickOutside)="removeClick()">
                              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                                (click)="editPCKnowledge({job:data, jobPCKnowledge: true})"></span>
                            </div>
                          </div>

                        </div>
                        <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data?.language?.length > 0"
                          [ngClass]="{ 'isActive': selectedLanguage && isUserProject}" (mouseenter)="selectLanguage()"
                          (mouseleave)="removeClick()" (click)="selectLanguage()">
                          <div class="Text-Text-text">
                            <strong>
                              {{ "skills.language" | translate}}
                            </strong>

                            <div *ngFor="let language of data?.language; let iLang = index">
                              <div class="Field-field-fieldBase">
                                <div class="Text-Text-wrapper">
                                  <div class="Text-Text-text-wrapper">
                                    <div class="progress">
                                      <div id="rate" [style.background]="'#d9d9d9'"
                                        [style.width]="language.rate + '20%'"
                                        style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                        [style.font-size.px]="10">
                                        <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                          id="language">
                                          {{getLanguageName(language.name)?.value}} </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedLanguage && isUserProject">
                            <div clickOutside (clickOutside)="removeClick()">
                              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                                (click)="editJobLanguage({job:data, jobLanguage: true, jobDescription: false})"></span>


                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data?.driveLicense?.length > 0"
                          [ngClass]="{ 'isActive': selectedDriveLicense && isUserProject}"
                          (mouseenter)="selectDriveLicense()" (mouseleave)="removeClick()"
                          (click)="selectDriveLicense()">
                          <div class="Text-Text-text">
                            <strong>
                              {{ "skills.driveLicense" | translate}}
                            </strong>

                            <div class="Text-Text-text-wrapper">
                              <app-chips [chips]="data?.driveLicense" stopPropagation></app-chips>
                            </div>
                          </div>
                          <div *ngIf="selectedDriveLicense && isUserProject">
                            <div clickOutside (clickOutside)="removeClick()">
                              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                                (click)="editJobDriveLicense({job:data, jobDriveLicense: true})"></span>


                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data?.qualifications?.length > 0"
                          [ngClass]="{ 'isActive': selectedQualifications && isUserProject}"
                          (mouseenter)="selectQualifications()" (mouseleave)="removeClick()"
                          (click)="selectQualifications()">
                          <div class="Text-Text-text">
                            <strong>
                              {{ "skills.qualifications" | translate}}
                            </strong>

                            <div class="Text-Text-text-wrapper">
                              <app-chips [chips]="data?.qualifications" stopPropagation></app-chips>
                            </div>
                          </div>
                          <div *ngIf="selectedQualifications && isUserProject">
                            <div clickOutside (clickOutside)="removeClick()">
                              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                                (click)="editQualifications({job:data, jobQualifications: true})"></span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>




                    <br>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2"
                      [ngClass]="{ 'isActive': selectedDescription && isUserProject}" (mouseenter)="selectDescription()"
                      (mouseleave)="removeClick()" (click)="selectDescription()">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "description" | translate}}
                        </strong>
                      </div>

                      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                        <div class="Text-Text-text-wrapper" [innerHTML]="data?.description | noSanitize">
                        </div>
                      </div>
                      <div *ngIf="selectedDescription && isUserProject">
                        <div clickOutside (clickOutside)="removeClick()">
                          <span class="bi bi-pencil-fill edit-icon" stopPropagation
                            (click)="editJobDescription({job:data, jobDescription: true})"></span>


                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>

            </div>



            <div class="button-bottom">
              <span  style="align-items: self-end;
            display: flex;" *ngIf="data?.public?.startDate">Published: {{data?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{data?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}

            </span>
              <ng-container>
                <button type="button" class="btn btn-light" (click)="moreInfo(data?._id)" *ngIf="!openMoreInfo"
                  stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
              </ng-container>
              <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                  class="bi me-1 bi-dash"></i> Less</button>

            </div>


          </div>
        </div>
        <div class="card mt-3 bg-none" *ngIf="selectedProject" style="background: none;">
          <app-board-dnd></app-board-dnd>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isSearchUser">

  <div id="profile-item" stopPropagation (click)="emmitProject(userData?._id)"
    class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link mb-3">
      <div class="card">
        <div class="flex col-12 job-buttons">
          <button type="button" class="btn btn-primary-soft btn-sm col-lg-2" *ngIf="isContactedUser" stopPropagation
            (click)="goToManage(userData)"><i class="bi me-1 bi-chat-dots"></i> {{'job.manage' | translate}}</button>
          <button type="button" class="btn btn-primary-soft btn-sm col-lg-2" *ngIf="!isContactedUser" stopPropagation
            (click)="contactUser(userData)"><i class="bi me-1 bi-chat-dots"></i> {{'contact' | translate}}</button>


          <button type="button" (click)="openShare({data: projectData, isUser: true, url: userCreatedJob.userUrl})" class="btn btn-success-soft d-block btn-sm col-lg-2"><i class="bi me-1 bi-share"></i>
            {{'job.share' | translate}}</button>
          <button type="button" class="btn btn-success-soft d-block btn-sm col-lg-1"><i
              class="bi me-1 bi-three-dots"></i></button>
        </div>
        <ng-container *ngIf="contactUserForm">
          <div class="d-sm-flex align-items-end">
            <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config"
              [(ngModel)]="contactMessage">
            </quill-editor>
            <button (click)="sendUserContactForm()" stopPropagation [disabled]="!contactMessage"
              class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
            <button (click)="cancelContact()" stopPropagation class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-x  fs-6"></i></button>

          </div>
        </ng-container>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">
              <div>
                <div>
                  <div class="flex">
                    <img borderWidth="2" borderColor="#ffffff" [src]="this.image" ngx-gravatar
                      [email]="'example@mail.com'" fallback="mp" size="30">

                    <h5 class="ms-1">{{userData?.firstName}} {{userData?.lastName}}</h5>
                  </div>
                 <!-- <span>{{'newJobTitle' | translate}}: {{userData?.cv.data[0]?.newJobTitle}} </span> -->

                  <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                      <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path
                        d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                    </svg> {{'project.firstPage.workplace' | translate}}: {{userRemote | translate}}</p>
                  <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
                    {{userData?.address.data[0]?.postalCode}}, {{userData?.address.data[0]?.city}},
                    {{countryJsonObjectForUser?.EN}}</p>
                  <p><i class="bi me-1 bi-cash"></i>
                    {{"job.fourthPage.paymentForm" | translate }}: {{paymentIDForUser | translate}}
                    {{userData?.incomes?.budget}}+
                    {{currencyUserSearchObject?.currency.Symbol}} ({{currencyUserSearchObject?.currency.Name}}) </p>
                  <div style="display: flex; align-items: center; flex-wrap: wrap;" class="col-md-12 px-0">
                    <div class="flex">
                      <p><i class="bi me-1 bi-check2-all me-1"></i>
                      </p>
                      <p style="margin: 0;">{{"skills.offer" | translate}}: </p>

                    </div>


                    <ng-container>
                      <div *ngFor="let sp of userData?.searchFilterSkills?.searchSkillsOffer.data; let searchIndex = index"
                        class="d-flex justify-content-between pr-1 pt-1 ms-1">
                        <div class="card__text">
                          {{sp.name}}
                        </div>
                      </div>
                    </ng-container>
                  </div>

                </div>
              </div>
              <div>
                <div>
                </div>
              </div>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div>


                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="userDataMore.cv?.data[0]?.skills[0]?.skillsOffer?.data"
                          stopPropagation></app-chips>
                      </div>
                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2"
                      *ngIf="userDataMore.cv.data[0]?.skills[0]?.pcKnowledge?.data?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="userDataMore.cv.data[0]?.skills[0]?.pcKnowledge?.data"
                          stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2"
                      *ngIf="userDataMore.cv.data[0]?.skills[0]?.languages?.data.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div
                          *ngFor="let language of userDataMore.cv.data[0]?.skills[0]?.languages?.data; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                      id="language">
                                      {{getLanguageName(language.name)?.value}} </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2"
                      *ngIf="userDataMore.cv.data[0]?.skills[0]?.driveLicenses?.data?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="userDataMore.cv.data[0]?.skills[0]?.driveLicenses?.data"
                            stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2"
                      *ngIf="userDataMore.cv.data[0]?.skills[0]?.qualifications?.data?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="userDataMore.cv.data[0]?.skills[0]?.qualifications?.data"
                            stopPropagation></app-chips>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2">
                      <div class="card-header px-0">
                        <strong>{{ 'category.Education' | translate }}</strong>
                      </div>

                      <div class="Text-Text-text-wrapper">

                        <div
                          *ngFor="let educationObj of userDataMore?.cv?.data[0]?.education?.subCategories; let iLang = index">
                          <div pageContent class="Line-line-container">
                            <div class="Line-line-line">
                              <div class="Field-field-fieldBase first-template-fieldField">
                                <div class="Text-Text-wrapper">
                                  <div class="Text-Text-text" style="display: flex;
                                            align-items: center;
                                            justify-content: space-around;">
                                    {{educationObj?.startDate | date:'MM.yyyy'}}
                                    <div class="float-right" *ngIf="!educationObj?.today">
                                      {{educationObj?.endDate |
                                      date:'MM.yyyy'}}
                                    </div>
                                    <div class="float-right" *ngIf="educationObj?.today">
                                      {{'present' | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="Field-field-fieldBase first-template-fieldValue">
                              <div class="Text-Text-wrapper">
                                <div class="Text-Text-text-wrapper">
                                  <b>{{educationObj?.title}}</b>
                                </div>
                              </div>
                            </div>
                            <div class="Field-field-fieldBase first-template-fieldValue">
                              <div class="Text-Text-wrapper">
                                <div class="Text-Text-text-wrapper">
                                  <app-education-name [educationObj]="educationObj" [educationObject]="true">
                                  </app-education-name>
                                </div>
                              </div>
                            </div>
                            <div class="Field-field-fieldBase first-template-fieldValue">
                              <div class="Text-Text-wrapper">
                                <div class="Text-Text-text-wrapper" aria-multiline="true"
                                  [innerHTML]="educationObj?.description | noSanitize">
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2">
                      <div class="card-header px-0">
                        <strong>{{ 'category.Career' | translate }}</strong>
                      </div>

                      <div class="Text-Text-text-wrapper">

                        <div *ngFor="let careerObj of userDataMore?.cv?.data[0]?.careers?.subCategories; let iLang = index">
                          <div class="Line-line-container">
                            <div class="Line-line-line">
                              <div class="Field-field-fieldBase first-template-fieldField">
                                <div class="Text-Text-wrapper">
                                  <div pageContent class="Text-Text-text" style="display: flex;
                                            align-items: center;
                                            justify-content: space-around;">
                                    {{careerObj?.startDate | date:'MM.yyyy'}}
                                    <div class="float-right" *ngIf="!careerObj?.today">
                                      {{careerObj?.endDate | date:'MM.yyyy'}}
                                    </div>
                                    <div class="float-right" *ngIf="careerObj?.today">
                                      {{'present' | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="Field-field-fieldBase first-template-fieldValue">
                              <div class="Text-Text-wrapper">
                                <div pageContent class="Text-Text-text-wrapper">
                                  <b>{{careerObj?.role}}</b>
                                </div>
                              </div>
                            </div>
                            <div class="Field-field-fieldBase first-template-fieldValue">
                              <div class="Text-Text-wrapper">
                                <div pageContent class="Text-Text-text-wrapper">
                                  <app-education-name [careerObj]="careerObj"
                                    [careerObject]="true"></app-education-name>
                                </div>
                              </div>
                            </div>
                            <div class="Field-field-fieldBase first-template-fieldValue">
                              <div class="Text-Text-wrapper">
                                <div pageContent class="Text-Text-text-wrapper" aria-multiline="true"
                                  [innerHTML]="careerObj?.description | noSanitize">
                                </div>
                              </div>
                            </div>
                            <div class="Field-field-fieldBase first-template-fieldValue"
                              *ngIf="careerObj?.showCompanyUrl">
                              <div class="Text-Text-wrapper">
                                <div pageContent class="Text-Text-text-wrapper">
                                  <app-education-name [careerObj]="careerObj" [companyURL]="true"></app-education-name>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="button-bottom justify-content-end">

            <button type="button" class="btn btn-light" style="align-items: self-end;" stopPropagation
              (click)="projectInfo(projectData?._id, $event)" *ngIf="!openMoreInfo"><i class="bi bi-plus"></i>
              More</button>
            <button type="button" class="btn btn-light" style="align-items: self-end;" (click)="lessInfo()"
              *ngIf="openMoreInfo" stopPropagation><i class="bi bi-dash"></i> Less</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isSearchPage">
  <div id="profile-item" stopPropagation (click)="emmitProject(userData?._id)"
    class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link mb-3">
      <div class="card">
        <div class="flex col-12 job-buttons">
          <button type="button" class="btn btn-primary-soft btn-sm col-lg-2" *ngIf="isContactedPage" stopPropagation
          (click)="goToManage(pageData)"><i class="bi me-1 bi-chat-dots"></i> {{'job.manage' | translate}}</button>
        <button type="button" class="btn btn-primary-soft btn-sm col-lg-2" *ngIf="!isContactedPage" stopPropagation
          (click)="contactPage(userData)"><i class="bi me-1 bi-chat-dots"></i> {{'contact' | translate}}</button>




          <button type="button" (click)="openShare({data: projectData, isUser: true, url: userCreatedJob.userUrl})" class="btn btn-success-soft d-block btn-sm col-lg-2"><i class="bi me-1 bi-share"></i>
            {{'job.share' | translate}}</button>
          <button type="button" class="btn btn-success-soft d-block btn-sm col-lg-1"><i
              class="bi me-1 bi-three-dots"></i></button>
        </div>

        <ng-container *ngIf="contactPageForm">
          <div class="d-sm-flex mt-1 ms-2">
            <span *ngIf="pageData?.offer?.status !== 7">Kostenpflichtig</span>
          </div>
        <br/>
          <div class="d-sm-flex align-items-end">
            <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config"
              [(ngModel)]="contactMessage">
            </quill-editor>
            <button (click)="sendPageContactForm()" stopPropagation [disabled]="!contactMessage"
              class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
            <button (click)="cancelPageContact()" stopPropagation class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-x  fs-6"></i></button>

          </div>
        </ng-container>

        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">
              <div>
                <div>
                  <div class="flex">
                    <img borderWidth="2" borderColor="#ffffff" [src]="this.image" ngx-gravatar
                      [email]="'example@mail.com'" fallback="mp" size="30">

                    <h5 class="ms-1">{{pageData?.name}}</h5>
                  </div>




                  <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                      <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path
                        d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                    </svg> {{'project.firstPage.workplace' | translate}}: {{userRemote | translate}}</p>


                  <ng-container *ngFor="let location of pageData?.locations">
                    <div> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:
                      <span>
                        {{location?.postalCode}}, {{location?.city}} {{countryJsonPage(location.c)?.EN |
                        translate}}</span>

                    </div>
                  </ng-container>
                  <p><i class="bi me-1 bi-cash"></i>
                    {{branchTitle | translate}} - {{subCategoryTitle | translate}}
                  </p>


                  <div style="display: flex; align-items: center; flex-wrap: wrap;" class="col-md-12 px-0">
                    <div class="flex">
                      <p><i class="bi me-1 bi-check2-all me-1"></i>
                      </p>
                      <p style="margin: 0;">{{"weOffer" | translate}}: </p>

                    </div>


                    <ng-container *ngFor="let sub of pageData?.categories.subCategory">
                      <div *ngFor="let sp of sub.keywords; let searchIndex = index"
                        class="d-flex justify-content-between pr-1 pt-1 ms-1">
                        <div class="card__text">
                          {{sp.name}}
                        </div>
                      </div>
                    </ng-container>
                  </div>

                </div>
              </div>
              <div>
                <div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-bottom justify-content-end">

            <button type="button" class="btn btn-light" style="align-items: self-end;" stopPropagation
              (click)="goToPage()"><i class="bi bi-plus"></i> More</button>


          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isPageApplyProject">
  <div id="profile-item" stopPropagation (click)="emmitProject(projectData?._id)"
    class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link mb-3">
      <div class="card">
        <div class="flex col-12 job-buttons">
          <button type="button" class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation (click)="message()"><i
              class="bi me-1 bi-chat-dots"></i> {{'job.message' | translate}}</button>
          <button stopPropagation type="button" class="btn btn-sm py-1 btn-danger-soft col-lg-3"
            (click)="cancel(job)"><i class="bi me-1 bi-x-circle"></i>
            {{'cancel' | translate}}</button>
          <button type="button" (click)="openShare({data: projectData, isUser: true, url: userCreatedJob.userUrl})" class="btn btn-success-soft d-block btn-sm col-lg-2"><i class="bi me-1 bi-share"></i>
            {{'job.share' | translate}}</button>
          <div class="dropdown">
            <button stopPropagation type="button" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2"
              id="chatcoversationDropdown2" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="false"><i class="bi me-1 bi-three-dots"></i></button>

            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chatcoversationDropdown2">
              <li><a class="dropdown-item" href="#"><i class="bi bi-check-lg me-2 fw-icon"></i>Mark as read</a></li>
              <li><a class="dropdown-item" href="#"><i class="bi bi-mic-mute me-2 fw-icon"></i>Mute conversation</a>
              </li>
              <li *ngIf="page" (click)="goToPage()"><a class="dropdown-item"><i
                    class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
              <li *ngIf="!page" (click)="viewProfile()"><a class="dropdown-item"><i
                    class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
              <li><a stopPropagation class="dropdown-item" (click)="deleteProject()"><i
                    class="bi bi-trash me-2 fw-icon"></i>Delete</a></li>
              <li class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#"><i class="bi bi-archive me-2 fw-icon"></i>Archive chat</a></li>
            </ul>
          </div>
        </div>
        <div *ngIf="cancelProject">
          <div class="d-sm-flex align-items-end">
            <quill-editor name="editor" stopPropagation [styles]="editorConfig" style="width:100%" [modules]="config"
              [(ngModel)]="cancelMessage">
            </quill-editor>
            <button *ngIf="isPage" (click)="cancelProjectButton()" [disabled]="!cancelMessage" class="btn btn-sm btn-primary ms-2"><i
                class="bi bi-send fs-6"></i></button>
                <button *ngIf="!isPage" (click)="cancelProjectUser()" [disabled]="!cancelMessage" class="btn btn-sm btn-primary ms-2"><i
                  class="bi bi-send fs-6"></i></button>
          </div>
        </div>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">

              <div class="flex">
                <img borderWidth="2" borderColor="#ffffff" [src]="this.image" ngx-gravatar [email]="'example@mail.com'"
                  fallback="mp" size="30">
                <h5 *ngIf="!projectData?.pageID" class="ms-1">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}
                </h5>
                <h5 *ngIf="projectData?.pageID" class="ms-1">{{page?.name}}</h5>
              </div>
              <div>
                <div>


                  <h5>{{projectData?.positionTitle}}</h5>
                  <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                      <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path
                        d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                    </svg> {{'project.firstPage.workplace' | translate}}: {{remoteForApply | translate}}</p>
                  <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}: {{projectData?.street}},
                    {{projectData?.postalCode}}, {{projectData?.city}}, {{countryJsonObject?.EN}}</p>
                  <p><i class="bi me-1 bi-cash"></i>
                    {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}}
                    {{projectData?.incomes.budget}}+
                    {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
                </div>
              </div>
              <div>
                <div>
                  <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                    {{dataDurationStringForApply | translate}} - Start date {{projectData?.startDate | date:
                    "dd.MM.yyyy"}}</p>
                  <div style="display: flex; align-items: center; flex-wrap: wrap;" class="col-md-12 px-0">
                    <div class="flex">
                      <p><i class="bi me-1 bi-check2-all me-1"></i>
                      </p>
                      <p style="margin: 0;">{{"job.todo" | translate}}: </p>

                    </div>

                    <ng-container *ngIf="projectData?.tasks">
                      <div *ngFor="let sp of projectData?.tasks; let searchIndex = index"
                        class="d-flex justify-content-between pr-1 pt-1 ms-1">
                        <div class="card__text">
                          {{sp.name}}
                        </div>
                      </div>
                    </ng-container>

                  </div>
                </div>
              </div>

              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <ng-container *ngIf="projectData.company">
                  <div class="card-header px-0">
                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>
                  <div class="Text-Text-text pt-2">
                    <strong>
                      {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                    </strong>
                  </div>

                  <div class="Text-Text-text-wrapper">
                    <app-chips [chips]="projectData?.tasks" stopPropagation></app-chips>
                  </div>
                </ng-container>
                <div *ngIf="projectData?.freelancer">
                  <span class="bi bi-trash3-fill remove-icon" *ngIf="projectData?.tasks.length > 0 && isMyProject"
                    (click)="deleteProjectFreelancer(index)"></span>
                  <span class="bi bi-trash3-fill remove-icon" *ngIf="!projectData?.company && isMyProject"
                    (click)="deleteProjectFreelancer(index)"></span>

                  <div class="card-header px-0">
                    <strong>{{'project.freelancer' | translate}}</strong>
                  </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="projectData?.skillsOffer" stopPropagation></app-chips>
                      </div>
                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="projectData?.pcKnowledge?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="projectData?.pcKnowledge" stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="projectData?.language?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of projectData?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;"
                                      id="language">
                                      {{getLanguageName(language.name)?.value}} </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="projectData?.driveLicense?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="projectData?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="projectData?.qualifications?.length > 0">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="projectData?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>




                <br>
                <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                  <div class="Text-Text-text">
                    <strong>
                      {{ "description" | translate}}
                    </strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                    <div class="Text-Text-text-wrapper" [innerHTML]="projectData?.description | noSanitize">
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="projectData?.public?.startDate">Published: {{projectData?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{projectData?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}

            </span>
            <button type="button" class="btn btn-light" storopagation (click)="projectInfo(projectData?._id, $event)"
              *ngIf="!openMoreInfo"><i class="bi bi-plus"></i> More</button>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi bi-dash"></i> Less</button>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-chat rounded-start-lg-0 border-start-lg-0 mb-3" *ngIf="messageSelected">
    <div class="card-body h-100">
      <div class="tab-content py-0 mb-0 h-100" id="chatTabsContent">
        <!-- Conversation item START -->
        <div class="fade tab-pane show active h-100" id="chat-1" role="tabpanel" aria-labelledby="chat-1-tab">
          <!-- Top avatar and status START -->

          <!-- Top avatar and status END -->
          <!-- Chat conversation START -->
          <div class="chat-conversation-content custom-scrollbar">
            <!-- Chat time -->
            <ng-container *ngIf="checkUserInPage">
              <div class="text-left small my-2">{{"Message"}}:   {{userApplied?.firstName}} {{userApplied?.lastName}} {{projectData?.manage_[0]?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
            </ng-container>
            <ng-container *ngIf="!checkUserInPage">
              <div *ngIf="pageId" class="text-left small my-2">{{"Message"}}:  {{page?.name}} {{projectData?.manage_[0]?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
              <div *ngIf="!pageId" class="text-left small my-2">{{"Message"}}:  {{userApplied?.firstName}} {{userApplied?.lastName}} {{projectData?.manage_[0]?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

            </ng-container>

            <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHTML]="projectData?.manage_[0]?.message?.description" disabled></div>
            <div class="row mt-1 mb-1 align-items-center" *ngIf="projectData?.manage_[0]?.cancel.status !== 2">
              <p *ngIf="!projectData" style="width: fit-content;">{{'jobText' | translate}}: </p>
              <p *ngIf="projectData" style="width: fit-content;">{{'projectText' | translate}}: </p>

               <button class="btn btn-sm py-1 btn-primary me-2 col-lg-2 col-4"   *ngIf="projectData?.manage_[0]?.cancel.status === 0" (click)="apply(userJob)">{{'job.apply' | translate}}</button>
               <button class="btn btn-sm py-1 btn-danger-soft col-lg-2 col-4" (click)="cancel()">{{'cancel' | translate}} </button>
             </div>
             <div class="row mt-1 mb-1 align-items-center" *ngIf="projectData?.manage_[0]?.cancel.status === 2">
              <p *ngIf="!projectData" style="width: fit-content;">{{'jobText' | translate}}: </p>
              <p *ngIf="projectData" style="width: fit-content;">{{'projectText' | translate}}: </p>
              <div class="bg-light text-secondary p-2 px-3 rounded-2" *ngIf="projectData?.manage_[0]?.cancel.description" [innerHTML]="projectData?.manage_[0]?.cancel.description" disabled></div>
          </div>
          <div *ngIf="cancelJob">
            <div class="d-sm-flex align-items-end">
              <quill-editor name="editorMessage"  style="width:100%" [modules]="config" [(ngModel)]="cancelMessage" >
              </quill-editor>
              <button (click)="cancelJobButton()" [disabled]="!cancelMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
            </div>
          </div>
             <hr>
             <div class="text-15 font-medium">Messages:</div>
            <!-- Chat message left -->
      <ng-container *ngFor="let comment of projectData?.manage_[0]?.comments">
        <div class="d-flex mb-1" *ngIf="comment.userID !== authService.userID">
          <div class="flex-shrink-0 avatar avatar-xs me-2">
            <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="">
          </div>
          <div class="flex-grow-1">
            <div class="w-100">
              <div class="d-flex flex-column align-items-start">
                <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHtml]="comment.body"></div>
                <div class="small my-2" *ngIf="projectData?.pageID"> {{page?.name}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                <div class="small my-2" *ngIf="!projectData?.pageID"> {{userHasWritten?.firstName}}  {{userHasWritten?.lastName}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

              </div>
            </div>
          </div>
        </div>
              <!-- Chat message right -->
              <div class="d-flex justify-content-end text-end mb-1" *ngIf="comment.userID === authService.userID">
                  <div class="w-100">
                    <div class="d-flex flex-column align-items-end">
                      <div class="bg-primary text-white p-2 px-3 rounded-2" [innerHtml]="comment.body"></div>
                      <div class="small my-2">{{userHasWritten?.firstName}} {{userHasWritten?.lastName}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                    </div>
                  </div>
                </div>


      </ng-container>

          </div>
          <!-- Chat conversation END -->
        </div>
                  <!-- Conversation item END -->
        <!-- Conversation item START -->
        <div class="fade tab-pane h-100" id="chat-2" role="tabpanel" aria-labelledby="chat-2-tab">
          <!-- Top avatar and status START -->
          <div class="d-sm-flex justify-content-between align-items-center">
            <div class="d-flex mb-2 mb-sm-0">
              <div class="flex-shrink-0 avatar me-2">
                <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="">
              </div>
              <div class="d-block flex-grow-1">
                <h6 class="mb-0 mt-1">Carolyn Ortiz</h6>
                <div class="small text-secondary"><i class="fa-solid fa-circle text-danger me-1"></i>Last active 2 days</div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <!-- Call button -->
              <a href="#!" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Audio call"><i class="bi bi-telephone-fill"></i></a>
              <a href="#!" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Video call"><i class="bi bi-camera-video-fill"></i></a>
              <!-- Card action START -->
              <div class="dropdown">
                <a class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" href="#" id="chatcoversationDropdown2" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chatcoversationDropdown2">
                  <li><a class="dropdown-item" href="#"><i class="bi bi-check-lg me-2 fw-icon"></i>Mark as read</a></li>
                  <li><a class="dropdown-item" href="#"><i class="bi bi-mic-mute me-2 fw-icon"></i>Mute conversation</a></li>
                  <li><a class="dropdown-item" href="#"><i class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
                  <li><a class="dropdown-item" href="#"><i class="bi bi-trash me-2 fw-icon"></i>Delete chat</a></li>
                  <li class="dropdown-divider"></li>
                  <li><a class="dropdown-item" href="#"><i class="bi bi-archive me-2 fw-icon"></i>Archive chat</a></li>
                </ul>
              </div>
              <!-- Card action END -->
            </div>
          </div>
          <!-- Top avatar and status END -->
          <hr>

          <!-- Chat conversation END -->
        </div>

        <!-- Conversation item END -->
      </div>
    </div>
    <div class="card-footer">
      <div class="d-sm-flex align-items-end">
        <quill-editor name="editor"  style="width:100%" [modules]="config" [(ngModel)]="writeMessage" >
        </quill-editor>
        <button class="btn btn-sm btn-danger-soft ms-sm-2"><i class="fa-solid fa-face-smile fs-6"></i></button>
        <button class="btn btn-sm btn-secondary-soft ms-2"><i class="fa-solid fa-paperclip fs-6"></i></button>
        <button (click)="sendMessage(data?.manage_[0])" [disabled]="!writeMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
      </div>
    </div>
  </div>

</ng-container>

