import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-applied-jobs',
  templateUrl: './applied-jobs.component.html',
  styleUrls: ['./applied-jobs.component.scss']
})
export class AppliedJobsComponent implements OnInit, OnDestroy {
  public user: User;
  public subscription: Subscription;
  public appliedJobs = [];


  constructor(public userService: UserService,
    jobService: JobsService,
    public authService: AuthService,
    public cd: ChangeDetectionService,
    public router: Router) { }
  ngOnInit(): void {
  // this.userService.getTestAppliedJobs();
  //  this.userService.getTestAppliedJobs();
    this.cd.emmitChanges.subscribe(t => {
      if(!!t) {

        this.userService.getUserContactData(this.authService.userID).subscribe(res => {
          this.user = res;
        });
      }
    });

    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.user = res;

    });
  }
  ngOnDestroy(): void {
    }

    createProject() {

      this.router.navigateByUrl(this.user?.userUrl+ "/jobs/create");
  }
  goToMyProjects() {
      this.router.navigateByUrl(this.user?.userUrl + "/jobs");

}

navigateToSaved() {

    this.router.navigateByUrl(this.user?.userUrl + "/jobs/saved");

}

}
