
<div class="container col-md-12" *ngIf="!newDesign">
  <div class="row">
    <div class="col-md-1">
      <img *ngIf="hasPhoto" [src]="this.imgProfile" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
      <!--  <img *ngIf="!hasPhoto" ngx-gravatar src="../../../assets/img/profile_img.png"  borderWidth="2" size="150" > -->
      <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    </div>
    <div class="col-md-9 px-0">
      <span>{{this.userName}}</span>
      <div class="col-md-12" *ngIf="editMode" style="display: flex;">
        <div class="" *ngFor="let privacy of privacies; let i = index">
          <div>
          <input type="checkbox" [ngModelOptions]="{standalone: true}" [checked]="privacy.checked" (change)="addRole($event, privacy)" [(ngModel)]="privacy.isChecked">
          {{privacy.name | translate}}
        </div>
        </div>
      </div>
    </div>
    <div class="col-md-2" style="display: flex;">
      <button *ngIf="!editMode" class="btn btn-light" (click)="editRole(roleDetail)">Edit</button>
      <a class="px-1" *ngIf="!editMode">
        <div class="dropdown">
          <button class="btn btn-light dropdown-toggle"  type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="fa fa-ellipsis-h"></span>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position: relative !important; transform: translate3d(-17px, 0px, 0px) !important;">
              <button (click)="remove()" class="dropdown-item text-align-end"><span class="span-padding-left10">Remove</span></button>
          </div>
        </div>
      
      </a>
  
      <button *ngIf="editMode" class="btn btn-light me-1" (click)="save(roleDetail)">Save</button>
      <button *ngIf="editMode" class="btn btn-light" (click)="cancelRole()">Cancel</button>
    </div>
  </div>
</div>
<div *ngIf="newDesign">

        <div class="col-sm-12 mt-2 me-1">
          <!-- Birthday START -->
          <div class="d-flex align-items-center rounded border px-3 py-2"> 
            <!-- Date -->
            <p class="mb-0">
              <i class="bi bi-calendar-date fa-fw me-2"></i> {{this.userName}}
            </p>
            <div class="dropdown ms-auto">
              <!-- Card share action menu -->
              <a class="nav nav-link text-secondary mb-0"  id="aboutAction2" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots"></i>
              </a>
              <!-- Card share action dropdown menu -->
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                <li><a class="dropdown-item" (click)="editRole(roleDetail)" > <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</a></li>
                <li *ngIf="dataRole.length > 1"><a class="dropdown-item" (click)="remove()" > <i class="bi bi-trash fa-fw pe-2"></i>Delete</a></li>
              </ul>
            </div>
          </div>
          <!-- Birthday END -->
        </div>
       



      </div>
 