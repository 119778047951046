import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Job, Project } from 'src/app/shared/interfaces/model';
import ImageResize from 'quill-image-resize-module';
import { ImageHandler, VideoHandler } from "ngx-quill-upload";
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageHandler', ImageHandler);
Quill.register('modules/videoHandler', VideoHandler);
import _ from 'lodash'
import { LanguagesService } from 'src/app/shared/services/languages.service';
import { Duration, EmploymentType, Incomes, Remote } from 'src/app/shared/enums/enums-model';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { InitialState } from 'src/app/store/jobs-state/+state/jobs-state.reducer';
import { updateJob } from 'src/app/store/jobs-state/+state/jobs-state.actions';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { takeUntil } from 'rxjs/operators';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';


@Component({
  selector: 'app-edit-jobs-dialog',
  templateUrl: './edit-jobs-dialog.component.html',
  styleUrls: ['./edit-jobs-dialog.component.scss'],
})
export class EditJobsDialogComponent implements OnInit {
  public modules;
  empty="";
  public name;
  driveLicense = "";
  starCount = 5;
  public model;
  currency;
  minDate = new Date();
  selectedDate = new Date();
  startDateLimited = new Date();
  currentYear = new Date().getFullYear();
  currentDate = new Date().getDate();
  currentMonth = new Date().getMonth();
  endDateLimited = new Date(this.currentYear, this.currentMonth, this.currentDate + 1);
  public remoteArray = [
    { id: Remote.onSite, description: "job.firstPage.onSite" },
    { id: Remote.hybrid, description: "job.firstPage.hybrid" },
    { id: Remote.remote, description: "job.firstPage.remote" },
  ];


  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]
  public jobDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]

  languages = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditDetailsData,
  private dialogRef: MatDialogRef<EditJobsDialogComponent>,
  public languageService: LanguagesService,
  public http: HttpClient,
  public jobService: JobsService,
  public store: Store<InitialState>,
  public arraysService: ArraysService,
  public countryDataService: CountryDataService


  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data.job) {
      this.data.job = _.cloneDeep(this.data.job);
    } else if (this.data.project) {
      this.data.job = _.cloneDeep(this.data.project);
    }

    /*  this.http.get<any>(`assets/languages/${this.isUserSelected?.cv[0].language}/language.json`).subscribe(data => {

        this.languages = data;
        this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));

      }) */

    this.arraysService.showEmploymentTypes();
    this.countryDataService.countryData$.pipe().subscribe(res => this.model = res);

    this.modules = {
      "emoji-toolbar": true,
      "emoji-textarea": true,
      "emoji-shortname": true,
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        linkTarget: "_blank",
        source: (searchTerm, renderList, mentionChar) => {

          let values = [{ id: "", value: "", link: null }];

       if (mentionChar === "@") {


          }
          if (searchTerm.length === 0) {

            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
              /*  this.dataService.getUserModel(values[i].id).subscribe((data: Model) => {                  });
                matches.unshift(values[i]); */
              }


              renderList(matches, searchTerm);

            }
          }
        },
      },
      imageResize: true,
      imageDrop: true,

    }

  }

  saveData() {
    if (this.data.project) {
      this.dialogRef.close(this.data.job)
    } else {
      this.dialogRef.close(this.data.job)


    }
  // this.store.dispatch(updateJob({ jobid: this.data.job._id, job: this.data.job }));

  }

  addJobQualifications(event) {
    if (this.data.job?.qualifications?.length > 0) {
      this.data.job.qualifications.unshift({ name: this.name});
    } else {
      this.data.job.qualifications = [];
      this.data.job.qualifications.unshift({ name: this.name });
    }
    this.name = "";
  }
  deleteJobQualifications(index) {
  this.data.job.qualifications.splice(index, 1);
  }

  addJobDriveLicense() {
    if (this.data.job?.driveLicense?.length > 0) {
    } else {
      this.data.job.driveLicense = [];
    }
    this.data.job.driveLicense.unshift({name: this.driveLicense})
    this.driveLicense = "";
  }

  deleteJobDriveLicense(index) {
    this.data.job.driveLicense.splice(index, 1);
  }
  deleteLanguage(index) {
    this.data.job.language.splice(index, 1);
  }

  addJobLanguage(event) {
    this.data.job.language.unshift({name: event.description, rate: 0});
  }

  onRatingLanguageChanged(index, rating) {
    this.data.job.language[index].rate = rating * 20;
  }
  onRatingPCKnowledgeChanged(index, rating) {
    this.data.job.pcKnowledge[index].rate = rating * 20;
  }
  onRatingTasksChanged(index, rating) {
    this.data.job.skillsOffer[index].rate = rating * 20;
  }
  changeCurrency(event) {
    const object = this.model.find(r => r.ISO_3 === event);
    this.data.job.currency = object.currency.Alphabeticcode;
  }




  addJobPCKnowledge() {
    if (this.data.job?.pcKnowledge?.length > 0) {
    } else {
      this.data.job.pcKnowledge = [];
    }
    this.data.job.pcKnowledge.unshift({name: this.name, rate: 0})
    this.name = "";
  }
  deletePcKnowledge(index) {
    this.data.job.pcKnowledge.splice(index, 1);
  }

  deleteJobTask(index) {
    this.data.job.skillsOffer.splice(index, 1);
  }

  addSkillsOffer() {
    if(this.data.job.skillsOffer.length > 0) {
      this.data.job.skillsOffer.unshift({name: this.name, rate: 0});
    } else {
      this.data.job.skillsOffer = [];
      this.data.job.skillsOffer.unshift({name: this.name, rate: 0});
    }
    this.name = "";
  }

  addJobTasks() {
    if(this.data.job.tasks.length > 0) {
      this.data.job.tasks.unshift({name: this.name});
    } else {
      this.data.job.tasks = [];
      this.data.job.tasks.unshift({name: this.name});
    }

    console.log(this.data.job, "project")
    this.name = "";
  }


  changeCountry(event) {
    const object = this.model.find(r => r.ISO_3 === event);
    this.data.job.currency = object.currency.Alphabeticcode;
  }
}

export interface EditDetailsData {
job?: any;
project?: Project;
edit?: boolean;
jobDescription?: boolean;
jobPCKnowledge?: boolean;
jobQualifications?: boolean;
jobLanguage?: boolean;
jobDriveLicense?: boolean;
jobTasks?: boolean;
jobSkillsOffer?: boolean;
secondGroup?: boolean;
firstGroup?: boolean;
}


