import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Duration, EmploymentType, Incomes, Remote } from "src/app/shared/enums/enums-model";
import { Job, Location, Page, UserPage } from "src/app/shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { UserService } from "src/app/shared/services/user.service";
import {
  EditOffer,
  OfferDialogComponent,
} from "../modals/editors/offer-dialog/offer-dialog.component";
import {
  EditHeaderDialog,
  HeaderDialogComponent,
} from "../modals/header-dialog/header-dialog.component";
import _ from "lodash";
import { SenderService } from "src/app/shared/services/sender.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { Observable } from "rxjs";
import { Event, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { delay, filter } from "rxjs/operators";
import { JobsService } from "src/app/shared/services/jobs.service";
import { EditDetailsData, EditJobsDialogComponent } from "./edit-jobs-dialog/edit-jobs-dialog.component";
import { User } from "src/app/shared/interfaces/user.model";
import { CountryDataService } from "src/app/shared/services/country-data.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { CategoryService } from "src/app/shared/services/category.service";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit, OnDestroy {
  public user: User;
  public userJobs: User;
  public jobID;
  public locations = new Location();
  keyword;
  countryJson;
  selectedCountry: any;
  empty;
  categoryTitle = "";
branchTitle =""
  public jobsJson = [];
  isPage: boolean = false;

  get dataIsPage() {
   return localStorage.getItem("isPageSearchJob");
  }
  toggleOptions: Array<String> = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  public pages$: Observable<Page[]>;
  page: Page | any;
  pageIndex: number;
  userSelected = false;
  url = "";
  job: Job;
  countryJsonObject;
  public remote;
  public employmentTypeString;
  public jobDurationString;
  selectedWorkplace;
  salaryID;
   jobUrl = localStorage.getItem("jobUrl");

   public userID;
   publishedJobs;
   selectedIndex;
   public userPage: UserPage;


   public paymentID;


  public employmentType = [
    {
      id: EmploymentType.EmployedFullTime,
      description: "employmentType.EmployedFullTime",
    },
    {
      id: EmploymentType.EmployedPartTime,
      description: "employmentType.EmployedPartTime",
    },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    {
      id: EmploymentType.BordMember,
      description: "employmentType.Boardmember",
    },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    {
      id: EmploymentType.SelfEmployed,
      description: "employmentType.SelfEmployed",
    },
    {
      id: EmploymentType.Shareholder,
      description: "employmentType.Shareholder",
    },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" },
  ];

  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite" },
    { id: Remote.remote, description: "project.firstPage.remote" },
  ];
  public remoteArrayJob = [
    {
      id: Remote.onSite,
      description: "project.firstPage.onSite",
      percent: "0%",
    },
    {
      id: Remote.hybrid,
      description: "project.firstPage.hybrid",
      percent: "50%",
    },
    {
      id: Remote.remote,
      description: "project.firstPage.remote",
      percent: "100%",
    },
  ];
  public jobDurationArray = [
    {
      id: Duration.unlimited,
      description: "job.fourthPage.duration.unlimited",
    },
    {
      id: Duration.temporary,
      description: "job.fourthPage.duration.temporary",
    },
  ];
  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]
  public currencyObject;
  isEditingNote: boolean = false;

  constructor(
    public userService?: UserService,
    public authService?: AuthService,
    public http?: HttpClient,
    public dialog?: MatDialog,
    public cd?: ChangeDetectorRef,
    public receiverService?: SenderService,
    public pageService?: PagesService,
    public router?: Router,
    public jobService?: JobsService,
    public countryDataService?: CountryDataService,
    public branchesService?: BranchesService,
    public categoryService?: CategoryService,

  ) {}
  ngOnDestroy(): void {
    localStorage.removeItem("isPageSearchJob");
  }

  ngOnInit(): void {

    this.userService
      .getUserContactData(this.authService.userID)
      .subscribe((res) => {
        this.user = res;
        this.userJobs = _.cloneDeep(this.user);
      });
      this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);
    this.jobService.findJobs(this.authService.userID).subscribe(t => {
      this.jobID = t;
      this.jobID = this.jobID.job.filter((el, i, a) => i === a.indexOf(el));
      /*let ids = this.user?.ignoredJobs.find(t => t.jobId)?.jobId;
      this.jobID = this.jobID.filter(t => t !== ids); */
    })
    this.pageService.getMyPages();
    this.pages$ = this.pageService.pageModel();

    this.receiverService.dataForProject$.subscribe((res: Page) => {
      this.page = res;
    });

    if (localStorage.getItem("searchJobId")) {
      this.jobService
        .getJob(localStorage.getItem("searchJobId"))
        .subscribe((job) => {
          this.job = job;
          this.getCountryID(this.job?.c);
          this.getRemoteID(this.job?.remote);
          this.getEmploymentTypeID(this.job?.employmentType);
          this.getJoDurationID(this.job?.duration);
          this.getCurrencyID(this.job?.currency);
          this.getSalaryForm(this.job?.incomes.s);

          if (this.dataIsPage) {
            this.userService.getUsersSearch(this.job._id).pipe().subscribe(t =>  {
              this.userID = t.user;
             this.userID  = this.userID.filter((item, index, self) => self.indexOf(item) === index);
            }
            );
          }
          if (this.router.url ===  this.jobUrl + '/search') {
            console.log(this.jobUrl, "jobuRL")

          this.job.skillsOffer.forEach(t => {
            let postalCode = this.job.postalCode.toString();
            postalCode = postalCode.slice(0, 1);
            console.log(localStorage.getItem("searchJobId"), "localstorage");

              this.userService.getUsersSearch(this.job._id).subscribe(t =>  {
                this.userID = t.user;
               this.userID  = this.userID.filter((item, index, self) => self.indexOf(item) === index);
              }
                );
          });
           }
        });
    }

    this.router.events
      .pipe(
        delay(10),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.url = event.url;
      });
    this.url = this.router.url;


  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }
  onChange(value, index) {
    this.locations.c = value;
    this.user.searchLocation.data[index].c = value
 //   this.userService.updateUser(this.user._id, this.user);
  }
  addKeyword(data: EditOffer) {
    this.dialog
      .open(OfferDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.user.searchFilterSkills.searchSkillsOffer = res.skillsOffer;
        }
      });
  }
  onChangeCountry(value) {}
  editHeader(data: EditHeaderDialog) {
    this.dialog
      .open(HeaderDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === undefined) {
        }
      });
  }

  selectIndex(event) {
    this.pageIndex = event;
  }

  removeSkillOfferJob(index) {
    this.user.searchFilterSkills.searchSkillsOffer.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].skillsOffer = JSON.parse(
      JSON.stringify(
        this.userJobs.cv[0].skills[0].subCategories[0].skillsOffer
      )
    );
    this.userService.updateUser(this.user._id, this.user);
  }

  replaceSkillsOffer() {
    this.user.searchFilterSkills.searchSkillsOffer.data = [];
    this.user.searchFilterSkills.searchSkillsOffer =
      this.user.cv[0].skills[0].subCategories[0].skillsOffer;
    this.userService.updateUser(this.user._id, this.user);
  }

  selectionChanged(item, index) {
    console.log(this.user.searchLocation.data[index].region.length, "imdex");
    if (this.user.searchLocation.data[index]?.region.length > 1) {
      this.user.searchLocation.data[index].postalCode = null;
      this.user.searchLocation.data[index].postalCode = "";
    } else if (this.user.searchLocation.data[index]?.region.length === 0) {
      this.user.searchLocation.data[index].postalCode = null;
      this.user.searchLocation.data[index].postalCode = "";
    } else {
      this.user.searchLocation.data[index]?.region.forEach(
        (i) => (this.user.searchLocation.data[index].postalCode = i)
      );
    }
  }

  removeQualifications(index) {
    this.user.searchFilterSkills.searchQualifications.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].qualifications = JSON.parse(
      JSON.stringify(
        this.userJobs.cv[0].skills[0].subCategories[0].qualifications
      )
    );
    this.userService.updateUser(this.user._id, this.user);
  }

  removeLanguage(index) {
    this.user.searchFilterSkills.searchLanguages.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].languages = JSON.parse(
      JSON.stringify(
        this.userJobs.cv[0].skills[0].subCategories[0].languages
      )
    );
    this.userService.updateUser(this.user._id, this.user);
  }

  removePcKnowledge(index) {
    this.user.searchFilterSkills.searchPCKnowledge.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].pcKnowledge = JSON.parse(
      JSON.stringify(
        this.userJobs.cv[0].skills[0].subCategories[0].pcKnowledge
      )
    );
    this.userService.updateUser(this.user._id, this.user);
  }

  removeDriveLicense(index) {
    this.user.searchFilterSkills.searchDriveLicenses.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].driveLicenses = JSON.parse(
      JSON.stringify(
        this.userJobs.cv[0].skills[0].subCategories[0].driveLicenses
      )
    );
    this.userService.updateUser(this.user._id, this.user);
  }
  replaceLanguage() {
    this.user.searchFilterSkills.searchLanguages =
      this.user.cv[0].skills[0].subCategories[0].languages;
    this.userService.updateUser(this.user._id, this.user);
  }
  replaceQualifications() {
    this.user.searchFilterSkills.searchQualifications.data = [];
    this.user.searchFilterSkills.searchQualifications =
      this.user.cv[0].skills[0].subCategories[0].qualifications;
    this.userService.updateUser(this.user._id, this.user);
  }
  replaceDriveLicense() {
    this.user.searchFilterSkills.searchDriveLicenses.data = [];
    this.user.searchFilterSkills.searchDriveLicenses =
      this.user.cv[0].skills[0].subCategories[0].driveLicenses;
    this.userService.updateUser(this.user._id, this.user);
  }
  replacePCKnowledge() {
    this.user.searchFilterSkills.searchPCKnowledge.data = [];
    this.user.searchFilterSkills.searchPCKnowledge =
      this.user.cv[0].skills[0].subCategories[0].pcKnowledge;
    this.userService.updateUser(this.user._id, this.user);
  }

  deleteSearchFilter(index) {
    this.user.searchLocation.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
  }
  addNewSearchFilter() {
    this.user.searchLocation.data.unshift(this.user.searchLocation.data[0]);
    this.userService.updateUser(this.user._id, this.user);
  }

  createJob() {
    if (this.url.endsWith("/jobs") || this.url.endsWith("jobs")) {
      this.router.navigate([this.user.userUrl + "/jobs", "create"]);
    } else {
      this.router.navigate([
        this.page.pageUrl + "/jobs",
        "create",
        { isPage: true },
      ]);
    }
  }

  goToPageJobs() {
    this.router.navigateByUrl(this.page.page?.pageUrl + "/ourjobs");

  }

  createPageJob() {
    this.receiverService.send(this.page.page);
    this.router.navigate([this.page.page.pageUrl + "/create_job"]);

  }
  remoteCheck(event) {
    this.user.searchLocation.remote = event;
  }
  getCountryID(id) {
    this.countryJsonObject = this.countryJson.find((t) => t.ISO_3 === id);
  }
  getRemoteID(id) {
    this.remote = this.remoteArrayJob.find((test) => test.id === id)?.description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(
      (t) => t.id === id
    ).description;
  }
  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(
      (t) => t.id === id
    ).description;
  }

  getSalaryForm(id) {
    this.salaryID = this.salaryArray.find(t => t.id === id)?.description
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJson.find(
      (t) => t.currency.Alphabeticcode === id
    );
  }
  navigate() {
    if (this.router.url !== "/jobs") {
      let getRoute = this.router.url;
      getRoute = getRoute.slice(5, 100);
      console.log(getRoute, "getRoute");
     this.router.navigateByUrl(getRoute + "/ourjobs")
    } else {
      this.router.navigateByUrl(this.user.userUrl + "/jobs")
    }
  }
  editJobTasks(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.skillsOffer = res.skillsOffer;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editJobLanguage(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.language = res.language;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editPCKnowledge(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.pcKnowledge = res.pcKnowledge;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  selectWorkplace() {
  this.selectedWorkplace = true;
  }
  setNoteEditMode(mode) {
    this.isEditingNote = mode;
  }
  cancelNodeEdit() {
    this.selectedIndex = undefined;
    this.selectedWorkplace = false;
  }

  saveNote(item, index) {
    if (item) {
      this.user.searchLocation.data[index].postalCode = item.postalCode;
    }
    this.userService.updateUser(this.user._id, this.user);
    this.selectedIndex = undefined;
    this.selectedWorkplace = false;
  }

  goToMyJobs() {
    if (this.router.url.endsWith("search")) {
      this.router.navigateByUrl(this.user.userUrl + "/jobs")
      console.log("test");
    } else {
      this.router.navigateByUrl(this.user.userUrl + "/jobs")
    }
  }

  selectIndexForButtons(index) {
    this.selectedIndex = index;
    this.selectedWorkplace = false;
  }

  editJobDriveLicense(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.driveLicense = res.driveLicense;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editQualifications(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.qualifications = res.qualifications;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editFirstGroup(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.positionTitle = res.positionTitle;
        this.job.employmentType = res.employmentType;
        this.job.remote = res.remote;
        this.job.street = res.street;
        this.job.postalCode = res.postalCode;
        this.job.city = res.city;
        this.job.c = res.country;
        this.job.state = res.state;
        this.getCountryID(this.job.c);
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editSecondGroup(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.incomes.budget = res.incomes.budget;
        this.job.incomes.s = res.incomes.s;
        this.job.currency = res.currency;
        if (res.duration === Duration.unlimited) {
          this.job.duration = Duration.unlimited;
          this.job.startDate = res.startDate;
        } else if (res.duration === Duration.temporary) {
          this.job.duration = Duration.temporary;
          this.job.startDate = res.startDate;
        }
        this.job.currency = res.currency;
        this.getCurrencyID(this.job.currency);
        this.getJoDurationID(this.job.duration);
        this.jobService.updateJob(this.job._id, this.job);
        this.getPaymentFormID(this.job.incomes.s);
      }
    });
  }

  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find((t) => t.id === id).description;
  }
  onChangeSavedJobs(index) {
    console.log(index);
    console.log(this.user.savedJobs);
    this.user.savedJobs.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
  }

  showSelectedPage(userPage: any) {
    console.log(userPage, "jeje")
    this.userPage = userPage.page;
    this.getBranch(this.userPage.branches);
    this.findCategoryName(this.userPage.categories.name);
    localStorage.setItem("userPageID", userPage.page.pageId)
  }

  findCategoryName(event) {
    this.categoryTitle = this.categoryService.categories.find(r => r.id === event).id;
  }
  getBranch(id) {
    this.branchTitle = this.branchesService.branches.find(t => t.id === id);
  }
  selectIndexProject(event) {
    this.pageIndex = event;
  }

  navigateToSaved() {
    if (this.page?._id) {
      this.router.navigateByUrl(this.page?.pageUrl + "/projects/saved");
    } else {
      this.router.navigateByUrl(this.user?.userUrl + "/jobs/saved");

    }
  }
}

