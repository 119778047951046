<div class="assisting-content w-100">
  <div class="search-form-container">

    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="!isPage && !isSearchSidebar">
      <h2>{{'search_filter' | translate}}</h2>
      <div style="margin-bottom: 10px" *ngIf="!isPage">
        {{user?.searchLocation?.remote + 'ekekekke'}}
        <label class="px-0">{{ "project.firstPage.workplace" | translate }}:</label>
        <select id="remote" [ngModel]="user?.searchLocation?.remote"
          (ngModelChange)="remoteCheck($event); selectWorkplace()" class="col-12 form-control-sm ">

          {{user?.searchLocation?.remote}}
          <option *ngFor="let remote of arrayService.remoteArray" [ngValue]="remote.id" (change)="remoteCheck(remote)"
         >
            {{ remote.description | translate }}
          </option>
        </select>
        <div class="flex
        items-center" style="
        flex-direction: row;
        display: flex;
        border:none;
    " class="specialty col-12 px-0 mt-1">
          <ng-container *ngIf="selectedWorkplace">

            <button class="btn btn-light me-2" (click)="saveNote(searchLocation, index)">
              <i class="bi bi-check"></i>
            </button>
            <button (click)="cancelNodeEdit()" class="btn btn-light">
              <i class="bi bi-x"></i>
            </button>
          </ng-container>
        </div>
      </div>
      <div style="margin-bottom: 10px" *ngIf="isPage">
        <label class="px-0">{{ "project.firstPage.workplace" | translate }}:</label>
        <select id="remote" [ngModel]="page?.searchLocation?.remote"
          (ngModelChange)="remoteCheck($event); selectWorkplace()" class="col-8 form-control-sm ">
          <option *ngFor="let remote of arrayService.remoteArray" [ngValue]="remote.id" (change)="remoteCheck(remote)"
            [selected]="remote">
            {{ remote.description | translate }}
          </option>
        </select>
        <div class="flex
          items-center" style="
          flex-direction: row;
          display: flex;
          border:none;
      " class="specialty col-12 px-0 mt-1">
          <ng-container *ngIf="selectedWorkplace">

            <button class="btn btn-light me-2" (click)="savePage(searchLocation, index)">
              <i class="bi bi-check"></i>
            </button>
            <button (click)="cancelNodeEdit()" class="btn btn-light">
              <i class="bi bi-x"></i>
            </button>
          </ng-container>
        </div>
      </div>
      <div class="search-widget" *ngIf="!isPage">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <ng-container
              *ngFor="let searchLocation of user?.searchLocation?.data; let index = index;">
              <div class="col-12 pb-2 pt-2 specialty" id="sp">
                <span *ngIf="user?.searchLocation?.data.length > 1" class="bi bi-trash3-fill remove-icon col-2"
                  (click)="deleteSearchFilter(index)"></span>
                <div class="col-12" style="padding: .25rem;">
                  <select class="col-12 form-control-sm" name="selectedBranch" id="selectBranch"
                    [(ngModel)]="searchLocation.c"
                    (ngModelChange)="onChange($event, index);  selectIndexForButtons(index)" required>
                    <option hidden value="" disabled selected>{{selectedCountry?.ISO_2}}</option>
                    <option *ngFor="let country of countryJson" [value]="country.ISO_2" [selected]="selectedCountry"
                      (change)="onChangeCountry(country)">
                      {{ country.EN }} - ({{country.LokalName}})
                    </option>
                  </select>

                </div>
                <mat-button-toggle-group style="margin-bottom: 0.75rem;" class="mat-group-toggle-buttons"
                  [(value)]="searchLocation.region" #group="matButtonToggleGroup" multiple=true>
                  <mat-button-toggle *ngFor="let item of toggleOptions; let i = index"
                    (change)="selectionChanged($event, index); selectIndexForButtons(index)"
                    [value]="item">{{item}}</mat-button-toggle>
                </mat-button-toggle-group>
                <ng-container *ngIf="selectedIndex === index; else readOnlyNote">
                  <label class="column" *ngIf="searchLocation?.region.length < 2">
                    <p>{{"data.zip" | translate }}-{{"area" | translate}}</p>
                    <input type="text" class="form-control-sm col-8" style="margin-bottom: 0.75rem;"
                      [(ngModel)]="searchLocation.postalCode">
                  </label>
                  <div class="flex
                items-center" style="
                flex-direction: row;
                display: flex;
            ">
                    <ng-container *ngIf="selectedIndex === index">

                      <button class="btn btn-light me-2" (click)="saveNote(searchLocation, index)">
                        <i class="bi bi-check"></i>
                      </button>
                      <button (click)="cancelNodeEdit()" class="btn btn-light">
                        <i class="bi bi-x"></i>
                      </button>
                    </ng-container>
                  </div>

                </ng-container>
                <ng-template #readOnlyNote>
                  <label class="column col-12">
                    <p *ngIf="searchLocation?.region.length < 2">{{"data.zip" | translate }}-{{"area" | translate}}</p>
                    <input type="text" class="form-control-sm col-12" style="margin-bottom: 0.75rem;"
                      *ngIf="searchLocation?.region.length < 2" [(ngModel)]="searchLocation.postalCode"
                      (click)="setNoteEditMode(true); selectIndexForButtons(index)">
                  </label>
                </ng-template>

              </div>
            </ng-container>
            <button class="btn btn-light ms-1 button-header" (click)="addNewSearchFilter()">
              <i class="bi bi-plus-circle"></i></button>
          </li>

        </ul>
      </div>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="!isPage && !isSearchSidebar">
      <h2>{{"job.header" | translate}}</h2>

      <div class="search-widget">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <div class="col-12">
              <label>{{ "career.employmentType" | translate }} </label>
              <select name="selectedEmploymentType" id="selectedEmploymentType" disabled [(ngModel)]="userCV?.data[0].employmentType"
          class="col-md-12 form-control-sm" #selectedEmploymentType="ngModel"
          required>
          <option *ngFor="let employment of employmentType" [value]="employment.id" [selected]="employment">
            {{ employment.description | translate }}
          </option>
        </select>
            </div>
            <div class="col-12">
              <label class="px-0"> {{'newJobTitle' | translate}}
              </label>
              <input class="form-control-sm col-12" name="JobTitle" type="text"
                [(ngModel)]="userCV?.data[0].jobTitle" disabled>
            </div>
            <div class="col column col-12 mt-2">
              <label>{{ "incomes.name" | translate }} </label>
              <br>

              <ng-container *ngIf="!!isJob">
                <select name="selectedCurrency" id="selectedCurrency" [(ngModel)]="uUser?.incomes.s"
                class="col-12 form-control-sm" #selectedSalary="ngModel" disabled>
                <option *ngFor="let salary of titleService.salaryNotTotal" [value]="salary.id" [selected]="salary">
                  {{ salary.description | translate }}
                </option>
              </select>
              </ng-container>
              <ng-container *ngIf="!isJob">
                <select name="selectedCurrency" id="selectedCurrency" [(ngModel)]="uUser?.incomes.s"
                class="col-12 form-control-sm" #selectedSalary="ngModel" disabled>
                <option *ngFor="let salary of titleService.salary" [value]="salary.id" [selected]="salary">
                  {{ salary.description | translate }}
                </option>
              </select>
              </ng-container>


              <input class="form-control-sm col-12 mt-2" [(ngModel)]="uUser?.incomes.budget" name="hideName" type="text"
                value="" disabled />
              <select name="selectedSalary" id="selectedSalary" [(ngModel)]="user?.address.data[0].c"
                class="col-12 mt-2  form-control-sm" #selectedSalary="ngModel" disabled>
                <option *ngFor="let country of countryJson" [value]="country.ISO_2" [selected]="salary">
                  {{ country.currency.Symbol }} - ({{country.currency.Alphabeticcode}} - {{ country.currency.Name }}) -
                  {{ country.LokalName }}
                </option>
              </select>
            </div>

          </li>
          <button class="btn btn-light ms-1 button-header" (click)="editHeader({edit: true, model: userCV?.data[0], index: 0})">
            <i class="bi bi-pencil"></i></button>
        </ul>
      </div>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="!isPage && !isSearchSidebar">
      <h2>{{"job.searchingFor" | translate}}</h2>
      <div class="search-widget">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <div class="col-12 pb-2 pt-2 specialty" id="sp">
              <div class="card__body d-flex column">
                <div class="d-flex align-items-start card__body__list">
                  <div *ngFor="let sp of user?.searchFilterSkills?.searchSkillsOffer.data; let searchIndex = index"
                    class="d-flex justify-content-between pr-1 pt-1">
                    <div class="card__text">
                      {{sp.name}}
                      <button  [hidden]="user?.searchFilterSkills?.searchSkillsOffer?.data?.length < 2" class="btn btn-empty btn-sm py-0 px-0" type="button"
                        (click)="removeSkillOfferJob(searchIndex)"><i class="fa fa-remove"></i></button>
                    </div>
                  </div>
                  <button class="btn btn-light ms-1" (click)="replaceSkillsOffer()">
                    <i class="bi bi-arrow-repeat"></i></button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="!isPage && !isSearchSidebar">
      <h2>{{"skills.language" | translate}}</h2>
      <ng-container *ngIf="!!user?.searchFilterSkills?.searchLanguages.data">
          222
      <ng-container *ngIf="!!user">
        <app-sidebar-language [replace]="true" [data]="user?.searchFilterSkills?.searchLanguages?.data" [user]="user" [userCV]="userCV" [isUser]="true"></app-sidebar-language>
          </ng-container>
      </ng-container>

    </div>

    <!-- PC Knowledge-->
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="!isPage && !isSearchSidebar">
      <h2>{{"skills.pcKnowledge" | translate}}</h2>
      <ng-container *ngIf="!!user">
      <app-sidebar-pc-knowledge [user]="user" [userCV]="userCV" [isUser]="true"></app-sidebar-pc-knowledge>
    </ng-container>

    </div>

    <!-- Drive License -->
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="!isPage && !isSearchSidebar">
      <h2>{{"skills.driveLicense" | translate}}</h2>
      <ng-container *ngIf="!!user">
      <app-drive-license [user]="user" [isUser]="true" [userCV]="userCV"></app-drive-license>
      </ng-container>
    </div>

    <!-- Qualifications -->
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="!isPage && !isSearchSidebar">
      <h2>{{"skills.qualifications" | translate}}</h2>
      <app-qualifications [user]="user" [userCV]="userCV" [isUser]="true"></app-qualifications>
    </div>

    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="isPage && !isSearchSidebar">

      <h2>{{'search_filter' | translate}}</h2>
      <div style="margin-bottom: 10px">
        <label class="px-0">{{ "project.firstPage.workplace" | translate }}:</label>
        <select id="remote" [ngModel]="pageSearchLocation?.searchLocation?.remote" disabled
          (ngModelChange)="remoteCheckPage($event); selectWorkplace()" class="col-12 form-control-sm ">
          <option *ngFor="let remote of arrayService.remoteArray" [ngValue]="remote.id"
            (change)="remoteCheckPage(remote)" [selected]="remote">
            {{ remote.description | translate }}
          </option>
        </select>
        <div class="flex
              items-center" style="
              flex-direction: row;
              display: flex;
              border:none;
          " class="specialty col-12 px-0 mt-1">
          <ng-container *ngIf="selectedWorkplace">

            <button class="btn btn-light me-2" (click)="savePage(searchLocation, index)">
              <i class="bi bi-check"></i>
            </button>
            <button (click)="cancelNodeEdit()" class="btn btn-light">
              <i class="bi bi-x"></i>
            </button>
          </ng-container>
        </div>

      </div>
      <div class="search-widget">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <ng-container
              *ngFor="let searchLocation of pageSearchLocation?.searchLocation?.data; let index = index;  let isFirst = first;">
              <div class="col-12 pb-2 pt-2 specialty" id="sp">
                <span class="bi bi-trash3-fill remove-icon" *ngIf="pageSearchLocation?.searchLocation?.data?.length > 1"
                  (click)="deleteSearchFilterProject(index)"></span>
                <div class="col-12" style="padding: .25rem;">
                  <select class="col-12 form-control-sm" name="selectedBranch" id="selectBranch"
                    [(ngModel)]="searchLocation.c" (ngModelChange)="onChange($event)" required disabled>
                    <option hidden value="" disabled selected>{{selectedCountry?.ISO_2}}</option>
                    <option *ngFor="let country of countryJson" [value]="country.ISO_2" [selected]="selectedCountry"
                      (change)="onChange(country)">
                      {{ country.EN }} - ({{country.LokalName}})
                    </option>
                  </select>

                </div>
                <mat-button-toggle-group style="margin-bottom: 0.75rem;" class="mat-group-toggle-buttons"
                  [(value)]="searchLocation.region" #group="matButtonToggleGroup" multiple=true disabled>
                  <mat-button-toggle *ngFor="let item of toggleOptions;" (change)="selectionChanged($event)"
                    [value]="item">{{item}}</mat-button-toggle>
                </mat-button-toggle-group>
                <ng-container *ngIf="searchLocation?.region.length < 2">
                  <label class="column">
                    <p>{{"data.zip" | translate }}-{{"area" | translate}}</p>
                    <input disabled type="text" class="form-control-sm col-8" style="margin-bottom: 0.75rem;"
                      [(ngModel)]="searchLocation.postalCode" (ngModelChange)="selectIndexForButtonsPage(index)">
                  </label>
                </ng-container>
                <div class="flex
                items-center" style="
                flex-direction: row;
                display: flex;
                border:none;
            " class="specialty col-12 px-0 mt-1">
                  <ng-container *ngIf="selectedIndexPage === index">
                    <button class="btn btn-light me-2" (click)="saveNotePageProject(searchLocation, index)">
                      <i class="bi bi-check"></i>
                    </button>
                    <button (click)="cancelNodeEdit()" class="btn btn-light">
                      <i class="bi bi-x"></i>
                    </button>
                  </ng-container>
                </div>


              </div>

            </ng-container>
            <div class="specialty" style="border: none;">
              <button stopPropagation class="btn btn-light ms-1" (click)="replaceSearchFilterPage()" *ngIf="isPage">
                <i class="bi bi-arrow-repeat"></i></button>
            </div>
          </li>

        </ul>
      </div>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="isPage && !isSearchSidebar">


      <h2>{{"pagesInfo.categories" | translate}}</h2>

      <div class="search-widget">
        <div class="Text-Text-text px-0" style="font-weight: 700;">
          {{pageSearchLocation?.categories?.name | translate}}
        </div>
        <div class="specialty" style="border: none;">
          <button class="btn btn-light ms-1 mt-1 mb-1" (click)="addCategory()">
            <i class="bi bi-arrow-repeat"></i></button>
        </div>
        <br>
        <ul class="ui-selection-list">
          <li class="line form-row">
            <div class="col-12 pb-2 pt-2 px-0" id="sp">
              <div class="card__body d-flex column">
                <div class="d-flex align-items-start card__body__list">
                  <div *ngFor="let sp of pageSearchLocation?.categories?.subCategory; let ID = index"
                    class="d-flex justify-content-between pr-1 pt-1 column specialty">
                    <span *ngIf="page?.categories?.subCategory.length > 1" class="bi bi-trash3-fill remove-icon" (click)="deleteSubCategory(ID)"></span>
                    <label>{{ "categories.subCategories.name" | translate }} </label>

                    <div class="card__text">
                      <div pageContent class="Text-Text-text-wrapper" style="font-weight: 700;">
                        {{findSubCategoryName(sp?.name)?.name | translate}}
                      </div>
                    </div>
                    <button class="btn btn-light ms-1 mt-1 mb-1" (click)="addSubCategory(ID)">
                      <i class="bi bi-arrow-repeat"></i></button>
                    <div class="Field-field-fieldBase pt-3">
                      <div class="Text-Text-wrapper">
                        <div pageContent class="Text-Text-text">
                          <div class="float-left">{{"pagesInfo.keywords" | translate}}:*</div>
                        </div>
                      </div>
                    </div>
                    <div class="Field-field-fieldBase">
                      <div class="Text-Text-wrapper">
                        <div pageContent class="Text-Text-text-wrapper">
                          <div class="d-flex column">
                            <div class="d-flex  card__body__list">
                              <div *ngFor="let key of sp.keywords; let i=index;"
                                class="d-flex justify-content-between pr-1 pt-1 pb-1">
                                <div class="card__text">
                                  {{key.name}}
                                  <button [hidden]="sp.keywords.length < 2" class="btn btn-empty btn-sm py-0 px-0" type="button"
                                    (click)="removeKeyword(i, ID)"><i class="fa fa-remove"></i></button>
                                </div>

                              </div>
                              <button class="btn btn-light ms-1 mt-1 mb-1" (click)="addKeyword(ID)">
                                <i class="bi bi-arrow-repeat"></i></button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <ng-container *ngIf="isSearchSidebar">


    <div class="content-box is-disabled search-form" id="box_skills">
      <div class="col-md-12 px-0 pb-2">
       <strong> {{ data?.positionTitle}}</strong>
    </div>

                <label class="px-0">{{ "project.firstPage.workplace" | translate }}:</label>
                <select id="remote" [ngModel]="data?.remote"
                  (ngModelChange)="remoteCheck($event)" class="col-12 form-control-sm " disabled>
                  <option *ngFor="let remote of arrayService.remoteArray" [ngValue]="remote.id" (change)="remoteCheck(remote)"
                    [selected]="remote">
                    {{ remote.description | translate }}
                  </option>
                </select>
      <div class="search-widget">
        <ul class="ui-selection-list">
          <div>
            <p>{{data?.street}} <br> {{data?.postalCode}}  {{data?.city}} <br>
              {{countryJsonObject?.EN}} </p>
            </div>

        </ul>
      </div>
      <button class="btn btn-light ms-1 button-header"  (click)="editFirstGroup({data:data, firstGroup: true})">
        <i class="bi bi-pencil"></i></button>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills">
      <h2>{{"job.header" | translate}}</h2>

    <div class="search-widget">
      <ul class="ui-selection-list">
        <li class="line form-row">

        <div class="col-md-12" >
          <p>{{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{data?.incomes.budget}}+
            {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
          <p>{{'job.fourthPage.duration.jobDuration' | translate}}: {{jobDurationString | translate}}
            <br>
             Start date:
            {{data?.startDate | date: "dd.MM.yyyy"}}</p>
        </div>



        </li>
        <button class="btn btn-light ms-1 button-header"   (click)="editSecondGroup({data:data, secondGroup: true})">
          <i class="bi bi-pencil"></i></button>
      </ul>
    </div>
  </div>
  <h5>{{"job.searchingFor" | translate}}</h5>
  <ng-container *ngIf="data?.company">
    <div class="content-box is-disabled search-form" id="box_skills">
    <h2>{{"project.company" | translate}}</h2>
    <div class="search-widget">
      <li class="line form-row">
        <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
          <div class="card__body d-flex column">
            <div class="d-flex align-items-start card__body__list">
              <div *ngFor="let sp of data?.tasks; let searchIndex = index"
                class="d-flex justify-content-between pr-1 pt-1">
                <div class="card__text">
                  {{sp.name}}
                </div>
              </div>
              <button class="btn btn-light ms-1 button-header"  (click)="editJobTasks({data:data, jobTasks: true})">
                <i class="bi bi-pencil"></i></button>
            </div>
          </div>
        </div>
      </li>
    </div>
    </div>
  </ng-container>
    <ng-container *ngIf="data?.freelancer">
    <div class="content-box is-disabled search-form" id="box_skills">
      <h2>{{"project.freelancer" | translate}}</h2>

      <div class="search-widget">
        <li class="line form-row">
          <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
            <div class="card__body d-flex column">
              <div class="d-flex align-items-start card__body__list">
                <div *ngFor="let sp of data?.skillsOffer; let searchIndex = index"
                  class="d-flex justify-content-between pr-1 pt-1">
                  <div class="card__text">
                    {{sp.name}}
                  </div>
                </div>
                <button class="btn btn-light ms-1 button-header"  (click)="({data:data, jobSkillsOffer: true, isJob: true})">
                  <i class="bi bi-pencil"></i></button>
              </div>
            </div>
          </div>
        </li>
      </div>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills">
      <h2>{{"skills.language" | translate}}</h2>
      <ng-container *ngIf="!!data.language">
        <ng-container *ngIf="!!user">
          {{data.language | json}}
          <app-sidebar-language [data]="data?.language" [isUser]="true" [user]="user"></app-sidebar-language>
        </ng-container>
      </ng-container>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills" *ngIf="data?.pcKnowledge">
      <h2>{{"skills.pcKnowledge" | translate}}</h2>
      <div class="search-widget">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
              <div class="card__body d-flex column">
                <div class="d-flex align-items-start card__body__list">
                  <div *ngFor="let sp of data?.pcKnowledge; let i=index"
                    class="d-flex justify-content-between pr-1 pt-1">
                    <div class="card__text">
                      {{sp.name}}
                    </div>
                  </div>
                  <button class="btn btn-light ms-1 button-header" (click)="editPCKnowledge({job:job, jobPCKnowledge: true})">
                    <i class="bi bi-pencil"></i></button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills">
      <h2>{{"skills.driveLicense" | translate}}</h2>
      <div class="search-widget">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
              <div class="card__body d-flex column">
                <div class="d-flex align-items-start card__body__list">
                  <div *ngFor="let sp of data?.driveLicense; let i=index"
                    class="d-flex justify-content-between pr-1 pt-1">
                    <div class="card__text">
                      {{sp.name}}
                    </div>
                  </div>
                  <button class="btn btn-light ms-1 button-header" (click)="editJobDriveLicense({data:data, jobDriveLicense: true})">
                    <i class="bi bi-pencil"></i></button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-box is-disabled search-form" id="box_skills">
      <h2>{{"skills.qualifications" | translate}}</h2>
      <div class="search-widget">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
              <div class="card__body d-flex column">
                <div class="d-flex align-items-start card__body__list">
                  <div *ngFor="let sp of data?.qualifications; let i=index"
                    class="d-flex justify-content-between pr-1 pt-1">
                    <div class="card__text">
                      {{sp.name}}
                    </div>
                  </div>
                  <button class="btn btn-light ms-1 button-header" (click)="editQualifications({data:data, jobQualifications: true})">
                    <i class="bi bi-pencil"></i></button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    </ng-container>
    <ng-container *ngIf="!!isJob">
      <div class="content-box is-disabled search-form" id="box_skills">
        <h2>{{"project.freelancer" | translate}}</h2>

        <div class="search-widget">
          <li class="line form-row">
            <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
              <div class="card__body d-flex column">
                <div class="d-flex align-items-start card__body__list">
                  <div *ngFor="let sp of data?.skillsOffer; let searchIndex = index"
                    class="d-flex justify-content-between pr-1 pt-1">
                    <div class="card__text">
                      {{sp.name}}
                    </div>
                  </div>
                  <button class="btn btn-light ms-1 button-header"  (click)="editJobTasks({data:data, jobSkillsOffer: true, isJob: true})">
                    <i class="bi bi-pencil"></i></button>
                </div>
              </div>
            </div>
          </li>
        </div>
      </div>
      <div class="content-box is-disabled search-form" id="box_skills">
        <h2>{{"skills.language" | translate}}</h2>
        <ng-container *ngIf="data.language">
          <ng-container *ngIf="!!user">
            1



            <app-sidebar-language [replace]="false" [dataObj]="data" [data]="data?.language" [user]="user" [isUser]="true"></app-sidebar-language>
          </ng-container>
        </ng-container>

      </div>
      <div class="content-box is-disabled search-form" id="box_skills" *ngIf="data?.pcKnowledge">
        <h2>{{"skills.pcKnowledge" | translate}}</h2>
        <div class="search-widget">
          <ul class="ui-selection-list">
            <li class="line form-row">
              <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
                <div class="card__body d-flex column">
                  <div class="d-flex align-items-start card__body__list">
                    <div *ngFor="let sp of data?.pcKnowledge; let i=index"
                      class="d-flex justify-content-between pr-1 pt-1">
                      <div class="card__text">
                        {{sp.name}}
                      </div>
                    </div>
                    <button class="btn btn-light ms-1 button-header" (click)="editPCKnowledge({job:data, jobPCKnowledge: true, isJob: true})">
                      <i class="bi bi-pencil"></i></button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="content-box is-disabled search-form" id="box_skills">
        <h2>{{"skills.driveLicense" | translate}}</h2>
        <div class="search-widget">
          <ul class="ui-selection-list">
            <li class="line form-row">
              <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
                <div class="card__body d-flex column">
                  <div class="d-flex align-items-start card__body__list">
                    <div *ngFor="let sp of data?.driveLicense; let i=index"
                      class="d-flex justify-content-between pr-1 pt-1">
                      <div class="card__text">
                        {{sp.name}}
                      </div>
                    </div>
                    <button class="btn btn-light ms-1 button-header" (click)="editJobDriveLicense({job:data, jobDriveLicense: true, isJob: true})">
                      <i class="bi bi-pencil"></i></button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="content-box is-disabled search-form" id="box_skills">
        <h2>{{"skills.qualifications" | translate}}</h2>
        <div class="search-widget">
          <ul class="ui-selection-list">
            <li class="line form-row">
              <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
                <div class="card__body d-flex column">
                  <div class="d-flex align-items-start card__body__list">
                    <div *ngFor="let sp of data?.qualifications; let i=index"
                      class="d-flex justify-content-between pr-1 pt-1">
                      <div class="card__text">
                        {{sp.name}}
                      </div>
                    </div>
                    <button class="btn btn-light ms-1 button-header" (click)="editQualifications({job:data, jobQualifications: true, isJob: true})">
                      <i class="bi bi-pencil"></i></button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      </ng-container>
  </ng-container>
  </div>
</div>
