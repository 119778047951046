<main>
  
<div class="flex flex-col w-full h-full py-8 pl-8 pr-6" style="    background: white;
padding: 1rem;
margin-top: 1rem;">
  <!--  <breadcrumbs [items]="breadcrumbs"></breadcrumbs> -->
    <header class="flex justify-between text-textDarkest">
        <div class="social-links">
            <div class="col-12 col-lg-12 d-block d-lg-flex pt-2">
                <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-3">
                  <h1>{{job?.positionTitle}}</h1>
                  <div style="margin-bottom: 0.5rem !important; display: flex; align-items: center;" *ngIf="!isProject"><p style="margin-right: 0.25rem;"><i class="bi me-1 bi-briefcase"></i> {{'career.employmentType' | translate}}</p> <p>{{employmentTypeString | translate}}</p></div>
                  <p> <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                    <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                    <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                  </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
                  <div style="display: flex; align-items: center">
                    <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}: </p>
                     <p class="ms-1">{{job?.street}}, {{job?.postalCode}}, {{job?.city}}, {{countryJsonObject?.EN}}</p>
                   </div>
                  <p><i class="bi me-1 bi-cash"></i>{{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{job?.incomes.budget}}+ {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
                  <p><i class="bi me-1 bi-calendar-week"></i>{{'job.fourthPage.duration.jobDuration' | translate}}: {{jobDurationString | translate}} - Start date {{job?.startDate | date: "dd.MM.yyyy"}}</p>
    
                  </div>
</div>
</div>
    </header>
</div>
<br>
<app-board-dnd></app-board-dnd>
</main>