<div class="container">
    <div class="col-md-12 px-0">
        <div class="card mt-2">
          <div class="card-body">
            <div class="col-12 col-lg-12 d-block d-lg-flex pt-4">
              <div class="col-lg-12 mt-0-5 mt-lg-0 ">
                  <div>
                <h1>{{dataObject?.positionTitle}}</h1>
                <p style="margin-bottom: 0.5rem !important;"><i class="me-1 bi bi-briefcase"></i>{{'career.employmentType' | translate}}
                  {{employmentTypeString | translate}}</p>
                  <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-workspace" viewBox="0 0 16 16">
                    <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                    <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                  </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
                    <div style="display: flex; align-items: center">
                  
                      <div style="display: flex; align-items: center">
                        <p> <i class="bi bi-geo-alt"></i> {{'job.address' | translate}}:</p>
                        <p style="margin-left: 0.25rem;">{{dataObject?.street}}, {{dataObject?.postalCode}}, {{dataObject?.city}},
                          {{countryJsonObject?.EN}}</p>
                      </div>
                </div>
                </div>
                <div >
                    <p><i class="me-1 bi bi-cash"></i>{{"job.fourthPage.paymentForm" | translate }}:  {{paymentID | translate}} {{dataObject?.incomes.budget}}+
                      {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
                    <p><i class="me-1 bi bi-calendar-week"></i>{{'job.fourthPage.duration.jobDuration' | translate}}: {{jobDurationString | translate}} - Start date
                      {{dataObject?.startDate | date: "dd.MM.yyyy"}}</p>
                  </div> 
              </div>
              
              
              </div>
            </div>
    
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-header">
            <strong>{{ 'category.Skills' | translate }}</strong>
          </div>
          <div class="card-body">
              <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" >
                <div class="Text-Text-text">
                  <strong>
                    {{ "job.secondGroup.tasks" | translate}}
                  </strong>
                </div>
    
                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="dataObject?.skillsOffer" stopPropagation></app-chips>
                </div>
              </div>
    
            <div class="col-lg-12 mt-0-5 mt-lg-0  pb-2" *ngIf="dataObject?.pcKnowledge">
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.pcKnowledge" | translate}}
                  </strong>
                </div>
    
                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="dataObject?.pcKnowledge" stopPropagation></app-chips>
              </div>
            </div>
            <div class="col-lg-12 mt-0-5 mt-lg-0  pb-2" *ngIf="dataObject?.language.length > 0"
              >
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.language" | translate}}
                  </strong>
    
                <div *ngFor="let language of dataObject?.language; let iLang = index">
                  <div class="Field-field-fieldBase">
                    <div class="Text-Text-wrapper">
                      <div class="Text-Text-text-wrapper">
                        <div class="progress">
                          <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                            style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                            [style.font-size.px]="10">
                            <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                              {{language.name | translate}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-0-5 mt-lg-0  pb-2" *ngIf="dataObject?.driveLicense.length > 0"
             >
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.driveLicense" | translate}}
                  </strong>
    
                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="dataObject?.driveLicense" stopPropagation></app-chips>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-0-5 mt-lg-0  pb-2" *ngIf="dataObject?.qualifications"
         >
                <div class="Text-Text-text">
                  <strong>
                    {{ "skills.qualifications" | translate}}
                  </strong>
    
                <div class="Text-Text-text-wrapper">
                  <app-chips [chips]="dataObject?.qualifications" stopPropagation></app-chips>
                </div>
              </div>
            </div>
    
          </div>
    
    
      </div>
      <div class="card mt-2"
    >
        <div class="card-header">
          <strong>{{"job.description" | translate}}</strong>
        </div>
        <div class="card-body">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-3 pb-2">
              <div class="Text-Text-text-wrapper" [innerHTML]="dataObject?.description | noSanitize">
            </div>

        </div>

      </div>
</div>
