import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
 import { MainHomeComponent } from "./main-home.component";
import { RouteUserBlockedGuard } from "../guards/route-user-blocked.guard";

const routes: Routes = [
    {
        path: '',
        canActivate: [RouteUserBlockedGuard],
        children: [
          { path: '', redirectTo: '/', pathMatch: 'full' },
          {
            path: '',
            component: MainHomeComponent
          }]
        }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

  export class MainHomeRoutingModule{
  }
