import {Component, Inject, OnInit} from "@angular/core";
import {DriveLicense, Job, Model, Project} from "../../../../shared/interfaces/model";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import { AuthService } from "src/app/shared/services/auth.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { UserService } from "src/app/shared/services/user.service";
import { User } from "src/app/shared/interfaces/user.model";
import { Observable } from "rxjs";

@Component({
  selector: "app-drive-license-dialog",
  templateUrl: "./drive-license-dialog.component.html",
  styleUrls: ["./drive-license-dialog.component.scss"]
})
export class DriveLicenseDialogComponent implements OnInit {
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public user: User;
  driveLicense;
  public dataList$: Observable<any>;
  currentUser: User;
  uUser: User;
  userCV: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditDriveLicense,
              private dialogRef: MatDialogRef<DriveLicenseDialogComponent>,
              public authService: AuthService,
              public dialog: MatDialog,
              public userService: UserService) { }

  ngOnInit(): void {
    if (!this.data.projectDriveLicense && !this.data.jobDriveLicense) {
      this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe(result => {
        if (result) {
          this.user = result.user;
          this.uUser = result.userUser;
          this.userCV = result.cv;
          this.data.driveLicense = this.userCV.data[this.data.indexOfLang].skills[0].driveLicenses.data;
        }
      });

    } else if (this.data.projectDriveLicense) {
      this.data.project = new Project();
      this.data.project.driveLicense = [];
    } else if (this.data.jobDriveLicense) {
      this.data.job = new Job();
      this.data.job.driveLicense = [];

    }

  }
  addDriveLicense(event) {
    this.data.driveLicense.unshift({name: event.name});
  }
  delete(index) {
    this.data.driveLicense.splice(index, 1);
  }
  saveData() {
    if(this.authService.isLoggedIn) {
      console.log(this.user, "this.user")

      this.user.searchFilterSkills.searchDriveLicenses.data.unshift(...this.data.driveLicense);
      const data = this.user.searchFilterSkills.searchDriveLicenses.data.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name))

      this.user.searchFilterSkills.searchDriveLicenses.data = data;
      this.userCV.data[this.data.indexOfLang].skills[0].driveLicenses.data = this.data.driveLicense;

      this.userService.updateUser(this.user._id, this.user);

      this.userService.updateCV(this.userCV._id, this.userCV);


     this.dialogRef.close( this.userCV.data[this.data.indexOfLang].skills[0]);
  //  this.userService.updateUser(this.user._id, this.user);

   // this.dataService.updatePage(this.data.model._id, this.data.model);
  } else {
    this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.afterClosed().subscribe(result => {
        if(result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
  }
}

addProjectDriveLicense() {
  if (this.data.project?.driveLicense?.length > 0) {
  } else {
    this.data.project.driveLicense = [];
  }
  this.data.project.driveLicense.unshift({name: this.driveLicense})
  this.driveLicense = "";
}

removeProjectDriveLicense(index) {
  this.data.project.driveLicense.splice(index, 1);

}

saveDataProjectDriveLicense() {
  this.dialogRef.close(this.data.project);
}

saveDataJobDriveLicense() {
  this.dialogRef.close(this.data.job);
}

}

export interface EditDriveLicense {
  driveLicense?: DriveLicense[];
  edit?: boolean;
  model?: Model;
  projectDriveLicense?: boolean;
  project?: Project;
  jobDriveLicense?: boolean;
  job?: Job;
  indexOfLang?: number
}
