<div class="container" *ngIf="!router.url.includes('projects')">
<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <div class="column" style="display: flex;
      width: 31%">
      <mat-label style="text-align:center">Public for the time</mat-label>
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input  [min]="minDate" [rangePicker]="picker">
          <input matStartDate formControlName="start" [(ngModel)]="startDateLimited" placeholder="Start date">
          <input matEndDate formControlName="end" [(ngModel)]="endDateLimited"  placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div>
        <button type="submit" (click)="saveData()" mat-button>Save</button>
      </div>
</form>
</mat-step>

</mat-horizontal-stepper>
</div>

<div class="container" *ngIf="router.url.includes('projects')">
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <div class="column" style="display: flex;
        width: 31%">
  <div class="form-check px-0">
    <label class="form-check-label" for="ComRadio3">
      Who can Apply
    </label>
    <button  class="btn btn-outline-light" style="border: none">
      <ng-container *ngIf="project">
      <i [class]="iconOffer" (click)="openPrivacyOffer({enum: project.apply.status, offer: true, isProject: true})"></i>
    </ng-container>
    </button>
    <span class="d-flex" [innerHTML]="textOffer"></span>

  </div>
        <br>
        <mat-label style="text-align:center">Public for the time</mat-label>
        <mat-form-field appearance="fill">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input  [min]="minDate" [rangePicker]="picker">
            <input matStartDate formControlName="start" [(ngModel)]="startDateLimited" placeholder="Start date">
            <input matEndDate formControlName="end" [(ngModel)]="endDateLimited"  placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div>
          <button type="submit" (click)="publicProject()" mat-button>Save</button>
        </div>
  </form>
  </mat-step>

  </mat-horizontal-stepper>
  </div>
