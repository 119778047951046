import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Job } from 'src/app/shared/interfaces/model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-jobs',
  templateUrl: './user-jobs.component.html',
  styleUrls: ['./user-jobs.component.scss']
})
export class UserJobsComponent implements OnInit {
  public dataList$: Observable<any>;
  userData: any;
  jobs: Job[];

  constructor(
    public userService: UserService,

  ) { }

  ngOnInit(): void {
    this.dataList$ = this.userService.dataList;
    this.dataList$.pipe(take(1)).subscribe((res: any) => {
      //   this.privacyIcon = this.loggedUser;
      //  this.privacyText = this.loggedUser;

      this.userData = res;

      this.userService.getMyJobs(this.userData.userUser.jobs.data[0].jobListID).pipe(take(1)).subscribe((res: any) => {
        this.jobs = res.jobs
      })

    });
  }

}
