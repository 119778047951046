import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserContactsComponent } from "./user-friends/user-contacts.component";
import { UserJobsPublishedComponent } from "./user-jobs-published/user-jobs-published.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { SettingsNotificationComponent } from "./user-settings/settings-notification/settings-notification.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { UserComponent } from "./user/user.component";
import { AuthGuard } from "src/app/guards";
import { RouteUserBlockedGuard } from "src/app/guards/route-user-blocked.guard";
import { UserAboutComponent } from "./user-profile/user-about/user-about.component";
import { UserCareerEducationComponent } from "./user-profile/user-career-education/user-career-education.component";
import { UserFollowingComponent } from "./user-profile/user-following/user-following.component";
import { UserFollowersComponent } from "./user-profile/user-followers/user-followers.component";
import { UserProjectsPublishedComponent } from "./user-projects-published/user-projects-published.component";
import { UserProjectsComponent } from "./user-profile/user-projects/user-projects.component";
import { UserJobsComponent } from "./user-profile/user-jobs/user-jobs.component";

const routes: Routes = [
  {
    path: ":user", component: UserComponent, canActivate: [AuthGuard],

    children: [
      {path: "start", component: UserProfileComponent},
      {path: "about_user", component: UserProfileComponent, children: [
        {path: "", component: UserAboutComponent,
          children: [
            {path: "career_and_education", component: UserCareerEducationComponent},
          ]
        },

      ]},
      {path: "publicated/projects", component: UserProfileComponent, children: [
        {path: "", component: UserProjectsComponent}
      ]},
      {path: "publicated/jobs", component: UserProfileComponent, children: [
        {path: "", component: UserJobsComponent}
      ]},


      {path: "about_skills", component: UserProfileComponent},
      {path: "about_places", component: UserProfileComponent},
      {path: "about_contact_and_basic_info", component: UserProfileComponent},
      {path: "about_family_and_relationships", component: UserProfileComponent},




      {path:"connections", component: UserProfileComponent, canActivate: [RouteUserBlockedGuard], children: [
        {path:"following", component: UserFollowingComponent},
        {path:"followers", component: UserFollowersComponent},
        {path:"contacts", component: UserProfileComponent},
        {path:"hashtags", component: UserProfileComponent},

      ]},
      {path: "publicated/jobs", component: UserProfileComponent,  canActivate: [RouteUserBlockedGuard]},
      { path: "publicated/projects/:id", component: UserProfileComponent},
      {path: "publicated/jobs/:id", component: UserProfileComponent,  canActivate: [RouteUserBlockedGuard]},


      {path: "user_settings", component: UserSettingsComponent,  canActivate: [RouteUserBlockedGuard],
      children: [
        { path: "account", component: UserSettingsComponent },
        { path: "profile", component: UserSettingsComponent },
        { path: "information", component: UserSettingsComponent },
        { path: "privacy", component: UserSettingsComponent },
        { path: "followers", component: UserSettingsComponent },
        { path: "location", component: UserSettingsComponent },
        { path: "languages", component: UserSettingsComponent },
        { path: "stories", component: UserSettingsComponent },
        { path: "notification", component:  SettingsNotificationComponent},
        { path: "blocking", component: UserSettingsComponent },
        { path: "facerec", component: UserSettingsComponent },
        { path: "mobile", component: UserSettingsComponent },
        { path: "apps", component: UserSettingsComponent },
        { path: "games", component: UserSettingsComponent },
        { path: "business_integration", component: UserSettingsComponent },
        { path: "ads", component: UserSettingsComponent },
        { path: "ads_payment", component: UserSettingsComponent },
        { path: "support_inbox", component: UserSettingsComponent },
        { path: "videos", component: UserSettingsComponent }]
    }]
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserRoutingModule{}
