import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from "ngx-quill";
import { HttpClient } from '@angular/common/http';
import { MaterialModule } from 'src/app/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PublicJobComponent } from './public-job/public-job.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobItemDetailsModule } from 'src/app/job-item-details/job-item-details.module';
import { BookmarksModule } from '../bookmarks/bookmarks.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { createTranslateLoader } from 'src/app/app.module';


@NgModule({
  declarations: [

    PublicJobComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    MaterialModule,
    JobItemDetailsModule,
    BookmarksModule,
    DirectivesModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    QuillModule.forRoot(),
  ],

  exports: [
  ]
})
export class JobsModule { }
