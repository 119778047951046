
<main>

  <!-- Container START -->
  <div class="container">
		<div class="row gx-0">
      <!-- Sidebar START -->
      <div class="col-lg-4 col-xxl-3" id="chatTabs" role="tablist">

        <!-- Divider -->
				<div class="d-flex align-items-center mb-4 d-lg-none">
					<button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
						<span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">Chats</span>
					</button>
				</div>
				<!-- Advanced filter responsive toggler END -->
        <div class="card card-body border-end-0 border-bottom-0 rounded-bottom-0">
          <div class=" d-flex justify-content-between align-items-center">
            <h1 class="h5 mb-0">Active chats <span class="badge bg-success bg-opacity-10 text-success">{{userMessages.length}}</span></h1>
            <!-- Chat new create message item START -->
            <div class="dropend position-relative">
              <div class="nav">
                <a class="icon-md rounded-circle btn btn-sm btn-primary-soft nav-link toast-btn" data-target="chatToast" href="#" > <i class="bi bi-pencil-square"></i> </a>
              </div>
            </div>
            <!-- Chat new create message item END -->
          </div>
        </div>

        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
						<div class="offcanvas-header">
							<button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"></button>
						</div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <div class="card card-chat-list rounded-end-lg-0 card-body border-end-lg-0 rounded-top-0">

                <!-- Search chat START -->
                <form class="position-relative">
                  <input class="form-control py-2" type="search" placeholder="Search for chats" aria-label="Search">
                  <button class="btn bg-transparent text-secondary px-2 py-0 position-absolute top-50 end-0 translate-middle-y" type="submit">
                    <i class="bi bi-search fs-5"></i>
                  </button>
                </form>
                <!-- Search chat END -->
                <!-- Chat list tab START -->
                <div class="mt-4 h-100">
                  <div class="chat-tab-list custom-scrollbar">
                    <ul class="nav flex-column nav-pills nav-pills-soft">
                      <div *ngFor="let chat of userMessages; let i = index">
                        <ng-container *ngIf="!!chat.projectID">
                          <app-general-chat *ngIf="!!chat.projectID" [newDesign]="true" (selectedProject)="selectProject(chat, i)" [project]="chat" [isProject]="true"></app-general-chat>
                        </ng-container>
                        <ng-container *ngIf="!!chat.jobID">
                          <app-general-chat *ngIf="!!chat.jobID" [newDesign]="true" (selectedProject)="selectProject(chat, i)" [project]="chat" [isJob]="true"></app-general-chat>

                        </ng-container>

                      </div>
                    </ul>
                  </div>
                </div>
                <!-- Chat list tab END -->
              </div>
            </div>
          </div>
        </nav>
      </div>
      <!-- Sidebar START -->

      <!-- Chat conversation START -->
      <div class="col-lg-8 col-xxl-9">
        <div class="card card-chat rounded-start-lg-0 border-start-lg-0">
          <div class="card-body h-100">
            <div class="tab-content py-0 mb-0 h-100" id="chatTabsContent">
              <!-- Conversation item START -->
              <app-message-right-side *ngIf="projectSelected?.projectID" [selectedIndex]="selectedIndex"></app-message-right-side>
              <app-message-right-side-job *ngIf="projectSelected?.jobID" [selectedIndex]="selectedIndex"></app-message-right-side-job>
              <!-- Conversation item END -->
              <!-- Conversation item START -->
              <div class="fade tab-pane h-100" id="chat-2" role="tabpanel" aria-labelledby="chat-2-tab">
                <!-- Top avatar and status START -->
                <div class="d-sm-flex justify-content-between align-items-center">
                  <div class="d-flex mb-2 mb-sm-0">
                    <div class="flex-shrink-0 avatar me-2">
                      <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="">
                    </div>
                    <div class="d-block flex-grow-1">
                      <h6 class="mb-0 mt-1">Carolyn Ortiz</h6>
                      <div class="small text-secondary"><i class="fa-solid fa-circle text-danger me-1"></i>Last active 2 days</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <!-- Call button -->
                    <a href="#!" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Audio call"><i class="bi bi-telephone-fill"></i></a>
                    <a href="#!" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Video call"><i class="bi bi-camera-video-fill"></i></a>
                    <!-- Card action START -->
                    <div class="dropdown">
                      <a class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" href="#" id="chatcoversationDropdown2" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></a>
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chatcoversationDropdown2">
                        <li><a class="dropdown-item" href="#"><i class="bi bi-check-lg me-2 fw-icon"></i>Mark as read</a></li>
                        <li><a class="dropdown-item" href="#"><i class="bi bi-mic-mute me-2 fw-icon"></i>Mute conversation</a></li>
                        <li><a class="dropdown-item" href="#"><i class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
                        <li><a class="dropdown-item" href="#"><i class="bi bi-trash me-2 fw-icon"></i>Delete chat</a></li>
                        <li class="dropdown-divider"></li>
                        <li><a class="dropdown-item" href="#"><i class="bi bi-archive me-2 fw-icon"></i>Archive chat</a></li>
                      </ul>
                    </div>
                    <!-- Card action END -->
                  </div>
                </div>
                <!-- Top avatar and status END -->
                <hr>

                <!-- Chat conversation END -->
              </div>

              <!-- Conversation item END -->
            </div>
          </div>
          <div class="card-footer">
            <div class="d-sm-flex align-items-end">
              <quill-editor name="editor"  style="width:100%" [modules]="configSend" [(ngModel)]="writeMessage" >
              </quill-editor>
              <button class="btn btn-sm btn-danger-soft ms-sm-2"><i class="fa-solid fa-face-smile fs-6"></i></button>
              <button class="btn btn-sm btn-secondary-soft ms-2"><i class="fa-solid fa-paperclip fs-6"></i></button>
              <button (click)="sendMessage()" [disabled]="!writeMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send  fs-6"></i></button>
            </div>
          </div>
        </div>
      </div>
      <!-- Chat conversation END -->
    </div> <!-- Row END -->
    <!-- =======================
    Chat END -->

	</div>
  <!-- Container END -->

</main>
<!-- **************** MAIN CONTENT END **************** -->

