import { HttpClient } from "@angular/common/http";
import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnChanges, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { EditUserDialog, UserDialogPictureComponent } from "src/app/components/modals/user-dialogs/user-dialog-picture/user-dialog-picture.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { PagesComponent } from "../pages.component";
import { CategoryChildren, Image, Page, Phone, Categories as Cat, Keywords, Location, SearchLocation, Project, PageProjects, PageRoles, UserPage, Job, AdsManager } from 'src/app/shared/interfaces/model';
import { EditCoverImage, UserCoverDialogComponent } from "src/app/components/modals/user-dialogs/user-dialog-picture/user-cover-dialog/user-cover-dialog.component";
import { UntypedFormControl, Validators, NgForm } from "@angular/forms";
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Category, Organization, Remote, StatusFollow } from "src/app/shared/enums/enums-model";
import { SenderService } from "src/app/shared/services/sender.service";
import { Role } from "src/app/shared/enums/role";
import { Categories, CategoryService } from "src/app/shared/services/category.service";
import { PageHelperService, Status } from "src/app/shared/utils/page-helper.service";
import { environment } from "src/assets/environments/environment";
import { UserService } from "src/app/shared/services/user.service";
import { EditCategoryDialogComponent, EditCategoryPageDetails } from "../dialogs/edit-category-dialog/edit-category-dialog.component";
import { EditSubcategoryDialogComponent, EditSubCategoryPageDetails } from "../dialogs/edit-subcategory-dialog/edit-subcategory-dialog.component";
import { EditBranchDialogComponent } from "../dialogs/edit-branch-dialog/edit-branch-dialog.component";
import { User } from "src/app/shared/interfaces/user.model";
import { CountryDataService } from "src/app/shared/services/country-data.service";
import { EditLocation, LocationDialogComponent } from "./location-dialog/location-dialog.component";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";
import { Subject } from "rxjs/internal/Subject";
import _ from 'lodash'
import { ChangeDetectionService } from "src/app/shared/services/change-detection.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogComponent } from "src/app/components/modals/confirmation-dialog/confirmation-dialog.component";
import { ProjectService } from "src/app/shared/services/project.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { AdsManagerService } from "src/app/shared/services/ads-manager.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { PrivacyDialogComponent } from "src/app/components/privacy-dialog/privacy-dialog.component";

@Component({
  selector: "app-page-details",
  templateUrl: "./page-details.component.html",
  styleUrls: ["./page-details.component.scss"]
})
export class PageDetailsComponent extends PagesComponent implements OnInit, OnChanges, AfterViewInit {
  public coverImage;
  selectedCat;
  tagName;
  editYourPageInfo = false;
  public selectedChild = null
  selectedChildren: CategoryChildren[];
  selectedKeywords: Keywords[];
  isOpen = false;
  hide:number=-1
  primaryLocation: Location;

  public role = new PageRoles();
  selectUser;
  @ViewChild('rolesForm') rolesForm: NgForm;
  public keyword = "";
  counter;

  displayedColumns: string[] = ['checkbox','name', 'budgetPlant.active', 'country', 'budgetPlan', 'budgetPlanEndDate', 'adsDetails', 'adsDetailsUrl', 'adsDetailsharedDescription','adsDetailbuttonName', 'budgetPlanStartDate'];

  public textOffer;
  public iconOffer;


  countryLocation;

  languageVersion = "";
  isNotTranslated: boolean = false;
  isNotSameVersion: boolean = false;
  showSecondaryLanguage: boolean = false;
  secondLanguageArray;
  selectedValueLanguage;
  selectedLanguage;
  selectedSecondLanguage;

  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;




  languages = [];


  showSidebarData:Subject<any> = new Subject();
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  public organizations = [
    { id: Organization.Organization0, description: "---" },
    { id: Organization.Organization1, description: "organization.listedAG" },
    { id: Organization.Organization2, description: "organization.privateCompany" },
    { id: Organization.Organization3, description: "organization.nonProfit" },
    { id: Organization.Organization4, description: "organization.partnership" },
    { id: Organization.Organization5, description: "organization.oneManBusiness" },
    { id: Organization.Organization6, description: "organization.selfEmployed" }
  ];
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  public organizationTitle;
  public image;
  public user: User;
  selectedSubCategory = "";
  selectedCategory = "";
  branchTitle = "";
  categoryTitle = ""
  subCategoryTitle = "";
  myControl = new UntypedFormControl('');
  myControlSecond = new UntypedFormControl('');

  adsData: AdsManager[] = [];




  toggleOptions: Array<String> = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  public locations = new Location();
   config = {
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": false,

    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["code-block"],
        // [{header: 1}, {header: 2}], // custom button values
        [{list: "ordered"}, {list: "bullet"}],
        // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{indent: "-1"}, {indent: "+1"}], // outdent/indent
        [{direction: "rtl"}], // text direction
        [{size: ["small", false, "large", "huge"]}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ["code-block"], // code block
        [{align: []}],
        ["emoji"],
        ["clean"], // remove formatting button
        ["link", "image", "video"],
      ]
    },
  }

  selectedWorkplace: boolean = false;

  CountryISO = CountryISO;
  phone = new Phone();

  public outsiderPageId;
  followersPageNr: number;
  employersPageNr: number;
  public follow = false;
  public selectedCareerIndex: number;
  selectedBranchIndex: number;
  countryJson;
  selectedSearchFilter: number;
  selectedIndexPage: boolean = false;
  pageProjects: PageProjects[];

  isStreetActive: boolean = false;
  public project = new Project();
  public job = new Job();



  public remoteArray = [
    { id: Remote.onSite, description: "no" },
    { id: Remote.remote, description: "yes" },
  ];
  searchCategory;
  public users: User[] = [];
  public searchUser = "";
  public privacies;

  public usersNotification: User[] = [];

  public indexOfCategory;
  indexOfKeywords;
  hasFollow = false;
  isPendingFollow = false;
  userFollowers$:User[];
  userEmployers$:User[];
  public projectID;
  public jobID;
  dataSource = new MatTableDataSource<AdsManager>(null);
  jobObjs: any;
  projectObjs: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public router: Router,
    public branchesService: BranchesService,
    public languageService: LanguagesService,
    public http: HttpClient,
    public authService: AuthService,
    public pageService: PagesService,
    public dialog: MatDialog,
    public imageService: ImageService,
    public shareData: SenderService,
    public recevier: SenderService,
    public cd: ChangeDetectorRef,
    public categoryService?: CategoryService,
    public userService?: UserService,
    public pageHelperService?: PageHelperService,
    public countryDataService?: CountryDataService,
    public titleService?: ArraysService,
    public changeDetector?: ChangeDetectionService,
    public translate?: TranslateService,
    public projectService?: ProjectService,
    public jobService?: JobsService,
    private adsService?: AdsManagerService

    ) {
    super(router, branchesService, languageService, http, authService, pageService, cd, imageService, shareData, recevier);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log(event, "event")
        if (event.url.startsWith("jobs")) {
          event.url = event.url.replace("/jobs", "");
        }

        if (event.url.includes('published/projects/')) {
         this.projectID = this.router.url.split('/').pop()
        }
        if (event.url.includes('published/jobs/')) {
          this.jobID = this.router.url.split('/').pop()
         }
        event.url = event.url.replace("/", "");
        event.url = event.url.replace("ourjobs", "");
        event.url = event.url.replace("jobs", "");
        const test = event.url.replace("/ourjobs", "");
      }
  });
  }
  ngAfterViewInit() {
    if (!!this.page.phone) {
      this.phone[0] = this.page.phone;
    } else {
      return this.phone;
    }
    this.primaryLocation =  this.page.locations.find(t => t.headquarters === true);
    this.countryNameLocation(this.primaryLocation?.c);


  }


  ngOnInit(): void {
    window.scrollTo(0,0);
    this.languageService.getPrimaryLanguages();
    this.titleService.showPricingDataPremium();
    this.titleService.showPricingDataVerified();
    this.titleService.getOfferArray();

  /*  this.projectService.getProject(this.projectID).subscribe(res => {
      this.project = res;
    })

    this.jobService.getJob(this.jobID).subscribe(res => {
      this.job = res;
    }) */


    this.http.get<any>(`assets/i18n/en.json`).subscribe(res => {
      this.languageVersion = res.version;
      localStorage.setItem("languageVersion", res.version)
    });

    this.pageService.getPages();
    this.categoryService.getCategory()
    this.categoryService.getCategories();
    this.branchesService.getBranches();
    this.titleService.showRemoteWithoutHybrid();
    this.pages$ = this.pageService.pageModel();
   this.userService.getUserContactData(this.authService.userID).subscribe((user: User) =>{
     this.user = user;
    });


    this.secondLanguageArray = this.languageService.primaryLanguages.filter(t => t.version === this.languageVersion );


    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);

  /*  this.userService.getUsers();
    this.userService.userModel().subscribe(res => {
      this.users = res;
    }); */

    this.coverImage = "";
    this.image = "";
    this.changeDetector.emmitChanges.subscribe(t => {
      if (!!t) {
        this.pageService.getPage(localStorage.getItem("pageID")).subscribe(page => {
          this.page = page.page;
          localStorage.setItem("pageID", page.page._id);
          this.pageService.getJobList(this.page._id).subscribe(res => {
            this.jobObjs = res[0].jobs;
          })

          this.pageService.getProjectList(this.page._id).subscribe(res => {
            this.projectObjs = res[0].projects;
          })

          this.pageRoles = page.roles;

          this.privacyIconOffer = this.page;
          this.privacyTextOffer = this.page;



          console.log(this.page, "pagggg")
          this.getAllOrders(this.page._id)


         /* this.pageService.showNotifications(this.page._id).subscribe((t: User[]) => {
            this.usersNotification = t
           })
          this.pageService.showFollowers(this.page._id).subscribe((users: User[]) => {
            this.userFollowers$ = users
          });
          this.pageService.showEmployers(this.page._id).subscribe((users: User[]) => {
            this.userEmployers$ = users
          });
          this.userService.getUserContactData(this.authService.userID).subscribe(res => {
            const pageData = res.following.data.find(t => t.pageId === this.page._id);

            this.hasFollow = pageData?.status === 1;
            this.isPendingFollow =pageData?.status === 0;


          }) */
          this.showSelectedLanguage(this.page)
          this.primaryLocation =  this.page.locations.find(t => t.headquarters === true);
          this.countryNameLocation(this.primaryLocation?.c);
          this.getBranch(this.page?.branches);

          this.getCategory(this.page?.categories.name);
          this.getSubCategoryTitle(this.page?.categories.name);

        });
      }
    });
    if (localStorage.getItem("pageID")) {

      this.pageService.getPage(localStorage.getItem("pageID")).subscribe(page => {
        this.page = page.page;
        localStorage.setItem("pageID", page.page._id);
        this.pageRoles = page.roles;
        this.pageService.getJobList(this.page._id).subscribe(res => {
          this.jobObjs = res[0].jobs;
        })
        this.pageService.getProjectList(this.page._id).subscribe(res => {
          this.projectObjs = res[0].projects;
        })


        this.getAllOrders(this.page._id)
        this.privacyIconOffer = this.page;
        this.privacyTextOffer = this.page;


      /*  this.pageService.showFollowers(this.page._id).subscribe((users: User[]) => {
          this.userFollowers$ = users
        });
        this.pageService.showEmployers(this.page._id).subscribe((users: User[]) => {
          this.userEmployers$ = users
        });
        this.userService.getUserContactData(this.authService.userID).subscribe(res => {
          const pageData = res.following.data.find(t => t.pageId === this.page._id);

          this.hasFollow = pageData?.status === 1;
          this.isPendingFollow =pageData?.status === 0;
        }) */

       /* this.pageService.showNotifications(this.page._id).subscribe((t: User[]) => {
         this.usersNotification = t
        }) */

        this.showSelectedLanguage(this.page)

        this.followersPageNr = this.page?.followers?.counter;
        this.employersPageNr = this.page?.employers?.counter

        this.getBranch(this.page?.branches);
        this.getCategory(this.page?.categories.name);
        this.getSubCategoryTitle(this.page?.categories.name);


        this.primaryLocation =  this.page.locations.find(t => t.headquarters === true);
        this.countryNameLocation(this.primaryLocation?.c);
        this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.page?.categories?.name);
        this.showPrivacies();
        if (!!this.page.phone) {
          this.phone[0] = this.page.phone;
        } else {
          return this.phone;
        }
     //   this.pageProjects = this.page.projects.data;

      });
    } else {
      this.pageService.getPageID.subscribe(data => {
        if(data.length > 0) {
          this.pageService.getPage(data).subscribe(page => {
            this.page = page.page;
            localStorage.setItem("pageID", page.page._id);

            this.pageRoles = page.roles;
            this.pageService.getJobList(this.page._id).subscribe(res => {
              this.jobObjs = res[0].jobs;
            })
            this.pageService.getProjectList(this.page._id).subscribe(res => {
              this.projectObjs = res[0].projects;
            })

            this.privacyIconOffer = this.page;
            this.privacyTextOffer = this.page;

            this.getAllOrders(this.page._id)


          /*  this.pageService.showNotifications(this.page._id).subscribe((t: User[]) => {
              this.usersNotification = t
             })
            this.pageService.showFollowers(this.page._id).subscribe((users: User[]) => {
              this.userFollowers$ = users
            });
            this.pageService.showEmployers(this.page._id).subscribe((users: User[]) => {
              this.userEmployers$ = users
            }); */
         /*   this.userService.getUserContactData(this.authService.userID).subscribe(res => {
              const pageData = res.following.data.find(t => t.pageId === this.page._id);

              this.hasFollow = pageData?.status === 1;
              this.isPendingFollow =pageData?.status === 0;
            }) */
            this.showSelectedLanguage(this.page)

            this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.page?.categories?.name);


            this.pageProjects = this.page.projects.data;
            this.showPrivacies();


            if (!this.page.location) {
              this.page.location = {} as Location;          }

            this.pageID = data;
           this.followersPageNr = this.page?.followers.counter;
           this.employersPageNr = this.page?.employers.counter
           this.getBranch(this.page?.branches);
           this.getCategory(this.page?.categories.name);
           this.getSubCategoryTitle(this.page?.categories.name);
            this.cd.detectChanges();
            if (this.page.profileImage) {
              this.imageService.getImageByID(this.page.profileImage).subscribe((res: Image) => {
                if (res.imageUrl) {
                  this.image = res.imageUrl;
                  this.hasPhoto = true;
                }
              });
            } else if (this.page.profileImage === undefined) {
              return;
            }

            if (this.page.coverImage) {
              this.imageService.getImageByID(this.page.coverImage).subscribe((res: Image) => {
                if (res.imageUrl) {
                  this.coverImage = res.imageUrl;
                  this.hasCoverPhoto = true;
                }
              });
            } else if (this.page.coverImage === undefined) {
              return this.hasCoverPhoto = false;
            }
            this.getOrganization(this.page.organization);

            if (this.page?.phone) {
              this.phone[0] = this.page.phone;
            } else {
              return this.phone;
            }

          });
        } else {
            if (this.outsiderPageId.endsWith("manage_")) {
              this.outsiderPageId = this.outsiderPageId.replace("manage_", "")
            }
            this.pageService.getPageUser(this.outsiderPageId).subscribe(t => {
             this.page = t[0];
             this.privacyIconOffer = this.page;
             this.privacyTextOffer = this.page;


             this.pageService.showNotifications(this.page._id).subscribe((t: User[]) => {
              this.usersNotification = t
             })
            /* this.pageService.showFollowers(this.page._id).subscribe((users: User[]) => {
              this.userFollowers$ = users
            }); */
          /* this.pageService.showEmployers(this.page._id).subscribe((users: User[]) => {
              this.userEmployers$ = users
            }); */
             this.userService.getUserContactData(this.authService.userID).subscribe(res => {
              const pageData = res.following.data.find(t => t.pageId === this.page._id);

              this.hasFollow = pageData?.status === 1;
              this.isPendingFollow =pageData?.status === 0;


            })

             this.showSelectedLanguage(this.page)

             this.primaryLocation =  this.page.locations.find(t => t.headquarters === true);
             this.countryNameLocation(this.primaryLocation.c);

             this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.page?.categories?.name);

             this.showPrivacies();

             this.pageID = data;
             this.followersPageNr = this.page?.followers.counter;
             this.employersPageNr = this.page?.employers.counter

              this.cd.detectChanges();

             this.getOrganization(this.page.organization);
             if (this.page.profileImage) {
               this.imageService.getImageByID(this.page.profileImage).subscribe((res: Image) => {
                 if (res.imageUrl) {
                   this.image = res.imageUrl;
                   this.hasPhoto = true;
                 }
               });
             } else if (this.page.profileImage === undefined) {
               return;
             }


             if (this.page.coverImage) {

               this.imageService.getImageByID(this.page.coverImage).subscribe((res: Image) => {
                 if (res.imageUrl) {
                   this.coverImage = res.imageUrl;
                   this.hasCoverPhoto = true;
                 }
               });
             } else if (this.page.coverImage === undefined) {
               return;
             }
             if (this.page.phone) {
               this.phone[0] = this.page.phone;
             } else {
               return this.phone;
             }
            })

          }
      });
    }
  }
  ngOnChanges():void {

    if (this.page.profileImage) {
      this.imageService.getImageByID(this.page.profileImage).subscribe((res: Image) => {
        if (res.imageUrl) {
          this.image = res.imageUrl;
          this.hasPhoto = true;
        }
      });
    } else if (this.page.profileImage === undefined) {
      return;
    }
    if (this.page.coverImage) {

      this.imageService.getImageByID(this.page.coverImage).subscribe((res: Image) => {
        if (res.imageUrl) {
          this.coverImage = res.imageUrl;
          this.hasCoverPhoto = true;
        }
      });
    } else if (this.page.coverImage === undefined) {
      return;
    }
  }
  public setCareerIndex(id) {
    this.selectedCareerIndex = id;
  }
  public setBranchIndex(id) {
    this.selectedBranchIndex = id;
  }
  public removeClick() {
    this.selectedCareerIndex = undefined;
    this.selectedBranchIndex = undefined;
  }


  public  countryName(id) {
    return this.countryJson.find((t) => t.ISO_2 === id)?.EN;
  }

  public countryNameLocation(id) {
    return this.countryLocation =  this.countryJson.find((t) => t.ISO_2 === id)?.EN;
  }

  changeInput(event) {
    if (event.target.value === '') {
      this.privacies.forEach(res => res.isChecked = false);
    }

  }

  updatePage() {

   // this.page.phone = this.phone.number as any;
    // this.page.userRole = Role.Admin;
   // this.page.pageUrl = this.pageUrl;
    this.pageService.updatePageObject(this.pageID, this.page);
   // this.locations = [] as any;
    this.search = "";
  }
  updatePageLocation(data: EditLocation) {
    console.log(this.page, "page");

    console.log(this.pageID, "pageID")
    this.page.locations.unshift(this.locations);
    this.pageService.updatePageObject(this.pageID, this.page);
    this.openLocation = false;
    this.dialog.open(LocationDialogComponent, {
      data: data
    }).afterClosed().subscribe(res => {
      if (res) {
        this.page.searchLocation.data.unshift({
            c: res.location.country,
            postalCode: res.location.postalCode,
            region: res.location.region
        })
      }
      this.pageService.updatePageObject(this.pageID, this.page);
    })


  }
  cancel() {
    this.search = "";
    this.editYourPageInfo = !this.editYourPageInfo;
  }
  openDialog(event: any): void {
    console.log(event.target.files[0]);
  }



 saveLanguage(data: Page) {
    this.pageService.updatePage(data._id, data);
  }
  saveNewLocation(data: EditLocation) {

    if(this.locations.headquarters === true) {
      this.page.locations.map(t => t.headquarters = false);
    }
    if (this.isStreetActive) {
      this.locations.street = undefined;
    }
    this.page.locations.unshift(this.locations);
    this.pageService.updatePage(this.page._id, this.page)
   // this.pageService.updatePageObject(this.page._id, this.page);
    this.openLocation = false;
    this.dialog.open(LocationDialogComponent, {
      data: data
    }).afterClosed().subscribe(res => {
      if (res) {
        this.page.searchLocation.data.unshift({
            c: res.location.country,
            postalCode: res.location.postalCode,
            region: res.location.region
        })
      }

      this.pageService.updatePage(this.page._id, this.page);
    })
    this.isStreetActive = false;
  }

  addTagName() {
    if (this.selectedKeywords.length > 0) {
    } else {
      this.selectedKeywords = [];
    }
    this.selectedKeywords.unshift({ name: this.tagName });
    this.tagName = "";
  }
  showChildren(event) {
    this.selectedCategory = event;
    console.log(event, "event")
    const dataCat = this.categoryService.categories.findIndex(t => t.id === event);
    this.categoryService.categories[this.indexOfCategory].subCategory = this.categoryService.categories[dataCat].subCategory;
    this.selectedSubCategory = this.categoryService.categories[this.indexOfCategory].subCategory[0].id;
    console.log(this.selectedSubCategory)
   //  this.selectedChildren.unshift(event.subCategories);
  }

  saveDescription() {
    this.pageService.updatePage(this.page._id, this.page);
  }
  showKeywords(event) {
    this.selectedSubCategory = event;
    this.selectedKeywords = event.tags;
  }
  removeTag(index) {
    this.selectedKeywords.splice(index, 1);
  }
  changeBranch(event) {
  this.page.branches = event.id;
  }
  editSubCategory(data: EditSubCategoryPageDetails) {
    this.dialog.open(EditSubcategoryDialogComponent, {data})
    .afterClosed().subscribe((res: EditSubCategoryPageDetails) => {
      if(res) {
        const t = this.categoryService.categories.find(t => t.id === res.category);
        this.subCategoryTitle =  t.subCategory.find(rtt => rtt.id === res.subCategory.name).name;
        this.findSubCategoryName(res.subCategory.name).id;
      //  this.page.categories.subCategory =
      }
    })
  }
  deleteSubCategory(index) {
    this.page.categories.subCategory.splice(index, 1);
    this.pageService.updatePage(this.page._id, this.page);
  }

  editLocation(data: EditLocation, index) {
    this.dialog.open(LocationDialogComponent, {data}).afterClosed().subscribe(res => {
      if(res) {
        if(res.headquarters === true) {
          this.page.locations.map(t => t.headquarters = false);
          this.page.locations[index].headquarters = true;
        }
        console.log(res, "ress")
        this.page.locations[index] = res.location;
        this.pageService.updatePage(this.page._id, this.page);
      }
    })
  }

  editCategory(data) {
    this.dialog.open(EditCategoryDialogComponent, {data})
    .afterClosed().subscribe(res => {
      if (res) {
        this.findCategoryName(res.categories.name);
      }
    })
  }
  editBranch(data) {
    this.dialog.open(EditBranchDialogComponent, {data})
    .afterClosed().subscribe(res => {
      if (res) {
        this.getBranch(res.branches);
      }
    })
  }
  addCategoryPageDetails(data: EditCategoryPageDetails) {
    this.dialog.open(EditSubcategoryDialogComponent, {data})
    .afterClosed().subscribe(res => {
     if (!res) {
       return;
     } else {
      console.log(res);
     }
    });
    this.cd.detectChanges();
  }

  findCategoryName(event) {
    return this.categoryService.categories.find(r => r.id === event);
  }

  findSubCategoryName(cat) {
    const index = this.categoryService.categories.findIndex(object => {
      return object.id === this.page.categories.name;
    });
   return this.categoryService.categories[index].subCategory.find(r => r.id === cat);
  }



  uploadCoverPicture(data: EditCoverImage) {
    this.dialog.open(UserCoverDialogComponent, {
      data
    }).afterClosed().subscribe(res => {
      if (res === undefined) {}
      this.imageService.getImageByID(res.page.coverImage).subscribe((img: Image) => {
        if (img.imageUrl) {
          this.coverImage = img.imageUrl;
          this.hasPhoto = true;
        }
      });
    });
  }


followPage(page: Page) {
  const api = `${environment.backend}/user/${this.authService.userID}`

  const pageFollow = {
    pageId: page._id,
    timestamp: new Date(),
    status: StatusFollow.Public
  }
  const pageFollowWithPrivacy = {
    pageId: page._id,
    timestamp: new Date(),
    status: StatusFollow.Private
  }
  if (this.page.privacy === true) {
    this.user.following.data.unshift(pageFollowWithPrivacy);
    this.isPendingFollow = true;
  } else {
    this.user.followers.counter =  this.user.followers.counter + 1;
    this.user.following.data.unshift(pageFollow);
    this.hasFollow = true;
  }



if (typeof this.page?.notifications?.followers?.counter === 'undefined') {
  const notifications = {
        followers: {
          counter: +1,
          seen: false

      }
  }
  this.page.notifications = notifications;
} else {
  this.page.notifications.followers.counter =  this.page.notifications.followers.counter + 1;
}

this.pageService.updatePage(this.page._id, this.page);
  this.http.put<any>(api, this.user).subscribe((res) => {
  })

}

/*  followPage(page: Page) {
    const following = {
      pageId: page._id,
      timestamp: new Date()
    }
    const follower = {
      userID: this.authService?.userID,
      timestamp: new Date()
    }
    const api = `${environment.backend}/user/${this.authService.userID}`
    this.userService.getUserContactData(this.authService.userID).subscribe((user: User) =>{
      user.following.data.unshift(following);
     this.http.put<any>(api, user).subscribe((res) => {
    })
    page.followers.unshift(follower);
    this.pageService.updatePage(page._id, page);
    });
    this.follow = true;
  } */
  openProfileImgDialog(data: EditUserDialog) {
    this.dialog.open(UserDialogPictureComponent, {
      data
    }).afterClosed().subscribe(res => {
      if (res === undefined) { }
      this.imageService.getImageByID(res.page.profileImage).subscribe((img: Image) => {
        if (img.imageUrl) {
          this.image = img.imageUrl;
          this.hasPhoto = true;
        }
      });
    });
  }
  getOrganization(id) {
    this.organizationTitle = this.organizations.find(test => test.id === id)
  }
  getBranch(id) {
    this.branchTitle = this.branchesService.branches.find(t => t.id === id).description;
  }
  getCategory(id) {
    this.categoryTitle = this.categoryService.categories.find(t => t.id === id).id;
  }
  getSubCategoryTitle(id) {
    const t = this.categoryService.categories.find(t => t.id === id);
    const tt = this.page.categories.subCategory.find(t => t.name);
    this.subCategoryTitle =  t.subCategory.find(rtt => rtt.id === tt.name).name;
  }



  changePageUrl(event) {
   this.pageUrl = event;
  }
  addCategory(event) {
//   this.page.categories.unshift({description: event.description});
   this.searchCategory = "";
  }
  isAddedRole() {
    return this.page?.roles?.Owner?.find(res => res.userID === this.authService.userID) ||
    this.page?.roles?.Admin.find(res => res.userID === this.authService.userID) ||
    this.page?.roles?.Moderator?.find(res => res.userID === this.authService.userID) ||
    this.page?.roles?.Editor?.find(res => res.userID === this.authService.userID) ||
    this.page?.roles?.Analyst?.find(res => res.userID === this.authService.userID) ||
    this.page?.roles?.Advertiser?.find(res => res.userID === this.authService.userID) ||
    this.page?.roles?.User?.find(res => res.userID === this.authService.userID);
  }



  addNewSearchFilter() {
  const searchLocation: SearchLocation = {
    c: "DEU",
    searchActive: true,
    postalCode: "8",
    region: ["8"],
    remote: true,
    available: new Date
  }
  this.page.searchLocation.data.unshift(searchLocation);
  this.pageService.updatePage(this.page._id, this.page);
  }


  addLocation() {
    this.openLocation = true;
    const data =  this.page.locations.find(t => t.headquarters === true);
    const searchLocation = {
      c: data.c,
      city: data.city,
      street: data.street,
      postalCode: data.postalCode,
      region: [data.postalCode.toString().slice(0, 1).toString()[0]]
     }
     this.locations = searchLocation;

  }
  addSearchLocation() {
   const data =  this.page.locations.find(t => t.headquarters === true);
   const searchLocation: SearchLocation = {
    c: data.c,
    postalCode: data.postalCode.slice(0, 3),
    region: [data.postalCode.slice(0, 1).toString()[0]]
   }
   this.page.searchLocation.data.unshift(searchLocation);
   this.pageService.updatePage(this.page._id, this.page);
  }
  selectionChanged(param, index) {
    if (this.page.searchLocation.data[index]?.region.length > 1) {
      this.page.searchLocation.data[index].postalCode = null;
      this.page.searchLocation.data[index].postalCode = "";
    } else if (this.page.searchLocation.data[index]?.region.length === 0 ) {
      this.page.searchLocation.data[index].postalCode = null;
      this.page.searchLocation.data[index].postalCode = "";
    } else {
      this.page.searchLocation.data[index]?.region.forEach(i => this.page.searchLocation.data[index].postalCode = i);
    }
  }

  deleteSearchFilter(index) {
    this.page.searchLocation.data.splice(index, 1);
    this.pageService.updatePage(this.page._id, this.page);
  }

  saveSearchFilter() {
    this.pageService.updatePage(this.page._id, this.page);
  }
  selectSearchFilter(index) {
    this.selectedSearchFilter = index;
  }
  onChange(value) {
    this.locations.c = value;
    }
    remoteCheck(event) {
      this.page.searchLocation.remote = event;
    }

    unfollowPage() {
        this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
        this.confirmationDialog.componentInstance.message = "You will no longer receive notifications or see relevant updates in your feed";
        this.confirmationDialog.componentInstance.header = "Unfollow" ;
        this.confirmationDialog.componentInstance.confirmButtonText = "Unfollow";
        this.confirmationDialog.afterClosed().subscribe(result => {
          if (result) {
            this.userService.getUserContactData(this.authService.userID).subscribe((user: User) =>{
             const pageFollowing =  user.following.data.findIndex(t => t.pageId === this.page._id);
             console.log(pageFollowing, "pageFollowing")
              user.following.data.splice(pageFollowing, 1);
             user.following.data.filter(t =>  {
             this.hasFollow = t.pageId === this.page._id});
             this.userService.updateUser(user._id, user);
            });
            this.page.followers.counter = this.page.followers.counter - 1;
            this.pageService.updatePage(this.page._id, this.page);
          }
        });

    }



    savePage() {
        this.page.phone = this.phone.number as any;



       if (this.selectedCategory) {
        this.page.categories.name = this.selectedCategory;
        this.page.categories.subCategory[0].name =  this.selectedSubCategory;
       }



      this.pageService.updatePage(this.page._id, this.page);
      this.selectedWorkplace = false;
    }

    goToUrl(url) {
      if(url.startsWith("https://") || url.startsWith("http://")) {
        url;
     } else {
       url = "https://" + url;
     }

     window.open(url, "_blank");

    }

    saveNotePageProject(item, index) {

      if (item) {
        this.page.searchLocation.data[index].postalCode = item.postalCode;
      }
      this.pageService.updatePage(this.page._id, this.page);
      this.selectedIndexPage = undefined;
      this.selectedWorkplace = false;
      this.selectedIndexPage = undefined;
    }

  deleteSearchFilterProject(index) {
    this.page.searchLocation.data.splice(index, 1);
    this.pageService.updatePage(this.page._id, this.page);
  }

    cancelNodeEdit() {
      this.selectedWorkplace = false;
      this.selectedIndexPage = false;
    }

navigateToProjects(event) {
  if (this.page?.pageUrl) {
    this.pageUrl = this.page?.pageUrl;
  } else {
    this.pageUrl = this.page?._id;

  }
}
createJob() {
    this.router.navigate([
      this.page.pageUrl + "/jobs",
      "create",
      { isPage: true },
    ]);
}
createProject() {

    this.router.navigate([this.page.pageUrl + "/projects", "create", {isPage: true}]);
}
selectIndexForButtonsPage(index) {
  this.selectedIndexPage = index;
}
goToMyProjects() {
    this.router.navigateByUrl(this.page?.pageUrl + "/ourprojects");
}

navigateToSaved() {
    this.router.navigateByUrl(this.page?.pageUrl + "/projects/saved");
}

selectWorkplace() {
  this.selectedWorkplace = true;
}

showSidebar() {
  this.showSidebarData.next(1);
}
toggleSidebar(){
  this.isOpen = !this.isOpen;
}
public get isOwnerOrAdmin() {
  return (this.pageRoles?.Owner?.find(res => res.userID === this.authService.userID) || this.page?.roles?.Admin?.find(res => res.userID === this.authService.userID));
}
public get isPayment() {
  return this.pageRoles?.Payments?.find(res => res.userID === this.authService.userID);
}

public get isMessaging() {
  return this.pageRoles?.Messages?.find(res => res.userID === this.authService.userID);
}

public get isNotifications() {
  return this.pageRoles?.Notifications?.find(res => res.userID === this.authService.userID);
}
public get isUserProjects() {
  return this.pageRoles?.Projects?.find(res => res.userID === this.authService.userID);
}
public get isUserJobs() {
  return this.pageRoles?.Jobs?.find(res => res.userID === this.authService.userID);
}

sendData() {


  this.recevier.pageData$(this.page);
}
sidebarClick(event) {
  event.stopPropagation();
  event.stopImmediatePropagation();
  const elementToShow = document.
  querySelector('.nav-sidenav');
  elementToShow.classList.toggle('show');

}

  outsideClick(hasClickedOutside) {

    const elementToShow = document.
    querySelector('.nav-sidenav');
    if (elementToShow.classList.contains('show')) {
      elementToShow.classList.toggle('show');
    }

  }

  showObject(i) {
    this.hide = i;
  }

  addUser(user: User, event) {
    this.role.userID = user._id;
    this.searchUser = `${user.firstName} ${user.lastName}`;
  }

  public get hasUserAccesOnSidebar() {
    return this.isUserJobs ||
    this.isUserProjects ||
    this.isOwnerOrAdmin ||
    this.isPayment ||
    this.isMessaging ||
    this.isNotifications
  }


  checkUserRoles() {

    if (this.pageRoles.Owner.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Owner).isChecked = true;
    }
    if (this.pageRoles.Admin.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Admin).isChecked = true;
    }
    if (this.pageRoles.Advertiser.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Advertiser).isChecked = true;
    }
    if (this.pageRoles.Moderator.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Moderator).isChecked = true;
    }
    if (this.pageRoles.Editor.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Editor).isChecked = true;
    }
    if (this.pageRoles.Analyst.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Analyst).isChecked = true;
    }
    if (this.pageRoles.User.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.User).isChecked = true;
    }
    if (this.pageRoles.Jobs.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Jobs).isChecked = true;
    }
    if (this.pageRoles.Projects.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Projects).isChecked = true;
    }
    if (this.pageRoles.Messages.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Messages).isChecked = true;
    }
    if (this.pageRoles.Notifications.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Notifications).isChecked = true;
    }
    if (this.pageRoles.Payments.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Payments).isChecked = true;
    }
    if (this.pageRoles.Marketing.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Marketing).isChecked = true;
    }


  }

  checkUserIsInRole() {
    if (this.pageRoles.Owner.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Owner).show = true;
      this.privacies.find(rt => rt.id === Role.Admin).show = true;
      this.privacies.find(rt => rt.id === Role.Advertiser).show = true;
      this.privacies.find(rt => rt.id === Role.Moderator).show = true;
      this.privacies.find(rt => rt.id === Role.Editor).show = true;
      this.privacies.find(rt => rt.id === Role.Analyst).show = true;
      this.privacies.find(rt => rt.id === Role.User).show = true;
      this.privacies.find(rt => rt.id === Role.Jobs).show = true;
      this.privacies.find(rt => rt.id === Role.Projects).show = true;
      this.privacies.find(rt => rt.id === Role.Messages).show = true;
      this.privacies.find(rt => rt.id === Role.Notifications).show = true;
      this.privacies.find(rt => rt.id === Role.Payments).show = true;
      this.privacies.find(rt => rt.id === Role.Marketing).show = true;
    }
    if (this.pageRoles.Admin.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Admin).show = true;
      this.privacies.find(rt => rt.id === Role.Advertiser).show = true;
      this.privacies.find(rt => rt.id === Role.Moderator).show = true;
      this.privacies.find(rt => rt.id === Role.Editor).show = true;
      this.privacies.find(rt => rt.id === Role.Analyst).show = true;
      this.privacies.find(rt => rt.id === Role.User).show = true;
      this.privacies.find(rt => rt.id === Role.Jobs).show = true;
      this.privacies.find(rt => rt.id === Role.Projects).show = true;
      this.privacies.find(rt => rt.id === Role.Messages).show = true;
      this.privacies.find(rt => rt.id === Role.Notifications).show = true;
      this.privacies.find(rt => rt.id === Role.Payments).show = true;
      this.privacies.find(rt => rt.id === Role.Marketing).show = true;
    }
    if (this.pageRoles.Advertiser.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Advertiser).show = true;
    }
    if (this.pageRoles.Moderator.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Moderator).show = true;
    }
    if (this.pageRoles.Editor.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Editor).show = true;
    }
    if (this.pageRoles.Analyst.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Analyst).show = true;
    }
    if (this.pageRoles.User.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.User).show = true;
    }
    if (this.pageRoles.Jobs.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Jobs).show = true;
    }
    if (this.pageRoles.Projects.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Projects).show = true;
    }
    if (this.pageRoles.Messages.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Messages).show = true;
    }
    if (this.pageRoles.Notifications.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Notifications).show = true;
    }
    if (this.pageRoles.Payments.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Payments).show = true;
    }
    if (this.pageRoles.Marketing.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Marketing).show = true;
    }


  }

  addNewUser(user) {
    this.role.userID = user._id;
    this.searchUser = `${user.firstName} ${user.lastName}`;
    this.checkUserRoles();
    this.checkUserIsInRole();
    this.selectUser = true;

  }
  saveUserRole() {
    const roles: UserPage = {
      pageId: this.page._id,
      timestamp: new Date(),
      status: Status.Pending,
    }
    this.userService.getUserContactData(this.role.userID).subscribe((res: User) => {
      if (res.pages.find(r => r.pageId === this.page._id) && this.checkIdIfExistOnRole) {
        return;
      }
      let a = res.pages.some(o => o.pageId === this.page._id);
      if (!this.checkIfIDExist && a) {
        res.pages.splice(_.indexOf(res.pages, _.find(res.pages, { pageId: this.page._id })), 1);
      } else if (res.pages.find(r => r.pageId === this.page._id)) {
        return;
      }
      else if (this.checkIdIfExistOnRole && !a) {
        res.pages.unshift(roles);
        this.userService.updateUser(res._id, res);
      }
      this.userService.updateUser(res._id, res);
    });
    this.pageService.updatePage(this.page._id, this.page);
    this.rolesForm.reset();
    this.privacies.forEach(res => res.isChecked = false);
    this.searchUser = '' ;
    this.selectUser = false;
  }

  addRole(event, id) {
    if (this.searchUser === "") {
      return;
    }

    const role = {
      userID: this.role.userID,
      timestamp: new Date()
    }
    switch (id.role) {
      case Role.Admin:
        if (id.isChecked) {
          return this.pageRoles.Admin.unshift(role);
        } else {
          this.pageRoles.Admin.splice(_.indexOf(this.pageRoles.Admin, _.find(this.pageRoles.Admin, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Owner:
        if (id.isChecked) {
          return this.pageRoles.Owner.unshift(role);
        }
        this.pageRoles.Owner.splice(_.indexOf(this.pageRoles.Owner, _.find(this.pageRoles.Owner, { userID: this.role.userID })), 1);
        break;
      case Role.Advertiser:
        if (id.isChecked) {
          this.pageRoles.Advertiser.unshift(role);
        } else {
          this.pageRoles.Advertiser.splice(_.indexOf(this.pageRoles.Advertiser, _.find(this.pageRoles.Advertiser, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Moderator:
        if (id.isChecked) {
          this.pageRoles.Moderator.unshift(role);
        } else {
          this.pageRoles.Moderator.splice(_.indexOf(this.pageRoles.Moderator, _.find(this.pageRoles.Moderator, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Editor:
        if (id.isChecked) {
          this.pageRoles.Editor.unshift(role);
        } else {
          this.pageRoles.Editor.splice(_.indexOf(this.pageRoles.Editor, _.find(this.pageRoles.Editor, { userID: this.role.userID })), 1);
        }
        break;
      case Role.User:
        if (id.isChecked) {
          this.pageRoles.User.unshift(role);
        } else {
          this.pageRoles.User.splice(_.indexOf(this.pageRoles.User, _.find(this.pageRoles.User, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Analyst:
        if (id.isChecked) {
          this.pageRoles.Analyst.unshift(role);
        } else {
          this.pageRoles.Analyst.splice(_.indexOf(this.pageRoles.Analyst, _.find(this.pageRoles.Analyst, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Projects:
        if (id.isChecked) {
          this.pageRoles.Projects.unshift(role);
        } else {
          this.pageRoles.Projects.splice(_.indexOf(this.pageRoles.Projects, _.find(this.pageRoles.Projects, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Jobs:
        if (id.isChecked) {
          this.pageRoles.Jobs.unshift(role);
        } else {
          this.pageRoles.Jobs.splice(_.indexOf(this.pageRoles.Jobs, _.find(this.pageRoles.Jobs, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Messages:
        if (id.isChecked) {
          this.pageRoles.Messages.unshift(role);
        } else {
          this.pageRoles.Messages.splice(_.indexOf(this.pageRoles.Messages, _.find(this.pageRoles.Messages, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Notifications:
        if (id.isChecked) {
          this.pageRoles.Notifications.unshift(role);
        } else {
          this.pageRoles.Notifications.splice(_.indexOf(this.pageRoles.Notifications, _.find(this.pageRoles.Notifications, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Payments:
        if (id.isChecked) {
          this.pageRoles.Payments.unshift(role);
        } else {
          this.pageRoles.Payments.splice(_.indexOf(this.pageRoles.Payments, _.find(this.pageRoles.Payments, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Marketing:
        if (id.isChecked) {
          this.pageRoles.Marketing.unshift(role);
        } else {
          this.pageRoles.Marketing.splice(_.indexOf(this.pageRoles.Marketing, _.find(this.pageRoles.Marketing, { userID: this.role.userID })), 1);
        }
        break;
      default:
        break;
    }

  }

  public get checkIfIDExist(): boolean {
    return this.pageRoles.Owner.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Admin.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Advertiser.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Analyst.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Moderator.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Editor.some(r => r.userID === this.role.userID) &&
      this.pageRoles.User.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Jobs.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Projects.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Messages.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Notifications.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Payments.some(r => r.userID === this.role.userID) &&
      this.pageRoles.Marketing.some(r => r.userID === this.role.userID)
  }
  public get checkIdIfExistOnRole(): boolean {
    return this.pageRoles.Owner.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Admin.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Advertiser.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Analyst.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Moderator.some(r => r.userID === this.role.userID) ||
      this.pageRoles.User.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Editor.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Jobs.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Projects.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Messages.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Notifications.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Payments.some(r => r.userID === this.role.userID) ||
      this.pageRoles.Marketing.some(r => r.userID === this.role.userID)

  }

  public showPrivacies() {
     this.privacies = [
      { isChecked: false, id: Role.Owner, name: "role.owner", role: Role.Owner, show: false, showData: this.pageRoles?.Owner },
      { isChecked: false, id: Role.Admin, name: "role.admin", role: Role.Admin, show: false, showData: this.pageRoles?.Admin  },
      { isChecked: false, id: Role.Editor, name: "role.editor", role: Role.Editor, show: false, showData: this.pageRoles?.Editor },
      { isChecked: false, id: Role.Moderator, name: "role.moderator", role: Role.Moderator, show: false, showData: this.pageRoles?.Moderator  },
      { isChecked: false, id: Role.Advertiser, name: "role.advertiser", role: Role.Advertiser, show: false, showData: this.pageRoles?.Advertiser  },
      { isChecked: false, id: Role.Analyst, name: "role.analyst", role: Role.Analyst, show: false, showData: this.pageRoles?.Analyst  },
      { isChecked: false, id: Role.User, name: "role.user", role: Role.User, show: false, showData: this.pageRoles?.User  },
      { isChecked: false, id: Role.Jobs, name: "role.jobs", role: Role.Jobs, show: false, showData: this.pageRoles?.Jobs  },
      { isChecked: false, id: Role.Projects, name: "role.projects", role: Role.Projects, show: false, showData: this.pageRoles?.Projects  },
      { isChecked: false, id: Role.Messages, name: "role.messages", role: Role.Messages, show: false, showData: this.pageRoles?.Messages  },
      { isChecked: false, id: Role.Notifications, name: "role.notifications", role: Role.Notifications, show: false, showData: this.pageRoles?.Notifications  },
      { isChecked: false, id: Role.Payments, name: "role.payments", role: Role.Payments, show: false, showData: this.pageRoles?.Payments  },
      { isChecked: false, id: Role.Marketing, name: "role.marketing", role: Role.Marketing, show: false, showData: this.pageRoles?.Marketing  },
    ];
  }



  deleteLocation(index) {
    this.page.locations.splice(index, 1);
    this.pageService.updatePage(this.page._id, this.page);
  }

  addKeyword() {
    this.page.categories?.subCategory[0].keywords.unshift({ name: this.keyword });
    this.pageService.updatePage(this.page._id, this.page);
    this.keyword = "";

  }
  removeKeyword(index) {
    this.page.categories?.subCategory[0].keywords.splice(index, 1);
    this.pageService.updatePage(this.page._id, this.page);

}

onContentChanged(context) {
  console.log('Content changed: ');
  console.log(context.html.length - 1);
  if (context == null || context.html == null) {
    return;
  }
  if (context.html.length >= 2000) {
    let oldDelta = context['oldDelta'];
    if (oldDelta == null) {
      return;
    }
    context.editor.setContents(oldDelta.ops);
  }
}
textChanged($event) {
  this.counter = $event.editor.getLength();
  if ($event.editor.getLength() > 2000) {
    $event.editor.deleteText(1999, $event.editor.getLength());
  }
}

findLocationCountry(id) {
  return this.countryJson.find(t => t.ISO_2 === id)?.EN;
}


changeLanguage(event) {

  console.log(this.selectedValueLanguage, "test")

  this.selectedLanguage = event;
  this.translate.use(this.selectedValueLanguage.lang);
if (event.version === this.languageVersion) {
setTimeout(() => {
  this.page.language = {};
  this.page.language.primary = this.selectedLanguage.lang;
  this.selectedValueLanguage =  this.selectedLanguage;
  this.isNotSameVersion = false;
  this.isNotTranslated = false;
}, 100)


} if (!!event.version && event.version !== this.languageVersion) {
setTimeout(() => {
  this.isNotSameVersion = true;
  this.page.language.primary = this.selectedLanguage.lang;
  this.selectedValueLanguage =  this.selectedLanguage;
  this.isNotTranslated = false;
}, 100)

} else if(!event.version) {
setTimeout(() => {
  this.isNotTranslated = true;
  this.showSecondaryLanguage = true;
  this.isNotSameVersion = false;
  this.page.language.primary = this.selectedLanguage.lang;
  this.selectedValueLanguage =   this.selectedLanguage;
}, 100)

}
}

showSelectedLanguage(page: Page) {
  if (this.page.language) {

    this.http.get<any>(`assets/languages/${this.page?.language?.primary}/country.json`).subscribe(data => {
      this.languages = data;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));
    });
    this.translate.use(this.page.language.primary);
    this.selectedValueLanguage = this.languageService.primaryLanguages.find(t => t.id === page?.language?.primary);

  } else {
   const language =this.translate.getBrowserLang();

    this.http.get<any>(`assets/languages/${language}/country.json`).subscribe(res => {
      this.languages = res;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));
      this.selectedValueLanguage = this.languageService.primaryLanguages.find(t => t.id === language);

    });

  }


  const version = this.languageService.primaryLanguages.find(t => t.id === page?.language?.primary)?.version;
  if (!!this.page?.language?.secondary) {
    this.selectedSecondLanguage = this.languageService.primaryLanguages.find(t => t.lang === page.language.secondary )

  }

  if (!!this.languageVersion && this.languageVersion !== version) {
    this.isNotSameVersion = true;

  } else if (!version) {
    this.isNotTranslated = true;

  }

}


getAllOrders(pageId) {
 /* this.adsService.getAds(pageId).subscribe((data: any) => {
    this.dataSource = new MatTableDataSource<AdsManager>(data); //pass the array you want in the table
    this.dataSource.paginator = this.paginator;
    return data;
  }) */

}

changeAccountStatus() {
  this.page.account.endDate = new Date();
  this.page.account.s = 2;
  this.page.account.deleted = false;
  this.pageService.updatePage(this.page._id, this.page);
}

activateAccount() {
  this.page.account.endDate = new Date();
  this.page.account.s = 2;
  this.page.account.deleted = false;
  this.pageService.updatePage(this.page._id, this.page);
}

openAds(element: AdsManager) {
  this.shareData.adsDataAds(element);
  this.router.navigateByUrl(this.router.url + '/edit')
}

updateActiveStatus(element: AdsManager, event) {
  element.budgetPlan.active = event;
  console.log("status", element)
}

sendPage(page: Page) {
  this.router.navigateByUrl(this.page.pageUrl + '/settings/deactivate_delete_account', { state: {page: this.page}});
}

public set privacyTextOffer(page: Page) {
  if(page.offer) {
    this.textOffer = this.titleService.offerArray.find(t => t.id === page.offer.status).text;

  }
}
public get privacyTextOffer() {
  return this.textOffer;
}

public set privacyIconOffer(page: Page) {
  if (page.offer) {
    this.iconOffer = this.titleService.offerArray.find(t => t.id === page?.offer?.status)?.icon;
  }
}

public get privacyIconOffer() {
  return this.iconOffer;
}

openPrivacyOffer(data) {
  this.dialog.open(PrivacyDialogComponent, {
    data: data
  }).afterClosed().subscribe(t => {
    if (t !== undefined) {
      this.page.offer.status = t;
      this.privacyIconOffer = this.page;
      this.privacyTextOffer = this.page;
      this.page.offer.timestamp = new Date();
      this.pageService.updatePage(this.page._id, this.page);
    }
  })
}




}
