import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BranchesService } from 'src/app/shared/services/branches.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';
import { JobsComponent } from '../jobs/jobs.component';
import _ from "lodash"
import { delay, filter } from 'rxjs/operators';
import { Page, Project, UserPage } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { T } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends JobsComponent implements OnInit, OnChanges {
  public pages$: Observable<Page[]>;
  public user: User;
  countryJson;
  selectedCountry: any;
  public page: any;
  public pageProject: Page;
  public pageIndex;
  public userPage: UserPage;
  public branchTitle = "";
  categoryTitle = "";
  public userProject: User;
  projectSearch: Project;
  projectURL = "";

  public userSearch: User;
  public pageSearch: Page;
  public pageID;
  constructor(public pageService: PagesService,
    public userService: UserService,
    public http: HttpClient,
    public dialog: MatDialog,
    public authService: AuthService,
    public router: Router,
    public receiverService: SenderService,
    public branchesService: BranchesService,
    public categoryService: CategoryService,
    public cdref: ChangeDetectorRef,
    public countryDataService: CountryDataService,
    public projectService: ProjectService,
    public titleService: ArraysService,
    public translateService: TranslateService,
    private changeDetectionService: ChangeDetectionService
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.branchesService.getBranches();
    this.categoryService.getCategories();
    this.titleService.showRemote();
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);

    this.projectService.findProjects(this.authService.userID).subscribe((res: any) => {

      this.jobID = res.projects;
      this.jobID = this.jobID.filter((el, i, a) => i === a.indexOf(el));
    })

    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.user = res;
      this.http.get<any>(`assets/languages/${this.user.language.primary}/language.json`).subscribe(data => {
        this.translateService.use(this.user.language.primary);
      });
      this.userProject = _.cloneDeep(this.user);
    })
    this.pageService.getMyPages();
    this.pages$ = this.pageService.pageModel();


    if (localStorage.getItem("searchProjectID")) {
      this.projectService.getProject(localStorage.getItem("searchProjectID")).subscribe(res => {
        this.projectSearch = res;
        if (this.projectSearch?.pageID) {
          this.pageService.getPage(this.projectSearch.pageID).subscribe(t => {
            this.page = t;
          })

        }
        if (!!this.projectSearch?.freelancer) {
          this.projectService.findProjectSearch(this.projectSearch._id).subscribe((t: any) => {
            this.userSearch = t;
          })
        }
        if (!!this.projectSearch?.company) {
          this.projectService.findProjectSearchPage(this.projectSearch._id).subscribe((t: any) => {
            this.pageSearch = t;
          })
        }

      })
     }

    this.receiverService.dataForProject$.subscribe((res: Page) => {
      this.page = res;

      this.changeDetectionService.emmitChanges.subscribe(t => {
        if (!!t) {
          if (this.page.page._id) {
            this.projectService.findProjectsCompany(this.page.page._id).subscribe((t: any) => {
              this.pageID = t.projects;
              this.pageID = this.pageID.filter((el, i, a) => i === a.indexOf(el));
            })
          }
        } else {
          if (this.page.page._id) {

            this.projectService.findProjectsCompany(this.page.page._id).subscribe((t: any) => {
              this.pageID = t.projects;
              this.pageID = this.pageID.filter((el, i, a) => i === a.indexOf(el));
            })
          }
        }
      })


      this.pageProject = _.cloneDeep(this.page);
    });
    this.router.events
      .pipe(
        delay(10),
        filter((e) => e instanceof NavigationEnd),
      )
      .subscribe((event: any) => {
        this.url = event.url;
      });
     this.url = this.router.url;


  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  selectIndexProject(event) {
    this.pageIndex = event;
  }
  getBranch(id) {
    this.branchTitle = this.branchesService.branches.find(t => t.id === id);
  }
  deleteSearchFilterProject(index) {
    this.user.pages[this.pageIndex].searchLocation.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
  }
  showSelectedPage(userPage: any) {
    this.userPage = userPage;
    this.getBranch(userPage.branches);
    this.findCategoryName(userPage.categories.name);
    localStorage.setItem("userPageID", userPage._id);
  }
  findCategoryName(event) {
    this.categoryTitle = this.categoryService.categories.find(r => r.id === event).id;
    console.log(this.categoryTitle, "ejejej")
  }
  findSubCategoryName(cat) {
    const index = this.categoryService.categories.findIndex(object => {
      return object.id === this.userPage.categories.name;
    });
    return this.categoryService.categories[index].subCategory.find(r => r.id === cat);
  }

  addBranch() {
    this.userPage.branches = this.page.branches;
    this.getBranch(this.userPage.branches);
    this.user.pages[this.pageIndex].branches = this.page.branches;
    this.userService.updateUser(this.user._id, this.user);
  }
  addKeyword(index) {
    this.userPage.categories.subCategory[index].keywords = this.page.categories.subCategory[index].keywords;
    this.user.pages[this.pageIndex].categories.subCategory[index].keywords = this.page.categories.subCategory[index].keywords;
    this.userService.updateUser(this.user._id, this.user);
  }
  addCategory() {
    this.userPage.categories = this.page.categories;
    this.user.pages[this.pageIndex].categories = this.page.categories;
    this.findCategoryName(this.userPage.categories.name);
    this.userService.updateUser(this.user._id, this.user);
  }

  addSubCategory(index) {
    this.userPage.categories.subCategory[index] = this.page.categories.subCategory[index];
    this.findSubCategoryName(this.userPage.categories.subCategory[index].name);
    this.user.pages[this.pageIndex].categories.subCategory[index] = this.page.categories.subCategory[index];
  }
  removeKeyword(index, id) {
    this.user.pages[this.pageIndex].categories.subCategory[id].keywords.splice(index, 1);
    this.userPage.categories.subCategory[id].keywords.splice(index, 1);
    this.page.categories.subCategory[id].keywords =  JSON.parse(JSON.stringify(this.pageProject.categories.subCategory[id].keywords));
    this.userService.updateUser(this.user._id, this.user);
  }

  deleteSubCategory(index) {
    this.user.pages[this.pageIndex].categories.subCategory.splice(index, 1);
    this.userPage.categories.subCategory.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
  }
  replaceLanguage() {
    this.user.searchFilterSkills.searchLanguages = this.user.cv[0].skills[0].subCategories[0].languages;
    this.userService.updateUser(this.user._id, this.user);
  }
  replaceQualifications() {
    this.user.searchFilterSkills.searchQualifications = this.user.cv[0].skills[0].subCategories[0].qualifications;
    this.userService.updateUser(this.user._id, this.user);
  }
  replaceDriveLicenseProject() {
    this.user.searchFilterSkills.searchDriveLicenses = this.user.cv[0].skills[0].subCategories[0].driveLicenses;
    this.userService.updateUser(this.user._id, this.user);

  }
  replaceSearchFilterProject() {
    this.user.pages[this.pageIndex].searchLocation.data = this.page.searchLocation.data;
    this.userService.updateUser(this.user._id, this.user);
  }
  replacePCKnowledgeProject() {
    this.user.searchFilterSkills.searchPCKnowledge = this.user.cv[0].skills[0].subCategories[0].pcKnowledge;
    this.userService.updateUser(this.user._id, this.user);

  }
  replaceSkillsOfferProject() {
    this.user.searchFilterSkills.searchSkillsOffer.data = [];
    this.user.searchFilterSkills.searchSkillsOffer = this.user.cv[0].skills[0].subCategories[0].skillsOffer;
    this.userService.updateUser(this.user._id, this.user);
  }
  removeDriveLicenseProject(index) {
    this.user.searchFilterSkills.searchDriveLicenses.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].driveLicenses = JSON.parse(JSON.stringify(this.userProject.cv[0].skills[0].subCategories[0].driveLicenses));
    this.userService.updateUser(this.user._id, this.user);
  }
  removeQualificationProject(index) {
    this.user.searchFilterSkills.searchQualifications.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].qualifications = JSON.parse(JSON.stringify(this.userProject.cv[0].skills[0].subCategories[0].qualifications));
    this.userService.updateUser(this.user._id, this.user);
  }

  removeLanguageProject(index) {
    this.user.searchFilterSkills.searchLanguages.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].languages = JSON.parse(JSON.stringify(this.userProject.cv[0].skills[0].subCategories[0].languages));
    this.userService.updateUser(this.user._id, this.user);
  }
  removePcKnowledgeProject(index) {
    this.user.searchFilterSkills.searchPCKnowledge.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].pcKnowledge = JSON.parse(JSON.stringify(this.userProject.cv[0].skills[0].subCategories[0].pcKnowledge));
    this.userService.updateUser(this.user._id, this.user);
  }
  removeSkillOfferProject(index) {
    this.user.searchFilterSkills.searchSkillsOffer.data.splice(index, 1);
    this.user.cv[0].skills[0].subCategories[0].skillsOffer = JSON.parse(JSON.stringify(this.userProject.cv[0].skills[0].subCategories[0].skillsOffer));
    this.userService.updateUser(this.user._id, this.user);
  }
  selectionChangedProject(item, index) {
    if (this.user.searchLocation[index]?.region.length > 1) {
      this.user.searchLocation[index].postalCode = null;
      this.user.searchLocation[index].postalCode = "";
    } else if (this.user.searchLocation[index]?.region.length === 0 ) {
      this.user.searchLocation[index].postalCode = null;
      this.user.searchLocation[index].postalCode = "";
    } else {
      this.user.searchLocation[index]?.region.forEach(i => this.user.searchLocation[index].postalCode = i);
    }
  }

  createProject() {
    if (this.url.endsWith("/projects") || this.url.endsWith("projects")) {
        this.router.navigateByUrl(this.user?.userUrl + "/projects/create")
    //  this.router.navigate([this.user.userUrl + "/projects", "create"]);
    } else {
      this.receiverService.send(this.page);
      this.router.navigate([this.page.page.pageUrl + "/projects/create_project"]);
    }
  }
  goToMyProjects() {
      if (this.page.page?._id) {
        this.router.navigateByUrl(this.page.page?.pageUrl + "/projects/ourprojects");
      } else {
        this.router.navigateByUrl(this.user?.userUrl + "/projects");
  }
  }

  navigateToSaved() {
    if (this.page?._id) {
      this.router.navigateByUrl(this.page?.pageUrl + "/projects/saved");
    } else {
      this.router.navigateByUrl(this.user?.userUrl + "/projects/saved");

    }
  }

  ignoreProjectPage(id) {
   this.page.ignoredProjects.unshift({projectId: id, timestamp: new Date()})
   this.pageService.updatePage(this.page._id, this.page);
  }

  deleteSavedProjectPage(i){
    this.page.savedProjects.splice(i, 1);
  //  this.pageService.updatePage(this.page._id, this.page);
    console.log(this.pageID)
  }

}
