import { Injectable } from "@angular/core";
import {
  AccountStatus,
  Duration,
  EmploymentType,
  FamilyMembers,
  Incomes,
  OfferPrivacy,
  Relationsship,
  Religion,
  Remote,
  StatusPrivacy,
  Title,
} from "../../enums/enums-model";
import { Role } from "../../enums/role";

@Injectable({
  providedIn: "root",
})
export class ArraysService {
  public titles = [];
  public employmentType = [];
  public salary = [];
  public jobDurationArray = [];
  public remoteArray = [];
  public privacies = [];
  public religions = [];
  public relationsship = [];
  public familyMembers = [];
  public socialLinks = [];
  public pricingData = [];
  public pricingDataVerified = [];
  public coins = [];
  public accountStatus = [];
  public privacyArray = [];

  public offerArray = [];
  public offerArrayProject = [];
  constructor() { }

  public showTitles() {
    this.titles = [
      { id: Title.Empty, description: "---" },
      { id: Title.Dr, description: "data.dr" },
      { id: Title.Prof, description: "data.prof" },
      { id: Title.ProfDr, description: "data.profDr" },
      { id: Title.Ing, description: "data.ing" },
      { id: Title.DipIng, description: "data.dipIng" },
      { id: Title.Mag, description: "data.mag" },
    ];
  }

  public showEmploymentTypes() {
    this.employmentType = [
      {
        id: EmploymentType.EmployedFullTime,
        description: "employmentType.EmployedFullTime",
      },
      {
        id: EmploymentType.EmployedPartTime,
        description: "employmentType.EmployedPartTime",
      },
      {
        id: EmploymentType.Internship,
        description: "employmentType.Internship",
      },
      { id: EmploymentType.Owner, description: "employmentType.Owner" },
      {
        id: EmploymentType.BordMember,
        description: "employmentType.Boardmember",
      },
      { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
      {
        id: EmploymentType.SelfEmployed,
        description: "employmentType.SelfEmployed",
      },
      {
        id: EmploymentType.Shareholder,
        description: "employmentType.Shareholder",
      },
      { id: EmploymentType.Official, description: "employmentType.Official" },
      { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
      {
        id: EmploymentType.Freelancer,
        description: "employmentType.Freelancer",
      },
      { id: EmploymentType.Partner, description: "employmentType.Partner" },
    ];
  }

  public showSalary() {
    this.salary = [
      { id: Incomes.hour, description: "incomes.hour" },
      { id: Incomes.month, description: "incomes.month" },
      { id: Incomes.year, description: "incomes.year" },
      { id: Incomes.total, description: "incomes.total" },
    ];
  }

  public showJobDuration() {
    this.jobDurationArray = [
      {
        id: Duration.unlimited,
        description: "job.fourthPage.duration.unlimited",
      },
      {
        id: Duration.temporary,
        description: "job.fourthPage.duration.temporary",
      },
    ];
  }

  public showRemote() {
    this.remoteArray = [
      {
        id: Remote.onSite,
        description: "project.firstPage.onSite",
        percent: "0%",
      },
      {
        id: Remote.hybrid,
        description: "project.firstPage.hybrid",
        percent: "50%",
      },
      {
        id: Remote.remote,
        description: "project.firstPage.remote",
        percent: "100%",
      },
    ];
  }

  public showRemoteWithoutHybrid() {
    this.remoteArray = [
      {
        id: Remote.onSite,
        description: "project.firstPage.onSite",
        percent: "0%",
      },
      {
        id: Remote.remote,
        description: "project.firstPage.remote",
        percent: "100%",
      },
    ];
  }

  public showRelationsship() {
    this.relationsship = [
      {
        id: Relationsship.Single,
        description: "relationsship.Single",
      },
      {
        id: Relationsship.In_a_relationship,
        description: "relationsship.In_a_relationship",
      },
      {
        id: Relationsship.Engaged,
        description: "relationsship.Engaged",
      },
      {
        id: Relationsship.Married,
        description: "relationsship.Married",
      },
      {
        id: Relationsship.In_a_civil_union,
        description: "relationsship.In_a_civil_union",
      },
      {
        id: Relationsship.In_a_domestic_partnership,
        description: "relationsship.In_a_domestic_partnership",
      },
      {
        id: Relationsship.In_a_open_relationship,
        description: "relationsship.In_a_open_relationship",
      },
      {
        id: Relationsship.Its_complicated,
        description: "relationsship.Its_complicated",
      },
      {
        id: Relationsship.Divorced,
        description: "relationsship.Divorced",
      },
      {
        id: Relationsship.Windowed,
        description: "relationsship.Windowed",
      },
    ]
  }

  public showReligions() {
    this.religions = [
      {
        id: Religion.Catholic,
        name: "religion.Catholic",
      },
      {
        id: Religion.Orthodox,
        name: "religion.Orthodox",
      },
      {
        id: Religion.Protestant,
        name: "religion.Protestant",
      },
      {
        id: Religion.Muslim,
        name: "religion.Muslim",
      },
      {
        id: Religion.Hindus,
        name: "religion.Hindus",
      },
      {
        id: Religion.Jews,
        name: "religion.Jews",
      },
      {
        id: Religion.Buddhists,
        name: "religion.Buddhists",
      },
      {
        id: Religion.Other,
        name: "religion.Other",
      },
    ];
  }

  public showFamilyMembers() {
    this.familyMembers = [

      {
        id: FamilyMembers.Mother,
        description: "Mother",
      }, {
        id: FamilyMembers.Father,
        description: "Father",
      }, {
        id: FamilyMembers.Daughter,
        description: "Daughter",
      }, {
        id: FamilyMembers.Son,
        description: "Son",
      }, {
        id: FamilyMembers.Sister,
        description: "Sister",
      }, {
        id: FamilyMembers.Brother,
        description: "Brother",
      }, {
        id: FamilyMembers.Aunt,
        description: "Aunt",
      }, {
        id: FamilyMembers.Uncle,
        description: "Uncle",
      }, {
        id: FamilyMembers.Niece,
        description: "Niece",
      }, {
        id: FamilyMembers.Nephew,
        description: "Nephew",
      }, {
        id: FamilyMembers.Cousin_female,
        description: "Cousin female",
      }, {
        id: FamilyMembers.Cousin_male,
        description: "Cousin male",
      }, {
        id: FamilyMembers.Grandmother,
        description: "Grandmother",
      }, {
        id: FamilyMembers.Grandfather,
        description: "Grandfather",
      }, {
        id: FamilyMembers.Granddaughter,
        description: "Granddaughter",
      }, {
        id: FamilyMembers.Grandson,
        description: "Grandson",
      }, {
        id: FamilyMembers.Stepsister,
        description: "Stepsister",
      }, {
        id: FamilyMembers.Stepbrother,
        description: "Stepbrother",
      }, {
        id: FamilyMembers.Stepmother,
        description: "Stepmother",
      },
      {
        id: FamilyMembers.Stepfather,
        description: "Stepfather",
      },
      {
        id: FamilyMembers.Stepdaughter,
        description: "Stepdaughter",
      }, {
        id: FamilyMembers.Stepson,
        description: "Stepson",
      }, {
        id: FamilyMembers.Sister_in_law,
        description: "Sister_in_law",
      }, {
        id: FamilyMembers.Brother_in_law,
        description: "Brother_in_law",
      }, {
        id: FamilyMembers.Mother_in_law,
        description: "Mother_in_law",
      }, {
        id: FamilyMembers.Father_in_law,
        description: "Father_in_law",
      }, {
        id: FamilyMembers.Daughter_in_law,
        description: "Daughter_in_law",
      }, {
        id: FamilyMembers.Son_in_law,
        description: "Son_in_law",
      }, {
        id: FamilyMembers.Sibling_gender_neutral,
        description: "Sibling_gender_neutral",
      }, {
        id: FamilyMembers.Parent_gender_neutral,
        description: "Parent_gender_neutral",
      }, {
        id: FamilyMembers.Child_gender_neutral,
        description: "Child_gender_neutral",
      },
      {
        id: FamilyMembers.Sibling_of_Parent_gender_neutral,
        description: "Sibling_of_Parent_gender_neutral",
      },
      {
        id: FamilyMembers.Child_of_Sibling_gender_neutral,
        description: "Child_of_Sibling_gender_neutral",
      },
      {
        id: FamilyMembers.Grandparent_gender_neutral,
        description: "Grandparent_gender_neutral",
      },
      {
        id: FamilyMembers.Grandchild_gender_neutral,
        description: "Grandchild_gender_neutral",
      },
      {
        id: FamilyMembers.Step_Sibling_gender_neutral,
        description: "Step_Sibling_gender_neutral",
      },
      {
        id: FamilyMembers.Step_Parent_gender_neutral,
        description: "Step_Parent_gender_neutral",
      },
      {
        id: FamilyMembers.Step_Child_gender_neutral,
        description: "Step_Child_gender_neutral",
      },
      {
        id: FamilyMembers.Sibling_in_law_gender_neutral,
        description: "Sibling_in_law_gender_neutral",
      },
      {
        id: FamilyMembers.Parent_in_law_gender_neutral,
        description: "Parent_in_law_gender_neutral",
      },
      {
        id: FamilyMembers.Child_in_law_gender_neutral,
        description: "Child_in_law_gender_neutral",
      },
      {
        id: FamilyMembers.Family_member_gender_neutral,
        description: "Family_member_gender_neutral",
      },
      {
        id: FamilyMembers.Pet_gender_neutral,
        description: "Pet_gender_neutral",
      }


    ]

  }

  public showSocialLinks() {
    this.socialLinks = [
      { icon: "bi bi-linkedin", id: "0", description: "Linkedin", url: "https://www.linkedin.com/in/" },
      { icon: "bi bi-facebook", id: "1", description: "Facebook", url: "https://www.facebook.com/" },
      { icon: "bi bi-youtube", id: "2", description: "Youtube", url: "https://www.youtube.com/@" },
      { icon: "bi bi-instagram", id: "3", description: "Instagram", url: "https://www.instagram.com/" },
      { icon: "bi bi-twitter", id: "4", description: "Twitter", url: "https://twitter.com/" },
      { icon: "bi bi-whatsapp", id: "5", description: "Whatsapp", url: "https://wa.me/" },
      { icon: "bi bi-tiktok", id: "6", description: "Tiktok", url: "https://www.tiktok.com/@" },
      { icon: "bi bi-messenger", id: "7", description: "Messenger", url: "https://www.messenger.com/t/" },
      { icon: "bi bi-microsoft-teams", id: "8", description: "Microsoft Teams", url: "https://teams.microsoft.com/l/chat/0/0?users=" },
      { icon: "bi bi-meta", id: "9", description: "Meta", url: "" },
      { icon: "bi bi-snapchat", id: "10", description: "Snapchat", url: "" },
      { icon: "bi bi-github", id: "11", description: "GitHub", url: "https://github.com/" },
      { icon: "bi bi-twitch", id: "12", description: "Twitch", url: "https://www.twitch.tv/" },
      { icon: "bi bi-google", id: "13", description: "Google", url: "" },
      { icon: "bi bi-vimeo", id: "14", description: "Vimeo", url: "https://vimeo.com/" },
      { icon: "bi bi-stack-overflow", id: "15", description: "Stack overflow", url: "https://meta.stackoverflow.com/users/" },
      { icon: "bi bi-pinterest", id: "16", description: "Pinterest", url: "https://www.pinterest.de/" },
      { icon: "bi bi-spotify", id: "17", description: "Spotify", url: "https://open.spotify.com/user/" },
      { icon: "bi bi-slack", id: "18", description: "Slack", url: "" },
      { icon: "bi bi-telegram", id: "19", description: "Telegram", url: "https://t.me/" },
      { icon: "bi bi-signal", id: "20", description: "Signal", url: "https://signal.me/#p/" },
      { icon: "bi bi-reddit", id: "21", description: "Reddit", url: "https://www.reddit.com/user/" },
      { icon: "bi bi-mastodon", id: "22", description: "Mastodon", url: "https://mastodon.social/users/" },
      { icon: "bi bi-discord", id: "23", description: "Discord", url: "" },
      { icon: "bi bi-skype", id: "24", description: "Skype", url: "" },
      { icon: "bi bi-line", id: "25", description: "Line", url: "" },
      { icon: "bi bi-wechat", id: "26", description: "WeChat", url: "" },
      { icon: "bi bi-tencent-qq", id: "27", description: "Tencent QQ", url: "" },
      { icon: "bi bi-sina-weibo", id: "28", description: "Sina Weibo", url: "" },
      { icon: "bi bi-yelp", id: "29", description: "Yelp", url: "" },
      { icon: "bi bi-medium", id: "30", description: "Medium", url: "" },
      { icon: "bi bi-strava", id: "31", description: "Strava", url: "" },
      { icon: "bi bi-dribbble", id: "32", description: "Dribbble", url: "" },
      { icon: "bi bi-paypal", id: "33", description: "Paypal", url: "" },
      { icon: "bi bi-wordpress", id: "34", description: "Wordpress", url: "" },]
  }

  public showPricingDataPremium() {
    this.pricingData = [
      {
        "months": "3",
        "description": "Months",
        "price": 19
      },
      {
        "months": "6",
        "description": "Months",
        "price": 29
      },
      {
        "months": "12",
        "description": "Months",
        "price": 49
      }
    ]
  }


  public showPricingDataVerified() {
    this.pricingDataVerified = [
      {
        "months": "1",
        "description": "Year",
        "price": 45
      },
    ]
  }

  public showAccountStatus() {
    this.accountStatus = [
      {
        status: AccountStatus.Blocked,
        description: 'Blocked'
      },
      {
        status: AccountStatus.Deactivated,
        description: 'Deactivated'
      },
      {
        status: AccountStatus.Active,
        description: 'Active'
      }
    ]
  }

  public getPrivacyArray() {
   this.privacyArray = [
      {id: StatusPrivacy.Public, description: 'Public', icon: 'bi bi-globe-europe-africa', text: 'Anyone'},
      {id: StatusPrivacy.Contacts, description: 'Contacts', icon: 'bi bi-people-fill', text: 'Your Contacts'},
      {id: StatusPrivacy.Followers, description: 'Followers', icon: 'bi bi-person-lines-fill', text:'Your Followers'},
      {id: StatusPrivacy.Only_me, description: 'Only me', icon: 'bi bi-lock-fill', text: '#'}
    ]
  }

  public getOfferArray() {
    this.offerArray = [
      {id: OfferPrivacy.Public, description: 'Public', icon: 'bi bi-7-circle', text: 'Anyone can Offer me Project/Job'},
      {id: OfferPrivacy.One, description: '', icon: 'bi bi-1-circle', text: `${'90% can Offer a Project/Job'} </br> ${'10% Protects'}`},
      {id: OfferPrivacy.Two, description: '', icon: 'bi bi-2-circle', text:`${'70% can Offer a Project/Job'} </br> ${'30% Protects'}`},
      {id: OfferPrivacy.Three, description: '', icon: 'bi bi-3-circle', text:`${'50% can Offer a Project/Job'} </br> ${'50% Protects'}`},
      {id: OfferPrivacy.Four, description: '', icon: 'bi bi-4-circle', text: `${'30% can Offer a Project/Job'} </br> ${'70% Protects'}`},
      {id: OfferPrivacy.Five, description: '', icon: 'bi bi-5-circle', text: `${'10% can Offer a Project/Job'} </br> ${'90% Protects'}`},
      {id: OfferPrivacy.Only_me, description: 'Only Me', icon: 'bi bi-0-circle', text: `${'No One can Offer a Project/Job'} </br> ${'100% Protect'}`}
    ]
  }

  public getOfferArrayProject() {
    this.offerArrayProject = [
      {id: OfferPrivacy.Public, description: 'Public', icon: 'bi bi-7-circle', text: 'Anyone can Apply on this'},
      {id: OfferPrivacy.One, description: '', icon: 'bi bi-1-circle', text: `${'90% can Apply'} </br> ${'10% Protect'}`},
      {id: OfferPrivacy.Two, description: '', icon: 'bi bi-2-circle', text:`${'70%  can Apply'} </br> ${'30% Protects'}`},
      {id: OfferPrivacy.Three, description: '', icon: 'bi bi-3-circle', text:`${'50%  can Apply'} </br> ${'50% Protects'}`},
      {id: OfferPrivacy.Four, description: '', icon: 'bi bi-4-circle', text: `${'30%  can Apply'} </br> ${'70% Protects'}`},
      {id: OfferPrivacy.Five, description: '', icon: 'bi bi-5-circle', text: `${'10%  can Apply'} </br> ${'90% Protects'}`},
    ]
  }
}




