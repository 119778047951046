<div class="container" *ngIf="!data.projectLanguage && !data.jobLanguage && !data.editJobQualifications && !data.editProjectQualifications && !data.cvLanguage">
  <div class="line" *ngFor="let language of data.language; let i = index">
    <div class="col-12">
      <div class="container d-flex justify-content-center">
        <div class ="row col-12">
          <div class="col-7">
            <div class="form-outline">
              <div class="form-control-sm col-md-7">{{getLanguageName(language.name)?.value}}</div>
            </div>
          </div>
          <div class="col-3">
            <app-star-rating stopPropagation [rating]="language.rate / 20" [starCount]="starCount"
            (ratingUpdated)="onRatingChanged(i, $event)" class="col-md-4">
          </app-star-rating>
          </div>
          <div class="col-1">
            <li class="bi bi-dash deleteIcon" (click)="deleteLanguage(i)"></li>

          </div>
        </div>
      </div>

    </div>
  </div>
  <hr style=";">
  <li class="line form-row">
    <div class="col-12">
      <div class="col-12 form-group">
        <form class="example-form d-flex align-items-center">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Select Language</mat-label>
            <input type="text"
                   placeholder="Pick one"
                   aria-label="Language"
                   matInput
                   [formControl]="myControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
              <mat-option *ngFor="let option of filteredOptions | async"  (onSelectionChange)="test(option, $event)" [value]="option.value">
                {{option.value}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="col-sm-12 position-relative" style="width:300px">
            <!-- Workplace on START -->
            <a class="btn btn-empty text-align-start" (click)="addLanguage()"> <i class="bi bi-plus-circle-dotted me-1"></i>Add {{'skills.language' | translate }}</a>
            <!-- Workplace on END -->
          </div>
        </form>


      </div>
    </div>
  </li>
  <mat-dialog-actions>
    <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    <button type="button" class="btn btn-success" mat-button (click)="saveData()">{{'save' | translate}}
    </button>
  </mat-dialog-actions>
</div>

<div class="container" *ngIf="data.projectLanguage">
  <div class="line" *ngFor="let language of data.project.language; let i = index">
    <div class="col-12 d-flex align-items-center">
      <input [ngModel]="language.name | translate" class="form-control col-7">
      <app-star-rating stopPropagation [rating]="language.rate / 20" [starCount]="starCount"
        (ratingUpdated)="onProjectRatingChanged(i, $event)" class="d-flex col-4">
      </app-star-rating>
      <li class="fa fa-minus deleteIcon" (click)="deleteLanguage(i)"></li>
    </div>
  </div>
  <hr style=";">
  <li class="line form-row">
    <div class="col-12">
      <div class="col-12 form-group">
        <select #selectedValue name="selectedValue" id="selectedValue" [(ngModel)]="empty"
          (ngModelChange)="addProjectLanguage($event)" class="col-12 form-control" placeholder="d.ff">
          <option hidden value="" disabled selected>{{'chooseLanguage' | translate}}</option>
          <option *ngFor="let languages of languageService.languages" [ngValue]="languages"
            (change)="addProjectLanguage(languages)" [selected]="languages">
            {{languages.description | translate}}
          </option>
        </select>
      </div>
    </div>
  </li>
  <mat-dialog-actions>
    <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    <button type="button" class="btn btn-success" mat-button (click)="saveProjectData()">{{'save' | translate}}
    </button>
  </mat-dialog-actions>
</div>
<div class="container" *ngIf="data.jobLanguage">
  <div class="line" *ngFor="let language of data.job.language; let i = index">
    <div class="col-12">
      <div class="container d-flex justify-content-center">
        <div class ="row col-12">
          <div class="col-7">
            <div class="form-outline">
              <div class="form-control-sm col-md-7">{{getLanguageName(language.name)?.value}}</div>
            </div>
          </div>
          <div class="col-3">
            <app-star-rating stopPropagation [rating]="language.rate / 20" [starCount]="starCount"
            (ratingUpdated)="onRatingChanged(i, $event)" class="col-md-4">
          </app-star-rating>
          </div>
          <div class="col-1">
            <li class="bi bi-dash deleteIcon" (click)="deleteLanguage(i)"></li>

          </div>
        </div>
      </div>

    </div>
  </div>
  <hr style=";">
  <li class="line form-row">
    <div class="col-12">
      <div class="col-12 form-group">
        <form class="example-form d-flex align-items-center">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Select Language</mat-label>
            <input type="text"
                   placeholder="Pick one"
                   aria-label="Language"
                   matInput
                   [formControl]="myControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
              <mat-option *ngFor="let option of filteredOptions | async"  (onSelectionChange)="test(option, $event)" [value]="option.value">
                {{option.value}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="col-sm-12 position-relative">
            <!-- Workplace on START -->
            <a class="btn btn-empty text-align-start" (click)="addJobLanguage()"> <i class="bi bi-plus-circle-dotted me-1"></i>Add {{'skills.language' | translate }}</a>
            <!-- Workplace on END -->
          </div>
        </form>


      </div>
    </div>
  </li>
  <mat-dialog-actions>
    <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    <button type="button" class="btn btn-success" mat-button (click)="saveLanguageJob()">{{'save' | translate}}
    </button>
  </mat-dialog-actions>
</div>
<div class="container" *ngIf="data.editJobQualifications">
  <div class="modal-header">
    <h3>{{'skills.qualifications' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line" *ngFor="let qualification of data.job?.qualifications; let i = index">
      <div class="col-12">
        <div class="container d-flex justify-content-center">
          <div class ="col-12 d-flex">
            <div class="col-7">
              <div class="form-outline">
                <input [(ngModel)]="qualification.name" class="form-control form-control-sm col-10">
              </div>
            </div>

            <div class="col-1">
              <li class="fa fa-minus deleteIcon" (click)="deleteJobQualifications(i)"></li>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-flex justify-content-center col-md-12">
      <div class ="col-12">
        <div class="col-9">
          <div class="form-outline">
            <input  autofocus [(ngModel)]="name" class="form-control-sm col-10">
          </div>
        </div>
        <div class="col-md-1" style="right: 4.1rem;
        position: absolute;">
            <button class="addIcon" [disabled]="!name" (click)="addJobQualifications()"> <i class="bi bi-plus"></i></button>
          </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveJobQualifications()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
<div class="container" *ngIf="data.editProjectQualifications">
  <div class="modal-header">
    <h3>{{'skills.qualifications' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line" *ngFor="let qualification of data.project?.qualifications; let i = index">
      <div class="col-12 d-flex">
        <input [(ngModel)]="qualification.name" class="form-control-sm col-10">
        <li class="fa fa-minus deleteIcon" (click)="deleteProjectQualifications(i)"></li>
      </div>
    </div>
    <li class="line form-row">
      <div class="col-12">
        <div class="col-12 d-flex form-group">
          <input  autofocus [(ngModel)]="name" class="form-control-sm col-10">
            <button class="addIcon" [disabled]="!name" (click)="addProjectQualifications()"> <i class="bi bi-plus"></i></button>
        </div>
      </div>
    </li>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveProjectQualifications()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>

<div class="container" *ngIf="data.cvLanguage">
  <div class="card">
    <!-- Card header START -->
    <div class="card-header border-0 pb-0">
      <h5 class="card-title">Language {{currentVersion}}</h5>
    </div>
    <!-- Card header START -->
    <!-- Card body START -->
    <div class="card-body">
      <div class="d-flex col-lg-12 align-items-center row">
        <mat-form-field class="example-full-width col-lg-4" appearance="fill">
          <mat-label>Select Language</mat-label>
          <input type="text"
                 placeholder="Pick one"
                 aria-label="Language"
                 matInput
                 [ngModel]="selectedValueLanguage?.description"
                 [matAutocomplete]="auto">

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="cvData?.language?.primary">

            <mat-option *ngFor="let option of languageService.primaryLanguages"  (onSelectionChange)="changeLanguage(option, $event)" [value]="option.description">
              {{option.description}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="!!isNotTranslated" class="col-lg-8 d-flex justify-content-between">
          <span class="">
            has not been translated yet <br>
Let it Translate
          </span>

          <button class="btn btn-primary">Buy</button>
        </div>
        <div *ngIf="!!isNotSameVersion" class="col-lg-8 d-flex justify-content-between">
          <span class="">
            update available <br>
            Let it Translate
          </span>

          <button class="btn btn-primary">Buy</button>
        </div>
      </div>
      <br>
      <hr>
      <span *ngIf="!!isNotTranslated || !!isNotSameVersion" > {{'languageText' | translate}}
      </span>
      <br *ngIf="!!isNotTranslated || !!isNotSameVersion">
      <mat-form-field class="example-full-width col-lg-4" appearance="fill" *ngIf="!!isNotTranslated || !!isNotSameVersion">
        <mat-label>Select Language</mat-label>
        <input type="text"
               placeholder="Pick one"
               aria-label="Language"
               matInput
               [ngModel]="selectedSecondLanguage?.description"
               [matAutocomplete]="auto">


        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="cvData?.language?.secondary">

          <mat-option *ngFor="let option of secondLanguageArray"  (onSelectionChange)="changeLanguageSecond(option, $event)" [value]="option.description">
            {{option.description}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br>
      <!-- Delete START -->
      <mat-dialog-actions>
        <button type="button" class="btn btn-secondary me-2" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
        <button type="button" class="btn btn-success" mat-button (click)="saveCVLanguage()">{{'save' | translate}}
        </button>
      </mat-dialog-actions>                  <!-- Delete END -->
    </div>
  <!-- Card body END -->
  </div>
</div>
