import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShareDialogComponent } from 'src/app/components/modals/share-dialog/share-dialog.component';
import { Page, Project, UserProjects } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-projects-published',
  templateUrl: './user-projects-published.component.html',
  styleUrls: ['./user-projects-published.component.scss']
})
export class UserProjectsPublishedComponent implements OnInit {

  @Input() userProjects: UserProjects;
  @Input() isPublished: boolean = false;
  @Input() public project: Project;
  public moreProjectInfo: Project;
  public jobDurationString;
  public employmentTypeString;
  public currencyObject;
  public countryJsonArray;
  public paymentID;
  countryJsonObject;
  public remote;
  public openMoreInfo = false;
  public subscription: Subscription;
  public page: Page;
  user: User;


applyForm;
selectedUser;
selectedPageID;
description;


public config = {
  toolbar: false
};
editorConfig = {
  padding: "0",
}
arrayPages;

projectOut: Project;







  constructor(public proejctService: ProjectService,
    public http: HttpClient,
    public router: Router,
    private countryDataService: CountryDataService,
    private arrayService: ArraysService,
    private pageService: PagesService,
    private userAuth: AuthService,
    private userService: UserService,
    private dialog: MatDialog,

    ) { }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    this.arrayService.showJobDuration();
    this.arrayService.showEmploymentTypes();
    this.arrayService.showSalary();
    this.arrayService.showRemote();
    this.pageService.getMyPagesOfProject().subscribe(t => {
      this.arrayPages = t;
    })

    this.userService.getUserContactData(this.userAuth.userID).subscribe(t => {
      this.user = t;
    })

    if (!!this.isPublished) {
      if (this.project) {
        this.getJoDurationID(this.project.duration);
        this.getRemoteID(this.project.remote);
        this.getCurrencyID(this.project.currency)
        this.getPaymentFormID(this.project.incomes.s);
        this.getCountryID(this.project.c)

      }

    }

    if (!!this.userProjects.projectID) {
      this.proejctService.getProject(this.userProjects.projectID).subscribe((project: Project) => {
        if (project?.public) {
          this.project = project;
          this.getJoDurationID(this.project.duration);
          this.getRemoteID(this.project.remote);
          this.getCurrencyID(this.project.currency)
          this.getPaymentFormID(this.project.incomes.s);
          this.getCountryID(this.project.c)
        }
      });

    }
  }
  getJoDurationID(id) {
    this.jobDurationString = this.arrayService.jobDurationArray.find(t => t.id === id).description;
  }

  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
  }
  getPaymentFormID(id) {
    this.paymentID = this.arrayService.salary.find(t => t.id === id).description;
  }
  getCountryID(id) {
   this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }

  getRemoteID(id) {
    this.remote = this.arrayService.remoteArray.find(test => test.id === id).description;
  }
  moreInfo(jobID) {
    this.openMoreInfo = true;


  }
  lessInfo() {
    this.openMoreInfo = false;
  }

  test(event) {
    if (event?.isUser) {
     this.selectedUser = true;


    } else {
      this.selectedUser = false;
      this.selectedPageID = event;
      this.pageService.getPage(this.selectedPageID).subscribe(res => {
        this.page = res;
      });
    }
  }

  apply() {
    if (this.checkForApplyFreelancer) {
      this.applyForm = true;
    } else if (this.checkForApplyCompany) {
      this.applyForm = true;
    } else if (!this.checkForApplyCompany) {
      this.applyForm = false;
    } else if (!this.checkForApplyFreelancerNoPage) {
      this.applyForm = false;
    }
  }

  public get checkForApplyFreelancer() {
    return this.project.freelancer;
  }

  public get checkForApplyCompany() {
    return !this.project.freelancer && this.user.pages.length > 0
  }
  public get checkForApplyFreelancerNoPage() {
    return !this.project.freelancer && this.user.pages.length === 0;

  }
  cancelApplyForm() {
    this.applyForm = false;

  }

  public get checkForFreelancerAndCompany() {
    return this.project.freelancer && this.project.company && this.user.pages.length === 0
    }

    openShare(data) {
      this.dialog.open(ShareDialogComponent, {
        data
      });
    }


}
