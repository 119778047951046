<div class="container px-0 py-0 d-flex column">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <app-data-item-details *ngIf="!!data.user && !!userObj" [isUserSearch]="true"   [showForApply]="false"  [isJob]="true" [published]="false" [data]="userObj?.cv?.data" [user]="user"  [showUser]="false" [showSkills]="true"></app-data-item-details>

  <app-data-item-details *ngIf="!!data.job" [isUserSearch]="false"   [showForApply]="false"  [isJob]="true" [published]="false" [data]="data.data" [user]="data.user" [showUser]="false" [showSkills]="true"></app-data-item-details>

  <app-data-item-details *ngIf="!!data.project" [isUserSearch]="false"   [showForApply]="false"  [isJob]="true" [published]="false" [data]="data.data" [user]="data.user" [showUser]="false" [showSkills]="true"></app-data-item-details>

</div>
