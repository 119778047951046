<main>
  <div class="container">
    <div class="row justify-content-between g-4">
      <div class="col-lg-8 vstack gap-4">
        <mat-horizontal-stepper [linear]="true" #stepper>
          <mat-step [stepControl]="firstFormGroup" *ngIf="!router.url.endsWith('edit')">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel></ng-template>
              <li class="mat-form-fiel--inline">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>{{'Ads Title Name'}}</mat-label>
                  <input matInput name="adsTitle" type="text" formControlName="adsTitle" [(ngModel)]="adsManager.name">


                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 50%;">
                  <mat-label>{{ "data.country" | translate }} </mat-label>
                  <mat-select [(ngModel)]="adsManager.country" formControlName="country" required
                    (ngModelChange)="changeCountry($event)">
                    <mat-option *ngFor="let country of countryJson" [value]="country?.ISO_2"
                      (change)="changeCountry($event)" [selected]="selectedCountry">
                      {{ country.EN }} - ({{ country.LokalName }})
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <div>
                <button mat-button matStepperNext class="btn btn-light">Next</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="secondFormGroup">
            <div class="card d-flex column">
              <h5>Name</h5>
              <input name="adsTitle" class="form-control" type="text" [(ngModel)]="adsManager.name">
              <h6 class="mt-3">Country</h6>
              <h6>{{countryJsonObject?.EN}}</h6>

            </div>
<div class="card column">

  <div class="card-header">
    <h5>
      Objective
    </h5>
    <h6>Select an objective that aligns with your business goals</h6>

  </div>
  <div class="card-body">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="card-item d-flex col-md-5">

        <div class="d-flex align-items-center rounded border px-3 py-2 mt-2 mb-2 col-md-12"
          (click)="activeBrand()">

          <a class="mb-0 d-flex align-items-center">
            <i class="bi bi-megaphone text-success pe-2"></i>
            Brand awareness
          </a>
          <input type="checkbox" class="ms-auto" id="adsBrand">
        </div>
      </div>
      <div class="card-item d-flex col-md-5">

        <div class="d-flex align-items-center rounded border px-3 py-2 mt-2 mb-2 col-md-12"
          (click)="activeVisitWeb()">

          <a class="mb-0 d-flex align-items-center">

            <i class="bi bi-file-bar-graph text-info pe-2"></i>
              Website visits
          </a>

          <input type="checkbox" class="ms-auto" id="adsVisitWeb">


        </div>
      </div>
    </div>

  </div>
</div>

            <div class="accordion">
              <div class="card column">

                <div class="d-flex column">
                  <h5>Audience</h5>
                  <div class="form-check">
                    <input class="form-check-input" checked type="checkbox" value="" id="audienceAll"
                      (change)="audienceAllSelected($event)">
                    <label class="form-check-label" for="audienceAll">
                      All
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="profilBased"
                      (change)="profilBased($event)">
                    <label class="form-check-label" for="profilBased">
                      Profil based
                    </label>
                  </div>
                </div>
                <br>
                <div class="accordion-item bg-transparent" style="border-top: 1px solid #676a7920;">
                  <h2 class="accordion-header" id="communicationTwo">
                    <button class="accordion-button mb-0 h6 collapsed" [disabled]="audienceAll" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapseTwo" aria-expanded="false"
                      aria-controls="communicationcollapseTwo">
                      Region
                    </button>
                  </h2>
                  <div id="communicationcollapseTwo" class="accordion-collapse collapse"
                    aria-labelledby="communicationTwo" data-bs-parent="#communications">
                    <div class="accordion-body px-1">
                      <div class="search-widget">
                        <div class="d-flex">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="countryAll" (change)="selectCountryAll()" [disabled]="audienceAll"
                              value="" [checked]="isCountrySelected"  [disabled]="audienceAll">
                            <label class="form-check-label" for="country">
                              {{"data.all" | translate }}
                            </label>
                          </div>
                          <!--<div class="form-check ms-2">
                          <input class="form-check-input" type="checkbox" value="" [disabled]="audienceAll" (change)="changeCitySelected()"
                            id="postalCodde" [checked]="!isCountrySelected">
                          <label class="form-check-label" for="postalCode">
                            {{ 'City' }}
                          </label>
                        </div> -->
                        </div>


                        <ul class="ui-selection-list" *ngIf="isCountrySelected">
                          <li class="line form-row">
                            <div class="pb-2 pt-2 specialty col-md-3 col-sm-12 col-xs-12" id="sp" style="max-width: 240px; width: 100%;">
                              <div class="col-12" style="padding: .25rem;">
                                <select class="col-12 form-control-sm" name="country" id="selectBranch"
                                  [(ngModel)]="adsManager.country" disabled
                                  [ngModelOptions]="{standalone: true}" required>
                                  <option hidden value="" disabled selected>{{selectedCountry?.ISO_2}}</option>
                                  <option *ngFor="let country of countryJson" [value]="country.ISO_2"
                                    [selected]="selectedCountry" [disabled]="audienceAll">
                                    {{ country.EN }} - ({{country.LokalName}})
                                  </option>
                                </select>

                              </div>
                              <mat-button-toggle-group style="margin-bottom: 0.75rem;" class="mat-group-toggle-buttons"
                                #group="matButtonToggleGroup" multiple=true [value]="adsManager?.region?.data"
                                (change)="adsManager.region === event">
                                <mat-button-toggle *ngFor="let item of toggleOptions; let i = index"
                                  (change)="selectionChanged(item, i); selectIndexForButtons(i)"
                                  [disabled]="audienceAll" [value]="item">{{item}}</mat-button-toggle>
                              </mat-button-toggle-group>
                              <ng-container>
                                <label class="column" *ngIf="adsManager?.region?.data?.length < 2">
                                  <p>{{"data.zip" | translate }}-{{"area" | translate}}</p>
                                  <input type="text" class="form-control-sm col-8" style="margin-bottom: 0.75rem;"
                                    [(ngModel)]="adsManager.postalCode" [disabled]="audienceAll">
                                </label>
                                <div class="flex
                                items-center" style="
                                flex-direction: row;
                                display: flex;
                            ">
                                </div>

                              </ng-container>

                            </div>
                          </li>

                        </ul>
                        <!--- <ng-container *ngIf="!isCountrySelected">
                        <div class="row col-12 row">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="categoryAll">
                            <label class="form-check-label" for="id">
                              {{ 'data.all' | translate }}
                            </label>
                          </div>
                          <div class="px-0 col-10">
                            <div class="form-outline"><input [(ngModel)]="skillOffer" autofocus=""
                                class="form-control form-control-sm col-8 ng-valid ng-dirty ng-touched"></div>
                          </div>
                          <div class="col-1">
                            <button [disabled]="!skillOffer" (click)="addCity()" class="addIcon"><i
                                class="bi bi-plus"></i></button>
                          </div>
                          <div class="d-flex align-items-start flex-wrap">
                            <div *ngFor="let city of adsManager.city let cityIndex = index"
                              class="d-flex justify-content-between pr-1 pt-1">
                              <div class="card__text">
                                {{city.name}}
                                <button class="btn btn-empty btn-sm py-0 px-0" type="button"
                                  (click)="removeCityObj(cityIndex)"><i class="fa fa-remove"></i></button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </ng-container> -->

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="communication3">
                    <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapset" aria-expanded="false"
                      aria-controls="communicationcollapseTwo" [disabled]="audienceAll">
                      Alter
                    </button>
                  </h2>
                  <div id="communicationcollapset" class="accordion-collapse collapse" aria-labelledby="communication3"
                    data-bs-parent="#communications">
                    <div class="accordion-body">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"  [disabled]="audienceAll" value="" (change)="dateCategoryAll()" id="dateCategoryAll">
                        <label class="form-check-label" for="id">
                          {{ 'data.all' | translate }}
                        </label>
                      </div>

                      <mat-form-field class="example-full-width ms-2 me-2">
                        <mat-label>From</mat-label>
                        <input matInput [matDatepicker]="picker1" [ngModel]="adsManager?.userAge?.startDate" (ngModelChange)="userAgeStartDate($event)" [max]="minDate" [disabled]="audienceAll">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="example-full-width">
                        <mat-label>Till</mat-label>
                        <input matInput [matDatepicker]="picker2" [ngModel]="adsManager?.userAge?.endDate" (ngModelChange)="userAgeEndDate($event)" [max]="minDateFrom" [disabled]="audienceAll">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="communication4">
                    <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapse4" aria-expanded="false"
                      aria-controls="communicationcollapse4" [disabled]="audienceAll">
                      Geschleht
                    </button>
                  </h2>
                  <div id="communicationcollapse4" class="accordion-collapse collapse"
                    aria-labelledby="communicationcollapse4" data-bs-parent="#communications">
                    <div class="accordion-body">
                      <div class="col-md-6">
                        <label>{{ "Gender" }}</label>
                        <select class="col-md-12 form-control" id="gender"  name="gender" [(ngModel)]="adsManager.gender.data"  [disabled]="audienceAll" (change)="changeGender($event)">
                          <option *ngFor="let gender of genders; let i = index" [value]="gender.id" (change)="changeGender(gender)" #gender [selected]="gender">
                            {{ gender.description | translate }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="communication5">
                    <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapse5" aria-expanded="false" [disabled]="audienceAll"
                      aria-controls="communicationcollapse5">
                      Karrierestufe
                    </button>
                  </h2>
                  <div id="communicationcollapse5" class="accordion-collapse collapse"
                    aria-labelledby="communicationcollapse5" data-bs-parent="#communications">
                    <div class="accordion-body">
                      <ng-container *ngFor="let employmentObj of employmentType; let i = index">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="employmentType" id="employmentType" [checked]="employmentObj?.checked"
                          [disabled]="audienceAll" (click)="employmentTypeFnc(employmentObj.id, i, $event)" >
                          <label class="form-check-label">
                            {{ employmentObj.description | translate }}
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="communication5">
                    <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapse6" aria-expanded="false" [disabled]="audienceAll"
                      aria-controls="communicationcollapse6">
                      Branch
                    </button>
                  </h2>
                  <div id="communicationcollapse6" class="accordion-collapse collapse"
                    aria-labelledby="communicationcollapse6" data-bs-parent="#communications">
                    <div class="accordion-body">
                      <ng-container *ngFor="let branch of branchesService.branches; let i = index">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox"
                            (click)="branchFnc(branch.id, i, $event)"
                            id="branchAds"
                            [disabled]="audienceAll"
                            [checked]="branch.checked" >
                          <label class="form-check-label" >
                            {{ branch.description | translate }}
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="communication5">
                    <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapse7" aria-expanded="false" [disabled]="audienceAll"
                      aria-controls="communicationcollapse7">
                      Kategori
                    </button>
                  </h2>
                  <div id="communicationcollapse7" class="accordion-collapse collapse"
                    aria-labelledby="communicationcollapse7" data-bs-parent="#communications">
                    <div class="accordion-body">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"  [disabled]="audienceAll" (click)="makeAllFalse()" id="categoryAll">
                        <label class="form-check-label" for="id">
                          {{ 'data.all' | translate }}
                        </label>
                      </div>
                      <div *ngFor="let category of categoryService.categories">
                        <h5>{{category.name | translate}}</h5>
                        <ng-container
                          *ngFor="let subCategory of category.subCategory; let i = index; let isLast = last">
                          <div class="form-check" [ngClass]="{'isLast': isLast}">
                            <input class="form-check-input" type="checkbox"   [disabled]="audienceAll"  [checked]="subCategory.checked"
                              (click)="categoryFnc(subCategory.id, i, $event)" value="" [id]="i">
                            <label class="form-check-label" [for]="id">
                              {{ subCategory.name | translate }}
                            </label>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="communication7">
                    <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapse8" aria-expanded="false"
                      [disabled]="audienceAll"
                      aria-controls="communicationcollapse8">
                      Kenntnisse
                    </button>
                  </h2>
                  <div id="communicationcollapse8" class="accordion-collapse collapse"
                    aria-labelledby="communicationcollapse8" data-bs-parent="#communications">
                    <div class="accordion-body">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"  [disabled]="audienceAll" value="" id="skillCategoryAll">
                        <label class="form-check-label" for="id">
                          {{ 'data.all' | translate }}
                        </label>
                      </div>
                      <div class="row col-12 row">
                        <div class="px-0 col-6 align-items-center">
                          <div class="form-outline"><input [disabled]="audienceAll" [(ngModel)]="skillOffer" autofocus=""
                              class="form-control form-control-sm col-8 ng-valid ng-dirty ng-touched"></div>
                        </div>
                        <div class="col-1">
                          <button [disabled]="!skillOffer" (click)="addOfferObj()" class="addIcon"><i
                              class="bi bi-plus"></i></button>
                        </div>
                        <div class="d-flex align-items-start flex-wrap">
                          <div *ngFor="let skill of adsManager?.skills?.data; let skillIndex = index"
                            class="d-flex justify-content-between pr-1 pt-1">
                            <div class="card__text">
                              {{skill.name}}
                              <button class="btn btn-empty btn-sm py-0 px-0" type="button"
                                (click)="removeSkillObj(skillIndex)"><i class="fa fa-remove"></i></button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="communication9">
                    <button class="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#communicationcollapse9" aria-expanded="false" [disabled]="audienceAll"
                      aria-controls="communicationcollapse9">
                      Tätigkeitsfeld
                    </button>
                  </h2>
                  <div id="communicationcollapse9" class="accordion-collapse collapse"
                    aria-labelledby="communicationcollapse9" data-bs-parent="#communications">
                    <div class="accordion-body">
                      <ng-container *ngFor="let actField of activityField; let i = index">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" [disabled]="audienceAll" [checked]="actField.checked" value="" [id]="i"
                            (click)="activityFieldFn(actField.id, i, $event)">
                          <label class="form-check-label" [for]="id">
                            {{ actField.description | translate }}
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
                    <div class="card d-flex column">

                <div class="card-body">

                  <div class="d-flex justify-content-between row">
                    <div class="col-md-6 column col-xs-12">
                      <h5>
                        Ad format
                      </h5>


                      <div class="col-md-12 col-xs-12">
                        <label class="col-md-12 col-12">
                          Text
                          <textarea (keyup)="changeAdsDetailsDescription($event)" maxLength="200"
                            id="adsDetailsDescription" class="form-control"
                            [ngModel]="adsManager?.adsDetails?.text"
                            (ngModelChange)="adsText($event)"></textarea>
                          <div style="margin-top: 0.25rem; text-align: right" id="counter1">200</div>

                        </label>
                      </div>

                      <div class="col-m-12 flex column">
                        <label class="mt-3 mb-3">



                          <input type="checkbox" class="ms-auto" id="adsFormatSingle" (click)="addSingleImg($event)" [checked]="adsFormat">
                          Single image or video <br>
                          One image or video, or a slideshow with multiple images
                        </label>
                        <label>

                          <input type="checkbox" class="ms-auto" id="adsFormatCarousel" (click)="addCards($event)"  [checked]="!adsFormat">
                          Carousel <br>
                          2 or more scrollable images or videos
                        </label>
                      </div>
                      <div class="col-md-12 mt-4 mb-2" *ngIf="adsManager?.adsDetails?.selectedCheckboxSingleImg">
                        <div class="dropdown">
                          <a href="#" class="text-secondary btn btn-secondary-soft tn-secondary-soft-hover py-1 px-2"
                            id="cardShareAction2" data-bs-toggle="dropdown" aria-expanded="false">

                            <i class="bi bi-images"></i>
                            Add Media
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
                            <li><a class="dropdown-item" href="#"> <i class="bi bi-image fa-fw pe-2"></i>Add image</a></li>
                            <li><a class="dropdown-item" href="#"> <i class="bi bi-play-btn-fill fa-fw pe-2"></i>Add video</a></li>

                          </ul>
                        </div>
                      </div>

                      <div class="col-md-12 mt-4 mb-2" *ngIf="!adsManager?.adsDetails?.selectedCheckboxSingleImg">
                        <div class="dropdown">
                          <a  (click)="addImageCard()" class="text-secondary btn btn-secondary-soft tn-secondary-soft-hover py-1 px-2"
                            id="cardCarousel" data-bs-toggle="dropdown" aria-expanded="false">

                            <i class="bi bi-plus"></i>
                            Add Cards
                          </a>
                          <!-- Card share action dropdown menu -->

                        </div>
                      </div>
                      <ng-container *ngIf="!adsManager?.adsDetails?.selectedCheckboxSingleImg">
                        <div cdkDropList class="example-list"  (cdkDropListDropped)="drop($event)">
                          <div class="example-box" *ngFor="let movie of adsManager?.adsDetails?.cards; let i = index; let isLast = last" cdkDrag>
                            <div>
                              <i class="bi bi-arrow-down-up"></i>
                              <img ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35" class="avatar-img rounded-2">
                            </div>
                            {{movie.headline}}
                            <div>
                            <button class="btn btn-light me-1 ms-1" (click)="openDialog({movie, adsManager, i, requiredUrl:adsManager.objective.websiteVisit})"> <i class="bi bi-pencil"></i></button>
                            <button *ngIf=" adsManager?.adsDetails?.cards.length > 2" class="btn btn-light" (click)="removeImageCard(i)"> <i class="bi bi-trash3-fill"></i></button>
                          </div>

                          </div>

                        </div>

                      </ng-container>

                      <ng-container *ngIf="adsManager?.adsDetails?.selectedCheckboxSingleImg">
 <div class="col-md-12 col-sm-12 py-2">
                        <label class="col-md-12 col-12">
                          Headline
                          <textarea maxlength="40" (keyup)="changeText($event)" id="headline"
                            class="form-control col-md-12" [ngModel]="adsManager?.adsDetails?.sharedDescription"
                            (ngModelChange)="adsSharedDesc($event)"></textarea>
                          <div style="margin-top: 0.25rem; text-align: right" id="counter">40</div>
                        </label>
                      </div>

                      <div class="col-md-12">
                        <label class="col-md-12 col-12">
                          Description
                          <textarea (keyup)="changeAdsDetailsDescription($event)" maxLength="200"
                            id="adsDetailsDescription" class="form-control"
                            [ngModel]="adsManager?.adsDetails?.description"
                            (ngModelChange)="adsDescription($event)"></textarea>
                          <div style="margin-top: 0.25rem; text-align: right" id="counter1">200</div>

                        </label>
                      </div>

                      <form name="ngForm" #adsDetailsForm="ngForm">
                        <div class="col-md-12 py-2">

                          <label class="col-md-8 col-12">
                            Website URL
                            <input  pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$" class="form-control" [ngModel]="adsManager?.adsDetails?.url"
                              (ngModelChange)="adsUrl($event)" #adsDetailsUrl="ngModel" name="adsDetailsUrl" [required]="adsManager.objective.websiteVisit" type="url"  placeholder="https://www.xy.expert">
                          </label>
                          <div *ngIf="adsDetailsForm.controls['adsDetailsUrl']?.invalid" class="invalid-feedback">
                            <span >{{ 'Web Url is required' }} </span>
                          </div>



                        </div>
                      </form>



                      <div class="col-md-12 py-2">
                        <label class="col-md-12 col-12">
                          Call to Action
                          <select class="col-12 form-control-sm me-2" name="pcKnowledge" id="selectBranch"
                            [ngModel]="adsManager?.adsDetails?.selectedButton"
                            (ngModelChange)="changeDropButton($event)">
                            <option *ngFor="let call of callAction" [value]="call.id" [selected]="call">
                              {{ call.description }}
                            </option>
                          </select>
                        </label>
                      </div>

                      </ng-container>

                    </div>
                    <div class="col-md-6" style="border-left: 1.5px solid lightgray;">
                      <h5>Vorschau</h5>

                      <div class="card column">
                        <!-- Card header START -->
                        <div class="card-header">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                              <!-- Avatar -->
                              <div class="avatar me-2">
                                <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/logo/12.svg"
                                    alt=""> </a>
                              </div>
                              <!-- Info -->
                              <div>
                                <h6 class="card-title mb-0"><a href="#!"> {{page?.name}} </a></h6>
                                <a href="#!" class="mb-0 text-body">Sponsored <i class="bi bi-info-circle ps-1"
                                    data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top"
                                    data-bs-content="You're seeing this ad because your activity meets the intended audience of our site."></i>
                                </a>
                              </div>
                            </div>
                            <!-- Card share action START -->
                            <div class="dropdown">
                              <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2"
                                id="cardShareAction2" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
                                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Save
                                    post</a></li>
                                <li><a class="dropdown-item" href="#"> <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow
                                    lori ferguson </a></li>
                                <li><a class="dropdown-item" href="#"> <i class="bi bi-x-circle fa-fw pe-2"></i>Hide
                                    post</a></li>
                                <li><a class="dropdown-item" href="#"> <i
                                      class="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                                <li>
                                  <hr class="dropdown-divider">
                                </li>
                                <li><a class="dropdown-item" href="#"> <i class="bi bi-flag fa-fw pe-2"></i>Report
                                    post</a></li>
                              </ul>
                            </div>
                            <!-- Card share action START -->
                          </div>
                        </div>
                        <!-- Card header START -->

                        <!-- Card body START -->

                          <p class="mb-0">{{adsManager?.adsDetails?.text}}</p>

                        <ng-container *ngIf="adsManager?.adsDetails?.selectedCheckboxSingleImg">
                        <img src="assets/images/post/3by2/02.jpg" alt="">
                        <!-- Card body END -->
                        <!-- Card footer START -->
                        <div
                          class="card-footer border-0 d-flex justify-content-between align-items-start column text-align-left px-0">
                          <strong class="w-100">{{adsManager?.adsDetails?.sharedDescription}}</strong>
                          <span class="w-100">{{adsManager?.adsDetails?.description}}</span>

                          <div class="col-md-12 justify-content-between d-flex">
                            <a href="" style="white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;" class="mb-0 col-md-8">{{adsManager?.adsDetails?.url}}</a>
                            <a *ngIf="!!adsManager?.adsDetails?.buttonName" class="btn btn-primary-soft btn-sm"
                              href="#"> {{adsManager?.adsDetails?.buttonName}} </a>

                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="carousel">
                        <div class="card-body px-0 py-0">
                          <owl-carousel-o [options]="customOptions">
                            <ng-container *ngFor="let slide of adsManager?.adsDetails?.cards">
                              <ng-template class="slide" carouselSlide>
                                <div class="card shadow-none text-center px-0 py-0">
                                  <!-- Card body -->
                                  <div class="card-body p-2 pb-0">
                                    <img class="card-img-top" src="assets/images/avatar/09.jpg" alt="">
                                    <h6 class="card-title mb-1 mt-3"> <a href="#!"> {{slide.headline}} </a></h6>
                                    <p class="mb-0 small lh-sm">{{slide.description}}</p>
                                  </div>
                                  <!-- Card footer -->
                                  <div class="card-footer p-2 border-0" *ngIf="!!slide?.buttonName">
                                    <button class="btn btn-sm btn-primary-soft w-100"> {{slide.buttonName}}</button>
                                  </div>
                                </div>
                              </ng-template>
                            </ng-container>
                          </owl-carousel-o>
                        </div>
                      </ng-container>
                        <!-- Card footer END -->
                      </div>
                    </div>


                  </div>

                </div>
              </div>

              <div class="card d-flex column">


                <div class="card-body px-2">
                  <div class="d-flex justify-content-between row">
                    <form class="col-md-6 col-xs-12"  name="ngForm" #budgetForm="ngForm">


                    <div class="col-md-12 col-12">
                      <h5>Budget und Zeitplan</h5>
                      <h6>Wählen Sie aus, wie viel Sie während der Dauer Ihrer Kampagne ausgeben möchten.</h6>
                        <div class="col-md-12 flex align-items-center">
                          <select class="col-5 form-control-sm me-2 mt-2 mb-2" id="selectedBudget" name="selectedBudget" #selectedBudget="ngModel" [ngModel]="adsManager?.budgetPlan?.form"
                          (ngModelChange)="budgetPlanForm($event)" id="selectBranch">
                          <option *ngFor="let budget of budgetArray; let i = index" [value]="budget.id" [selected]="i">
                            {{ budget.description }}
                          </option>
                        </select>
                          <ng-container *ngIf="adsManager?.budgetPlan?.form === 0 || adsManager?.budgetPlan?.form === '0'">
                            <input  class="col-5 ms-4 mt-2 mb-2 form-control-sm" [value]="5" [ngClass]="{ 'is-invalid': budgetValueDay.invalid }" type="number"  name="budgetValueDay" min="5" [ngModel]="adsManager?.budgetPlan?.value"
                            (ngModelChange)="changeBudgetPlanV($event)" #budgetValueDay="ngModel" required>
                          </ng-container>
                          <ng-container  *ngIf="adsManager?.budgetPlan?.form === 1 || adsManager?.budgetPlan?.form === '1'">
                            <input class="col-5 mt-2 mb-2 form-control-sm ms-4"
                            [value]="155" [ngClass]="{ 'is-invalid': budgetValueBased.invalid }" type="number"  name="budgetValueBased" [min]="calculateDays() * 5"
                            [ngModel]="adsManager?.budgetPlan?.value"
                            (ngModelChange)="changeBudgetPlanV($event)" #budgetValueBased="ngModel" required>
                          </ng-container>
                        </div>
                        <div *ngIf="budgetForm.controls['budgetValueDay']?.invalid" class="invalid-feedback">
                          <span >{{ 'Daily Budget needs to be at least: 5.00 coins' }} </span>
                        </div>
                        <div *ngIf="budgetForm.controls['budgetValueBased']?.invalid" class="invalid-feedback">
                          <span *ngIf="adsManager?.budgetPlan?.form === 1 || adsManager?.budgetPlan?.form === '1'">{{ 'Lifetime Budget needs to be at least:' }} {{calculateDays() * 5 }} {{'coins'}} </span>
                        </div>


                    <div class="col-12 d-flex">
                      <li class="mat-form-field--inline d-flex row col-12 ms-0 mr-0">
                        <mat-form-field appearance="fill" style="width: 47%; margin: 0; padding:3px">
                          <mat-label>From</mat-label>
                          <input matInput #budgetPlanStartDate="ngModel" [matDatepicker]="picker3" [min]="todayDate" name="budgetPlanStartDate"
                            [(ngModel)]="budgetPlanStartDateDum" (ngModelChange)="bPlanStartDate($event)">
                          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill" style="width: 47%; margin: 0;padding:3px">
                          <mat-label>Till</mat-label>
                          <input matInput name="budgetPlanEndDate"  [matDatepicker]="picker4" [min]="budgetPlanStartDate.value"
                            [ngModel]="budgetPlanEndDate"
                            (ngModelChange)="adsManager.budgetPlan.endDate = $event">
                          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                          <mat-datepicker #picker4></mat-datepicker>
                        </mat-form-field>
                        <span class="px-0" *ngIf="adsManager?.budgetPlan?.form === 0 || adsManager?.budgetPlan?.form === '0'">
                          Your campaign will run for   {{calculateDays()}} days starting from {{adsManager?.budgetPlan?.startDate | date: 'mediumDate'}}. Your campaign will spend no more than {{calculateDays() * adsManager?.budgetPlan?.value}} total coins.

                        </span>

                        <div class="flex row mt-3 col-md-12 col-12"  *ngIf="adsManager?.budgetPlan?.form === 0 || adsManager?.budgetPlan?.form === '0'">
                          <div class="column col-md-3 col-3">
                            <b>Preis</b>
                            <br>
                            <span>{{calculateDays() * adsManager?.budgetPlan?.value}}</span>
                          </div>
                          <div class="column col-md-1 px-0 py-0">
                            <b></b>
                            <br>
                            <span> - </span>
                          </div>
                          <div class="column col-md-3 col-3">
                            <b>Budget</b>
                            <br>
                            <span>{{adsManager?.budgetPlan?.budget}} </span>
                          </div>
                          <div class="column col-md-1 px-0 py-0 col-1">
                            <b></b>
                            <br>
                            <span> = </span>
                          </div>
                          <div class="column col-md-3 col-3">
                            <b>Preis</b>
                            <br>
                            <span>{{calculateDays() * adsManager?.budgetPlan?.value - adsManager?.budgetPlan?.budget}} </span>
                          </div>
                            <button class="btn btn-primary">Buy</button>

                        </div>
                        <span class="px-0" *ngIf="adsManager?.budgetPlan?.form === 1 || adsManager?.budgetPlan?.form === '1'">
                          Your campaign will run for   {{calculateDays()}} days starting from {{adsManager?.budgetPlan?.startDate | date: 'mediumDate'}}. Your campaign will spend no more than {{adsManager?.budgetPlan?.value}} total coins.


                        </span>

                        <div class="flex row mt-3 col-md-12 col-12" *ngIf="adsManager?.budgetPlan?.form === 1 || adsManager?.budgetPlan?.form === '1'">
                          <div class="column col-md-2 px-1 col-2">
                            <b>Preis</b>
                            <br>
                            <span>{{adsManager?.budgetPlan?.value}}</span>
                          </div>
                          <div class="column col-md-1 px-0 py-0 col-1">
                            <b></b>
                            <br>
                            <span> - </span>
                          </div>
                          <div class="column col-md-3 col-3">
                            <b>Budget</b>
                            <br>
                            <span>{{adsManager?.budgetPlan?.budget}} </span>
                          </div>
                          <div class="column col-md-1 px-0 py-0 col-1">
                            <b></b>
                            <br>
                            <span> = </span>
                          </div>
                          <div class="column col-md-3 col-3">
                            <b>Preis</b>
                            <br>
                            <span>{{adsManager?.budgetPlan?.value - adsManager?.budgetPlan?.budget}} </span>
                          </div>
                            <button class="btn btn-primary col-md-2 col-2 px-0">Buy</button>

                        </div>

                        <div class="column col-md-3 mt-3 col-3">
                          <b>Budget</b>
                          <br>
                          <b>{{adsManager?.budgetPlan?.budget}} </b>
                        </div>
                        <div  class="table-column-100 mt-4">
                          Off/On
                        </div>
                          <div  class="form-check form-switch"><input  type="checkbox" role="switch" id="NotiSwitchCheckChecked" checked="" class="form-check-input" [checked]="adsManager.budgetPlan.active" [(ngModel)]="adsManager.budgetPlan.active"></div>





                      </li>
                    </div>
                  </div>
                </form>
                  <div class="col-md-6 col-xs-12" style="border-left: 1.5px solid lightgray;">
                    <h5>Forecasted results</h5>
                    <h6>Audience size</h6>

                    <hr>
                    <h6>Objective</h6>
                    <h6>Total spend</h6>
                    <h6>Clicks</h6>

                  </div>
                </div>




                </div>
              </div>
            </div>

            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button class="btn btn-sm btn-primary mb-0" [disabled]="!adsManager" (click)="createAds()">Save</button>
            </div>

          </mat-step>

        </mat-horizontal-stepper>

      </div>
    </div>
  </div>
</main>
