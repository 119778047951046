<div class="issue-wrap" *ngIf="!isProject">
    <div stopPropagation (click)="openIssueModal({issue: issue, job: job})"
         class="issue">
         <div class="d-flex mobile-page">
            <img ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35" class="img-card">
            <p class="pb-3 text-15 text-textDarkest ms-2" *ngIf="!page">
               {{user?.firstName}} {{user?.lastName}}
            </p>
            <p class="pb-3 text-15 text-textDarkest ms-2" *ngIf="page">
                {{page?.name}}
             </p>
         </div>
    </div>
</div>
<div class="issue-wrap" *ngIf="isProject">
    <div stopPropagation (click)="openProjectModal({issue: issue, project: job, isNotPage: true ? !page : page})"
         class="issue">
         <div class="d-flex mobile-page align-items-center">
            <div class="d-flex justify-flex-start">
                <img ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35" class="img-card">
            </div>
            <p class="text-15 text-textDarkest ms-2"  *ngIf="!page && userSeenMsg">
               {{user?.firstName}} {{user?.lastName}}
            </p>

            <p class="text-15 text-textDarkest ms-2" style="font-weight: 600;"  *ngIf="!page && !userSeenMsg">
              {{user?.firstName}} {{user?.lastName}}
           </p>
            <p class="pb-3 text-15 text-textDarkest ms-2" *ngIf="!!page && !!pageSeen">
                {{page?.name}}
             </p>
             <p class="pb-3 text-15 text-textDarkest ms-2" style="font-weight: 600;"  *ngIf="!!page && !pageSeen">
              {{page?.name}}
           </p>
         </div>
         <p class="ms-5 badge-notify-blue" *ngIf="!page && !userSeenMsg">
        </p>
        <p class="ms-5 badge-notify-blue" *ngIf="!!page && !pageSeen">
        </p>




    </div>
</div>
