<main>
  <div class="container">
    <div class="row justify-content-between g-4" *ngIf="!router.url.endsWith('search')">
    <div class="card-footer">
      <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
        <li >
          <!-- Chat user tab item -->
          <a routerLink="/jobs" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
            <div class="d-flex">
              <div class="flex-shrink-0 avatar avatar-story me-2">
                <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
              </div>
              <div class="flex-grow-1 d-block">
                <h6 class="mb-0 mt-1"> {{user?.firstName}} {{user?.lastName}}</h6>
              </div>
            </div>
          </a>
        </li>
        <ng-container *ngFor="let userPage of pages$ | async; let i = index">
          <li>

            <app-pages-list class="d-flex me-1 ms-1" [pageProjects]="userPage" [projectPageID]="i" [projects]="'projects'" (selectedIndex)="selectIndexProject($event)" (selectedPage)="showSelectedPage($event)"></app-pages-list>

          </li>
        </ng-container>
       </ul>
    </div>
    <app-top-nav-buttons-jobs-projects
    *ngIf="router.url.endsWith('jobs')"
    [textButtonCreate]="'job.create'"
    [textButtonData]="'job.myJobs'"
    [textButtonApplied]="'project.applied'"
    [textButtonSaved]="'job.savedJobs'"
    [routerLinkForApplied]="'../' +  user?.userUrl + '/jobs/applied'"
    (buttonCreate)="createJob()"
    (buttonData)="goToMyJobs()"
    (buttonSaved)="navigateToSaved()"
  ></app-top-nav-buttons-jobs-projects>

  <app-top-nav-buttons-jobs-projects
  *ngIf="router.url === '/jobs/' + page.page?.pageUrl"
    [textButtonCreate]="'job.create'"
    [textButtonData]="'pagesSettings.jobs'"
    (buttonCreate)="createPageJob()"
    (buttonData)="goToPageJobs()"
  ></app-top-nav-buttons-jobs-projects>
  <div class="col-lg-3" *ngIf="router.url === '/jobs'" >
    <div class="d-flex align-items-center mb-4 d-lg-none">
      <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
        <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
        <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'searchDetails' | translate}}</span>
      </button>
    </div>
    <nav class="navbar navbar-light navbar-expand-lg mx-0">
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
        <!-- Offcanvas header -->
        <div class="offcanvas-header">
          <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <!-- Offcanvas body -->
        <div class="offcanvas-body p-0">
          <!-- Card START -->
          <div class="card w-100">
            <!-- Card body START -->
            <div class="card-body px-0 py-0">

            <!-- Side Nav START -->
            <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                <li class="nav-item w-100">
                  <app-sidebar-search-jobs-projects class="w-100" [isPage]="false"></app-sidebar-search-jobs-projects>
                </li>
                </ul>
                </div>
                </div>
                </div>
                </div>
                </nav>




  </div>

  <div class="col-lg-6 vstack gap-4" *ngIf="router.url === '/jobs'" >
    <div class="tab-content py-0 mb-0">
        <ng-container  *ngFor="let userJobSearchId of jobID; let i = index">
          <app-job-item [userJobSearchId]="userJobSearchId" [userUrl]="user?.userUrl" [searchForJob]="true" (unsaveJobUsers)="onChangeSavedJobs(i)" [user]="user"></app-job-item>
        </ng-container>
        <div class="card">
          <div class="card-body d-flex column align-items-start">
            <h5>To see more jobs, customise your CV:
              <br>
              <div class="d-flex flex-column align-items-start">
                <a class="me-1" [routerLink]="'../' +user?.userUrl + '/cv/'">{{'header.cv' | translate}}</a>
                <a   class="me-1 mr-1"[routerLink]="'../' +user?.userUrl + '/cv/search_locations'">{{'search_filter' | translate}}</a>
                <a  class="me-1" [routerLink]="'../' +user?.userUrl + '/cv/branch'">{{'Branchen.name' | translate}}</a>

              </div>



             </h5>
             </div>
          </div>
    </div>

  </div>

  </div>
  <div class="row justify-content-between g-4" *ngIf="router.url.endsWith('search')">
    <div class="card-footer">
      <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
       <li *ngIf="!job?.pageID">
         <!-- Chat user tab item -->
         <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
           <div class="d-flex">
             <div class="flex-shrink-0 avatar avatar-story me-2">
               <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
             </div>
             <div class="flex-grow-1 d-block">
               <h6 class="mb-0 mt-1"> {{user?.firstName}} {{user?.lastName}}</h6>
             </div>
           </div>
         </a>
       </li>
       <li *ngIf="job?.pageID">
        <!-- Chat user tab item -->
        <a  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
          <div class="d-flex">
            <div class="flex-shrink-0 avatar avatar-story me-2">
              <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
            </div>
            <div class="flex-grow-1 d-block">

              <h6 class="mb-0 mt-1"> {{page?.name}}</h6>
            </div>
          </div>
        </a>
      </li>
      </ul>

       </div>
       <app-top-nav-buttons-jobs-projects
        *ngIf="!dataIsPage"
        [textButtonCreate]="'job.create'"
        [textButtonData]="'job.myJobs'"
        [textButtonApplied]="'project.applied'"
         [textButtonSaved]="'job.savedJobs'"
         [textButtonIgnored]="'job.ignoredJobs'"
         [routerLinkForIgnored]="'../' + page?.pageUrl + '/projects/ignored'"
         [routerLinkForApplied]="'../' +  page?.pageUrl + '/projects/applied_projects'"
         (buttonCreate)="createProject()"
         (buttonData)="goToMyProjects()"
         (buttonSaved)="navigateToSaved()"
       ></app-top-nav-buttons-jobs-projects>
       <app-top-nav-buttons-jobs-projects
       *ngIf="dataIsPage"
       [textButtonCreate]="'job.create'"
       [textButtonData]="'pagesSettings.jobs'"
           (buttonCreate)="createPageJob()"
        (buttonData)="goToPageJobs()"
      ></app-top-nav-buttons-jobs-projects>
       <div class="col-lg-3">

        <!-- Advanced filter responsive toggler START -->
        <!-- Divider -->
        <div class="d-flex align-items-center mb-4 d-lg-none">
          <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'searchDetails' | translate}}</span>
          </button>
        </div>
        <!-- Advanced filter responsive toggler END -->

        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <!-- Card START -->
              <div class="card w-100">
                <!-- Card body START -->
                <div class="card-body px-0 py-0">

                <!-- Side Nav START -->
                <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                  <li class="nav-item w-100">
                    <ng-container *ngIf="!!job">
                      <app-sidebar-search-jobs-projects class="w-100" [isJob]="true" [data]="job" [isPage]="false" [isSearchSidebar]="true"  [categoryTitle]="categoryTitle" [pageIndex]="pageIndex"></app-sidebar-search-jobs-projects>
                    </ng-container>
                  </li>
                </ul>
                <!-- Side Nav END -->

              </div>
              <!-- Card body END -->
              <!-- Card footer -->

              </div>
            <!-- Card END -->
            </div>


          </div>
        </nav>
      </div>
      <div class="col-lg-6 vstack gap-4">
        <!-- Setting Tab content START -->
        <div class="tab-content py-0 mb-0">
          <ng-container *ngIf="dataIsPage">
            <ng-container *ngFor="let user of userID; let i = index">
              <app-job-item [searchUserID]="user" [dataJob]="job" [userSearchFromJob]="true"></app-job-item>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!dataIsPage">
            <ng-container *ngFor="let user of userID; let i = index">
              <app-job-item [searchUserID]="user" [data]="job" [userSearchFromJob]="true"></app-job-item>
            </ng-container>

          </ng-container>





            </div>
            </div>
  </div>
</div>
</main>

<div class="main-container">
 <!-- <app-bookmarks class="left-sidebar"></app-bookmarks> -->
  <div class="main-content">





  </div>
</div>
