<main *ngIf="page?.account?.s === 2">

  <!-- Container START -->
  <div class="container">
    <div class="row justify-content-between g-4">


      <!-- Sidenav START -->
      <div class="mt-n4 col-lg-1" *ngIf="hasUserAccesOnSidebar">
        <div class="d-flex align-items-center d-lg-none d-lg-none-show">
          <a class="btn text-secondary py-0 me-sm-3 sidebar-start-toggle" type="button" (click)="sidebarClick($event)">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0 avatar-sm avatar-story me-2">
                <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="" />
              </div>
              <div class="flex-grow-1 d-block">
                <h6 class="mb-0 mt-1">
                  {{ page?.name }}
                </h6>
              </div>
            </div>
          </a>
        </div>
        <div class="nav-sidenav p-4 bg-mode h-100 custom-scrollbar" (appOutsideClick)=outsideClick($event)>
          <!-- Card START -->
          <!-- Side Nav START -->
          <ul class="nav nav-link-secondary flex-column fw-bold gap-2">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" [routerLink]="'/' + this.page?.pageUrl"
                [routerLinkActive]="['active']"> <img ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                <span class="nav-text">{{page?.name}} </span> <span class="caret"></span></a>

            </li>
            <li class="nav-item" (appOutsideClick)="outsideClick($event)">
              <a class="nav-link" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + this.page?.pageUrl"
                [routerLinkActive]="['active']"> <i class="bi bi-house-door nav-icon"></i> <span
                  class="nav-text">{{'pagesSettings.home' | translate}} </span></a>
            </li>
            <li class="nav-item" *ngIf="isMessaging" (appOutsideClick)=outsideClick($event)>
              <a class="nav-link" (click)="sendData()" [routerLink]="'/' + this.page?.pageUrl + '/messages'"
                [routerLinkActive]="['active']"> <i class="bi bi-chat-left-text nav-icon"></i> <span
                  class="nav-text">{{'pagesSettings.sidebar.messaging' | translate}} </span></a>
            </li>
            <li class="nav-item" *ngIf="isUserJobs" (appOutsideClick)=outsideClick($event)>
              <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/ourjobs'" [routerLinkActive]="['active']">
                <i class="bi bi-briefcase nav-icon"></i> <span class="nav-text">{{'pagesSettings.jobs' | translate}}
                </span></a>
            </li>
            <li class="nav-item" *ngIf="isUserProjects" (appOutsideClick)=outsideClick($event)>
              <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/projects/ourprojects'"
                [routerLinkActive]="['active']"> <i class="bi bi-bar-chart-steps nav-icon"></i> <span
                  class="nav-text">{{'pagesSettings.projects' | translate}} </span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" *ngIf="isAdvertiser" [routerLink]="'/' + this.page?.pageUrl + '/ads_manager'"
                [routerLinkActive]="['active']"><i class="bi bi-badge-ad nav-icon"></i><span
                  class="nav-text">{{'sidebar.advertisement' | translate}} </span></a>
            </li>

            <li class="nav-item" *ngIf="isPayment" (appOutsideClick)=outsideClick($event)>
              <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/payment/billing'"
                [routerLinkActive]="['active']"> <i class="bi bi-credit-card nav-icon"></i> <span
                  class="nav-text">{{'pagesSettings.payment' | translate}} </span></a>
            </li>
            <li class="nav-item" *ngIf="isPayment" (appOutsideClick)=outsideClick($event)>
              <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/payments_management'"
                [routerLinkActive]="['active']"> <i class="bi bi-credit-card nav-icon"></i> <span
                  class="nav-text">{{'pagesSettings.payment_management' | translate}} </span></a>
            </li>
            <li class="nav-item" *ngIf="isNotifications" (appOutsideClick)=outsideClick($event)>
              <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/notifications'"
                [routerLinkActive]="['active']"> <i class="bi bi-bell nav-icon"></i> <span
                  class="nav-text">{{'pagesSettings.notifications' | translate}} </span></a>
            </li>
            <li class="nav-item" *ngIf="isOwnerOrAdmin" (appOutsideClick)=outsideClick($event)>
              <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/settings'" [routerLinkActive]="['active']">
                <i class="bi bi-gear nav-icon"></i> <span class="nav-text">{{'pagesSettings.settings' | translate}}
                </span></a>
            </li>
          </ul>
          <!-- Side Nav END -->
          <!-- Card body END -->
        </div>
      </div>


      <ng-container
        *ngIf="!router.url.endsWith('ourprojects') && !router.url.endsWith('ourjobs') && !router.url.endsWith('applied_projects') && !router.url.endsWith('create_project') && !router.url.endsWith('create_job')  && !router.url.endsWith('saved') && !router.url.endsWith('ignored') && !router.url.endsWith('settings') && !router.url.endsWith('page_roles') && !router.url.endsWith('settings/locations') && !router.url.endsWith('settings/search_locations') && !router.url.endsWith('settings/language') && !router.url.endsWith('settings/premium') && !router.url.endsWith('settings/verified') && !router.url.endsWith('settings/about') && !router.url.endsWith('payment') && !router.url.endsWith('billing') && !router.url.endsWith('transactions') && !router.url.endsWith('purchased') && !router.url.endsWith('notifications') && !router.url.endsWith('ads_manager') && !router.url.endsWith('ads_manager/create') &&  !router.url.endsWith('ads_manager/edit') && !router.url.endsWith('settings/close_account')">
        <div class="col-lg-8 vstack gap-4">
          <!-- My profile START -->
          <div class="card">
            <!-- Cover image -->
            <div class="h-200px rounded-top"
              style="background-image:url(assets/images/bg/05.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;">
            </div>
            <!-- Card body START -->
            <div class="card-body py-0">
              <div class="d-sm-flex align-items-start text-center text-sm-start">
                <div>
                  <!-- Avatar -->
                  <div class="avatar avatar-xxl mt-n5 mb-3">
                    <img class="avatar-img rounded-circle border border-white border-3"
                      src="assets/images/avatar/07.jpg" alt="">
                  </div>
                </div>
                <div class="ms-sm-4 mt-sm-3">
                  <!-- Info -->
                  <h1 class="mb-0 h5">{{page?.name}}<i class="bi bi-patch-check-fill text-success small"></i></h1>
                  <div class="d-flex column">
                    <a [routerLinkActive]="['active']" routerLink="connections/followers_page">{{followersPageNr}}
                      {{'followers' | translate}}</a>
                    <a [routerLinkActive]="['active']" routerLink="connections/employers">{{employersPageNr}}
                      {{'employers' | translate}}</a>
                  </div>

                </div>
                <!-- Button -->
                <div class="d-flex mt-3 justify-content-center ms-sm-auto">
                  <button *ngIf="hasUserAccesOnSidebar" class="btn btn-danger-soft me-2" type="button"> <i
                      class="bi bi-pencil-fill pe-1"></i> Edit profile </button>
                  <button (click)="followPage(page)" *ngIf="!hasFollow && !isPendingFollow"
                    class="btn btn-primary-soft me-2 me-2" type="button"> <i class="bi bi-plus pe-1"></i> {{'follow' |
                    translate}} </button>
                  <button (click)="unfollowPage(page)" *ngIf="!!hasFollow" class="btn btn-primary-soft me-2"
                    type="button"> <i class="bi bi-check2 pe-1"></i>{{'following' | translate}} </button>
                  <button *ngIf="isPendingFollow" class="btn btn-primary-soft me-2" type="button"
                    (click)="unfollowPage()"><i class="bi bi-clock-history pe-1"></i>
                    {{'request' | translate}}
                  </button>
                  <div class="dropdown">
                    <!-- Card share action menu -->
                    <button class="icon-md btn btn-light" type="button" id="profileAction2" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="bi bi-three-dots"></i>
                    </button>
                    <!-- Card share action dropdown menu -->
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileAction2">
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Share profile in a
                          message</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-file-earmark-pdf fa-fw pe-2"></i>Save your
                          profile to PDF</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-lock fa-fw pe-2"></i>Lock profile</a></li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-gear fa-fw pe-2"></i>Profile settings</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- List profile -->
              <ul class="list-inline mb-0 text-center text-sm-start mt-3 mt-sm-0">
                <li class="list-inline-item"><i class="bi bi-briefcase me-1"></i> {{branchTitle | translate}} -
                  {{subCategoryTitle | translate}} </li>
                <!-- <li class="list-inline-item"><i class="bi bi-geo-alt me-1"></i> New Hampshire</li>
            <li class="list-inline-item"><i class="bi bi-calendar2-plus me-1"></i> Joined on Nov 26, 2019</li> -->
              </ul>
            </div>
            <!-- Card body END  -->
            <div class="card-footer mt-3 pt-2 pb-0">
              <!-- Nav profile pages -->
              <ul
                class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                <li class="nav-item"> <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link"
                    [routerLinkActive]="['active']" [routerLink]="'/' + this.page?.pageUrl"> {{"userProfile.posts" |
                    translate}} </a> </li>
                <li class="nav-item"> <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link"
                    [routerLink]="'/' + this.page?.pageUrl + '/about'" [routerLinkActive]="['active']">
                    {{"pageSidebar.info" | translate}} </a> </li>
                <li class="nav-item"> <a [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                    routerLink="published/jobs" class="nav-link">{{ "header.jobs" | translate }}</a></li>
                <li class="nav-item"> <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link"
                    [routerLinkActive]="['active']" routerLink="published/projects">{{ "header.projects" |
                    translate}}</a></li>
                <!--  <li class="nav-item"> <a [routerLinkActiveOptions]="{ exact: true }" class="nav-link" [routerLinkActive]="['active']"[routerLink]="'/' + this.page?.pageUrl + '/connections/followers_page'"> {{"userProfile.connections" | translate}} <span class="badge bg-success bg-opacity-10 text-success small"> {{followersPageNr}}</span> </a> </li> -->
                <!--  <li class="nav-item"> <a class="nav-link" href="my-profile-media.html"> Media</a> </li>
            <li class="nav-item"> <a class="nav-link" href="my-profile-videos.html"> Videos</a> </li>
            <li class="nav-item"> <a class="nav-link" href="my-profile-events.html"> Events</a> </li>
            <li class="nav-item"> <a class="nav-link" href="my-profile-activity.html"> Activity</a> </li>-->
              </ul>
            </div>
          </div>
          <!-- My profile END -->

          <ng-container *ngIf="router.url.endsWith('/start')">
            <!-- Share feed START -->
            <div class="card card-body">
              <div class="d-flex mb-3">
                <!-- Avatar -->
                <div class="avatar avatar-xs me-2">
                  <a href="#"> <img class="avatar-img rounded-circle" src="assets/images/avatar/07.jpg" alt=""> </a>
                </div>
                <!-- Post input -->
                <form class="w-100">
                  <input class="form-control pe-4 border-0" placeholder="Share your thoughts..." data-bs-toggle="modal"
                    data-bs-target="#modalCreateFeed">
                </form>
              </div>
              <!-- Share feed toolbar START -->
              <ul class="nav nav-pills nav-stack small fw-normal">
                <li class="nav-item">
                  <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal"
                    data-bs-target="#feedActionPhoto"> <i class="bi bi-image-fill text-success pe-2"></i>Photo</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal"
                    data-bs-target="#feedActionVideo"> <i class="bi bi-camera-reels-fill text-info pe-2"></i>Video</a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link bg-light py-1 px-2 mb-0" data-bs-toggle="modal"
                    data-bs-target="#modalCreateEvents"> <i
                      class="bi bi-calendar2-event-fill text-danger pe-2"></i>Event </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal"
                    data-bs-target="#modalCreateFeed"> <i class="bi bi-emoji-smile-fill text-warning pe-2"></i>Feeling
                    /Activity</a>
                </li>
                <li class="nav-item dropdown ms-sm-auto">
                  <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </a>
                  <!-- Dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a>
                    </li>
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                      </a></li>
                    <li>
                      <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                  </ul>
                </li>
              </ul>
              <!-- Share feed toolbar END -->
            </div>
            <!-- Share feed END -->

            <!-- Card feed item START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <!-- Avatar -->
                    <div class="avatar avatar-story me-2">
                      <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="">
                      </a>
                    </div>
                    <!-- Info -->
                    <div>
                      <div class="nav nav-divider">
                        <h6 class="nav-item card-title mb-0"> <a href="#!"> Lori Ferguson </a></h6>
                        <span class="nav-item small"> 2hr</span>
                      </div>
                      <p class="mb-0 small">Web Developer at Webestica</p>
                    </div>
                  </div>
                  <!-- Card feed action dropdown START -->
                  <div class="dropdown">
                    <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardFeedAction1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="bi bi-three-dots"></i>
                    </a>
                    <!-- Card feed action dropdown menu -->
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardFeedAction1">
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow lori
                          ferguson </a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                    </ul>
                  </div>
                  <!-- Card feed action dropdown END -->
                </div>
              </div>
              <!-- Card header END -->
              <!-- Card body START -->
              <div class="card-body">
                <p>I'm thrilled to share that I've completed a graduate certificate course in project management with
                  the president's honor roll.</p>
                <!-- Card img -->
                <img class="card-img" src="assets/images/post/3by2/01.jpg" alt="Post">
                <!-- Feed react START -->
                <ul class="nav nav-stack py-3 small">
                  <li class="nav-item">
                    <a class="nav-link active" href="#!"> <i class="bi bi-hand-thumbs-up-fill pe-1"></i>Liked (56)</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#!"> <i class="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                  </li>
                  <!-- Card share action START -->
                  <li class="nav-item dropdown ms-sm-auto">
                    <a class="nav-link mb-0" href="#" id="cardShareAction8" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                    </a>
                    <!-- Card share action dropdown menu -->
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction8">
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Send via Direct
                          Message</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark
                        </a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-link fa-fw pe-2"></i>Copy link to post</a>
                      </li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-share fa-fw pe-2"></i>Share post via …</a>
                      </li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Share to News
                          Feed</a></li>
                    </ul>
                  </li>
                  <!-- Card share action END -->
                </ul>
                <!-- Feed react END -->

                <!-- Add comment -->
                <div class="d-flex mb-3">
                  <!-- Avatar -->
                  <div class="avatar avatar-xs me-2">
                    <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/avatar/12.jpg" alt=""> </a>
                  </div>
                  <!-- Comment box  -->
                  <form class="position-relative w-100">
                    <textarea class="form-control pe-4 bg-light" rows="1" placeholder="Add a comment..."></textarea>
                  </form>
                </div>
                <!-- Comment wrap START -->
                <ul class="comment-wrap list-unstyled">
                  <!-- Comment item START -->
                  <li class="comment-item">
                    <div class="d-flex position-relative">
                      <!-- Avatar -->
                      <div class="avatar avatar-xs">
                        <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg"
                            alt=""></a>
                      </div>
                      <div class="ms-2">
                        <!-- Comment by -->
                        <div class="bg-light rounded-start-top-0 p-3 rounded">
                          <div class="d-flex justify-content-between">
                            <h6 class="mb-1"> <a href="#!"> Frances Guerrero </a></h6>
                            <small class="ms-2">5hr</small>
                          </div>
                          <p class="small mb-0">Removed demands expense account in outward tedious do. Particular way
                            thoroughly unaffected projection.</p>
                        </div>
                        <!-- Comment react -->
                        <ul class="nav nav-divider py-2 small">
                          <li class="nav-item">
                            <a class="nav-link" href="#!"> Like (3)</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!"> Reply</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!"> View 5 replies</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- Comment item nested START -->
                    <ul class="comment-item-nested list-unstyled">
                      <!-- Comment item START -->
                      <li class="comment-item">
                        <div class="d-flex">
                          <!-- Avatar -->
                          <div class="avatar avatar-xs">
                            <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/06.jpg"
                                alt=""></a>
                          </div>
                          <!-- Comment by -->
                          <div class="ms-2">
                            <div class="bg-light p-3 rounded">
                              <div class="d-flex justify-content-between">
                                <h6 class="mb-1"> <a href="#!"> Lori Stevens </a> </h6>
                                <small class="ms-2">2hr</small>
                              </div>
                              <p class="small mb-0">See resolved goodness felicity shy civility domestic had but
                                Drawings offended yet answered Jennings perceive.</p>
                            </div>
                            <!-- Comment react -->
                            <ul class="nav nav-divider py-2 small">
                              <li class="nav-item">
                                <a class="nav-link" href="#!"> Like (5)</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" href="#!"> Reply</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <!-- Comment item END -->
                      <!-- Comment item START -->
                      <li class="comment-item">
                        <div class="d-flex">
                          <!-- Avatar -->
                          <div class="avatar avatar-story avatar-xs">
                            <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/07.jpg"
                                alt=""></a>
                          </div>
                          <!-- Comment by -->
                          <div class="ms-2">
                            <div class="bg-light p-3 rounded">
                              <div class="d-flex justify-content-between">
                                <h6 class="mb-1"> <a href="#!"> Billy Vasquez </a> </h6>
                                <small class="ms-2">15min</small>
                              </div>
                              <p class="small mb-0">Wishing calling is warrant settled was lucky.</p>
                            </div>
                            <!-- Comment react -->
                            <ul class="nav nav-divider py-2 small">
                              <li class="nav-item">
                                <a class="nav-link" href="#!"> Like</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" href="#!"> Reply</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <!-- Comment item END -->
                    </ul>
                    <!-- Load more replies -->
                    <a href="#!" role="button"
                      class="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center mb-3 ms-5"
                      data-bs-toggle="button" aria-pressed="true">
                      <div class="spinner-dots me-2">
                        <span class="spinner-dot"></span>
                        <span class="spinner-dot"></span>
                        <span class="spinner-dot"></span>
                      </div>
                      Load more replies
                    </a>
                    <!-- Comment item nested END -->
                  </li>
                  <!-- Comment item END -->
                  <!-- Comment item START -->
                  <li class="comment-item">
                    <div class="d-flex">
                      <!-- Avatar -->
                      <div class="avatar avatar-xs">
                        <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg"
                            alt=""></a>
                      </div>
                      <!-- Comment by -->
                      <div class="ms-2">
                        <div class="bg-light p-3 rounded">
                          <div class="d-flex justify-content-between">
                            <h6 class="mb-1"> <a href="#!"> Frances Guerrero </a> </h6>
                            <small class="ms-2">4min</small>
                          </div>
                          <p class="small mb-0">Removed demands expense account in outward tedious do. Particular way
                            thoroughly unaffected projection.</p>
                        </div>
                        <!-- Comment react -->
                        <ul class="nav nav-divider pt-2 small">
                          <li class="nav-item">
                            <a class="nav-link" href="#!"> Like (1)</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!"> Reply</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#!"> View 6 replies</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <!-- Comment item END -->
                </ul>
                <!-- Comment wrap END -->
              </div>
              <!-- Card body END -->
              <!-- Card footer START -->
              <div class="card-footer border-0 pt-0">
                <!-- Load more comments -->
                <a href="#!" role="button"
                  class="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center"
                  data-bs-toggle="button" aria-pressed="true">
                  <div class="spinner-dots me-2">
                    <span class="spinner-dot"></span>
                    <span class="spinner-dot"></span>
                    <span class="spinner-dot"></span>
                  </div>
                  Load more comments
                </a>
              </div>
              <!-- Card footer END -->
            </div>
            <!-- Card feed item END -->

            <!-- Card feed item START -->
            <div class="card">

              <div class="border-bottom">
                <p class="small mb-0 px-4 py-2"><i class="bi bi-heart-fill text-danger pe-1"></i>Sam Lanson likes this
                  post</p>
              </div>
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <!-- Avatar -->
                    <div class="avatar me-2">
                      <a href="#"> <img class="avatar-img rounded-circle" src="assets/images/logo/13.svg" alt=""> </a>
                    </div>
                    <!-- Title -->
                    <div>
                      <h6 class="card-title mb-0"> <a href="#!"> Apple Education </a></h6>
                      <p class="mb-0 small">9 November at 23:29</p>
                    </div>
                  </div>
                  <!-- Card share action menu -->
                  <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction5"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </a>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction5">
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow lori
                        ferguson </a></li>
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                    <li>
                      <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item" href="#"> <i class="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
                  </ul>
                </div>
                <!-- Card share action END -->
              </div>
              <!-- Card header START -->

              <!-- Card body START -->
              <div class="card-body pb-0">
                <p>Find out how you can save time in the classroom this year. Earn recognition while you learn new
                  skills on iPad and Mac. Start recognition your first Apple Teacher badge today!</p>
                <!-- Feed react START -->
                <ul class="nav nav-stack pb-2 small">
                  <li class="nav-item">
                    <a class="nav-link active text-secondary" href="#!"> <i
                        class="bi bi-heart-fill me-1 icon-xs bg-danger text-white rounded-circle"></i> Louis, Billy and
                      126 others </a>
                  </li>
                  <li class="nav-item ms-sm-auto">
                    <a class="nav-link" href="#!"> <i class="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                  </li>
                </ul>
                <!-- Feed react END -->
              </div>
              <!-- Card body END -->
              <!-- Card Footer START -->
              <div class="card-footer py-3">
                <!-- Feed react START -->
                <ul class="nav nav-fill nav-stack small">
                  <li class="nav-item">
                    <a class="nav-link mb-0 active" href="#!"> <i class="bi bi-heart pe-1"></i>Liked (56)</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link mb-0" href="#!"> <i class="bi bi-chat-fill pe-1"></i>Comments (12)</a>
                  </li>
                  <!-- Card share action dropdown START -->
                  <li class="nav-item dropdown">
                    <a href="#" class="nav-link mb-0" id="cardShareAction6" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="bi bi-reply-fill flip-horizontal ps-1"></i>Share (3)
                    </a>
                    <!-- Card share action dropdown menu -->
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction6">
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Send via Direct
                          Message</a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Bookmark
                        </a></li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-link fa-fw pe-2"></i>Copy link to post</a>
                      </li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-share fa-fw pe-2"></i>Share post via …</a>
                      </li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Share to News
                          Feed</a></li>
                    </ul>
                  </li>
                  <!-- Card share action dropdown END -->
                  <li class="nav-item">
                    <a class="nav-link mb-0" href="#!"> <i class="bi bi-send-fill pe-1"></i>Send</a>
                  </li>
                </ul>
                <!-- Feed react END -->
              </div>
              <!-- Card Footer END -->
            </div>
            <!-- Card feed item END -->
          </ng-container>
          <ng-container *ngIf="router.url === '/' + this.page?.pageUrl + '/published/projects'">

            <div class="main-content right-aligned">
              <section class="search-result-container">
                <div>

                  <div class="result-list" *ngFor="let pageProjects of projectObjs; let i = index">
                    <app-page-jobs-published [pageProjects]="pageProjects" [page]="page" [isPublished]="true"
                      [isPageProject]="true"></app-page-jobs-published>
                  </div>
                </div>
              </section>
            </div>
          </ng-container>
          <ng-container *ngIf="router.url.includes(this.page?.pageUrl + '/published/projects;')">

                {{"ekekekek"}}
            <div class="main-content right-aligned">
              <section class="search-result-container">

                <app-page-jobs-published [projectOut]="projectData" [page]="page"
                  [isProjectOutside]="true"></app-page-jobs-published>

              </section>
            </div>
          </ng-container>

          <ng-container *ngIf="router.url === '/' + this.page?.pageUrl + '/published/jobs'">


            <div class="main-content right-aligned">
              <section class="search-result-container">
                <div>

                  <div class="result-list" *ngFor="let pageJobs of jobObjs">

                    <app-page-jobs-published [pageJobs]="pageJobs" [page]="page"
                      [isPublished]="true"></app-page-jobs-published>
                  </div>
                </div>
              </section>
            </div>
          </ng-container>
          <ng-container *ngIf="router.url.includes(this.page?.pageUrl + '/published/jobs/')">

            <div class="main-content right-aligned">
              <section class="search-result-container">
                <ng-container *ngIf="!!job">
                  <app-page-jobs-published [job]="job" [page]="page" [isJobOutside]="true"></app-page-jobs-published>


                </ng-container>


              </section>
            </div>
          </ng-container>
          <ng-container *ngIf="router.url === '/' + this.page?.pageUrl + '/about'">

            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">{{'pagesSettings.sidebar.pageInf' | translate}}</h5>
              </div>
              <!-- Card header END -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="rounded border px-3 py-2 mb-3">
                  <div class="d-flex align-items-center justify-content-between">
                    <h6>{{'pagesInfo.about' | translate}}</h6>

                  </div>
                  <p [innerHtml]="page?.about"> </p>
                </div>

                <div class="rounded border px-3 py-2 mb-3 d-flex column">
                  <div class="d-flex align-items-center justify-content-between" *ngIf="page?.website">
                    <h6 class="px-0 py-0 mb-0">{{'pagesInfo.website' | translate}}</h6>

                  </div>
                  <a href="" stopPropagation (click)="goToUrl(page?.website);$event.preventDefault()"
                    rel="noopener noreferrer" [innerHtml]="page?.website"></a>
                  <br *ngIf="page?.website">

                  <div class="d-flex align-items-center justify-content-between" *ngIf="page?.email">
                    <h6 class="px-0 py-0 mb-0">{{'pagesInfo.email' | translate}}</h6>

                  </div>
                  <p [innerHtml]="page.email[0].name"></p>
                  <br *ngIf="page?.email">
                  <div class="d-flex align-items-center justify-content-between" *ngIf="page?.phone">
                    <h6 class="px-0 py-0 mb-0">{{'pagesInfo.telephone' | translate}}</h6>

                  </div>
                  <p [innerHtml]="page?.phone.internationalNumber"></p>
                  <br *ngIf="page?.phone">


                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="px-0 py-0 mb-0">{{'pagesInfo.branch' | translate}}</h6>

                  </div>
                  <p>{{branchTitle | translate}} </p>
                  <!-- Branch end-->

                  <!--Category Start -->
                  <br>
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="px-0 py-0 mb-0">{{'pagesInfo.categories' | translate}}</h6>

                  </div>
                  <p>{{categoryTitle?.name | translate}} - {{subCategoryTitle | translate}} </p>
                  <!-- Category end -->

                  <!-- Keywords start -->

                  <br>
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="px-0 py-0 mb-0">{{'pagesInfo.keywords' | translate}}</h6>

                  </div>
                  <div class="d-flex pr-1 pt-1 flex-wrap">

                    <ng-container *ngFor="let keyword of page?.categories?.subCategory[0].keywords;">
                      <p class="card__text me-1 mb-1">{{keyword?.name }} </p>


                    </ng-container>
                  </div>


                  <!-- Keywords end -->





                </div>

              </div>

              <!-- Card body END -->
            </div>
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">{{'pageSidebar.locations' | translate}}</h5>
              </div>
              <!-- Card header END -->
              <!-- Card body START -->
              <div class="row card-body d-flex flex-wrap">
                <ng-container *ngFor="let location of page?.locations">

                  <div class="col-sm-6 mb-3">
                    <!-- Lives START -->
                    <div class="d-flex align-items-center rounded border px-3 py-2">
                      <!-- Date -->
                      <p class="mb-0">
                        <i class="bi bi-geo-alt fa-fw me-2"></i> <strong
                          *ngIf="location?.headquarters">{{'locations.primaryLocation' | translate}}</strong>
                        <br>
                        {{location?.street}} <br> {{location?.postalCode}} {{location?.city}} <br>
                        {{findLocationCountry(location.c)}}
                      </p>

                    </div>
                    <!-- Lives END -->
                  </div>
                </ng-container>

              </div>

              <!-- Card body END -->
            </div>
          </ng-container>
          <ng-container
            *ngIf="router.url === '/' +  this.page?.pageUrl + '/connections' || router.url === '/' +  this.page?.pageUrl + '/connections/followers_page'  || router.url === '/' +  this.page?.pageUrl + '/connections/employers'">
            <div class="card">
              <ul
                class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0 ms-3">
                <li class="nav-item">
                  <a class="nav-link" [routerLinkActive]="['active']"
                    [routerLink]="'/' + this.page?.pageUrl + '/connections/followers_page'">
                    {{ "followers" | translate }}
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                    [routerLink]="'/' + this.page?.pageUrl + '/connections/employers'">
                    {{ "employers" | translate }}
                  </a>
                </li>
                <!-- <li class="nav-item">
              <a
                class="nav-link"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLinkActive]="['active']"
                [routerLink]="'/' + this.page?.pageUrl + '/connections/hashtags'"
              >
                {{ "hashtags" | translate }}
              </a>
            </li> -->

              </ul>

              <ng-container *ngIf="router.url === '/' + this.page?.pageUrl + '/connections/followers_page'">

                <div class="card">
                  <!-- Card header START -->
                  <div class="card-header border-0 pb-0">
                    <h5 class="card-title">{{'followers' | translate}}</h5>
                  </div>
                  <!-- Card header END -->
                  <!-- Card body START -->
                  <div class="card-body position-relative pt-0 mt-4">
                    <div class="row g-4">
                      <div class="col-sm-6" *ngFor="let userFollowers of userFollowers$">
                        <app-user-connections  [loggedUser]="user" [user]="userFollowers"
                          [followers]="true"></app-user-connections >
                        <!-- Birthday START -->
                        <!-- Birthday END -->
                      </div>
                    </div>



                  </div>

                  <!-- Card body END -->
                </div>

              </ng-container>
              <ng-container *ngIf="router.url === '/'+ this.page?.pageUrl + '/connections/employers'">
                <div class="card">
                  <!-- Card header START -->
                  <div class="card-header border-0 pb-0">
                    <h5 class="card-title">{{'employers' | translate}}</h5>
                  </div>
                  <!-- Card header END -->
                  <!-- Card body START -->
                  <div class="card-body position-relative pt-0 mt-4">
                    <div class="row g-4">
                      <div class="col-sm-6" *ngFor="let userEmployers of userEmployers$">
                        <app-user-connections [loggedUser]="user" [user]="userEmployers"
                          [followers]="true"></app-user-connections >
                        <!-- Birthday START -->
                        <!-- Birthday END -->
                      </div>
                    </div>



                  </div>

                  <!-- Card body END -->
                </div>
              </ng-container>

            </div>


          </ng-container>



        </div>
        <!-- Main content END -->

        <!-- Right sidebar START -->
        <div class="col-lg-3">

          <div class="row g-4">

            <!-- Card START -->
            <div class="col-md-6 col-lg-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h5 class="card-title">About</h5>
                  <!-- Button modal -->
                </div>
                <!-- Card body START -->
                <div class="card-body position-relative pt-0">
                  <p>He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire difficulty gay
                    assistance joy.</p>
                  <!-- Date time -->
                  <ul class="list-unstyled mt-3 mb-0">
                    <li class="mb-2"> <i class="bi bi-calendar-date fa-fw pe-1"></i> Born: <strong> October 20, 1990
                      </strong> </li>
                    <li class="mb-2"> <i class="bi bi-heart fa-fw pe-1"></i> Status: <strong> Single </strong> </li>
                    <li> <i class="bi bi-envelope fa-fw pe-1"></i> Email: <strong> webestica@gmail.com </strong> </li>
                  </ul>
                </div>
                <!-- Card body END -->
              </div>
            </div>
            <!-- Card END -->

            <!-- Card START -->
            <div class="col-md-6 col-lg-12">
              <div class="card">
                <!-- Card header START -->
                <div class="card-header d-flex justify-content-between border-0">
                  <h5 class="card-title">Experience</h5>
                  <a class="btn btn-primary-soft btn-sm" href="#!"> <i class="fa-solid fa-plus"></i> </a>
                </div>
                <!-- Card header END -->
                <!-- Card body START -->
                <div class="card-body position-relative pt-0">
                  <!-- Experience item START -->
                  <div class="d-flex">
                    <!-- Avatar -->
                    <div class="avatar me-3">
                      <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/logo/08.svg" alt=""> </a>
                    </div>
                    <!-- Info -->
                    <div>
                      <h6 class="card-title mb-0"><a href="#!"> Apple Computer, Inc. </a></h6>
                      <p class="small">May 2015 – Present Employment Duration 8 mos <a
                          class="btn btn-primary-soft btn-xs ms-2" href="#!">Edit </a></p>
                    </div>
                  </div>
                  <!-- Experience item END -->

                  <!-- Experience item START -->
                  <div class="d-flex">
                    <!-- Avatar -->
                    <div class="avatar me-3">
                      <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/logo/09.svg" alt=""> </a>
                    </div>
                    <!-- Info -->
                    <div>
                      <h6 class="card-title mb-0"><a href="#!"> Microsoft Corporation </a></h6>
                      <p class="small">May 2017 – Present Employment Duration 1 yrs 5 mos <a
                          class="btn btn-primary-soft btn-xs ms-2" href="#!">Edit </a></p>
                    </div>
                  </div>
                  <!-- Experience item END -->

                  <!-- Experience item START -->
                  <div class="d-flex">
                    <!-- Avatar -->
                    <div class="avatar me-3">
                      <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/logo/10.svg" alt=""> </a>
                    </div>
                    <!-- Info -->
                    <div>
                      <h6 class="card-title mb-0"><a href="#!"> Tata Consultancy Services. </a></h6>
                      <p class="small mb-0">May 2022 – Present Employment Duration 6 yrs 10 mos <a
                          class="btn btn-primary-soft btn-xs ms-2" href="#!">Edit </a></p>
                    </div>
                  </div>
                  <!-- Experience item END -->

                </div>
                <!-- Card body END -->
              </div>
            </div>
            <!-- Card END -->

            <!-- Card START -->
            <div class="col-md-6 col-lg-12">
              <div class="card">
                <!-- Card header START -->
                <div class="card-header d-sm-flex justify-content-between border-0">
                  <h5 class="card-title">Photos</h5>
                  <a class="btn btn-primary-soft btn-sm" href="#!"> See all photo</a>
                </div>
                <!-- Card header END -->
                <!-- Card body START -->
                <div class="card-body position-relative pt-0">
                  <div class="row g-2">
                    <!-- Photos item -->
                    <div class="col-6">
                      <a href="assets/images/albums/01.jpg" data-gallery="image-popup" data-glightbox="">
                        <img class="rounded img-fluid" src="assets/images/albums/01.jpg" alt="">
                      </a>
                    </div>
                    <!-- Photos item -->
                    <div class="col-6">
                      <a href="assets/images/albums/02.jpg" data-gallery="image-popup" data-glightbox="">
                        <img class="rounded img-fluid" src="assets/images/albums/02.jpg" alt="">
                      </a>
                    </div>
                    <!-- Photos item -->
                    <div class="col-4">
                      <a href="assets/images/albums/03.jpg" data-gallery="image-popup" data-glightbox="">
                        <img class="rounded img-fluid" src="assets/images/albums/03.jpg" alt="">
                      </a>
                    </div>
                    <!-- Photos item -->
                    <div class="col-4">
                      <a href="assets/images/albums/04.jpg" data-gallery="image-popup" data-glightbox="">
                        <img class="rounded img-fluid" src="assets/images/albums/04.jpg" alt="">
                      </a>
                    </div>
                    <!-- Photos item -->
                    <div class="col-4">
                      <a href="assets/images/albums/05.jpg" data-gallery="image-popup" data-glightbox="">
                        <img class="rounded img-fluid" src="assets/images/albums/05.jpg" alt="">
                      </a>
                      <!-- glightbox Albums left bar END  -->
                    </div>
                  </div>
                </div>
                <!-- Card body END -->
              </div>
            </div>
            <!-- Card END -->

            <!-- Card START -->
            <div class="col-md-6 col-lg-12">
              <div class="card">
                <!-- Card header START -->
                <div class="card-header d-sm-flex justify-content-between align-items-center border-0">
                  <h5 class="card-title">Friends <span class="badge bg-danger bg-opacity-10 text-danger">230</span></h5>
                  <a class="btn btn-primary-soft btn-sm" href="#!"> See all friends</a>
                </div>
                <!-- Card header END -->
                <!-- Card body START -->
                <div class="card-body position-relative pt-0">
                  <div class="row g-3">

                    <div class="col-6">
                      <!-- Friends item START -->
                      <div class="card shadow-none text-center h-100">
                        <!-- Card body -->
                        <div class="card-body p-2 pb-0">
                          <div class="avatar avatar-story avatar-xl">
                            <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/02.jpg"
                                alt=""></a>
                          </div>
                          <h6 class="card-title mb-1 mt-3"> <a href="#!"> Amanda Reed </a></h6>
                          <p class="mb-0 small lh-sm">16 mutual connections</p>
                        </div>
                        <!-- Card footer -->
                        <div class="card-footer p-2 border-0">
                          <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Send message"> <i class="bi bi-chat-left-text"></i> </button>
                          <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Remove friend"> <i class="bi bi-person-x"></i> </button>
                        </div>
                      </div>
                      <!-- Friends item END -->
                    </div>

                    <div class="col-6">
                      <!-- Friends item START -->
                      <div class="card shadow-none text-center h-100">
                        <!-- Card body -->
                        <div class="card-body p-2 pb-0">
                          <div class="avatar avatar-xl">
                            <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg"
                                alt=""></a>
                          </div>
                          <h6 class="card-title mb-1 mt-3"> <a href="#!"> Samuel Bishop </a></h6>
                          <p class="mb-0 small lh-sm">22 mutual connections</p>
                        </div>
                        <!-- Card footer -->
                        <div class="card-footer p-2 border-0">
                          <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Send message"> <i class="bi bi-chat-left-text"></i> </button>
                          <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Remove friend"> <i class="bi bi-person-x"></i> </button>
                        </div>
                      </div>
                      <!-- Friends item END -->
                    </div>

                    <div class="col-6">
                      <!-- Friends item START -->
                      <div class="card shadow-none text-center h-100">
                        <!-- Card body -->
                        <div class="card-body p-2 pb-0">
                          <div class="avatar avatar-xl">
                            <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg"
                                alt=""></a>
                          </div>
                          <h6 class="card-title mb-1 mt-3"> <a href="#"> Bryan Knight </a></h6>
                          <p class="mb-0 small lh-sm">1 mutual connection</p>
                        </div>
                        <!-- Card footer -->
                        <div class="card-footer p-2 border-0">
                          <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Send message"> <i class="bi bi-chat-left-text"></i> </button>
                          <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Remove friend"> <i class="bi bi-person-x"></i> </button>
                        </div>
                      </div>
                      <!-- Friends item END -->
                    </div>

                    <div class="col-6">
                      <!-- Friends item START -->
                      <div class="card shadow-none text-center h-100">
                        <!-- Card body -->
                        <div class="card-body p-2 pb-0">
                          <div class="avatar avatar-xl">
                            <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg"
                                alt=""></a>
                          </div>
                          <h6 class="card-title mb-1 mt-3"> <a href="#!"> Amanda Reed </a></h6>
                          <p class="mb-0 small lh-sm">15 mutual connections</p>
                        </div>
                        <!-- Card footer -->
                        <div class="card-footer p-2 border-0">
                          <button class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Send message"> <i class="bi bi-chat-left-text"></i> </button>
                          <button class="btn btn-sm btn-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Remove friend"> <i class="bi bi-person-x"></i> </button>
                        </div>
                      </div>
                      <!-- Friends item END -->
                    </div>

                  </div>
                </div>
                <!-- Card body END -->
              </div>
            </div>
            <!-- Card END -->
          </div>

        </div>
      </ng-container>
      <ng-container *ngIf="router.url.endsWith('ads_manager/create') || router.url.endsWith('ads_manager/edit') ">
        <div class="col-lg-9 vstack gap-4">
          <div class="tab-content py-0 mb-0">
            <div class="result-list">
              <ng-container *ngIf="!!page">
                <app-ads-manager [page]="page"></app-ads-manager>

              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="router.url.endsWith('ourprojects')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'pagesSettings.projects' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/ourprojects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bar-chart-steps nav-icon"></i>
                          <span>{{'pagesSettings.projects' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/create_project'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'project.create' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/applied_projects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-box-arrow-up-right nav-icon"></i>
                          <span>{{'project.applied' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/saved'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bookmark-fill nav-icon"></i> <span>{{'job.savedJobs' |
                            translate}} </span></a>
                      </li>


                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <!-- Sidenav END -->

        <!-- Main content START -->
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">


            <!-- Close account tab END -->

            <div class="result-list" *ngFor="let pageJobs of projectObjs; let i = index">
              <ng-container>
                <app-page-jobs-published [pageJobs]="pageJobs" [page]="page" [isPublished]="false" [isProject]="true"
                  (showOnlySelected)="showObject(i)"></app-page-jobs-published>
              </ng-container>
            </div>
          </div>

          <!-- Setting Tab content END -->
        </div>


      </ng-container>
      <ng-container *ngIf="router.url.endsWith('ourjobs')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'pagesSettings.jobs' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/ourjobs'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bar-chart-steps nav-icon"></i>
                          <span>{{'pagesSettings.jobs' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/create_job'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'job.create' |
                            translate}} </span></a>
                      </li>

                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <!-- Sidenav END -->

        <!-- Main content START -->
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">


            <!-- Close account tab END -->
            <div class="result-list" *ngFor="let pageJobs of jobObjs">
              <app-page-jobs-published [pageJobs]="pageJobs" [page]="page"
                [isPublished]="false"></app-page-jobs-published>
            </div>
          </div>

          <!-- Setting Tab content END -->
        </div>


      </ng-container>

      <ng-container *ngIf="router.url.endsWith('applied_projects')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'pagesSettings.appliedProjects' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/ourprojects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bar-chart-steps nav-icon"></i>
                          <span>{{'pagesSettings.projects' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/create_project'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'project.create' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/applied_projects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-box-arrow-up-right nav-icon"></i>
                          <span>{{'project.applied' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/saved'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bookmark-fill nav-icon"></i> <span>{{'job.savedJobs' |
                            translate}} </span></a>
                      </li>


                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">


            <!-- Close account tab END -->
            <div class="result-list">
              <app-applied-projects [isPage]="true"></app-applied-projects>
            </div>
          </div>

          <!-- Setting Tab content END -->
        </div>

      </ng-container>

      <ng-container *ngIf="router.url.endsWith('create_project')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'project.create' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/ourprojects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bar-chart-steps nav-icon"></i>
                          <span>{{'pagesSettings.projects' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/create_project'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'project.create' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/applied_projects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-box-arrow-up-right nav-icon"></i>
                          <span>{{'project.applied' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/saved'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bookmark-fill nav-icon"></i> <span>{{'job.savedJobs' |
                            translate}} </span></a>
                      </li>


                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">


            <app-create-your-project [isPage]="true"></app-create-your-project>
          </div>
        </div>


      </ng-container>

      <ng-container *ngIf="router.url.endsWith('create_job')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'project.create' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/ourjobs'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bar-chart-steps nav-icon"></i>
                          <span>{{'pagesSettings.jobs' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/create_job'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'job.create' |
                            translate}} </span></a>
                      </li>

                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">


            <app-create-your-job [isPage]="true" [page]="page"></app-create-your-job>
          </div>
        </div>


      </ng-container>

      <ng-container *ngIf="router.url.endsWith('saved')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'project.savedProjects' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/ourprojects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bar-chart-steps nav-icon"></i>
                          <span>{{'pagesSettings.projects' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/create_project'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'project.create' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/applied_projects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-box-arrow-up-right nav-icon"></i>
                          <span>{{'project.applied' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/saved'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bookmark-fill nav-icon"></i> <span>{{'job.savedJobs' |
                            translate}} </span></a>
                      </li>


                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">


            <app-saved-projects [isPage]="true" [selectedPage]=></app-saved-projects>
          </div>
        </div>



      </ng-container>
      <ng-container *ngIf="router.url.endsWith('ignored')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'pagesSettings.ignoredProjects' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/ourprojects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bar-chart-steps nav-icon"></i>
                          <span>{{'pagesSettings.projects' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/create_project'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'project.create' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/applied_projects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-box-arrow-up-right nav-icon"></i>
                          <span>{{'project.applied' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/saved'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bookmark-fill nav-icon"></i> <span>{{'job.savedJobs' |
                            translate}} </span></a>
                      </li>


                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">


            <app-ignored-projects></app-ignored-projects>
          </div>
        </div>



      </ng-container>
      <ng-container *ngIf="router.url.endsWith('notifications')">
        <app-notifications [data]="usersNotification" [pageID]="page?._id"></app-notifications>
      </ng-container>

      <ng-container *ngIf="router.url.endsWith('ads_manager')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-badge-ad nav-icon nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'Ads Manager'}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/ads_manager'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-badge-ad nav-icon nav-icon"></i>
                          <span>Ads Manager</span></a>
                      </li>
                      <!--    <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/create_project'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'project.create' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/applied_projects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-box-arrow-up-right nav-icon"></i>
                          <span>{{'project.applied' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/saved'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bookmark-fill nav-icon"></i> <span>{{'job.savedJobs' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/ignored'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-eye-slash-fill nav-icon"></i> <span>{{'job.ignoredJobs'
                            | translate}} </span></a>
                      </li> -->

                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4 card px-0 py-0">
          <div class="tab-content py-0 mb-0">
            <div class="row g-2 m-3">
              <div class="col-lg-3">
                <!-- Card title -->
                <h1 class="h4 card-title mb-lg-0"> {{'sidebar.advertisement' | translate}}</h1>
              </div>
              <div class="col-sm-6 col-lg-3 ms-lg-auto">
                <!-- Select Groups -->
                <select class="form-select js-choice choice-select-text-none" data-search-enabled="false">
                  <option value="AB">Alphabetical</option>
                  <option value="NG">Newest group</option>
                  <option value="RA">Recently active</option>
                  <option value="SG">Suggested</option>
                </select>
              </div>
              <div class="col-sm-6 col-lg-3">
                <!-- Button modal -->
                <a class="btn btn-primary-soft ms-auto w-100" routerLink="ads_manager/create"> <i
                    class="fa-solid fa-plus pe-1"></i> {{'create' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Tab nav line -->
            <ul class="nav nav-tabs nav-bottom-line justify-content-center justify-content-md-start">
              <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#tab-1"> {{'Ads'}} </a> </li>
              <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-2"> Ad sets </a>
              </li>
              <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-3"> Campaings </a>
              </li>
            </ul>
            <div class="tab-content mb-0 pb-0">

              <!-- Friends groups tab START -->
              <div class="tab-pane fade show active" id="tab-1">
                <div class="row g-4">
                  <div class="mat-elevation-z8">
                    <div fxFlex="1 0 100%" fxLayout="column" fxLayoutAlign="start stretch">
                      <mat-sidenav-container fxFlex="0 1 auto">
                        <mat-sidenav-content>
                          <div class="mat-elevation-z8 m-12">
                            <div class="table-container">
                              <!-- <table mat-table [dataSource]="dataSource"> -->
                              <table mat-table [dataSource]="dataSource">
                                <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->



                                <ng-container matColumnDef="checkbox" sticky>

                                  <mat-header-cell *matHeaderCellDef class="table-column-10">
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-10 px-0">
                                    <input type="checkbox">

                                  </mat-cell>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name" sticky>


                                  <mat-header-cell *matHeaderCellDef class="table-column-150">
                                    Name
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-150">
                                    <a href="javascript: void(0);" (click)="openAds(element)">{{element.name}}</a>

                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="budgetPlant.active">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Off/On
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    <div class="form-check form-switch"><input
                                        (change)="updateActiveStatus(element, $event)" type="checkbox" role="switch"
                                        id="NotiSwitchCheckChecked" checked="" class="form-check-input"></div>
                                  </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="country">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100"> Country
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.country}}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="budgetPlan">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Campaign
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.budgetPlan.value}}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="budgetPlanEndDate">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Budget
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.budgetPlan.budget}}
                                  </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="adsDetails">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Spent
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="adsDetailsUrl">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Results
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="adsDetailsharedDescription">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Cost per Result
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="adsDetailbuttonName">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Reach
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>




                                <ng-container matColumnDef="budgetPlanStartDate">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Impressions
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.budgetPlan.startDate | date: 'dd/MM/yyyy'}}
                                  </mat-cell>
                                </ng-container>


                                <!-- Weight Column -->


                                <!-- Symbol Column -->


                                <!-- Position Column -->

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row stopPropagation *matRowDef="let row; columns: displayedColumns;"></mat-row>
                              </table>

                              <div>
                                <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
                                  [showFirstLastButtons]="true">
                                </mat-paginator>
                              </div>
                            </div>
                            <!-- end table container -->
                          </div>
                        </mat-sidenav-content>
                      </mat-sidenav-container>
                    </div>




                    <!-- <app-smart-payments-table firstTd="Name" secondTd="Status" thirdTd="Type" fourthTD="'Bill'"
                    fifthTD="'Price'" [sixthTD]="'Impression'" [data]="adsData" [reference]="true" [status]="true"
                      (print)="goForPrint($event)"></app-smart-payments-table> -->

                  </div>
                  <!-- Card START -->

                  <!-- Card END -->
                </div>
              </div>
              <!-- Friends' groups tab END -->

              <!-- Suggested for you START -->
              <div class="tab-pane fade" id="tab-2">
                <div class="row g-4">
                  <div class="col-sm-6 col-lg-4">
                    <!-- Card START -->
                    <div class="card">
                      <div class="h-80px rounded-top"
                        style="background-image:url(assets/images/bg/01.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;">
                      </div>
                      <!-- Card body START -->
                      <div class="card-body text-center pt-0">
                        <!-- Avatar -->
                        <div class="avatar avatar-lg mt-n5 mb-3">
                          <a href="group-details.html"><img
                              class="avatar-img rounded-circle border border-white border-3 bg-white"
                              src="assets/images/logo/01.svg" alt=""></a>
                        </div>
                        <!-- Info -->
                        <h5 class="mb-0"><a href="group-details.html">Real humans</a></h5>
                        <small> <i class="bi bi-globe pe-1"></i> Public Group</small>
                        <!-- Group stat START -->
                        <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">23k</h6>
                            <small>Members</small>
                          </div>
                          <!-- Divider -->
                          <div class="vr"></div>
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">12</h6>
                            <small>Post per day</small>
                          </div>
                        </div>
                        <!-- Group stat END -->
                        <!-- Avatar group START -->
                        <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/06.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/07.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/09.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <div class="avatar-img rounded-circle bg-primary"><span
                                class="smaller text-white position-absolute top-50 start-50 translate-middle">+12</span>
                            </div>
                          </li>
                        </ul>
                        <!-- Avatar group END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Card Footer START -->
                      <div class="card-footer text-center">
                        <a class="btn btn-danger-soft btn-sm" href="#!"> Leave group </a>
                      </div>
                      <!-- Card Footer END -->
                    </div>
                    <!-- Card END -->
                  </div>
                  <div class="col-sm-6 col-lg-4">
                    <!-- Card START -->
                    <div class="card">
                      <div class="h-80px rounded-top"
                        style="background-image:url(assets/images/bg/02.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;">
                      </div>
                      <!-- Card body START -->
                      <div class="card-body text-center pt-0">
                        <!-- Avatar -->
                        <div class="avatar avatar-lg mt-n5 mb-3">
                          <a href="group-details.html"><img
                              class="avatar-img rounded-circle border border-white border-3 bg-white"
                              src="assets/images/logo/03.svg" alt=""></a>
                        </div>
                        <!-- Info -->
                        <h5 class="mb-0"><a href="group-details.html">Strong signals</a></h5>
                        <small> <i class="bi bi-lock pe-1"></i> Private Group</small>
                        <!-- Group stat START -->
                        <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">45k</h6>
                            <small>Members</small>
                          </div>
                          <!-- Divider -->
                          <div class="vr"></div>
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">16</h6>
                            <small>Post per day</small>
                          </div>
                        </div>
                        <!-- Group stat END -->
                        <!-- Avatar group START -->
                        <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <div class="avatar-img rounded-circle bg-primary"><span
                                class="smaller text-white position-absolute top-50 start-50 translate-middle">+05</span>
                            </div>
                          </li>
                        </ul>
                        <!-- Avatar group END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Card Footer START -->
                      <div class="card-footer text-center">
                        <a class="btn btn-success-soft btn-sm" href="#!"> Join group </a>
                      </div>
                      <!-- Card Footer END -->
                    </div>
                    <!-- Card END -->
                  </div>
                  <div class="col-sm-6 col-lg-4">
                    <!-- Card START -->
                    <div class="card">
                      <div class="h-80px rounded-top"
                        style="background-image:url(assets/images/bg/03.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;">
                      </div>
                      <!-- Card body START -->
                      <div class="card-body text-center pt-0">
                        <!-- Avatar -->
                        <div class="avatar avatar-lg mt-n5 mb-3">
                          <a href="group-details.html"><img
                              class="avatar-img rounded-circle border border-white border-3 bg-white"
                              src="assets/images/logo/05.svg" alt=""></a>
                        </div>
                        <!-- Info -->
                        <h5 class="mb-0"><a href="group-details.html">Team yes, we can</a></h5>
                        <small> <i class="bi bi-lock pe-1"></i> Private Group</small>
                        <!-- Group stat START -->
                        <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">32k</h6>
                            <small>Members</small>
                          </div>
                          <!-- Divider -->
                          <div class="vr"></div>
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">05</h6>
                            <small>Post per day</small>
                          </div>
                        </div>
                        <!-- Group stat END -->
                        <!-- Avatar group START -->
                        <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/14.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/13.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/12.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <div class="avatar-img rounded-circle bg-primary"><span
                                class="smaller text-white position-absolute top-50 start-50 translate-middle">+08</span>
                            </div>
                          </li>
                        </ul>
                        <!-- Avatar group END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Card Footer START -->
                      <div class="card-footer text-center">
                        <a class="btn btn-success-soft btn-sm" href="#!"> Join group </a>
                      </div>
                      <!-- Card Footer END -->
                    </div>
                    <!-- Card END -->
                  </div>
                </div>
              </div>
              <!-- Suggested for you END -->

              <!-- Popular near you START -->
              <div class="tab-pane fade" id="tab-3">
                <!-- Add group -->
                <div class="my-sm-5 py-sm-5 text-center">
                  <i class="display-1 text-muted bi bi-people"> </i>
                  <h4 class="mt-2 mb-3 text-body">No group founds</h4>
                  <button class="btn btn-primary-soft btn-sm" data-bs-toggle="modal" data-bs-target="#modalCreateGroup">
                    Click here to add </button>
                </div>
              </div>
              <!-- Popular near you END -->

              <!-- More suggestions START -->
              <div class="tab-pane fade" id="tab-4">
                <!-- Add group -->
                <div class="my-sm-5 py-sm-5 text-center">
                  <i class="display-1 text-muted bi bi-people"> </i>
                  <h4 class="mt-2 mb-3 text-body">No group founds</h4>
                  <button class="btn btn-primary-soft btn-sm" data-bs-toggle="modal" data-bs-target="#modalCreateGroup">
                    Click here to add </button>
                </div>
              </div>
              <!-- More suggestions END -->

            </div>




          </div>
        </div>


      </ng-container>

      <ng-container
        *ngIf="router.url.endsWith('payment') || router.url.endsWith('transactions') || router.url.endsWith('purchased') || router.url.endsWith('billing')">
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">Payment</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + page?.pageUrl + '/payment/billing'"
                          [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <img class="me-2 h-20px fa-fw"
                            src="assets/images/icon/person-outline-filled.svg" alt=""><span>Billing Details </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + page?.pageUrl + '/payment/transactions'"
                          [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                          data-bs-toggle="tab"> <img class="me-2 h-20px fa-fw"
                            src="assets/images/icon/notification-outlined-filled.svg" alt=""><span>Transactions
                          </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + page?.pageUrl + '/payment/purchased'"
                          [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                          data-bs-toggle="tab"> <img class="me-2 h-20px fa-fw"
                            src="assets/images/icon/shield-outline-filled.svg" alt=""><span>Purchased </span></a>
                      </li>

                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>
              <!-- Offcanvas body -->



            </div>
          </nav>
        </div>
        <div class="col-md-8 col-lg-6 vstack gap-4">
          <div class="tab-pane" *ngIf="router.url.endsWith('/billing')">
            <div class="card">
              <div class="col-md-12 px-0 pt-4 pb-2" *ngIf="payment === 'isPage'">
                <div class="col-md-4">
                  <img src="../../../assets/avatar.png" height="40px" width="40px">
                  <span class="pl-2 pr-2">{{user?.firstName}}</span>
                </div>

              </div>
              <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                      <a class="nav-link" (click)="setPayment()">Billing Details <span
                          class="sr-only">(current)</span></a>
                    </li>
                  </ul>
                </div>
              </nav>

              <div>
                <div class="cards-body d-flex">
                  <app-price-card [data]="page?.payment?.coins" [name]="'Credits'"></app-price-card>

                  <mat-card class="example-card">
                    <mat-card-header>
                      <mat-card-title>
                        <div>
                          <span>Current Payment Method</span>
                          <span>Edit</span>
                        </div>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div>
                        <i class="bi bi-plus-circle"></i>
                        <h3>
                          Add Card
                        </h3>
                      </div>
                    </mat-card-content>
                  </mat-card>
                  <span>1 Coins = 1 €</span>
                </div>
                <app-smart-payments-table [data]="page?.payment?.history" (print)="goForPrint($event)">
                </app-smart-payments-table>

              </div>


            </div>
          </div>
          <div class="tab-pane" *ngIf="router.url.endsWith('/transactions')">
            <div class="card">
              <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                      <a class="nav-link" (click)="setPayment()">Transactions<span class="sr-only">(current)</span></a>
                    </li>
                  </ul>
                </div>
              </nav>

              <div style="display: flex;
      flex-direction: row;
      justify-content: space-between;">

                <div style="display: flex;">

                  <mat-form-field color="accent" appearance="fill" style="margin-right: 1rem;">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="picker1">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 color="primary"></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field color="accent" appearance="fill">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="picker2">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="fill">
                    <mat-label>Sort by</mat-label>
                    <mat-select [(value)]="selected">
                      <mat-option value="option1">Option 1</mat-option>
                      <mat-option value="option2">Option 2</mat-option>
                      <mat-option value="option3">Option 3</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="mat-elevation-z8">

                <app-smart-payments-table firstTd="Name" secondTd="Status" thirdTd="Type" fourthTD="'Bill'"
                  fifthTD="'Price'" [sixthTD]="'Impression'" [data]="page?.payment?.transactions" [reference]="true"
                  [status]="true" (print)="goForPrint($event)"></app-smart-payments-table>

              </div>
            </div>
          </div>
          <div class="tab-pane" *ngIf="router.url.endsWith('/purchased')">
            <div class="card">
              <div class="col-md-12 px-0 pt-4 pb-2" *ngIf="payment === 'isPage'">
                <div class="col-md-4">
                  <img src="../../../assets/avatar.png" height="40px" width="40px">
                  <span class="pl-2 pr-2">{{user?.firstName}}</span>
                </div>

              </div>
              <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                      <a class="nav-link" (click)="setPayment()">Purchased <span class="sr-only">(current)</span></a>
                    </li>
                  </ul>
                </div>
              </nav>

              <div>
                <div class="cards-body d-flex">
                  <app-price-card [data]="page?.payment?.Job" [name]="'Job'"></app-price-card>
                  <app-price-card [data]="page?.payment?.Project" [name]="'Project'"></app-price-card>
                </div>

              </div>


            </div>
          </div>


        </div>
      </ng-container>

      <ng-container
        *ngIf="router.url.endsWith('settings') || router.url.endsWith('settings/page_roles') ||  router.url.endsWith('settings/locations') || router.url.endsWith('settings/search_locations') || router.url.endsWith('settings/about') || router.url.endsWith('settings/language') || router.url.endsWith('settings/premium') || router.url.endsWith('settings/verified') || router.url.endsWith('settings/close_account') ">


        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">Settings</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0 active" [routerLinkActiveOptions]="{ exact: true }"
                          [routerLink]="'settings'" [routerLinkActive]="['active']" data-bs-toggle="tab"> <img
                            class="me-2 h-20px fa-fw" src="assets/images/icon/person-outline-filled.svg"
                            alt=""><span>{{'pagesSettings.name' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLinkActiveOptions]="{ exact: true }"
                          [routerLink]="'settings/about'" [routerLinkActive]="['active']" data-bs-toggle="tab"> <img
                            class="me-2 h-20px fa-fw" src="assets/images/icon/person-outline-filled.svg"
                            alt=""><span>{{'pagesSettings.about' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLinkActiveOptions]="{ exact: true }"
                          [routerLink]="'settings/page_roles'" [routerLinkActive]="['active']" data-bs-toggle="tab">
                          <img class="me-2 h-20px fa-fw" src="assets/images/icon/notification-outlined-filled.svg"
                            alt=""><span>{{'pagesSettings.sidebar.roles' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a [routerLinkActiveOptions]="{ exact: true }" [routerLink]="'settings/locations'"
                          [routerLinkActive]="['active']" class="nav-link d-flex mb-0" data-bs-toggle="tab"> <img
                            class="me-2 h-20px fa-fw" src="assets/images/icon/shield-outline-filled.svg"
                            alt=""><span>{{'pageSidebar.locations' | translate}}</span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a [routerLinkActiveOptions]="{ exact: true }" routerLink="settings/search_locations"
                          [routerLinkActive]="['active']" class="nav-link d-flex mb-0" data-bs-toggle="tab"> <i
                            class="bi bi-search fa-fw me-2 h-20px fa-fw"></i><span>{{'pageSidebar.service_area'
                            | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" href="#nav-setting-tab-5" data-bs-toggle="tab"> <img
                            class="me-2 h-20px fa-fw" src="assets/images/icon/chat-alt-outline-filled.svg"
                            alt=""><span>Messaging </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" data-bs-toggle="tab" [routerLinkActiveOptions]="{ exact: true }"
                          [routerLinkActive]="['active']" routerLink="settings/close_account">
                          <img class="me-2 h-20px fa-fw" src="assets/images/icon/trash-var-outline-filled.svg"
                            alt=""><span>Close account </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="settings/language" [routerLinkActive]="['active']" data-bs-toggle="tab"> <i
                            class="bi bi-translate fa-fw me-2 h-20px fa-fw"></i><span>Language</span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a [routerLinkActiveOptions]="{ exact: true }" routerLink="settings/premium"
                          [routerLinkActive]="['active']" class="nav-link d-flex mb-0" data-bs-toggle="tab"> <i
                            class="bi bi-gem fa-fw me-2 h-20px fa-fw"></i><span>Premium</span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLinkActiveOptions]="{ exact: true }"
                          routerLink="settings/verified" [routerLinkActive]="['active']" data-bs-toggle="tab"> <i
                            class="bi bi-patch-check-fill text-success small fa-fw me-2 h-20px fa-fw"></i><span>Verified</span></a>
                      </li>
                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->
                  <div class="card-footer text-center py-2">
                    <a class="btn btn-link text-secondary btn-sm" href="#!">View Profile </a>
                  </div>
                </div>
                <!-- Card END -->
              </div>
              <!-- Offcanvas body -->

              <!-- Helper link START -->
              <ul class="nav small mt-4 justify-content-center lh-1">
                <li class="nav-item">
                  <a class="nav-link" href="my-profile-about.html">About</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="settings.html">Settings</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" target="_blank" href="docs/index.html">Docs </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="help.html">Help</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                </li>
              </ul>
              <!-- Helper link END -->
              <!-- Copyright -->
              <p class="small text-center mt-1">©2023 <a class="text-body" target="_blank"
                  href="https://www.webestica.com/"> Webestica </a></p>

            </div>
          </nav>
        </div>
        <!-- Sidenav END -->

        <!-- Main content START -->
        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings')">
          <!-- Create a page START -->
          <div class="card">
            <!-- Title START -->
            <div class="card-header border-0 pb-0">
              <h1 class="h4 card-title mb-0">{{'pagesSettings.name' | translate}}</h1>
            </div>
            <!-- Title END -->
            <!-- Create a page form START -->
            <div class="card-body">
              <form class="row g-3" name="ngForm" #myForm="ngForm" (ngSubmit)="savePage(myForm)">
                <!-- Page information -->
                <div class="col-12">
                  <label class="form-label">{{ "pagesInfo.pageName" | translate }}</label>
                  <input type="text" class="form-control" name="pageName" [(ngModel)]="page.name" #pageName="ngModel">
                  <small>Name that describes what the page is about.</small>
                </div>

                <!-- Display name -->
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">{{'pagesInfo.pageUrl' | translate}}</label>
                  <input type="text" class="form-control" name="pageUrl" [(ngModel)]="page.pageUrl" #pageUrl="ngModel"
                    disabled>
                </div>
                <!-- Email -->
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">{{'pagesInfo.email' | translate}}</label>
                  <input type="text" class="form-control" [(ngModel)]="page.email[0].name" name="email">
                </div>
                <!-- Category -->
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">Branch (required)</label>
                  <select class="form-select js-choice" data-search-enabled="true" id="selectedBranch"
                    name="selectedBranch" #selectedBranch="ngModel" [(ngModel)]="page.branches">
                    <option *ngFor="let level of branchesService.branches" [ngValue]="level.id" [selected]="level"> {{
                      level.description | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">{{ "pagesInfo.categories" | translate }}</label>
                  <select class="form-select js-choice" name="selectedCategory" #selectedCategory="ngModel"
                    [ngModel]="page?.categories?.name" (ngModelChange)="showChildren($event)"
                    data-search-enabled="true">
                    <option *ngFor="let level of categoryService.categories" [ngValue]="level.id" [selected]="level"> {{
                      level.name | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-6">

                  <label class="form-label">{{ "categories.subCategories.name" | translate }}</label>
                  <select class="form-select js-choice" name="selectedSubCategory" #selectedSubCategory="ngModel"
                    [ngModel]="page?.categories?.subCategory[0].name" (ngModelChange)="showKeywords($event)"
                    data-search-enabled="true">
                    <option *ngFor="let level of categoryService.categories[indexOfCategory]?.subCategory"
                      [ngValue]="level.id" (change)="showKeywords($event)"> {{ level.name | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-12">
                  <label>{{'pagesInfo.keywords' | translate}}</label>
                  <div class="d-flex align-items-center card__body__list">
                    <div *ngFor="let sp of page?.categories?.subCategory[0].keywords; let i=index"
                      class="d-flex justify-content-between pr-1  me-1 mt-1">
                      <div class="card__text">
                        {{sp.name}}
                        <button [hidden]="page?.categories?.subCategory[0].keywords.length < 2" class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeKeyword(i)"><i
                            class="fa fa-remove"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-lg-12 col-sm-6">
                    <input [(ngModel)]="keyword" #key="ngModel" name="key" [required]="selectedKeywords?.length < 1"
                      class="form-control">

                    <button type="button" class="btn btn-light ms-3 btn-light-keyword" [disabled]="!keyword"
                      (click)="addKeyword()">
                      <i class="bi bi-plus fa-lg"></i></button>
                  </div>

                </div>
                <!-- Website URL -->
                <div class="col-sm-6">
                  <label class="form-label">Website URL</label>
                  <input type="text" class="form-control" [(ngModel)]="page.website" name="website">
                </div>
                <!-- Phone number -->
                <div class="col-lg-6">
                  <label class="form-label">{{'pagesInfo.telephone' | translate}}</label>
                  <br>
                  <ngx-intl-tel-input [enablePlaceholder]="true"
                    [preferredCountries]="['de', 'us', 'gb', 'it', 'es', 'fr', 'ch', 'at']" [separateDialCode]="true"
                    required [(ngModel)]="page.phone.number" [selectedCountryISO]="page.phone?.countryCode"
                    [phoneValidation]="true" [cssClass]="'form-control col-md-12'" name="number" #number="ngModel">
                  </ngx-intl-tel-input>
                </div>
                <!-- Page information -->


                <!-- Divider -->
                <!-- Social Links START -->
                <div class="col-12">
                  <h5 class="card-title mb-0">Social Links</h5>
                </div>
                <!-- Facebook -->
                <div class="col-sm-6">
                  <label class="form-label">Facebook</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-facebook text-facebook"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.facebook.com">
                  </div>
                </div>
                <!-- Twitter -->
                <div class="col-sm-6">
                  <label class="form-label">Twitter</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-twitter text-twitter"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.twitter.com">
                  </div>
                </div>
                <!-- Instagram -->
                <div class="col-sm-6">
                  <label class="form-label">Instagram</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-instagram text-instagram"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.instagram.com">
                  </div>
                </div>
                <!-- Pinterest -->
                <div class="col-sm-6">
                  <label class="form-label">Pinterest</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-pinterest text-pinterest"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.pinterest.com">
                  </div>
                </div>
                <!-- Button  -->
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary mb-0">{{'save' | translate}}</button>
                </div>
              </form>
            </div>
            <!-- Create a page form END -->
          </div>
          <!-- Create a page END -->
        </div>
        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/page_roles')">
          <div class="" id="nav-setting-tab-4">
            <!-- Communications START -->
            <div class="card">
              <!-- Title START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">{{'pagesSettings.sidebar.roles' | translate}}</h5>
                <p class="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire
                  difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</p>
              </div>
              <form name="ngForm" #rolesForm="ngForm" id="rolesForm" class="form-grid"
                (ngSubmit)="rolesForm.form.valid && saveUserRole()">

                <div class="d-flex justify-content-between">
                  <div class="d-flex w-100" style="padding-left: 20px; padding-right: 20px;">
                    <div class="nav mt-3 mt-lg-0 flex-nowrap align-items-center px-4 px-lg-0">
                      <div class="nav-item w-100">
                        <form class="rounded position-relative">
                          <input class="form-control ps-5 bg-light" id="userSearch" name="userSearch" type="search"
                            [(ngModel)]="searchUser" (keyup)="searchObj($event)" autocomplete="off" placeholder="Search..." aria-label="Search">
                          <button
                            class="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
                            type="submit"><i class="bi bi-search fs-5"> </i></button>
                        </form>
                      </div>
                    </div>


                  </div>

                </div>
                <div *ngIf="searchUser" class="card__search">
                  <div class="card_list">
                    <ng-container *ngIf="users$ | async as user">
                    <div *ngFor="let user of user.userData">
                      <div class="row">
                        <div class="d-flex row pr-2 pl-2 align-items-center card_list" (click)="addNewUser(user)">
                          <div class="col-md-1">
                            <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
                          </div>
                          <span class="pr-2 pl-2 col-md-10">
                            {{ user.firstName }} {{ user.lastName }}
                            <span> </span>
                          </span>
                        </div>

                      </div>
                    </div>
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="selectUser && searchUser" class="w-100 d-flex g-4" style="    flex-direction: row;
              flex-wrap: wrap;
              padding-left: 20px;
              padding-right: 20px;">
                  <ng-container *ngFor="let privacy of privacies">

                    <div class="col-sm-4  pt-2">
                      <!-- Birthday START -->
                      <div class="d-flex align-items-center rounded border px-3 py-2">
                        <!-- Date -->
                        <p class="mb-0">
                          <input type="checkbox" name="checkbox" class="form-check-input" [checked]="privacy.isChecked"
                            [(ngModel)]="privacy.isChecked" [ngModelOptions]="{standalone: true}"
                            (change)="addRole($event, privacy)">
                          {{privacy.name | translate}}
                        </p>

                      </div>
                      <!-- Birthday END -->
                    </div>
                  </ng-container>
                </div>
                <div class="col-md-2" style="padding: 20px;" *ngIf="selectUser && searchUser">
                  <button [disabled]="!rolesForm.form.valid" class="btn btn-primary col-md-12 btn-sm"
                    type="submit">Save</button>
                </div>

                <div class="form-group form-check me-3 pl-1">
                </div>

              </form>
              <!-- Title START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Accordion START -->
                <div class="accordion" id="communications">
                  <ng-container *ngFor="let roles of privacies; let i = index">
                    <div class="accordion-item bg-transparent">
                      <h2 class="accordion-header" id="communicationOne">
                        <button class="accordion-button mb-0 h6" type="button" data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapse' + i" aria-expanded="true"
                          aria-controls="communicationcollapseOne">
                          {{roles.name | translate}}
                        </button>
                      </h2>

                      <div [id]="'collapse' + i" class="accordion-collapse collapse" [aria-labelledby]="i"
                        data-bs-parent="#communications">
                        <div class="accordion-body">
                          <div class="rounded border px-3 py-2 mb-3">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6>Overview</h6>
                              <div class="dropdown ms-auto">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction">

                                  <li><a class="dropdown-item" href="#"> <i
                                        class="bi bi-pencil-square fa-fw pe-2"></i>Edit</a></li>
                                  <li><a class="dropdown-item" href="#"> <i
                                        class="bi bi-trash fa-fw pe-2"></i>Delete</a></li>
                                </ul>
                              </div>
                            </div>
                         <!--  <p>He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire difficulty
                              gay assistance joy. Handsome met debating sir dwelling age material. As style lived he
                              worse dried. Offered related so visitors we private removed. Moderate do subjects to
                              distance. </p> -->
                          </div>


                          <div class="card" style="border:none">
                            <!-- Card header START --
                            <!-- Card header END -->
                            <!-- Card body START -->
                            <div class="card-body px-0 py-0">
                              <div class="g-4 d-flex" style="flex-wrap: wrap">
                                <ng-container *ngFor="let role of roles.showData; let index = index">

                                  <app-roles-details class="col-sm-6" [roleDetail]="role" [newDesign]="true"
                                    [dataRole]="roles.showData" [rolePages]="page" [index]="index"></app-roles-details>
                                </ng-container>

                              </div>
                              <!-- Card body END -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>


                </div>
                <!-- Accordion END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
              <div class="card-footer pt-0 text-end border-0">
                <button type="submit" class="btn btn-sm btn-primary mb-0">Save changes</button>
              </div>
            </div>
            <!-- Communications  END -->
          </div>
        </div>
        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/locations')">
          <div class="card">
            <div class="card-header border-0 pb-0">
              <h5 class="card-title">{{'pageSidebar.locations' | translate}}</h5>
            </div>
            <div class="card-body">
              <div class="col-md-12">
                <div class="col-md-12" *ngIf="!openLocation">
                  <button type="button" class="col-md-2 button-f button-sc" (click)="addLocation()">
                    <div>
                      <i class="bi bi-plus width-40"></i>
                      <span class="span-padding-left10"><strong>Add Adress</strong></span>

                    </div>
                  </button>

                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Adresse</th>
                        <th scope="col">{{"locations.primaryLocation" | translate}}</th>
                        <th scope="col">Aktionen</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let location of page?.locations; let i = index">
                        <td>{{location?.street}} <br>{{location?.postalCode}} {{location?.city}} <br>
                          {{location?.state}} {{countryName(location.c)}}</td>
                        <td><input disabled type="checkbox" [(ngModel)]="location.headquarters"></td>
                        <td>
                          <button stopPropagation type="button" class="bi bi-pencil span-padding-right10 btn btn-empty"
                            (click)="editLocation({location: location, isEditLocation: true }, i)"></button>
                          <button type="button" class="bi bi-trash span-padding-right10 btn btn-empty"
                            *ngIf="!location.headquarters" (click)="deleteLocation(i)"></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12" *ngIf="openLocation">
                  <button class="btn btn-light" (click)="openLocation = !openLocation">Back</button>

                  <form class="form-grid" name="ngForm" #myForm="ngForm"
                    (ngSubmit)="myForm.form.valid &&  saveNewLocation({isAddNewLocation: true, location: locations})">
                    <li class="line form-row">
                      <div class="col-md-12">
                        <input name="showEmail" type="checkbox" value="" [(ngModel)]="isStreetActive" />
                        <label class="px-2">{{'pagesInfo.noStreet' | translate}}.</label>
                      </div>
                    </li>

                    <li class="line form-row" *ngIf="!isStreetActive">
                      <div class="col-md-12">
                        <label>{{ "addressData.street" | translate }}</label>
                        <input class="form-control col-md-12" autocomplete="off" [(ngModel)]="locations.street"
                          name="street" #street="ngModel"
                          [ngClass]="{ 'is-invalid': myForm.submitted && street.invalid }" required />
                        <div *ngIf="myForm.submitted && street.invalid" class="invalid-feedback">
                          <div *ngIf="street.errors.required">
                            {{ "field" | translate }}
                            {{ "error.isRequired" | translate }}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="line form-row">


                      <div class="col-md-6">
                        <label>{{ "data.zip" | translate }}</label>
                        <input class="form-control col-md-12" name="postalCode" [(ngModel)]="locations.postalCode"
                          autocomplete="postal-code" #postalCode="ngModel"
                          [ngClass]="{ 'is-invalid': myForm.submitted && postalCode.invalid }" required />
                        <div *ngIf="myForm.submitted && postalCode.invalid" class="invalid-feedback">
                          <div *ngIf="postalCode.errors.required">
                            {{ "field" | translate }}
                            {{ "error.isRequired" | translate }}
                          </div>
                        </div>

                      </div>
                      <div class="col-md-6">
                        <label>{{ "data.city" | translate }}</label>
                        <input class="form-control col-md-12" name="city" [(ngModel)]="locations.city" #city="ngModel"
                          [ngClass]="{ 'is-invalid': myForm.submitted && city.invalid }" required />
                        <div *ngIf="myForm.submitted && city.invalid" class="invalid-feedback">
                          <div *ngIf="city.errors.required">
                            {{ "field" | translate }}
                            {{ "error.isRequired" | translate }}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="line form-row">

                      <div class="col-md-6">
                        <label>{{ "data.state" | translate }}</label>
                        <input class="form-control col-md-12" name="state" [(ngModel)]="locations.state" />

                      </div>
                      <div class="col-md-6">
                        <label>{{ "data.country" | translate }}</label>
                        <select class="col-md-12 form-control" name="selectedCountry" id="selectedCountry"
                          [(ngModel)]="locations.c" (ngModelChange)="onChange($event)" required>
                          <option *ngFor="let country of countryJson" [ngValue]="country.ISO_2" [selected]="country">
                            {{ country.EN }} - ({{country.LokalName}})
                          </option>
                        </select>

                      </div>

                    </li>
                    <div class="col-md-6">
                      <span class="checkbox-inline margin5px">
                        <input [(ngModel)]="locations.headquarters" [checked]="locations.headquarters" name="showEmail"
                          type="checkbox" value="" />
                        {{'locations.makePrimary' | translate}}
                      </span>
                    </div>
                    <button class="btn btn-primary" type="submit">{{'save' | translate}}</button>

                  </form>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/search_locations')">
          <app-service-area [dataArray]="page?.searchLocation.data" [data]="page?.searchLocation.remote"
            (buttonSaved)="savePage()"></app-service-area>
        </div>
        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/about')">
          <div class="card">
            <!-- Title START -->
            <div class="card-header border-0 pb-0">
              <h1 class="h4 card-title mb-0">{{'pagesSettings.about' | translate}}</h1>
            </div>
            <!-- Title END -->
            <!-- Create a page form START -->
            <div class="card-body">
              <form class="row g-3" name="ngForm" #myForm="ngForm" (ngSubmit)="savePage(myForm)">
                <!-- Page information -->

                <!-- Website URL -->

                <!-- Page information -->
                <div class="col-12">
                  <label class="form-label">About page</label>
                  <quill-editor name="description" (onContentChanged)="textChanged($event)" [(ngModel)]="page.about"
                    [modules]="config" [maxLength]="2000" #description="ngModel"></quill-editor>
                  <span [ngClass]="{'hints': counter > 1999}">
                    {{counter}}/2000</span>
                </div>

                <!-- Divider -->
                <hr>

                <!-- Social Links START -->

                <!-- Facebook -->

                <!-- Button  -->
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary mb-0">{{'save' | translate}}</button>
                </div>
              </form>
            </div>
            <!-- Create a page form END -->
          </div>
        </div>
        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/language')">
          <app-language-page [data]="page" (saveObject)="saveLanguage($event)"></app-language-page>
        </div>

        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/premium')">

          <div class="card">
            <div class="card-header">
              <b>Who can offer us Project/Job</b>
            </div>
            <div class="card-body">
              <div class="col-md-3 d-flex align-items-center">
                <label class="me-2">{{'settings.privacy' | translate}}</label>
                <button  class="btn btn-outline-light" style="border: none">
                  <i [class]="iconOffer" (click)="openPrivacyOffer({enum: page?.offer?.status, offer: true})"></i>
                </button>
                <span class="me-2" style="text-wrap: nowrap"  [innerHTML]="textOffer"></span>
              </div>
              <br>

            </div>
          </div>
          <app-pricing [data]="titleService.pricingData"></app-pricing>

        </div>
        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/verified')">

          <app-pricing [data]="titleService.pricingDataVerified"></app-pricing>

        </div>

        <div class="col-md-8 col-lg-6 vstack gap-4" *ngIf="router.url.endsWith('settings/close_account')">
          <div class="card">
            <!-- Card header START -->

            <!-- Card header START -->
            <!-- Card body START -->
            <div class="card-body">

              <!-- Delete START -->
              <h6>Before you go...</h6>
              <ul>
                <li>Take a backup of your data <a href="#">Here</a> </li>
                <li>If you delete your account, you will lose your all data.</li>
              </ul>

              <li class="list-group-item d-md-flex justify-content-between align-items-start">
                <div class="me-md-3">
                  <h6 class="mb-0">Deactivation and deletion</h6>
                  <p class="small mb-0">Temporarily deactivate or permanently delete your Page.</p>
                </div>
                <button class="btn btn-primary-soft btn-sm mt-1 mt-md-0" (click)="sendPage(page)" data-bs-toggle="modal"
                  data-bs-target="#modalLoginActivity"> <i class="bi bi-eye"></i> View</button>
              </li>
              <!-- Delete END -->
            </div>
            <!-- Card body END -->
          </div>
        </div>



      </ng-container>
      <!-- Main content START -->

      <!-- Right sidebar END -->

    </div> <!-- Row END -->
  </div>
  <!-- Container END -->

</main>


<main *ngIf="page?.account?.s === 0 && !isOwnerOrAdmin || page?.account?.s === 1 && !isOwnerOrAdmin">


  <div class="container">
    <!-- Extra space -->
    <div class="h-100px d-none d-lg-block"></div>
    <div class="row align-items-center text-center py-sm-5">
      <!-- Error 404 START -->
      <div class="col-lg-8 mx-auto">
        <!-- SVG START -->
        <figure class="m-0 pt-5 pt-md-0">
          <svg class="col-sm-7 col-lg-5" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1285.3 712.4"
            style="enable-background:new 0 0 1285.3 712.4;" xml:space="preserve">
            <style type="text/css">
              .st0 {
                opacity: 0.2;
                fill: #0B2252;
                enable-background: new;
              }

              .st1 {
                fill: #DAEEFF;
              }

              .st2 {
                fill: #6AC1FF;
              }

              .st3 {
                fill: #3FB790;
              }

              .st4 {
                fill: #34916F;
              }

              .st5 {
                fill: #90DAD8;
              }

              .st6 {
                fill: #FFFFFF;
              }

              .st7 {
                fill: url(#SVGID_1_);
              }

              .st8 {
                fill: #14336D;
              }

              .st9 {
                fill: #FFAA9D;
              }

              .st10 {
                fill: url(#SVGID_00000080196097221175926860000015854635151939166352_);
              }

              .st11 {
                fill: url(#SVGID_00000125570478491118859570000016173875777852424844_);
              }

              .st12 {
                fill: url(#SVGID_00000139994743433262585760000002217132992610938001_);
              }

              .st13 {
                fill: url(#SVGID_00000103980266939832754290000005538789883632006285_);
              }
            </style>
            <g id="illustration">
              <g>
                <ellipse class="st0" cx="642.7" cy="698.2" rx="642.6" ry="14.2" />
                <g>
                  <path class="st1" d="M37,400.5c-17.9-0.5-37.4-6.8-37-35.6s21.3-33.3,23.5-54.8c2.1-21.5,2.2-41.6,13.5-41.8s4.1,9,9.4,19
                    c5.2,10,15.4,22.8,13.4,41.4c-2,18.7,11.3,25.8,10.6,41.1C69.8,385,60.2,401.2,37,400.5z" />
                  <path class="st2" d="M43.7,327c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0,0,4.7,4.7,4.2,20.6c-0.4,13.8-7.5,35.1-9.8,41.5
                    c-2.1-5-4.9-10.5-7.2-15.2c-1.6-3.1-2.9-5.9-3.6-7.6c-2.7-6.3,1.4-19.4,1.4-19.6c0.1-0.4-0.1-0.9-0.5-1s-0.9,0.1-1,0.5
                    c-0.2,0.6-4.3,13.8-1.4,20.6c0.7,1.8,2.1,4.5,3.7,7.7c4.2,8.5,10.6,21.3,10.5,26.3c-0.1,6.9-1,16.9-1,17c0,0.4,0.3,0.8,0.7,0.8
                    c0,0,0,0,0.1,0c0.4,0,0.7-0.3,0.8-0.7c0-0.1,0.8-10.1,1-17.1c0-2.2-1-5.7-2.6-9.6c1-2.8,10-28,10.5-43.6
                    C48.9,332,43.9,327.2,43.7,327z" />
                </g>
                <g>
                  <path class="st1"
                    d="M1056.6,282.4c-15.3-0.5-31.9-5.8-31.5-30.4c0.4-24.6,18.2-28.4,20-46.7c1.8-18.4,1.9-35.5,11.5-35.6
                    c9.6-0.2,3.5,7.7,8,16.2s13.1,19.4,11.5,35.3c-1.7,15.9,9.7,22,9.1,35C1084.5,269.2,1076.4,283,1056.6,282.4z" />
                  <path class="st2" d="M1062.3,219.7c-0.3-0.3-0.7-0.2-0.9,0c-0.3,0.3-0.2,0.7,0,0.9c0,0,4,4,3.6,17.6c-0.3,11.7-6.4,29.9-8.4,35.4
                    c-1.8-4.3-4.1-9-6.1-13c-1.3-2.7-2.5-5-3.1-6.5c-2.3-5.3,1.2-16.6,1.2-16.7c0.1-0.4-0.1-0.7-0.4-0.8c-0.4-0.1-0.7,0.1-0.8,0.4
                    c-0.1,0.5-3.6,11.8-1.2,17.6c0.6,1.5,1.8,3.8,3.1,6.5c3.6,7.2,9,18.2,8.9,22.4c-0.1,5.9-0.8,14.4-0.8,14.5c0,0.4,0.2,0.7,0.6,0.7
                    c0,0,0,0,0.1,0c0.3,0,0.6-0.3,0.7-0.6c0-0.1,0.7-8.6,0.8-14.5c0-1.9-0.9-4.9-2.2-8.2c0.9-2.4,8.5-23.8,8.9-37.2
                    C1066.7,224,1062.4,219.9,1062.3,219.7z" />
                </g>
                <g>
                  <path class="st1"
                    d="M198.6,254.9c-10.5-0.3-21.9-4-21.7-20.9c0.3-16.9,12.5-19.5,13.8-32.2c1.3-12.6,1.3-24.4,7.9-24.5
                    c6.6-0.1,2.4,5.3,5.5,11.1c3.1,5.9,9,13.4,7.9,24.3c-1.2,11,6.6,15.1,6.2,24.1C217.8,245.9,212.2,255.4,198.6,254.9z" />
                  <path class="st2" d="M202.5,211.8c-0.2-0.2-0.5-0.2-0.6,0c-0.2,0.2-0.2,0.5,0,0.6c0,0,2.7,2.8,2.5,12.1
                    c-0.2,8.1-4.4,20.6-5.8,24.3c-1.2-2.9-2.8-6.2-4.2-8.9c-0.9-1.8-1.7-3.4-2.1-4.4c-1.6-3.7,0.8-11.4,0.8-11.5
                    c0.1-0.2-0.1-0.5-0.3-0.6s-0.5,0.1-0.6,0.3c-0.1,0.3-2.5,8.1-0.8,12.1c0.4,1,1.2,2.6,2.2,4.5c2.5,5,6.2,12.5,6.1,15.4
                    c-0.1,4.1-0.6,9.9-0.6,9.9c0,0.3,0.2,0.5,0.4,0.5l0,0c0.2,0,0.4-0.2,0.5-0.4c0-0.1,0.5-5.9,0.6-10c0-1.3-0.6-3.3-1.5-5.7
                    c0.6-1.7,5.9-16.4,6.1-25.6C205.5,214.8,202.6,211.9,202.5,211.8z" />
                </g>
                <g>
                  <path class="st1" d="M1251,411.8c-18.4-0.6-38.3-7-37.9-36.5s21.9-34.1,24.1-56.2s2.3-42.6,13.8-42.9c11.5-0.2,4.2,9.2,9.6,19.5
                    s15.8,23.3,13.8,42.5c-2,19.1,11.6,26.5,10.9,42.1C1284.6,395.9,1274.8,412.5,1251,411.8z" />
                  <path class="st2" d="M1257.8,336.4c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0,0,4.8,4.8,4.3,21.1
                    c-0.4,14.1-7.7,36-10.1,42.5c-2.2-5.1-5-10.8-7.3-15.6c-1.6-3.2-3-6-3.7-7.8c-2.7-6.4,1.4-19.9,1.5-20c0.1-0.4-0.1-0.9-0.5-1
                    s-0.9,0.1-1,0.5c-0.2,0.6-4.4,14.2-1.4,21.1c0.8,1.8,2.2,4.6,3.8,7.9c4.3,8.7,10.8,21.8,10.7,27c-0.1,7.1-1,17.3-1,17.4
                    c0,0.4,0.3,0.8,0.7,0.9c0,0,0,0,0.1,0c0.4,0,0.8-0.3,0.8-0.7c0-0.1,0.9-10.3,1-17.5c0-2.3-1-5.8-2.6-9.9
                    c1.1-2.9,10.3-28.7,10.7-44.7C1263.2,341.6,1258.1,336.6,1257.8,336.4z" />
                </g>
                <g>
                  <g>
                    <path class="st3" d="M896.2,474.7c-12.9,4.3-3.2,22.5-3.2,35.3s-6.4,48.1,6.4,54.5s32.1,19.3,32.1,32.1s3.2,28.9,16,35.3
                      s25.7,6.4,25.7,16s-6.4,12.8-3.2,22.5c3.2,9.6,9.6,12.8,6.4,16h83.4c0,0,16-25.7,12.8-41.7s-19.3-16-22.5-28.9
                      c-3.2-12.8-19.3-16-19.3-28.9c0-12.8-6.4-32.1-16-38.5s-28.9,0-35.3-12.8s0-25.7-9.6-32.1c-9.6-6.4-28.9,3.2-38.5-6.4
                      C921.9,487.5,905.8,471.5,896.2,474.7z" />
                    <path class="st4" d="M909.1,540c0.2-0.9,1.1-1.3,2-1.1c0.2,0,14.8,4.2,25.2,4.1c-7.2-27.4-22.8-33.1-22.9-33.1
                      c-0.8-0.3-1.3-1.2-1-2s1.2-1.3,2-1s20.2,7.1,26.7,42.9c1.5,8.3,6.9,16.2,14.2,23.7c6.2-6.2,8.1-25.7,8.3-33.1
                      c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.8,1.6,1.6c0,1.1-0.9,26.8-9.3,35.3c8.1,7.9,18.2,15.5,28.1,23
                      c10.6,8,20.9,15.8,28.8,23.7c1.3-3.2,1.5-13.4,0.5-24.2c-0.1-0.9,0.6-1.7,1.5-1.7s1.7,0.6,1.7,1.5c0.6,6.8,1.6,21.8-1.5,26.8
                      c5.3,5.7,9.2,11.6,11.1,17.8c0.3,0.8-0.2,1.7-1.1,2c-0.2,0-0.3,0.1-0.5,0.1c-0.7,0-1.3-0.4-1.5-1.1
                      c-3.9-13.1-18.4-25.1-34.8-37.6c-8.9,8.4-33.1,9.8-34.2,9.8h-0.1c-0.8,0-1.6-0.7-1.6-1.5c0-0.9,0.6-1.6,1.5-1.7
                      c0.2,0,23.4-1.4,31.8-8.6c-1.2-0.9-2.4-1.8-3.6-2.7c-20.8-15.6-42.2-31.8-45.7-51c-0.3-1.5-0.6-2.8-0.9-4.2
                      c-0.5,0-0.9,0.1-1.4,0.1c-10.8,0-24.8-4-25.5-4.2C909.3,541.8,908.8,540.9,909.1,540z" />
                  </g>
                  <path class="st5"
                    d="M1088.4,481.1c16.7,8.4,0,44.9,3.2,57.8c3.2,12.8,12.8,22.5,9.6,35.3c-3.2,12.8-9.6,22.5-9.6,35.3
                    s19.3,25.7,16,48.1c-3.2,22.5-12.8,28.9-12.8,28.9h-70.6c0,0-6.4-6.4-6.4-19.3c0-12.8,16-25.7,12.8-32.1s-16-19.3-12.8-41.7
                    c3.2-22.5,12.8-22.5,16-35.3s-3.2-19.3,0-28.9c3.2-9.6,19.3-19.3,22.5-25.7C1059.5,497.2,1069.1,471.5,1088.4,481.1z" />
                  <path class="st6"
                    d="M1034.4,593.8c0.7-0.6,1.7-0.5,2.3,0.2c3.2,3.8,11.4,13.1,16.6,17.3c-0.1-2.2-0.2-4.4-0.2-6.6
                    c0-10,0-20.1,0.5-29.9c0-0.1,0-0.1,0-0.2c0.4-8.7,1.2-17.1,2.7-24.8c3.2-16.2,18.9-38.2,19.5-39.1c0.5-0.7,1.5-0.9,2.2-0.4
                    s0.9,1.5,0.4,2.2c-0.2,0.2-15.9,22.4-19,37.9c-1.2,5.9-1.9,12.4-2.4,19.1c5.8-6,15.6-11,16.1-11.2c0.8-0.4,1.8-0.1,2.2,0.7
                    c0.4,0.8,0.1,1.8-0.7,2.2c-0.1,0.1-14.3,7.2-17.9,14c-0.5,9.6-0.5,19.6-0.5,29.5c0,8.5,1.3,17.7,2.4,25.8
                    c1.1,7.7,2,14.2,1.6,18.8c5.6-3.2,14.9-7,16.2-7.5c0.8-0.3,1.8,0.1,2.1,0.9c0.3,0.8-0.1,1.8-0.9,2.1c-4.3,1.7-15.9,6.7-18.5,9.1
                    c-3.6,7.3-9.4,22.4-9.9,32.8c0,0.9-0.7,1.5-1.6,1.5c0,0,0,0-0.1,0c-0.9,0-1.6-0.8-1.5-1.7c0.5-11,6.6-26.9,10.3-34.3
                    c1.6-3.1,0.3-11.9-1-21.2c-0.7-4.9-1.4-10.1-1.8-15.4c-6.5-3.9-18.9-18.8-19.5-19.4C1033.7,595.4,1033.8,594.4,1034.4,593.8z" />
                </g>
                <g>
                  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="612.1077" y1="-352.8986"
                    x2="612.7188" y2="-370.0066" gradientTransform="matrix(1 0 0 -1 0 310)">
                    <stop offset="0" style="stop-color:#14336D" />
                    <stop offset="1" style="stop-color:#0E2253" />
                  </linearGradient>
                  <path class="st7" d="M1103.1,685.3c0,6.2-55.7,11.8-145.7,15.9l0,0c-10.7,0.5-21.9,0.9-33.6,1.4c-52.4,2-114.1,3.4-181.4,4.3
                    c-1.2,0-2.5,0-3.7,0c-5.9,0.1-11.9,0.2-17.9,0.2c-34.7,0.3-70.8,0.5-107.9,0.5c-11.3,0-22.5,0-33.5-0.1c-16,0-31.8-0.1-47.3-0.3
                    c-83.2-0.6-159.4-2.2-222.8-4.5c-3.1-0.1-6.3-0.3-9.4-0.3c-24.7-0.9-47.3-2-67.5-3.1c-68.6-3.8-109.7-8.7-109.7-14.1
                    c0-5.7,47-10.9,124.4-14.9c13-0.7,26.9-1.3,41.5-1.9c3.5-0.2,7-0.3,10.6-0.4c1.5,0,3-0.1,4.5-0.2c0.7,0,1.4-0.1,2.1-0.1
                    c59.7-2.2,130.9-3.8,208.9-4.5c16.5-0.2,33.3-0.3,50.3-0.4c15.7-0.1,31.7-0.1,47.9-0.1c37.3,0,73.7,0.2,108.6,0.5
                    c6.2,0.1,12.4,0.2,18.5,0.2c3.8,0.1,7.7,0.1,11.5,0.2c61.5,0.8,117.9,2.2,166.7,4c8.7,0.3,17.2,0.6,25.4,1
                    C1041.5,672.8,1103.1,678.7,1103.1,685.3z" />
                  <g>
                    <polygon class="st8" points="317,29 304.8,0 156.2,34.9 186.7,64.2 				" />
                    <path class="st9" d="M317,29L186.7,64.2c0,0,2.1,31.6,3.8,41.9l145-40.7L317,29z" />

                    <linearGradient id="SVGID_00000017490826837838658910000002142626527740690084_"
                      gradientUnits="userSpaceOnUse" x1="-1035.0624" y1="723.5262" x2="-1213.3068" y2="725.9439"
                      gradientTransform="matrix(-1 -7.900000e-03 -7.900000e-03 1 -664.6019 -403.5178)">
                      <stop offset="0" style="stop-color:#14336D" />
                      <stop offset="1" style="stop-color:#0E2253" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000017490826837838658910000002142626527740690084_);" d="M484,594.6
                      c0,0-144.6-153.4-197.1-240.3c-52.5-87-81.5-150.6-100.5-247.1L340,63.8c0,0,79,172.5,146.3,248.7S657,560.6,657,560.6
                      L484,594.6z" />
                    <polygon class="st8" points="829.9,267.8 866.9,239.3 759.3,96.2 740.4,142.8 				" />
                    <polygon class="st9" points="829.9,267.8 740.4,142.8 689.2,158.7 790.8,296.3 				" />
                    <path class="st8" d="M738.1,494c0,0-24.4-68-90.3-88.8c0,0,136.4-95.8,148.2-102.4L686.5,154.2c0,0-152.7,44.5-209.4,124.6
                      S463.2,422,503.9,471.2c40.8,49.3,98.9,89,98.9,89L738.1,494z" />
                    <path class="st9" d="M309.1,702.8c-3.1-0.1-6.3-0.3-9.4-0.3c-24.7-0.9-47.3-2-67.5-3.1c3.5-9.2,8.6-19.4,14.6-28.9
                      c9.5-15.1,21.5-28.8,33.8-35.4c2.6-1.4,5.3-2.5,7.9-3.2l10.5,36.2L309.1,702.8z" />

                    <linearGradient id="SVGID_00000034068006629406653660000012692420182534256547_"
                      gradientUnits="userSpaceOnUse" x1="437.5333" y1="-327.5063" x2="414.9105" y2="-426.9531"
                      gradientTransform="matrix(1 0 0 -1 0 310)">
                      <stop offset="0" style="stop-color:#3162DF" />
                      <stop offset="0.9649" style="stop-color:#173DB8" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000034068006629406653660000012692420182534256547_);" d="M579.3,707.5c-16,0-31.8-0.1-47.3-0.3
                      c-83.2-0.6-159.4-2.2-222.8-4.5c-3.1-0.1-6.3-0.3-9.4-0.3c-3.7-9.3-7.8-21.9-11.3-33.9c-4.2-14.4-7.5-27.8-7.8-33.5
                      c0-0.3,0-0.7,0-1c0.1-11.5,77-64.1,190.8-42.1c4.1,0.8,8.3,1.7,12.5,2.7c39.6,9.4,65.5,35.8,80.9,68.4
                      C571.5,677,576.3,692.2,579.3,707.5z" />

                    <linearGradient id="SVGID_00000101803975007610050020000011470946412395280313_"
                      gradientUnits="userSpaceOnUse" x1="859.6946" y1="-341.0738" x2="765.2094" y2="-442.8716"
                      gradientTransform="matrix(1 0 0 -1 0 310)">
                      <stop offset="0" style="stop-color:#3162DF" />
                      <stop offset="0.9649" style="stop-color:#173DB8" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000101803975007610050020000011470946412395280313_);" d="M957.4,701.2
                      c-10.7,0.5-21.9,0.9-33.6,1.4c-52.4,2-114.1,3.4-181.4,4.3c-1.2,0-2.5,0-3.7,0c-5.9,0.1-11.9,0.2-17.9,0.2
                      c6.4-14.7,12.9-29.4,19.2-43.4c7.4-16.2,14.5-31.4,21.1-44.7l0,0c12.9-25.9,23.6-44.5,29.4-48.3c23.7-15.6,51.6-12.5,99.9,25.1
                      c21.4,16.7,39.7,45.4,53.1,73C948.9,680.1,953.6,691.2,957.4,701.2z" />

                    <linearGradient id="SVGID_00000030466211624571626890000017464207134027537062_"
                      gradientUnits="userSpaceOnUse" x1="626.2271" y1="-346.4812" x2="646.8491" y2="-515.5172"
                      gradientTransform="matrix(1 0 0 -1 0 310)">
                      <stop offset="0" style="stop-color:#3162DF" />
                      <stop offset="0.9649" style="stop-color:#173DB8" />
                    </linearGradient>
                    <path style="fill:url(#SVGID_00000030466211624571626890000017464207134027537062_);" d="M761,619L761,619c-0.2,2.1-0.5,4-0.8,6
                      c-1.6,10.1-4.7,23.6-8.8,38.9c-3.6,13.3-8,27.9-12.8,43c-5.9,0.1-11.9,0.2-17.9,0.2c-34.7,0.3-70.8,0.5-107.9,0.5
                      c-11.3,0-22.5,0-33.5-0.1c-16,0-31.8-0.1-47.3-0.3c-4.6-16.6-10.7-31.1-17.4-43.9c-17.1-33.2-37.5-55-43.1-71.5
                      c-0.4-1.3-0.8-2.5-1-3.7c-0.3-1.5,0.6-3.3,2.5-5.5c9.8-11.5,47.5-32,91.4-52.1l0,0c18.9-8.7,38.9-17.4,58.4-25.2
                      c45.9-18.5,88.6-32.6,105.1-32.4c2.5,0,4.3,0.4,5.5,1.1C755,486.3,767.7,565.1,761,619z" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </figure>
        <!-- SVG END -->
        <!-- 404 info -->
        <h1 class="display-1 mt-4">404</h1>
        <h2 class="mb-2 h1">Page Not Found!</h2>
        <p>Either something went wrong or this page doesn't exist anymore.</p>
        <!-- Button -->
        <a class="btn btn-primary-soft btn-sm" href="index.html">Got to home page</a>
      </div>
      <!-- Error 404 START -->
    </div>
    <!-- Extra space -->
    <div class="h-100px d-none d-lg-block"></div>
    <!-- Row END -->
  </div>
  <!-- Container END -->

  <!-- Container END -->

</main>
<main *ngIf="page?.account?.s === 1 && isOwnerOrAdmin && !page?.account.deleted ">
  <div class="container">
    <div class="row g-4">
      <!-- Main content START -->
      <div class="col-lg-8 mx-auto">
        <!-- Privacy & terms START -->
        <div class="card">
          <div class="card-header">
            <h1 class="card-title h4 mb-0">{{page?.name}} is deactivated</h1>
          </div>
          <div class="card-body">
            <!-- Title -->

            <button class="btn btn-primary" (click)="activateAccount()">Activate</button>

            <!-- Divider -->
            <hr class="mt-2 mb-4">

            <!-- Table of Contents -->

            <!-- Divider -->
          </div>

        </div>
        <!-- Privacy & terms END -->
      </div>
    </div> <!-- Row END -->
  </div>
</main>

<main *ngIf="page?.account?.s === 0 && isOwnerOrAdmin && !page?.account.deleted">

  <!-- Container START -->
  <div class="container">
    <div class="row g-4">
      <!-- Main content START -->
      <div class="col-lg-8 mx-auto">
        <!-- Privacy & terms START -->
        <div class="card">
          <div class="card-header">
            <h1 class="card-title h4 mb-0">{{page?.name}} is blocked</h1>
          </div>
          <div class="card-body">
            <!-- Title -->
            <p class="mt-3 mb-3">For violating our terms of use Account has been suspended until
              <b>{{page?.account?.endDate | date: 'dd.MM.yyyy'}}</b><br>

              Activate this account now for <b> 3 Coins</b>

            </p>

            <button class="btn btn-primary" (click)="changeAccountStatus()">Activate</button>

            <!-- Divider -->
            <hr class="mt-2 mb-4">

            <!-- Table of Contents -->

            <!-- Divider -->
          </div>

        </div>
        <!-- Privacy & terms END -->
      </div>
    </div> <!-- Row END -->
  </div>
  <!-- Container END -->

</main>
<main *ngIf="page?.account?.s === 1 && isOwnerOrAdmin && page?.account.deleted">

  <!-- Container START -->
  <div class="container">
    <div class="row g-4">
      <!-- Main content START -->
      <div class="col-lg-8 mx-auto">
        <!-- Privacy & terms START -->
        <div class="card">
          <div class="card-header">
            <h1 class="card-title h4 mb-0">{{page?.name}} ist Deactivated</h1>
          </div>
          <div class="card-body">
            <!-- Title -->
            <p class="mt-3 mb-3">
              <span>Page will be deleted soon.</span> <br>
            </p>

            <button class="btn btn-primary" (click)="changeAccountStatus()">Activate</button>

            <!-- Divider -->
            <hr class="mt-2 mb-4">

            <!-- Table of Contents -->

            <!-- Divider -->
          </div>

        </div>
        <!-- Privacy & terms END -->
      </div>
    </div> <!-- Row END -->
  </div>
  <!-- Container END -->

</main>
