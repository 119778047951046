   <!-- <ng-container *ngIf="details">
<div class="card d-flex isMobilePages col-12">
    <div class="page__firstdiv" (click)="goToWeb(page._id)">
      <div class="page__firstdiv_img">
        <img *ngIf="hasPhoto" [src]="page.profileImage" ngx-gravatar [round]="false" [email]="'example@mail.com'" fallback="mp" size="50">
        <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="50">
      </div>
      <div class="page__firstdiv_data">
      <h6>{{page?.name}}</h6>
    </div>

    </div>
    <div class="page__secdiv" *ngIf="userPage.status === 1">
      <a class="fa fa-comments"><span class="span-padding-left10">Notifications</span></a>
      <a class="ms-3 fa fa-envelope"><span class="span-padding-left10">Messages</span></a>
    </div>
      <div class="page__secdiv" *ngIf="userPage.status === 0">
        <button (click)="acceptPage(page)" class="btn btn-success"><span class="span-padding-left10">Accept</span></button>
        <button (click)="rejectPage(page)" class="btn btn-danger"><span class="span-padding-left10">Reject</span></button>
      </div>
  <div>
  </div>
  </div>
</ng-container> -->
<ng-container *ngIf="details">
  <div class="card">
    <div class="h-80px rounded-top" style="background-image:url(assets/images/bg/01.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;"></div>
      <!-- Card body START -->
      <div class="card-body text-center pt-0">
        <!-- Avatar -->
        <div class="avatar avatar-lg mt-n5 mb-3">
           <a (click)="goToWeb(pageDetails._id)"><img class="avatar-img rounded-circle border border-white border-3 bg-white" src="assets/images/logo/08.svg" alt=""></a>
        </div>
        <!-- Info -->
        <h5 class="mb-0"> <a (click)="goToWeb(pageDetails._id)">{{pageDetails?.name}}</a> </h5>
        <small> <i class="bi bi-lock pe-1"></i> Private Group</small>
        <!-- Group stat START -->
        <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
          <!-- Group stat item -->
          <div>
            <h6 class="mb-0">32k</h6>
            <small>Members</small>
          </div>
          <!-- Divider -->
          <div class="vr"></div>
          <!-- Group stat item -->
          <div>
            <h6 class="mb-0">20</h6>
            <small>Post per day</small>
          </div>
        </div>
        <!-- Group stat END -->
        <!-- Avatar group START -->
        <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
          <li class="avatar avatar-xs">
            <img class="avatar-img rounded-circle" src="assets/images/avatar/02.jpg" alt="avatar">
          </li>
          <li class="avatar avatar-xs">
            <img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt="avatar">
          </li>
          <li class="avatar avatar-xs">
            <img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt="avatar">
          </li>
          <li class="avatar avatar-xs">
            <img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt="avatar">
          </li>
          <li class="avatar avatar-xs">
            <div class="avatar-img rounded-circle bg-primary"><span class="smaller text-white position-absolute top-50 start-50 translate-middle">+22</span></div>
          </li>
        </ul>
        <!-- Avatar group END -->
    </div>
    <!-- Card body END -->
    <!-- Card Footer START -->
    <div class="card-footer text-center" *ngIf="pageDetails.status === 1">
      <a class="btn btn-success-soft btn-sm" href="#!"> Message </a>
      <a class="btn btn-danger-soft btn-sm ms-2" href="#!"> Leave</a>
    </div>
    <div class="card-footer text-center" *ngIf="pageDetails.status === 0">
      <a class="btn btn-success-soft btn-sm" href="#!"> Accept</a>
      <a class="btn btn-danger-soft btn-sm ms-2" href="#!"> Reject</a>
    </div>
    <!-- Card Footer END -->
  </div>
</ng-container>


<ng-container *ngIf="sidebar">
  <div class="container-fluid px-0">
    <div class="row">
      <div class="d-flex row pb-1 pt-31 pl-2 sidebar-details" (click)="goToWeb(page._id)">
        <button class="btn btn4-default px-0 nav-item" type="button" id="menu2">
          <img ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
            <span id="isSidebar" class="isSidebar ms-1">{{page?.name}}</span>
        </button>


       </div>
    </div>
  </div>

</ng-container>
<ng-container *ngIf="sidebarPage">
  <div class="d-flex row pb-3 pt-3 pl-2 sidebar-details" (click)="goToWeb(page?._id)" [ngClass]="{'active': selectedID === page?._id}">
    <img *ngIf="hasPhoto" [src]="page.profileImage" ngx-gravatar [round]="false" [email]="'example@mail.com'" fallback="mp" size="30">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="30">
    <div class="d-flex column pl-3">
      {{page?.name}}
    </div>


   </div>
  </ng-container>

  <ng-container *ngIf="pageProjects">
    <a class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab" [routerLink]="pageProjects?.pageUrl" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" (click)="goToPageProjectDetails(pageProjects)">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 avatar avatar-story me-2">
          <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
        </div>
        <div class="flex-grow-1 d-block">
          <h6 class="mb-0 mt-1">{{pageProjects?.name}}</h6>
        </div>
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="projects && filterPage">
    <a class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab" [routerLink]="page?.pageUrl" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" (click)="goToPageProject(page)">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 avatar avatar-story me-2">
          <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
        </div>
        <div class="flex-grow-1 d-block">
          <h6 class="mb-0 mt-1"> {{page?.name}}</h6>
        </div>
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="fromJobs && filterPageJobs">
    <div class="card project me-1 ms-1 active-route" [routerLink]="page?.pageUrl" [routerLinkActive]="['active']" (click)="selectPage(page)">
      <div class="card-body px-0 py-0">
        <div class="d-flex align-items-center justify-flex-start align-items-center column">
          <img *ngIf="hasPhoto" [src]="page.profileImage" ngx-gravatar [round]="false" [email]="'example@mail.com'" fallback="mp" size="30">
          <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="30">
            <div role="heading" class="font-weight-600">{{page?.name}}</div>
         </div>
      </div>
    </div>
  </ng-container>
