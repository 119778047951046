import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShareDialogComponent } from 'src/app/components/modals/share-dialog/share-dialog.component';
import { Duration, EmploymentType, Remote, Incomes } from 'src/app/shared/enums/enums-model';
import { Job, UserJobs } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-jobs-published',
  templateUrl: './user-jobs-published.component.html',
  styleUrls: ['./user-jobs-published.component.scss']
})
export class UserJobsPublishedComponent implements OnInit, AfterViewInit {
  @Input() isPublished: boolean = false;
  @Input() public job: Job;
  public moreJobInfo: Job;
  public jobDurationString;
  public employmentTypeString;
  public currencyObject;
  public countryJsonArray;
  public paymentID;
  countryJsonObject;
  public remote;
  public openMoreInfo = false;
  public subscription: Subscription;



  public jobDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]
  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];
  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]
  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite", percent: '0%' },
    { id: Remote.hybrid, description: "project.firstPage.hybrid", percent: '50%' },
    { id: Remote.remote, description: "project.firstPage.remote", percent: '100%' },
  ];

  user: User;


  @Input() userJobs: UserJobs;
  constructor(public jobService: JobsService,
    public http: HttpClient,
    public router: Router,
    private countryDataService: CountryDataService,
    private userAuth: AuthService,
    private userService: UserService,
    private dialog: MatDialog,

  ) { }
  ngAfterViewInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);


if (this.isPublished && this.job) {
  this.getJoDurationID(this.job.duration);
  this.getRemoteID(this.job.remote);
  this.getCurrencyID(this.job.currency)
  this.getPaymentFormID(this.job.incomes.s);
  this.getCountryID(this.job.c);
  this.getEmploymentTypeID(this.job.employmentType)

}

  }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);
    console.log(this.countryJsonArray, "ahahhha")

    this.userService.getUserContactData(this.userAuth.userID).subscribe(t => {
      this.user = t;
    })

if (this.isPublished && this.job) {
        this.getJoDurationID(this.job.duration);
        this.getRemoteID(this.job.remote);
        this.getCurrencyID(this.job.currency)
        this.getPaymentFormID(this.job.incomes.s);
        this.getCountryID(this.job.c);
        this.getEmploymentTypeID(this.job.employmentType)

}




if (this.userJobs) {
  if (!!this.userJobs.jobId) {
    this.jobService.getJob(this.userJobs.jobId).subscribe((job: Job) => {
      if (job?.public) {
        this.job = job;
        this.getJoDurationID(this.job.duration);
        this.getRemoteID(this.job.remote);
        this.getCurrencyID(this.job.currency)
        this.getPaymentFormID(this.job.incomes.s);
        this.getCountryID(this.job.c);
        this.getEmploymentTypeID(this.job.employmentType)
      }
    });
  }

}


  }
  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(t => t.id === id).description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(t => t.id === id).description;
  }
  getCurrencyID(id) {
    this.countryDataService.countryData$.pipe().subscribe(res => {
      this.countryJsonArray = res;
      this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
    });
  }
  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find(t => t.id === id).description;
  }
  getCountryID(id) {
   this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }

  getRemoteID(id) {
    this.remote = this.remoteArray.find(test => test.id === id).description;
  }
  moreInfo(jobID) {
    this.openMoreInfo = true;
   this.subscription =  this.jobService.getJobMoreInfo(jobID).subscribe((job: Job) => {
        this.moreJobInfo = job;
    });
  }
  lessInfo() {
    this.openMoreInfo = false;
    this.subscription.unsubscribe();
  }

  emitID(id) {
    localStorage.setItem('jobID', id);
    this.router.navigateByUrl("jobs/public/" + id);

  }

  openShare(data) {
    this.dialog.open(ShareDialogComponent, {
      data
    });
  }




}
