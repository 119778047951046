<div class="container-main px-0" *ngIf="!enums.offer">
  <h6 mat-dialog-title>
    <mat-toolbar role="toolbar" class="task-header d-flex justify-content-between">
      <h5>Select Audience</h5>
      <span class="fx-spacer"></span>
      <button mat-icon-button mat-dialog-close>
        <mat-icon mat-list-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </h6>
  <div height="500" class="privacy-list" style="height: 500px;">
    <div style="
        padding-left: .75rem;
        padding-right: .75rem;
        padding-bottom: .75rem;
    ">
    </div>
    <div class="column main-column">
      <div class="column flex main-column">

        <div *ngFor="let privacy of privacyArray">
          <div class="d-flex justify-content-between btn btn-outline-light align-items-center" [ngClass]="{'active': selectedId === privacy.id}" style="border:none">
            <div class="mb-2 d-flex mt-2 align-items-center">
              <ul class="nav nav-link-secondary flex-column fw-bold gap-2">

                <li class="nav-item">
                  <a class="nav-link"><i [class]="privacy.icon" class="nav-icon"></i>

                  </a>
                </li>
              </ul>

              <div class="text-align-start">
                <strong>{{ privacy?.description }}
                </strong>
                <br />
                <div class="d-flex align-items-center">
                  <p class="mb-0 small me-2">
                    {{privacy.text}}
                  </p>
                </div>
              </div>

            </div>
            <input (click)="selectedId = privacy.id" class="form-check-input" type="radio" name="radio" [checked]="selectedId === privacy.id">
          </div>



        </div>


      </div>
    </div>

  </div>
  <footer class="sticky-modal-footer" style="line-height: 19px;
padding: 10px 12px;
background: white;
display: flex;
justify-content: flex-end;
border-top: 1px solid #d3d3d3;">
    <mat-dialog-actions>
      <button mat-dialog-close class="btn btn-light btn-sm" color="primary" style="margin-right: 1rem">Cancel</button>
      <button (click)="saveData()" mat-dialog-close class="btn btn-primary btn-sm" color="primary">Save</button>
    </mat-dialog-actions>
  </footer>


</div>

<div class="container-main px-0" *ngIf="enums.offer">
  <h6 mat-dialog-title>
    <mat-toolbar role="toolbar" class="task-header d-flex justify-content-between">
      <h5>Who can apply</h5>
      <span class="fx-spacer"></span>
      <button mat-icon-button mat-dialog-close>
        <mat-icon mat-list-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </h6>
  <div height="500" class="privacy-list" style="height: 500px;">
    <div style="
        padding-left: .75rem;
        padding-right: .75rem;
        padding-bottom: .75rem;
    ">
    </div>
    <div class="column main-column">
      <div class="column flex main-column">
        <div *ngFor="let privacy of offerArray">
          <div class="d-flex justify-content-between btn btn-outline-light align-items-center px-1 py-1" [ngClass]="{'active': selectedId === privacy.id}" style="border:none">
            <div class="d-flex align-items-center">
              <ul class="nav nav-link-secondary flex-column fw-bold gap-2">

                <li class="nav-item">
                  <a class="nav-link"><i [class]="privacy.icon" class="nav-icon"></i>

                  </a>
                </li>
              </ul>
              <div class="text-align-start">
                <strong>{{ privacy?.description }}
                </strong>
                <br />
                <div class="d-flex align-items-center">
                  <p class="mb-0 small me-2">
                    <span [innerHtml]="privacy.text"></span>
                  </p>
                </div>
              </div>

            </div>
            <input (click)="selectedId = privacy.id" class="form-check-input" type="radio" name="radio" [checked]="selectedId === privacy.id">
          </div>



        </div>


      </div>
    </div>

  </div>
  <footer class="sticky-modal-footer" style="line-height: 19px;
padding: 10px 12px;
background: white;
display: flex;
justify-content: flex-end;
border-top: 1px solid #d3d3d3;">
    <mat-dialog-actions>
      <button mat-dialog-close class="btn btn-light btn-sm" color="primary" style="margin-right: 1rem">Cancel</button>
      <button (click)="saveData()" mat-dialog-close class="btn btn-primary btn-sm" color="primary">Save</button>
    </mat-dialog-actions>
  </footer>


</div>




