import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { User, UserUser } from "../shared/interfaces/user.model";
import { AuthService } from "../shared/services/auth.service";
import { UserService } from "../shared/services/user.service";
import _ from "lodash";
import { CountryDataService } from "../shared/services/country-data.service";
import {  Job, Location, Page, Project, UserPage, UserSearchLocation } from "src/app/shared/interfaces/model";

import {
  EditHeaderDialog,
  HeaderDialogComponent,
} from "../components/modals/header-dialog/header-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ArraysService } from "../shared/services/arrays-service/title-service";
import { PagesService } from "../shared/services/pages.service";
import { CategoryService } from "../shared/services/category.service";
import { ChangeDetectionService } from "../shared/services/change-detection.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EmploymentType } from "../shared/enums/enums-model";

@Component({
  selector: "app-sidebar-search-jobs-projects",
  templateUrl: "./sidebar-search-jobs-projects.component.html",
  styleUrls: ["./sidebar-search-jobs-projects.component.scss"],
})
export class SidebarSearchJobsProjectsComponent implements OnInit, OnChanges {
  @Input() user: User;
  countryJson;
  selectedWorkplace: boolean = false;
  selectedIndex: boolean = false;
  isEditingNote: boolean = false;
  selectedIndexPage: boolean = false;
  public locations = new Location();
  public page: UserPage | any;
  public userPage: Page;
  jobDurationString;
  paymentID;
  public currencyObject;
  countryJsonObject;
  public languages = [];


  uUser: User;
  userCV: any;

  public dataList$: Observable<any>;
  @Input() isProjectCompanySearch;
  @Input() pageID;
  @Input() isPage: boolean = false;
  @Input() categoryTitle;
  @Input() pageIndex;
  @Input() isSearchSidebar;
  @Input() data: any;
  @Input() isJob: boolean = false;


  remoteSidebarSearch;


  toggleOptions: Array<String> = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];


  public pageCloned: Page;

  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];
  public loggedUser: Observable<any>;
  public currentUser: User | UserUser;
  pageSearchLocation: any;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public countryDataService: CountryDataService,
    public dialog: MatDialog,
    public arrayService: ArraysService,
    private pageService: PagesService,
    public categoryService: CategoryService,
    public titleService: ArraysService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private changeDetectionService: ChangeDetectionService,
  ) {
    this.titleService.showEmploymentTypes();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.isPage) {
        this.pageService.getPage(this.pageID).subscribe((res: any) => {
          this.userPage = res.page;
          this.pageCloned = _.cloneDeep(res.page);

          this.page = _.cloneDeep(res.page);
          this.loggedUser = this.userService.userList;
          this.loggedUser.subscribe(res => {
            this.currentUser = res.userUser;
            this.pageSearchLocation = this.currentUser.pages.find(t => t.pageId === this.userPage._id);
            console.log(this.pageSearchLocation, "pageSearchLocation")

          })


          this.dataList$ = this.userService.userList;


          this.dataList$.subscribe(result => {
            this.user = result.user;
            this.uUser = result.userUser;
            this.userCV = result.cv;

          });



        })
      }
    }
   this.cd.detectChanges();
  }

  ngOnInit(): void {



    this.titleService.showRemote();
    this.categoryService.getCategories();
    this.titleService.showJobDuration();
    this.titleService.showSalary();
    this.dataList$ = this.userService.userList;


          this.dataList$.subscribe(result => {
            this.user = result.user;
            this.uUser = result.userUser;
            this.userCV = result.cv;
            if (!!this.user) {

              this.http.get<any>(`assets/languages/${this.user?.language.primary}/language.json`).subscribe(data => {

                this.languages = data;
                this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));

              })
            }




    });







    if (this.isPage) {
      this.pageService.getPage(this.pageID).subscribe(t => {

        this.userPage = t.page;
         this.page = _.cloneDeep(this.userPage);
      })
    }

    this.countryDataService.countryData$
      .pipe()
      .subscribe((res) => (this.countryJson = res));
      this.arrayService.showRemoteWithoutHybrid();
      this.arrayService.showSalary();
      this.getJoDurationID();
      this.getPaymentFormID();
      this.getCurrencyID();
      this.getCountryID();




  }

  selectIndexForButtonsPage(index) {
    this.selectedIndexPage = index;
  }

  selectWorkplace() {
    this.selectedWorkplace = true;
    console.log(this.selectedWorkplace)
    }
    setNoteEditMode(mode) {
      this.isEditingNote = mode;
    }
    cancelNodeEdit() {
      this.selectedIndex = undefined;
      this.selectedWorkplace = false;
      this.selectedIndexPage = false;
    }

    saveNote(item, index) {
      if (item) {
        this.user.searchLocation.data[index].postalCode = item.postalCode;
      }
      this.userService.updateUser(this.user._id, this.user);
      this.selectedIndex = undefined;
      this.selectedWorkplace = false;
      this.changeDetectionService.emitChanges(true);

    }

    saveNotePageProject(item, index) {

      if (item) {
        this.user.pages[this.pageIndex].searchLocation.data[index].postalCode = item.postalCode;
      }

      this.userService.updateUser(this.user._id, this.user);
      this.selectedIndex = undefined;
      this.selectedWorkplace = false;
      this.selectedIndexPage = undefined;
      this.changeDetectionService.emitChanges(true);

    }


  replaceQualifications() {
    this.user.searchFilterSkills.searchQualifications.data = [];
    this.user.searchFilterSkills.searchQualifications.data =
      this.user.cv.data[0].skills[0].qualifications.data;
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }
  replaceDriveLicense() {
    this.user.searchFilterSkills.searchDriveLicenses.data = [];
    this.user.searchFilterSkills.searchDriveLicenses.data =
      this.user.cv.data[0].skills[0].driveLicenses.data;
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }

  replaceLanguage() {
    this.user.searchFilterSkills.searchLanguages.data =
      this.user.cv.data[0].skills[0].languages.data;
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }

  replaceSkillsOffer() {
    console.log(this.userCV, "replaceSkillsOffer")


    const data = this.userCV.data.map(t => t.skills[0].skillsOffer.data);
    let  newArr = Array.prototype.concat(...data);

    newArr =  newArr.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name));

    this.user.searchFilterSkills.searchSkillsOffer.data = [];
    this.user.searchFilterSkills.searchSkillsOffer.data = newArr;

    console.log(newArr, "new Array")
    this.userService.updateUser(this.user._id, this.user);
   // this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);
  }

  selectionChanged(item, index) {
    if (this.user.searchLocation.data[index]?.region.length > 1) {
      this.user.searchLocation.data[index].postalCode = null;
      this.user.searchLocation.data[index].postalCode = "";
    } else if (this.user.searchLocation.data[index]?.region.length === 0) {
      this.user.searchLocation.data[index].postalCode = null;
      this.user.searchLocation.data[index].postalCode = "";
    } else {
      this.user.searchLocation.data[index]?.region.forEach(
        (i) => (this.user.searchLocation.data[index].postalCode = i)
      );
    }
  }
  deleteSearchFilter(index) {
    this.user.searchLocation.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }

  deleteSearchFilterProject(index) {
    this.pageCloned.searchLocation.data.splice(index, 1);
    this.user.pages[this.pageIndex].searchLocation.data = this.page.searchLocation.data;
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);
  }

  removeKeyword(index, id) {
    this.pageSearchLocation.categories.subCategory[id].keywords.splice(index, 1);
    this.currentUser.pages[this.pageIndex].categories.subCategory = this.pageSearchLocation.categories.subCategory;
   // this.userService.updateUser(this.user._id, this.user);
   console.log(this.currentUser, "-klmkm")

   this.userService.updateuUser(this.currentUser._id, this.currentUser);

    this.changeDetectionService.emitChanges(true);
  }

  removeSkillOfferJob(index) {
    this.user.searchFilterSkills.searchSkillsOffer.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }


  selectIndexForButtons(index) {
    this.selectedIndex = index;
    this.selectedWorkplace = false;
  }
  onChange(value, index) {
    this.locations.c = value;
    this.user.searchLocation.data[index].c = value
   this.userService.updateUser(this.user._id, this.user);
  }


  editHeader(data: EditHeaderDialog) {
    this.dialog
      .open(HeaderDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === undefined) {
        }
      });
  }
  addNewSearchFilter() {
    let digit = this.user.address.data[0].postalCode.toString()[0];
    const data: UserSearchLocation = {
      c: this.user.address.data[0].c,
      region: [`${digit}`],
      postalCode: this.user.address.data[0].postalCode.toString().substring(0, 3),
    }
    this.user.searchLocation.data.unshift(data);
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }
  remoteCheck(event) {
    this.user.searchLocation.remote = event;
  }
  remoteCheckPage(event) {
    this.page.searchLocation.remote = event;
    this.user.pages[this.pageIndex].searchLocation.remote = event;
  }

  savePage(item, index) {
    if (item) {
      this.page.searchLocation[index].postalCode = item.postalCode;
    }

    this.userService.updateUser(this.user._id, this.user);
    this.selectedIndex = undefined;
    this.selectedWorkplace = false;
    this.changeDetectionService.emitChanges(true);

  }

  findSubCategoryName(cat) {
    const index = this.categoryService.categories.findIndex(object => {
      return object.id ===  this.pageSearchLocation.categories.name;
    });

    return this.categoryService.categories[index].subCategory.find(r => r.id === cat);
  }

  replaceSearchFilterProject() {
    this.user.pages[this.pageIndex].searchLocation.data = this.userPage.searchLocation.data;
    this.page.searchLocation.data = this.userPage.searchLocation.data;

    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }

  replaceSearchFilterPage() {




    this.page.searchLocation.data = [];
    this.page.searchLocation.data = this.pageCloned.searchLocation.data;
    this.page.searchLocation.remote = this.pageCloned.searchLocation.remote;
//    this.page.searchLocation.data.unshift(this.userPage.location);
    this.pageSearchLocation.searchLocation.data = []
    this.pageSearchLocation.searchLocation.data = this.pageCloned.searchLocation.data;
     this.currentUser.pages.find(t => t.pageId === this.page._id).searchLocation.data =  this.pageSearchLocation.searchLocation.data;
     this.userService.updateuUser(this.currentUser._id, this.currentUser);


   //  this.user.pages[this.pageIndex].searchLocation = this.page.searchLocation;
    // this.userService.updateUser(this.user._id, this.user);
     this.changeDetectionService.emitChanges(true);


  //  this.page.searchLocation.data = this.page.
  }



  /*removeKeyword(index, id) {
    this.pageSearchLocation.categories.subCategory[id].keywords.splice(index, 1);
    this.currentUser.pages[this.pageIndex].categories.subCategory = this.pageSearchLocation.categories.subCategory;
   // this.userService.updateUser(this.user._id, this.user);
   console.log(this.currentUser, "-klmkm")

   this.userService.updateuUser(this.currentUser._id, this.currentUser);

    this.changeDetectionService.emitChanges(true);
  } */

  addKeyword(index) {
    console.log(index, this.pageSearchLocation, "page")
    const findIndex = this.currentUser.pages.findIndex(t => t.pageId === this.page.page._id);
    console.log(findIndex, "index")
    const data = this.page.page.categories.subCategory[index].keywords;
    let  newArr = Array.prototype.concat(...data);


    this.currentUser.pages[findIndex].categories.subCategory[index].keywords = newArr;
    this.page.page.categories.subCategory[index].keywords = newArr;

 this.userService.updateuUser(this.currentUser._id, this.currentUser);

//this.user.pages[findIndex].categories.subCategory[index].keywords = [];
  //  this.user.pages[findIndex].categories.subCategory[index].keywords = newArr;


    this.changeDetectionService.emitChanges(true);

  }

  addSubCategory(index) {
    this.page.categories.subCategory[index] = this.userPage.categories.subCategory[index];
    this.findSubCategoryName(this.userPage.categories.subCategory[index].name);
    this.page.categories.subCategory[index] = this.userPage.categories.subCategory[index];
    this.changeDetectionService.emitChanges(true);
  }
  addCategory() {
    this.page.categories = this.userPage.categories;
    this.user.pages[this.pageIndex].categories = this.userPage.categories;
    this.findSubCategoryName(this.userPage.categories.name);
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }

  deleteSubCategory(index) {
    this.user.pages[this.pageIndex].categories.subCategory.splice(index, 1);
    this.userPage.categories.subCategory.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);
  }

  getJoDurationID() {
   this.jobDurationString = this.titleService.jobDurationArray.find(t => t.id === this.data?.duration)?.description;
  }

  getPaymentFormID() {
    this.paymentID = this.titleService.salary.find(t => t.id === this.data?.incomes?.status)?.description;
  }

  getCurrencyID() {
    this.currencyObject = this.countryJson.find(t => t.currency.Alphabeticcode === this.data?.currency);
  }

  getCountryID() {
    this.countryJsonObject = this.countryJson.find(t => t.ISO_2 === this.data?.country);
  }
  public getLanguageName(type) {
    console.log(this.languages, "languages");
    return this.languages.find(t => t.type === type);
  }

}
