import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Project } from 'src/app/shared/interfaces/model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit {

  public dataList$: Observable<any>;
  userData: any;
  projects: Project[];


  constructor(
    public userService: UserService,

  ) {

  }
  ngOnInit(): void {
    this.dataList$ = this.userService.dataList;
    this.dataList$.pipe(take(1)).subscribe((res: any) => {
      //   this.privacyIcon = this.loggedUser;
      //  this.privacyText = this.loggedUser;

      this.userData = res;

      this.userService.getMyProjects(this.userData.userUser.projects.data[0].projectListID).pipe(take(1)).subscribe((res: any) => {
        this.projects = res.projects
      })

    });
  }

}
