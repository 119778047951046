import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataItemDetailsComponent } from './data-item-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { GravatarModule } from 'ngx-gravatar';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from 'src/app/material.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { SmartitsChipsModule } from 'src/app/smartits-ui/chips/smartits-chips.module';
import { ProjectModule } from '../jira-system/project/project.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    DataItemDetailsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    DirectivesModule,
    GravatarModule,
    PipeModule,
    SmartitsChipsModule,
    QuillModule,
    ProjectModule,
    RouterModule
  ],

  exports: [
    DataItemDetailsComponent
  ]
})
export class DataItemDetailsModule { }
