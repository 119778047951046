
<main>
<div class="container">
  <div class="row gx-0">
    <app-sidebar-page-details class="mt-n4 col-lg-1"></app-sidebar-page-details>
    <div class="col-lg-3 col-xxl-3" id="chatTabs" role="tablist">

      <!-- Divider -->
      <div class="d-flex align-items-center mb-4 d-lg-none">
        <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
          <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
          <span class="h6 mb-0 fw-bold d-lg-none ms-2">Chats</span>
        </button>
      </div>
      <!-- Advanced filter responsive toggler END -->
      <div class="card card-body border-end-0 border-bottom-0 rounded-bottom-0">
        <div class=" d-flex justify-content-between align-items-center">
          <h1 class="h5 mb-0">Active chats <span class="badge bg-success bg-opacity-10 text-success">6</span></h1>
          <!-- Chat new create message item START -->
          <div class="dropend position-relative">
            <div class="nav">
              <a class="icon-md rounded-circle btn btn-sm btn-primary-soft nav-link toast-btn" data-target="chatToast" href="#" > <i class="bi bi-pencil-square"></i> </a>
            </div>
          </div>
          <!-- Chat new create message item END -->
        </div>
      </div>

      <nav class="navbar navbar-light navbar-expand-lg mx-0">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
          <!-- Offcanvas header -->
          <div class="offcanvas-header">
            <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"></button>
          </div>

          <!-- Offcanvas body -->
          <div class="offcanvas-body p-0">
            <div class="card card-chat-list rounded-end-lg-0 card-body border-end-lg-0 rounded-top-0">

              <!-- Search chat START -->
              <form class="position-relative">
                <input class="form-control py-2" type="search" placeholder="Search for chats" aria-label="Search">
                <button class="btn bg-transparent text-secondary px-2 py-0 position-absolute top-50 end-0 translate-middle-y" type="submit">
                  <i class="bi bi-search fs-5"></i>
                </button>
              </form>
              <!-- Search chat END -->
              <!-- Chat list tab START -->
              <div class="mt-4 h-100">
                <div class="chat-tab-list custom-scrollbar">
                  <ul class="nav flex-column nav-pills nav-pills-soft">
                    <ng-container *ngFor="let message of pageMessages">
                      <app-page-project-message [pageMessage]="message" (selectedProject)="selectProject(message)"></app-page-project-message>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <!-- Chat list tab END -->
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="col-lg-8 col-xxl-8">
      <div class="card card-chat rounded-start-lg-0 border-start-lg-0">
        <div class="card-body h-100">
          <div class="tab-content py-0 mb-0 h-100" id="chatTabsContent" *ngIf="projectSelected">
            <!-- Conversation item START -->
            <!-- Conversation item END -->
            <!-- Conversation item START -->
            <div class="fade tab-pane show active h-100" id="chat-2" role="tabpanel" aria-labelledby="chat-2-tab">
              <!-- Top avatar and status START -->
              <div class="d-sm-flex justify-content-between align-items-center">
                <div class="d-flex mb-2 mb-sm-0">
                  <div class="flex-shrink-0 avatar me-2">
                    <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="">
                  </div>
                  <div class="d-block flex-grow-1">
                    <h6 class="mb-0 mt-1" *ngIf="project?.pageID"> {{appliedPage?.name}}</h6>
                    <h6 class="mb-0 mt-1" *ngIf="!project?.pageID">    {{userPage?.firstName}} {{userPage?.lastName}}</h6>
                    <div class="d-flex align-items-center">
                      <button class="py-1 btn btn-sm btn-primary-soft col-lg-12 col-4 me-1" (click)="openProject({project: project})">{{"projectText" | translate}}</button>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <!-- Call button -->
                  <!-- Card action START -->
                  <div class="dropdown">
                    <a class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" href="#" id="chatcoversationDropdown2" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chatcoversationDropdown2">
                      <li><a class="dropdown-item" href="#"><i class="bi bi-check-lg me-2 fw-icon"></i>Mark as read</a></li>
                      <li><a class="dropdown-item" href="#"><i class="bi bi-mic-mute me-2 fw-icon"></i>Mute conversation</a></li>
                      <li  *ngIf="project?.pageID" (click)="goToPage()" ><a class="dropdown-item"><i class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
                      <li  *ngIf="!project?.pageID" (click)="viewProfile()" ><a class="dropdown-item"><i class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
                      <li *ngIf="!project?.pageID"><a class="dropdown-item" (click)="deleteUserChat()" ><i class="bi bi-trash me-2 fw-icon"></i>Delete chat</a></li>
                      <li *ngIf="project?.pageID"><a class="dropdown-item"  (click)="deletePageChat()" ><i class="bi bi-trash me-2 fw-icon"></i>Delete chat</a></li>

                      <li class="dropdown-divider"></li>
                      <li><a class="dropdown-item" href="#"><i class="bi bi-archive me-2 fw-icon"></i>Archive chat</a></li>
                    </ul>
                  </div>
                  <!-- Card action END -->
                </div>
              </div>
              <!-- Top avatar and status END -->
              <hr>
              <div class="chat-conversation-content custom-scrollbar">
                <!-- Chat time -->
                <ng-container *ngIf="!!checkIDOfUserPage">
                  <div class="text small my-2">{{"Message"}} {{user?.firstName}}  {{user?.lastName}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                </ng-container>
                <ng-container *ngIf="!checkIDOfUserPage">
                  <div class="text small my-2" *ngIf="project?.pageID">{{"Message"}} {{appliedPage?.name}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                  <div class="text small my-2" *ngIf="!project?.pageID">{{"Message"}} {{user?.firstName}}  {{user?.lastName}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

                </ng-container>


               <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHTML]="jobManage?.message?.description" disabled></div>
               <div class="row mt-1 mb-1 align-items-center" *ngIf="jobManage?.cancel.status !== 2">
                 <p style="width: fit-content;">{{'projectText' | translate}}: </p>
                  <button class="btn btn-sm py-1 btn-primary me-2 col-lg-2 col-4"  *ngIf="jobManage?.cancel.status === 0" (click)="apply(data)">{{'job.apply' | translate}}</button>
                  <button class="btn btn-sm py-1 btn-danger-soft col-lg-2 col-4" (click)="cancel()">{{'cancel' | translate}} </button>
                </div>
                <div class="mt-1 mb-1 align-items-center" *ngIf="jobManage?.cancel.status === 2">
                  <ng-container *ngIf="!!isMessaging">

                    <p *ngIf="!cancelledPage" style="width: fit-content;" class="text small my-2">{{'projectText' | translate}} Cancelled: {{cancelledUser?.firstName}} {{cancelledUser?.lastName}} <span> - {{jobManage.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>
                    <p *ngIf="!!cancelledPage " style="width: fit-content;" class="text small my-2">{{'projectText' | translate}} Cancelled: {{cancelledPage?.name}}  <span> - {{jobManage.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>
                  </ng-container>
                  <ng-container *ngIf="!isMessaging">
                    <p *ngIf="!cancelledPage" style="width: fit-content;" class="text small my-2">{{'projectText' | translate}} Cancelled: {{cancelledUser?.firstName}} {{cancelledUser?.lastName}} <span> - {{jobManage.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>
                    <p *ngIf="!!cancelledPage " style="width: fit-content;" class="text small my-2">{{'projectText' | translate}} Cancelled: {{cancelledPage?.name}}  <span> - {{jobManage.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>

            </ng-container>

                  <div class="bg-light text-secondary p-2 px-3 rounded-2" *ngIf="jobManage?.cancel.description" [innerHTML]="jobManage?.cancel.description" disabled></div>

              </div>
              <div *ngIf="cancelProject">
                <div class="d-sm-flex align-items-end">
                  <quill-editor name="editor" [styles]="editorConfig"  style="width:100%" [modules]="config" [(ngModel)]="cancelMessage" >
                  </quill-editor>
                  <button (click)="cancelProjectButton()" [disabled]="!cancelMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
                </div>
              </div>
                <hr>
                <div class="text-15 font-medium">Messages:</div>


                <ng-container *ngFor="let comment of jobManage?.comments">
                  <div class="d-flex mb-1" *ngIf="(comment.userID !== authService.userID) && (comment.pageID !== selectedPage?._id)">
                      <div class="flex-shrink-0 avatar avatar-xs me-2">
                        <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="">
                      </div>
                      <div class="flex-grow-1">
                        <div class="w-100">
                          <div class="d-flex flex-column align-items-start">
                            <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHtml]="comment.body"></div>
                            <div class="small my-2" *ngIf="project?.pageID"> {{appliedPage?.name}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                            <div class="small my-2" *ngIf="!project?.pageID">    {{userPage?.firstName}} {{userPage?.lastName}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

                          </div>
                        </div>
                      </div>
                    </div>
                      <!-- Chat message right -->
                      <div class="d-flex justify-content-end text-end mb-1" *ngIf="(comment.userID === authService?.userID) || (comment.pageID === selectedPage?._id)">
                          <div class="w-100">
                            <div class="d-flex flex-column align-items-end">
                              <div class="bg-primary text-white p-2 px-3 rounded-2" style="text-align: left;" [innerHtml]="comment.body"></div>
                              <ng-container>
                                <app-user-dialog-name  [isForMessages]="true" [commentId]="comment.userID" [comment]="comment"></app-user-dialog-name>
                              </ng-container>
                            </div>
                          </div>
                        </div>


              </ng-container>
              </div>

              <!-- Chat conversation END -->
            </div>

            <!-- Conversation item END -->
          </div>
        </div>
        <div class="card-footer">
          <div class="d-sm-flex align-items-end">
            <quill-editor name="editor" [styles]="editorConfig"  style="width:100%" [modules]="config" [(ngModel)]="writeMessage" >
            </quill-editor>
            <button class="btn btn-sm btn-danger-soft ms-sm-2"><i class="fa-solid fa-face-smile fs-6"></i></button>
            <button class="btn btn-sm btn-secondary-soft ms-2"><i class="fa-solid fa-paperclip fs-6"></i></button>
            <button (click)="addComment()" [disabled]="!writeMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</main>
