<main>
  <div class="container">
    <div class="row g-4">
      <div class="col-lg-3">

        <!-- Advanced filter responsive toggler START -->
        <!-- Divider -->
        <div class="d-flex align-items-center mb-4 d-lg-none">
          <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">Payment</span>
          </button>
        </div>
        <!-- Advanced filter responsive toggler END -->

        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <!-- Card START -->
              <div class="card w-100">
                <!-- Card body START -->
                <div class="card-body">

                  <!-- Side Nav START -->
                  <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" [routerLink]="'/' + user?.userUrl + '/payments/billing'"
                        [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                        [routerLinkActive]="['active']" data-bs-toggle="tab"> <img class="me-2 h-20px fa-fw"
                          src="assets/images/icon/person-outline-filled.svg" alt=""><span>Billing Details </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" [routerLink]="'/' + user?.userUrl + '/payments/transactions'"
                      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                      data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/notification-outlined-filled.svg"
                          alt=""><span>Transactions </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" [routerLink]="'/' + user?.userUrl + '/payments/purchased'"
                      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" data-bs-toggle="tab"> <img
                          class="me-2 h-20px fa-fw" src="assets/images/icon/shield-outline-filled.svg"
                          alt=""><span>Purchased </span></a>
                    </li>

                  </ul>
                  <!-- Side Nav END -->

                </div>
                <!-- Card body END -->
                <!-- Card footer -->

              </div>
              <!-- Card END -->
            </div>
            <!-- Offcanvas body -->



          </div>
        </nav>
      </div>
      <div class="col-md-8 col-lg-6 vstack gap-4">
        <div class="tab-pane" *ngIf="router.url.endsWith('/billing')">
          <div class="card">
            <div class="col-md-12 px-0 pt-4 pb-2" *ngIf="payment === 'isPage'">
              <div class="col-md-4">
                <img src="../../../assets/avatar.png" height="40px" width="40px">
                <span class="pl-2 pr-2">{{user?.firstName}}</span>
              </div>

            </div>
            <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto">
                  <li class="nav-item">
                    <a class="nav-link" (click)="setPayment()">Billing Details <span
                        class="sr-only">(current)</span></a>
                  </li>
                </ul>
              </div>
            </nav>

            <div>
              <div class="cards-body">
                <mat-card class="example-card">
                  <mat-card-header>

                    <mat-card-title>Credits</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div>
                      <button class="btn btn-primary">{{user?.payment?.coins}} Coins</button>
                      <br>
                      <button (click)="buyCoins()" class="btn btn-secondary mt-3">Buy Coins</button>
                      <br>


                    </div>
                  </mat-card-content>
                </mat-card>
                <mat-card class="example-card">
                  <mat-card-header>
                    <mat-card-title>
                      <div>
                        <span>Current Payment Method</span>
                        <span>Edit</span>
                      </div>
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div>
                      <i class="bi bi-plus-circle"></i>
                      <h3>
                        Add Card
                      </h3>
                    </div>
                  </mat-card-content>
                </mat-card>
                <span>1 Coins = 1 €</span>
              </div>
              <app-smart-payments-table [data]="user?.payment?.history" (print)="goForPrint($event)"> </app-smart-payments-table>

            </div>


          </div>
        </div>
        <div class="tab-pane" *ngIf="router.url.endsWith('/transactions')">
          <div class="card">
            <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto">
                  <li class="nav-item">
                    <a class="nav-link" (click)="setPayment()">Transactions<span
                        class="sr-only">(current)</span></a>
                  </li>
                </ul>
              </div>
            </nav>

            <div style="display: flex;
          flex-direction: row;
          justify-content: space-between;">

              <div style="display: flex;">

                <mat-form-field color="accent" appearance="fill" style="margin-right: 1rem;">
                  <mat-label>Start Date</mat-label>
                  <input matInput [matDatepicker]="picker1">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 color="primary"></mat-datepicker>
                </mat-form-field>

                <mat-form-field color="accent" appearance="fill">
                  <mat-label>End Date</mat-label>
                  <input matInput [matDatepicker]="picker2">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="fill">
                  <mat-label>Sort by</mat-label>
                  <mat-select [(value)]="selected">
                    <mat-option value="option1">Option 1</mat-option>
                    <mat-option value="option2">Option 2</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="mat-elevation-z8">

              <app-smart-payments-table [data]="user?.payment?.transactions" [reference]="true" [status]="true" (print)="goForPrint($event)"></app-smart-payments-table>

            </div>
          </div>
        </div>
        <div class="tab-pane" *ngIf="router.url.endsWith('/purchased')">
          <div class="card">
            <div class="col-md-12 px-0 pt-4 pb-2" *ngIf="payment === 'isPage'">
              <div class="col-md-4">
                <img src="../../../assets/avatar.png" height="40px" width="40px">
                <span class="pl-2 pr-2">{{user?.firstName}}</span>
              </div>

            </div>
            <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto">
                  <li class="nav-item">
                    <a class="nav-link" (click)="setPayment()">Purchased <span
                        class="sr-only">(current)</span></a>
                  </li>
                </ul>
              </div>
            </nav>

            <div>
              <div class="cards-body">
                <app-price-card [data]="user?.payment?.Job" [name]="'Job'" ></app-price-card>
                <app-price-card [data]="user?.payment?.Project" [name]="'Project'"></app-price-card>
              </div>

            </div>


          </div>
        </div>


      </div>
    </div>
  </div>
</main>
