import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ReplaySubject } from "rxjs";
import { first, map, shareReplay, take, tap } from "rxjs/operators";
import { environment } from "src/assets/environments/environment";
import { Model } from "../interfaces/model";
import { User, UserUser } from "../interfaces/user.model";
import { AuthService } from "./auth.service";
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class UserService {
  public baseUrl = environment.backend;
  public baseUrlXY = environment.backendXY;
  private data = new ReplaySubject<any>(1);
  private users = new ReplaySubject<any>(1);
  private userCV = new ReplaySubject<any>(1);
  private userData = new ReplaySubject<any>(1);
  private ignoredUserJobs = new ReplaySubject<any>(1);
  private savedUserJobs = new ReplaySubject<any>(1);
  private appliedUserJobs = new ReplaySubject<any>(1);
  private savedUserProjects = new ReplaySubject<any>(1);
  private ignoredUserProjects = new ReplaySubject<any>(1);
  private userMessages$ = new ReplaySubject<any>(1);
  private userJobs$ = new ReplaySubject<any>(1);
  private userAuth = new ReplaySubject<any>(1);
  private _getUserAppliedJobs: Observable<User[]>;


  private _dataListSource: BehaviorSubject<any> = new BehaviorSubject({});
  dataList: Observable<any> = this._dataListSource.asObservable().pipe(distinctUntilChanged());

  public  _pageListSource: BehaviorSubject<any> = new BehaviorSubject({});
  pageList: Observable<any> = this._pageListSource.asObservable().pipe(distinctUntilChanged());

  private _loadUsers: BehaviorSubject<any> = new BehaviorSubject({});
  loadUsers: Observable<any> = this._loadUsers.asObservable().pipe(distinctUntilChanged());


  private _loggedUser: BehaviorSubject<any> = new BehaviorSubject({});
  userList: Observable<any> = this._loggedUser.asObservable().pipe(distinctUntilChanged());



  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    })
  };


  constructor(private http: HttpClient, private authService: AuthService) { }

  public getUsers() {
    const id = this.authService.userID;
    return this.http.post(`${this.baseUrl}/users`, JSON.stringify({ id: id }),
      this.httpOptions).subscribe(res => this.users.next(res));
  }

  public showUsers() {
    return this.users.asObservable();
  }
  public userModel(): Observable<User[]> {
    return this.users.asObservable();
  }
  public userDataModel(): Observable<User> {
    return this.data.asObservable();
  }
  public user(): Observable<User> {
    return this.userData.asObservable();
  }

  public ignoredJobs(): Observable<User> {
    return this.ignoredUserJobs.asObservable();
  }
  public savedJobs(): Observable<User> {
    return this.savedUserJobs.asObservable();
  }
  public appliedJobs(): Observable<User> {
    return this.appliedUserJobs.asObservable();
  }

  public savedProjects(): Observable<User> {
    return this.savedUserProjects.asObservable();
  }
  public ignoredProjects(): Observable<User> {
    return this.ignoredUserProjects.asObservable();
  }
  public userMessages(): Observable<User> {
    return this.userMessages$.asObservable();
  }
  public userJobs(): Observable<User> {
    return this.userJobs$.asObservable();
  }
  public autUser(): Observable<User> {
    return this.userData.asObservable();
  }
  public authUserCV(): Observable<Model> {
    return this.userCV.asObservable();
  }
  public getUser(userID) {
    if (!!userID) {
      this.http.get(`${this.baseUrl}/user/${userID}`).subscribe(res => this.data.next(res));

    }
  }

  public getUserEM(userID): Observable<any> {
    if (!!userID) {
     return this.http.get(`${this.baseUrlXY}/userEmea/${userID}`);

    }
  }


  public getUserData(userId): Observable<any> {
    if (!!userId) {
      return this.http.get(`${this.baseUrlXY}/user-profile/${userId}`).pipe(
        tap(res => this.userData.next(res))).pipe(shareReplay(1));
    }
  }

  public getUserContactData(userId): Observable<any> {
    if (!!userId) {
      return this.http.get(`${this.baseUrlXY}/user-profile/${userId}`);
    }
  }

  public getUserOnce(userId): Observable<any> {
    if (!!userId) {
      return this.http.get(`${this.baseUrlXY}/userLoad/${userId}`);
    }
  }

  public getJobsAndProjects(userId): Observable<any> {
    if (!!userId) {
      return this.http.get(`${this.baseUrl}/getJobsAndProjects/${userId}`);
    }
  }



  public updateUserForPrivacy(userId, user: User) {
    const api = `${this.baseUrl}/userForPrivacy/${userId}`
    this.http.put<any>(api, user).pipe(take(1)).subscribe((res) => {
    })
  }

  public showNotifications(userId): Observable<User[]> {
    if (!!userId) {
      return this.http.get<User[]>(`${this.baseUrlXY}/notifications/${userId}`).pipe(shareReplay(1));
    }
  }

  public showFollowers(userId): Observable<User[]> {
    if (!!userId) {
      return this.http.get<User[]>(`${this.baseUrlXY}/followers/${userId}`).pipe(first());
    }
  }

  public showFollowing(userId): Observable<User[]> {
    if (!!userId) {
      return this.http.get<User[]>(`${this.baseUrl}/userFollowing/${userId}`).pipe(shareReplay(1));
    }
  }
  public showContacts(userId): Observable<User[]> {
    if (!!userId) {
      return this.http.get<User[]>(`${this.baseUrl}/userContacts/${userId}`).pipe(shareReplay(1));
    }
  }

  public showBlocked(userId): Observable<User[]> {
    if (!!userId) {
      return this.http.get<User[]>(`${this.baseUrl}/userBlocked/${userId}`).pipe(shareReplay(1));
    }
  }




  public search(name: string): Observable<any> {
    return this.http.get<User[]>(`${this.baseUrlXY}/findUsers/${name}`).pipe(map(res => {
      this._loadUsers.next(res);
    }))
  }

  patchUser(userId, user: User) {
    const api = `${this.baseUrl}/user/${userId}`
    this.http.patch<any>(api, user).pipe(take(1)).subscribe((res) => {
      this.userData.next(res);
    })
  }

  getLoggedUser(id): Observable<any> {
    if (!!id) {
      return this.http.get<any>(`${this.baseUrlXY}/loggedIN/${id}`).pipe(map(res => {
        this._loggedUser.next(res);
      }));
    }
  }

  getProjectList(id): Observable<any> {
    if(!!id) {
      return this.http.get<any>(`${this.baseUrlXY}/projectList/${id}`)
    }
  }

  getJobList(id): Observable<any> {
    if(!!id) {
      return this.http.get<any>(`${this.baseUrlXY}/jobList/${id}`)
    }
  }

  getMessageList(id): Observable<any> {
    if(!!id) {
      return this.http.get<any>(`${this.baseUrlXY}/messageList/${id}`)
    }
  }

  createJobList(): Observable<any> {
    const params = new HttpParams().append('authUser', this.authService.userID);
      return this.http.post(`${this.baseUrlXY}/createJobList`, params)
  }

  createProjectList(): Observable<any> {
    const params = new HttpParams().append('authUser', this.authService.userID);
    return this.http.post(`${this.baseUrlXY}/createProjectList`, params)

  }

  checkUser(id) {
    const params = new HttpParams().append('authUser', this.authService.userID).append("userID", id);
    if (!!id) {
      return this.http.post<any>(`${this.baseUrlXY}/userCheck`, params );
    }
  }

  checkUserPrivacy(id) {
    if (!!id) {
      return this.http.get<any>(`${this.baseUrlXY}/userPrivacyCheck/${id}`);
    }
  }

  getMyProjects(id) {
    if (!!id) {
      return this.http.get<any>(`${this.baseUrlXY}/findYourProjects/${id}`);
    }
  }

  getMyJobs(id) {
    if (!!id) {
      return this.http.get<any>(`${this.baseUrlXY}/findYourJobs/${id}`);
    }

  }


  public getUserCVwithoutLogin(userID): Observable<any> {
    return this.http.get(`${this.baseUrl}/userCVwithoutLogin/${userID}`);
  }

  public loadPages(userID): Observable<any> {
    return this.http.get<any>(`${this.baseUrlXY}/loadPages/${userID}`).pipe(map(res => {
      this._pageListSource.next(res);
    }));
  }

  public getUserCV(userID): Observable<any> {
    if (!!userID) {
      return this.http.get<any>(`${this.baseUrlXY}/loadCV/${userID}`).pipe(map(res => {
        this._dataListSource.next(res);
      }));
    }
  }


  public updateCV(id, body) {
    const api = `${this.baseUrlXY}/updateCV/${id}`
    this.http.put<any>(api, body).pipe(take(1)).subscribe((res) => {
      this._dataListSource.next(res);
    })
  }

  public updateUser(userId, user: User | UserUser) {
    const api = `${this.baseUrlXY}/user/${userId}`
    this.http.put<any>(api, user).pipe(take(1)).subscribe((res) => {
      this._dataListSource.next(res);
    })
  }

  public updateFollowing(id, data) {
    const api = `${this.baseUrlXY}/follow/${id}`
    this.http.put<any>(api, data).subscribe();
  }

  public updateuUser(userId, user: UserUser | User) {
    const api = `${this.baseUrlXY}/userEm/${userId}`
    this.http.put<any>(api, user).pipe(take(1)).subscribe();
  }

public updateProjectList(id, data) {
  const api = `${this.baseUrlXY}/projectList/${id}`
  this.http.put<any>(api, data).pipe(take(1)).subscribe();
}
public updateJobList(id, data) {
  const api = `${this.baseUrlXY}/jobList/${id}`
  this.http.put<any>(api, data).pipe(take(1)).subscribe();
}

public updateMessageList(id, data) {
  const api = `${this.baseUrlXY}/messageList/${id}`
  this.http.put<any>(api, data).pipe(take(1)).subscribe();
}

  public getUserProfile(userID): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams().append('param', this.authService.userID);
    return this.http.get(`${this.baseUrl}/userProfile/${userID}`, { headers, params });
  }
  public getUserProfileForSearch(userID): Observable<any> {
    return this.http.get(`${this.baseUrl}/userSearchProfile/${userID}`);
  }

  myFollowers(userID): Observable<any> {
    return this.http.get(`${this.baseUrl}/followers/${userID}`).pipe(shareReplay(1));

  }

  public getUserIgnoredJobs() {
    this.http.get(`${this.baseUrl}/findIgnoredJobs/${this.authService.userID}`).subscribe(res => this.ignoredUserJobs.next(res));;
  }

  getCustomers(): Observable<User[]> {
    return this._getUserAppliedJobs;
  }
  public getSavedJobs() {
    this.http.get(`${this.baseUrl}/findSavedJobs/${this.authService.userID}`).subscribe(res => this.savedUserJobs.next(res));;
  }

  public getAppliedJobs() {
    this.http.get(`${this.baseUrl}/appliedJobs/${this.authService.userID}`).subscribe(res => this.appliedUserJobs.next(res));
  }
  public getMessages(): Observable<any> {
    return this.http.get(`${this.baseUrl}/userJobMessages/${this.authService.userID}`);
  }

  public getSavedProjects() {
    this.http.get(`${this.baseUrl}/findSavedProjects/${this.authService.userID}`).subscribe(res => this.savedUserProjects.next(res));;
  }
  public getIgnoredProjects() {
    this.http.get(`${this.baseUrl}/findIgnoredProjects/${this.authService.userID}`).subscribe(res => this.ignoredUserProjects.next(res));;
  }



  getTestAppliedJobs() {
    this._getUserAppliedJobs = this.http.get<User[]>(`${this.baseUrl}/appliedJobs/${this.authService.userID}`).pipe(shareReplay());
    this._getUserAppliedJobs.subscribe(t => console.log(t, "  this.userService.getTestAppliedJobs()"))

  }

  public getUserJobs() {
    this.http.get(`${this.baseUrl}/jobs/${this.authService.userID}`).subscribe(res => this.userJobs$.next(res));;
  }

  public getUserFollowing(userID): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams().append('param', this.authService.userID);
    return this.http.get(`${this.baseUrl}/userFollowing/${userID}`, { headers, params });
  }
  public getUserFollowers(userID): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams().append('param', this.authService.userID);
    return this.http.get(`${this.baseUrl}/userFollowers/${userID}`, { headers, params });
  }
  public getUserContacts(userID): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams().append('param', this.authService.userID);
    return this.http.get(`${this.baseUrl}/userContacts/${userID}`, { headers, params });
  }



  public getUserContact(userId): Observable<any> {
    const api = `${this.baseUrl}/user-friends`
    return this.http.post(api, JSON.stringify({ friendId: userId }), this.httpOptions).pipe(
      map((response: User) => {
        console.log(response);
        return response;
      },
        (err) => {
          throw err;
        })
    )
  }

  public searchForJobsAndProjects(id): Observable<any> {
    const api = `${this.baseUrl}/searchJobsAndProject`;
    const params = new HttpParams().append('jobID', id);
    return this.http.get(`${api}`, { params });
  }

  public getUsersSearch(id): Observable<any> {
    const api = `${this.baseUrlXY}/users-search`;
    const params = new HttpParams().append('jobID', id);
    return this.http.get(`${api}`, { params });
  }

  public userDataLogin(data) {
    this._dataListSource.next(data);
  }
}
