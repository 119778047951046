import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { tns } from 'tiny-slider';
import { Model } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { ImageService } from '../shared/services/image.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-main-home',
  templateUrl: './main-home.component.html',
  styleUrls: ['./main-home.component.scss']
})
export class MainHomeComponent implements OnInit, AfterViewInit {

  editShortcut = false;
  public model$: Observable<Model>;
  public model = new  Model();
  private subscriptions: Subscription;
  public currentUser: User;
  public image;
  public hasPhoto;
  public getFirstName;
  public getLastName;
  public userUrl;
public title;
public userList$: Observable<User>;
public loggedUser: Observable<any>;

  constructor(public authService: AuthService,
    public imageService: ImageService,
    private router: Router,
    private sendData?: SenderService,
    private route?:ActivatedRoute,
    private userService?: UserService) { }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.userList$ = this.authService.userList;
      this.loggedUser = this.userService.userList;
      this.loggedUser.subscribe(res => {
        this.currentUser = res.user
      })
    /*  this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
        if (res) {
          this.currentUser = res;
          if (this.currentUser.userUrl) {
             this.userUrl = this.currentUser.userUrl;
          } else {
            this.userUrl = this.currentUser._id;
          }
        }
      }); */

    /* this.userService.getJobsAndProjects(this.authService.userID).pipe(take(1)).subscribe(res => {
        console.log(res, "rfrfrfr")
      }) */
  }
  }

  cv() {
    this.userService.getUserCV(this.currentUser._id).subscribe();
  }


  navigateToUser() {
    this.userService.getUserCV(this.currentUser._id).subscribe();
    setTimeout(() => {
      localStorage.setItem("userSearchID", this.currentUser._id)
      const test = [this.currentUser, "outside"];
      this.sendData.send(test);
      this.router.navigate([this.currentUser.userUrl, "start" ],{relativeTo:this.route});
    }, 200);

    }


  navigateToPayment() {
    localStorage.setItem("payment", "isUser");
    this.sendData.paymentDataVisibility(this.currentUser);
    this.router.navigateByUrl(this.currentUser?.userUrl + '/payments/billing'.replace(this.title, 'isUser'));
  }

  removePageSearchJob() {
    localStorage.removeItem("isPageSearchJob")
  }

}
